import { AxiosRequestConfig } from 'axios';
import { FindAddressPath, FindAddressVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { AddressFinderApiResponse } from './models';

export const findAddress = (
  version: FindAddressVersion,
  moniker: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<AddressFinderApiResponse>(
    `${FindAddressPath[version]}/${moniker}`
  );
};
