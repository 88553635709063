import { initialState } from "../../../Reducers/iJoin/PropertyRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { updateProperty } from "../../../Actions/iJoin/PropertyActions";
import { pair } from "../../utils";
import { SITUATION_NOT_MOVING } from "../../../../Config/Constants";

interface MoveInfo {
  IsMovingHouse: boolean;
  AccountStart?: string;
  MoveInDate?: string;
  MoveOutDate?: string;
  MoveFromAddress?: string;
  Situation?: string;
  SituationOtherDetails: string;
}

function updateMoveInfo(info: Partial<MoveInfo>) {
  return updateProperty({ MoveInfo: info });
}

export function getMoveInfo(state): MoveInfo {
  return (state.Property || initialState).MoveInfo || initialState.MoveInfo;
}

export function useMoveInfoSelector() {
  return useSelector(getMoveInfo);
}

export function getMoveInfoIsMovingHouse(state) {
  return getMoveInfo(state).IsMovingHouse;
}

export function useMoveInfoIsMovingHouseSelector() {
  return useSelector(getMoveInfoIsMovingHouse);
}

export function useMoveInfoIsMovingHouseSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (IsMovingHouse: MoveInfo["IsMovingHouse"]) =>
      dispatch(updateMoveInfo({ IsMovingHouse })),
    [dispatch]
  );
}

export function useMoveInfoIsMovingHouse() {
  const value = useMoveInfoIsMovingHouseSelector();
  const setter = useMoveInfoIsMovingHouseSetter();
  return pair(value, setter);
}

export function getMoveInfoAccountStart(state) {
  return getMoveInfo(state).AccountStart;
}

export function useMoveInfoAccountStartSelector() {
  return useSelector(getMoveInfoAccountStart);
}

export function useMoveInfoAccountStartSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AccountStart: MoveInfo["AccountStart"]) =>
      dispatch(updateMoveInfo({ AccountStart })),
    [dispatch]
  );
}

export function useMoveInfoAccountStart() {
  const value = useMoveInfoAccountStartSelector();
  const setter = useMoveInfoAccountStartSetter();
  return pair(value, setter);
}

export function getMoveInfoMoveInDate(state) {
  return getMoveInfo(state).MoveInDate;
}

export function useMoveInfoMoveInDateSelector() {
  return useSelector(getMoveInfoMoveInDate);
}

export function useMoveInfoMoveInDateSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MoveInDate: MoveInfo["MoveInDate"]) =>
      dispatch(updateMoveInfo({ MoveInDate })),
    [dispatch]
  );
}

export function useMoveInfoMoveInDate() {
  const value = useMoveInfoMoveInDateSelector();
  const setter = useMoveInfoMoveInDateSetter();
  return pair(value, setter);
}

export function getMoveInfoMoveOutDate(state) {
  return getMoveInfo(state).MoveOutDate;
}

export function useMoveInfoMoveOutDateSelector() {
  return useSelector(getMoveInfoMoveOutDate);
}

export function useMoveInfoMoveOutDateSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MoveOutDate: MoveInfo["MoveOutDate"]) =>
      dispatch(updateMoveInfo({ MoveOutDate })),
    [dispatch]
  );
}

export function useMoveInfoMoveOutDate() {
  const value = useMoveInfoMoveOutDateSelector();
  const setter = useMoveInfoMoveOutDateSetter();
  return pair(value, setter);
}

export function getMoveInfoMoveFromAddress(state) {
  return getMoveInfo(state).MoveFromAddress;
}

export function useMoveInfoMoveFromAddressSelector() {
  return useSelector(getMoveInfoMoveFromAddress);
}

export function useMoveInfoMoveFromAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MoveFromAddress: MoveInfo["MoveFromAddress"]) =>
      dispatch(updateMoveInfo({ MoveFromAddress })),
    [dispatch]
  );
}

export function useMoveInfoMoveFromAddress() {
  const value = useMoveInfoMoveFromAddressSelector();
  const setter = useMoveInfoMoveFromAddressSetter();
  return pair(value, setter);
}

export function getMoveInfoSituation(state) {
  return getMoveInfo(state).Situation;
}

export function useMoveInfoSituationSelector() {
  return useSelector(getMoveInfoSituation);
}

export function useMoveInfoSituationSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Situation: MoveInfo["Situation"]) => {
      dispatch(updateMoveInfo({ Situation }));
      if (Situation === SITUATION_NOT_MOVING) {
        dispatch(
          updateMoveInfo({
            AccountStart: undefined,
          })
        );
      }
    },
    [dispatch]
  );
}

export function useMoveInfoSituation() {
  const value = useMoveInfoSituationSelector();
  const setter = useMoveInfoSituationSetter();
  return pair(value, setter);
}

export function getMoveInfoSituationOtherDetails(state) {
  return getMoveInfo(state).SituationOtherDetails;
}

export function useMoveInfoSituationOtherDetailsSelector() {
  return useSelector(getMoveInfoSituationOtherDetails);
}

export function useMoveInfoSituationOtherDetailsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (SituationOtherDetails: MoveInfo["SituationOtherDetails"]) =>
      dispatch(updateMoveInfo({ SituationOtherDetails })),
    [dispatch]
  );
}

export function useMoveInfoSituationOtherDetails() {
  const value = useMoveInfoSituationOtherDetailsSelector();
  const setter = useMoveInfoSituationOtherDetailsSetter();
  return pair(value, setter);
}
