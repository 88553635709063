import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { forgotPassword, ForgotPasswordVersion } from '@contact/data-access';

type TData = void;
type TError = unknown;
type TVariables = {
  username: string;
};

export const useForgotPassword = (
  version: ForgotPasswordVersion,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables>
) =>
  useMutation<TData, TError, TVariables>(async ({ username }) => {
    const { data } = await forgotPassword(version, username, axiosOptions);
    return data;
  }, mutationOptions);
