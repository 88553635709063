import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getPanelPlans,
  GetPanelPlansPath,
  GetPanelPlansVersion,
  PanelPlansApiResponse,
} from '@contact/data-access';

export const usePanelPlans = (
  version: GetPanelPlansVersion,
  token: string,
  accountId: string,
  config?: AxiosRequestConfig,
  options?: UseQueryOptions<PanelPlansApiResponse>
) =>
  useQuery<PanelPlansApiResponse>(
    [GetPanelPlansPath[version], token, accountId],
    async () => {
      const { data } = await getPanelPlans(version, token, accountId, config);
      return data;
    },
    {
      ...options,
      enabled: !!(token && accountId),
    }
  );
