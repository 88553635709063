import * as actionTypes from "../actionTypes";

export const updateElectricityRates = (payload) => {
  return {
    type: actionTypes.UPDATE_ELEC_RATES,
    payload,
  };
};

export const updateGasRates = (payload) => {
  return {
    type: actionTypes.UPDATE_GAS_RATES,
    payload,
  };
};

export const updateBroadbandRates = (payload) => {
  return {
    type: actionTypes.UPDATE_BB_RATES,
    payload,
  };
};
