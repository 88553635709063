import { useMutation, UseMutationOptions } from 'react-query';
import {
  unregisterFromPushNotifications,
  PushNotificationUnregisterParams,
  PushNotificationUnregisterVersion,
} from '@contact/data-access';
import axios from 'axios';

export interface UnregisterFromPushNotificationsData {
  wasRegistered: boolean;
}

export const useUnregisterFromPushNotifications = (
  version: PushNotificationUnregisterVersion,
  options?: Omit<
    UseMutationOptions<
      UnregisterFromPushNotificationsData,
      unknown,
      PushNotificationUnregisterParams
    >,
    'mutationFn'
  >
) => {
  return useMutation(
    async (
      params: PushNotificationUnregisterParams
    ): Promise<UnregisterFromPushNotificationsData> => {
      try {
        await unregisterFromPushNotifications(version, params);
        // Unregistered
        return { wasRegistered: true };
      } catch (error: unknown) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          // Was already unregistrered
          return { wasRegistered: false };
        } else {
          throw error;
        }
      }
    },
    options
  );
};
