import { useQuery } from 'react-query';
import {
  UserVoiceApiResponse,
  getUserVoiceToken,
  GetUserVoiceVersion,
  GetUserVoicePath,
} from '@contact/data-access';

export const useUserVoice = (
  version: GetUserVoiceVersion,
  token: string,
  bp: string
) => {
  return useQuery<UserVoiceApiResponse>(
    [GetUserVoicePath[version], token, bp],
    async () => {
      const { data } = await getUserVoiceToken(version, token, bp);
      return data;
    },
    {
      enabled: !!(token && bp),
    }
  );
};
