import { combineReducers } from "redux";
import Auth from "../AuthRedux";
import Login from "./LoginRedux";
import UserProfile from "./UserProfileRedux";
import Customer from "../iJoin/CustomerRedux";
import LpgInfo from "../iJoin/BottledGasRedux";
import Payment from "../iJoin/PaymentRedux";
import Services from "../iJoin/ServicesRedux";
import Property from "../iJoin/PropertyRedux";
import Confirmation from "../iJoin/ConfirmationRedux";
import BroadbandInfo from "../iJoin/BroadbandRedux";
import Readonly from "../iJoin/ReadonlyRedux";

export default combineReducers({
  Auth,
  Login,
  UserProfile,
  Customer,
  LpgInfo,
  Payment,
  Services,
  Property,
  Confirmation,
  BroadbandInfo,
  Readonly,
});
