import { initialState } from "../../Reducers/CSRAgent/AddAuthorizedPersonRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { pair } from "../utils";
import { updateAuthorizedPerson } from "../../Actions/CSRAgent/AuthorizedPersonAction";
import {
  NonContractualRelationshipTypeDesc,
  NonContractualRelationshipTypeId,
} from "@contact/api-account-access";

export interface AuthorizedPerson {
  Id: string;
  FirstName: string;
  LastName: string;
  RelationshipTypeId: NonContractualRelationshipTypeId;
  RelationshipTypeDesc: NonContractualRelationshipTypeDesc;
  EmailAddress?: string;
  AssociatedBa?: string;
}

type AuthorizedPersonInfo = AuthorizedPerson[];

export function getAuthorizedPersonInfo(state): AuthorizedPersonInfo {
  return state.AuthorizedPersonInfo || initialState;
}

export function useAuthorizedPersonInfoSelector() {
  return useSelector(getAuthorizedPersonInfo);
}

export function useAuthorizedPersonInfoSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AuthorizedPersonInfo: AuthorizedPersonInfo) =>
      dispatch(updateAuthorizedPerson(AuthorizedPersonInfo)),
    [dispatch]
  );
}

export function useAuthorizedPersonInfo() {
  const value = useAuthorizedPersonInfoSelector();
  const setter = useAuthorizedPersonInfoSetter();
  return pair(value, setter);
}
