import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { ConsumptionApiResponse } from './models';
import { ConsumptionPath, ConsumptionVersion } from '..';

export const getConsumption = (
  version: ConsumptionVersion,
  token: string,
  xcsrfToken: string,
  contractId: string,
  interval: string,
  from: string,
  to: string,
  config?: AxiosRequestConfig
) => {
  const path = `${ConsumptionPath[version]}/${contractId}`;

  return baseInstance(config).post<ConsumptionApiResponse>(path, null, {
    params: {
      interval,
      from,
      to,
    },
    headers: {
      Authorization: token,
      'x-csrf-token': xcsrfToken,
    },
  });
};
