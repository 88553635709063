export const AddressDetailsFixture = {
  pxid: "2-.F.1W.L.6ww",
  a: "6 Andaman Grove, Khandallah, Wellington 6035",
  postal: "6 Andaman Grove, Khandallah, Wellington 6035",
  aims_address_id: "361470",
  sufi: 361470,
  ta: "Wellington City",
  number: "6",
  x: "174.78038",
  y: "-41.242412",
  postcode: "6035",
  mailtown: "Wellington",
  post_suburb: "Khandallah",
  street: "Andaman Grove",
  street_name: "Andaman",
  street_type: "grove",
  city: "Wellington",
  suburb: "Khandallah",
  region: "Wellington Region",
  postal_line_1: "6 Andaman Grove",
  postal_line_2: "Khandallah",
  postal_line_3: "Wellington 6035",
  dpid: "1001043",
  aims_road_section_id: "85278",
  rural: false,
  meshblock: "2094400",
  success: true,
  id: "2-.F.1W.L.6ww",
  uuid: "e9e97e00-03dd-11e9-9bcf-b90fbca769a3",
};
