import * as React from "react";
import classNames from "classnames";

import "./Card.scss";

const Card = (props) => (
  <div name={props.name} className={classNames("card", props.className)}>
    {props.children}
  </div>
);

export default Card;
