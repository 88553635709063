import React, { useState, useEffect, useMemo, Fragment } from "react";
import { LayoutRow, LayoutColumn, Tabs, Modal, HTML } from "react-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./MyDetails.scss";
import Sidebar from "./Sidebar";
import {
  getHasBottledGas,
  getHasBroadband,
  getHasError,
  getIsBottleGasOnly,
} from "../../Utils/selectors";
import AboutYou from "../AboutYou";
import Property from "../Property";
import BottledGas from "../BottledGas";
import BroadbandOrder from "../BroadbandOrder";
import Payment from "../Payment";
import FinalStep from "../FinalStep";
import { MyDetails as Constants } from "../../Config/Constants";
import { redirect } from "../../Redux/Actions/Redirect";
import GoBack from "./GoBack";
import * as Analytics from "../../Utils/analytics";
// import { saveAuthData } from '../../Redux/Actions/iJoin/PlanActions';
import { SaveForLater } from "Containers/SaveForLater";
import {
  triggerCustomerInfo,
  triggerPropertyInfo,
} from "../../Redux/Actions/iJoin/AnalyticsActions";
import { useJourneyTypeSet } from "../../Redux/Hooks/CSRJoinJourney";

const ABOUT_YOU = "AboutYou";
const PROPERTY = "Property";
const BOTTLED_GAS = "BottledGas";
const BROADBAND_ORDER = "BroadbandOrder";
const PAYMENT = "Payment";
const FINAL_STEP = "FinalStep";

const sections = [
  { id: ABOUT_YOU, Component: AboutYou },
  { id: PROPERTY, Component: Property },
  { id: BOTTLED_GAS, Component: BottledGas },
  { id: BROADBAND_ORDER, Component: BroadbandOrder },
  { id: PAYMENT, Component: Payment },
  { id: FINAL_STEP, Component: FinalStep },
];

const MyDetails = (props) => {
  useJourneyTypeSet(Constants.journeyType);
  const [cardOpen, setCardOpen] = useState(0);
  const [isModalActive, setIsModalActive] = useState(false);
  const [userinteracted, setUserinteracted] = useState(false);
  document.title = Constants.pageTitle;

  // useEffect(() => {
  //   if (!props.sessionId) {
  // 		const session = localStorage.getItem('session');
  // 			const auth = {
  // 				sessionID: JSON.parse(session)?.sessionID,
  // 				deleteAfter: JSON.parse(session)?.deleteAfter,
  // 			};
  // 			props.saveSessionID(auth);
  // 	}
  //   // eslint-disable-next-line
  // }, []); //TODO bring back when find plan user journey implemented

  useEffect(() => {
    setIsModalActive(props.hasError);
  }, [props.hasError]);

  useEffect(() => {
    Analytics.triggerMyDetailsJourney("Join");
  }, []);

  const cards = useMemo(() => {
    return [
      ABOUT_YOU,
      PROPERTY,
      props.hasBottledGas && BOTTLED_GAS,
      props.hasBroadband && BROADBAND_ORDER,
      !props.isBottledGasOnly && PAYMENT,
      FINAL_STEP,
    ].filter(Boolean);
  }, [props.hasBottledGas, props.hasBroadband, props.isBottledGasOnly]);

  const [completedCards, setCompletedCards] = useState(() =>
    cards.map(() => false)
  );

  useEffect(() => {
    const index = completedCards.findIndex((element) => !element);
    setCardOpen(index);
    if (userinteracted && index >= 0 && props.apiPlan && props.services) {
      Analytics.triggerCheckoutStep(
        props.apiPlan,
        props.services,
        cards[index]
      );
    }
    scrollToCard(cards[index]);
  }, [completedCards, props.apiPlan, props.services, cards, userinteracted]);

  useEffect(() => {
    setCompletedCards(cards.map(() => false));
  }, [cards]);

  const isCardOpen = (card) => {
    const index = cards.findIndex((el) => el === card);
    return cardOpen === index;
  };

  const isCardDisabled = (card) => {
    const index = cards.findIndex((el) => el === card);
    return cardOpen < index;
  };

  const scrollToCard = (name) => {
    const element = document.getElementById(name);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 400);
    }
  };

  const triggerOnCompletedAnalytics = (card) => {
    if (card === ABOUT_YOU) {
      props.triggerCustomerInfo();
    } else if (card === PROPERTY) {
      props.triggerPropertyInfo();
    }
  };

  const handleCompleted = (card) => {
    const index = cards.findIndex((el) => el === card);
    if (!props.hasError) {
      setCompletedCards(
        completedCards.map((el, position) => (position === index ? true : el))
      );
      triggerOnCompletedAnalytics(card);
    } else {
      setIsModalActive(true);
    }
  };

  const handleCardClick = (card) => {
    const index = cards.findIndex((el) => el === card);
    setCardOpen(index === cardOpen ? index + 0.5 : index);
    if (index !== cardOpen) {
      Analytics.triggerCheckoutStep(props.apiPlan, props.services, card);
    }
  };

  const getCards = sections
    .filter(({ id }) => cards.includes(id))
    .map(({ id, Component }) => (
      <Component
        key={id}
        onClick={() => handleCompleted(id)}
        isCurrentCardOpen={isCardOpen(id)}
        isCardDisabled={isCardDisabled(id)}
        onCardClick={() => (id === FINAL_STEP ? null : handleCardClick(id))}
        triggerFirstinteraction={() => setUserinteracted(true)}
      />
    ));

  const staticTabList = [{ header: Constants.header, content: getCards }];

  return (
    <Fragment>
      {props.submitted && (
        <Redirect to="/residential/my-details/join-success" />
      )}
      {props.hasError && (
        <Modal
          isActive={isModalActive}
          className="myDetails_modal"
          handlePrimaryClick={() => {
            props.redirectOnError();
            setIsModalActive(!isModalActive);
          }}
          content={<HTML html={Constants.joinErrorModalContent.content} />}
          buttonTextPrimary={Constants.joinErrorModalContent.buttonLabel}
        />
      )}
      <LayoutRow
        reverseRow
        left={
          <div>
            <GoBack
              backToJourneyUrl={Constants.backToJourneyUrl}
              backToJourneyText={Constants.backToJourneyText}
            />
            <Tabs tabList={staticTabList} withOverflow />
            <div className="myDetails_saveforlater">
              <SaveForLater journeyType="JOIN" origin="CUSTOMER" />
            </div>
          </div>
        }
        right={<LayoutColumn hideBottom hideColumn top={<Sidebar />} />}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasBottledGas: getHasBottledGas(state),
  hasBroadband: getHasBroadband(state),
  hasError: getHasError(state),
  submitted: state.Auth.submitted,
  services: state.Services,
  apiPlan: state.Readonly.ApiPlan,
  isBottledGasOnly: getIsBottleGasOnly(state),
  // sessionId: state.Auth.sessionId,
});

const mapDispatchToProps = (dispatch) => ({
  redirectOnError: () => {
    if (Constants.redirectOnErrorUrl) {
      dispatch(redirect(Constants.redirectOnErrorUrl));
    }
  },
  // saveSessionID: (sessionId, deleteAfter) => {
  // 	dispatch(saveAuthData(sessionId, deleteAfter));
  // },
  triggerCustomerInfo: () => dispatch(triggerCustomerInfo()),
  triggerPropertyInfo: () => dispatch(triggerPropertyInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails);
