import React from "react";
import Button from "../Button";
import classNames from "classnames";

import "./ServiceButtons.scss";

const ServiceButtons = (props) => {
  const {
    addValue,
    isInList,
    setValidateItself,
    setHover,
    list,
    hover,
    setlist,
    className,
    buttonCustomStyle,
    buttons,
    isGas,
    servicesContent,
    narrow,
  } = props;

  const hasTooltip = (code) => {
    const service = servicesContent?.find(
      (item) => item.service === code && item.tooltip
    );
    return service?.tooltip;
  };

  const cls = classNames({
    // eslint-disable-next-line
    serviceButtons_buttonHolder: true,
    "serviceButtons_buttonHolder--gas": isGas,
    "serviceButtons_buttonHolder--narrow": narrow,
  });

  return (
    <div className="serviceButtons">
      {buttons.map((button, index) => (
        <div className={cls} key={index}>
          <Button
            name={button.code}
            type="button"
            icon={button.icon}
            className={classNames(
              "serviceButtons_buttonHolder_service-button",
              className,
              isInList(button.code, list) &&
                "serviceButtons_buttonHolder_service-button_active"
            )}
            text={button.name}
            handleClick={() => addValue(button.code, list, setlist)}
            darkBorder={!isInList(button.code, list)}
            primaryOnDark={isInList(button.code, list)}
            customStyle={{
              ...(buttonCustomStyle || {}),
              backgroundColor: isInList(button.code, list)
                ? hover === button.code
                  ? button.bgSelectedHover
                  : button.bgSelected
                : hover === button.code
                ? button.bgHover
                : button.bg,
            }}
            disabled={false}
            handleValidationClick={() => setValidateItself(true)}
            id={`${button.code}-button`}
            onMouseEnter={() => {
              setHover(button.code);
            }}
            onMouseLeave={() => {
              setHover(null);
            }}
          />
          {hasTooltip(button.code) && button.code === hover && (
            <div className="serviceButtons_buttonHolder_service-button_tooltip">
              {hasTooltip(button.code)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceButtons;
