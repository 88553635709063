import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  submitTrimester,
  SubmitTrimesterResponse,
  SubmitTrimesterParams,
  FourthTrimesterSubmitVersion,
  FourthTrimesterSubmitPath,
} from '@contact/data-access';

export const useTrimesterSubmit = (
  version: FourthTrimesterSubmitVersion,
  requestId: string,
  params: SubmitTrimesterParams,
  readyToCall: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<SubmitTrimesterResponse>(
    [FourthTrimesterSubmitPath[version], requestId, params],
    async () => {
      const { data } = await submitTrimester(
        version,
        requestId,
        params,
        options
      );
      return data;
    },
    {
      enabled: readyToCall,
    }
  );
};
