import { AxiosRequestConfig } from 'axios';
import { GasLookupPath, GasLookupVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { GasLookupApiResponse, GasLookupParams } from './models';

export const gasLookup = (
  version: GasLookupVersion,
  params: GasLookupParams,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<GasLookupApiResponse>(
    GasLookupPath[version],
    {
      params,
    }
  );
};
