import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { resetPassword, ResetPasswordVersion } from '@contact/data-access';

type TData = void;
type TError = unknown;
type TVariables = {
  activationKey: string;
  requestId: string;
  password: string;
};

export const useResetPassword = (
  version: ResetPasswordVersion,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables>
) =>
  useMutation<TData, TError, TVariables>(
    async ({ activationKey, requestId, password }) => {
      const { data } = await resetPassword(
        version,
        activationKey,
        requestId,
        password,
        axiosOptions
      );
      return data;
    },
    mutationOptions
  );
