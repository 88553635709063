export type MeterReadType = 'Estimated' | 'Actual' | 'CustomerSupplied';
export type InvoiceReadType = 'Estimated' | 'Actual' | 'CustomerSupplied';
export type PaymentScheme = 'SmoothPay' | 'ControlPay';
export type PaymentSchemeFrequency = 'Weekly' | 'Fortnightly' | 'Monthly';

export interface InteractiveBillHeaderDetail extends Record<string, unknown> {
  FileName?: string;
  BusinessPartnerNumber: string;
  AccountNumber: string;
  InvoiceNumber: string;
  PrintDate: string;
  DueDate: string;
  StartBillingPeriod: string;
  EndBillingPeriod: string;
  DirectDebit: boolean;
  FinalBill: boolean;
  OverlappingDueDates: boolean;
  InvoiceReadType?: InvoiceReadType;
  OverdueAmount?: string;
  PaymentScheme: boolean;
  PaymentSchemeType?: PaymentScheme;
  PaymentSchemeFrequency?: PaymentSchemeFrequency;
  PaymentSchemeAmount?: string;
  PaymentSchemeNextDue?: string;
  TotalAmount?: string;
  TotalAmountDueLessPromptPaymentDiscount?: string;
  TotalPromptPaymentDiscount?: string;
  CurrentAmount?: string;
  CustomerType?: string;
  NextBillDate?: string;
  CurrentChargesGST?: string;
  CurrentChargesImportGST?: string;
  InvoiceGST?: string;
  PromptPaymentDiscountGSTIncl?: string;
  PromptPaymentDiscountGSTExcl?: string;
  PromptPaymentDiscountGSTAmount?: string;
  OpeningBalance?: string;
  PreviousBalance?: string;
  TotalCurrentCharges?: string;
  CreationDate?: string;
  WriteOffsTotalInclGST?: string;
  WriteOffsTotalExclGST?: string;
  WriteOffsTotalGST?: string;
  BalanceTransfersInTotalInclGST?: string;
  BalanceTransfersInTotalExclGST?: string;
  BalanceTransfersOutTotalInclGST?: string;
  BalanceTransfersOutTotalExclGST?: string;
  PaymentsTotal?: string;
  ReversalsTotal?: string;
  ReversalsTotalGST?: string;
  TechnicalDetails?: unknown;
  DynamicPeriodControl?: boolean;
  PPDAdjustmentsTotalInclGST?: string;
  PPDAdjustmentsTotalExclGST?: string;
  PPDAdjustmentsTotalGST?: string;
}

// export interface InteractiveBillClassificationCharge {
//   ClassificationChargeName: string;
// }

export type InteractiveBillClassificationTypeElectricity = '001';
export type InteractiveBillClassificationTypeGas = '002';
export type InteractiveBillClassificationTypeRLPGDivision3 = '003';
export type InteractiveBillClassificationTypeSolar = '004';
export type InteractiveBillClassificationTypeBroadband = '005';
export type InteractiveBillClassificationTypeDiscounts = '006';
export type InteractiveBillClassificationTypeOtherChargesDebits = '007';
export type InteractiveBillClassificationTypeOtherChargesCredits = '008';
export type InteractiveBillClassificationTypeOtherSecurityDeposits = '012';
// export type InteractiveBillClassificationTypeOtherPayments = "009";
// export type InteractiveBillClassificationTypeOtherTransfers = "010";
// export type InteractiveBillClassificationTypeOtherReturns = "011";

export type InteractiveBillClassificationType =
  | InteractiveBillClassificationTypeElectricity
  | InteractiveBillClassificationTypeGas
  | InteractiveBillClassificationTypeRLPGDivision3
  | InteractiveBillClassificationTypeSolar
  | InteractiveBillClassificationTypeBroadband
  | InteractiveBillClassificationTypeDiscounts
  | InteractiveBillClassificationTypeOtherChargesDebits
  | InteractiveBillClassificationTypeOtherChargesCredits
  | InteractiveBillClassificationTypeOtherSecurityDeposits;
// | InteractiveBillClassificationTypeOtherPayments
// | InteractiveBillClassificationTypeOtherTransfers
// | InteractiveBillClassificationTypeOtherReturns

// export interface InteractiveBillClassification {
//   ClassificationName: never;
// }

export enum InteractiveBillClassificationTypeEnum {
  Electricity = '001',
  Gas = '002',
  RLPG = '003',
  Solar = '004',
  Broadband = '005',
  OtherChargesDebits = '007',
  OtherChargesCredits = '008',
}

// Order:
// 1. Electricity imported (Electricity)
// 2. Electricity exported (Solar)
// 3. Natural gas
// 4. Reticulated LPG
// 5. Broadband
// 6. Other charges
// 7. Prompt payment discount
export const InteractiveBillClassificationTypeEnumOrder: (
  | InteractiveBillClassificationTypeEnum
  | string
)[] = [
  InteractiveBillClassificationTypeEnum.Electricity,
  InteractiveBillClassificationTypeEnum.Solar,
  InteractiveBillClassificationTypeEnum.Gas,
  InteractiveBillClassificationTypeEnum.RLPG,
  InteractiveBillClassificationTypeEnum.Broadband,
  InteractiveBillClassificationTypeEnum.OtherChargesDebits,
  InteractiveBillClassificationTypeEnum.OtherChargesCredits,
];

export const InteractiveBillClassificationTypeMapping: Record<
  InteractiveBillClassificationType,
  string
> = {
  '001': 'Electricity',
  '002': 'Natural Gas',
  '003': 'Reticulated Gas',
  '004': 'Solar',
  '005': 'Broadband',
  '006': '',
  '007': '',
  '008': '',
  '012': '',
  // '009': '',
  // '010': '',
  // '011': '',
};

export interface InteractiveBillInstallation {
  Premise: string;
  Contract: string;
  Installation: string;
  ICP: string;
  BillingClass: string;
  RateCategory: string;
  ValidFrom: string;
  ValidTo: string;
  // TODO: Check if string or number
  Days: string;
  MeterReadingUnit: string;
  Product: string;
}

export interface InteractiveBillPremise {
  Premise: string;
  // TODO: Check if "AddressNumber" or "SAPAddressNumber"
  // AddressNumber: string;
  SAPAddressNumber: string;
  AddressLine1?: string;
  AddressLine2?: string;
  AddressLine3?: string;
  AddressLine4?: string;
  AddressLine5?: string;
  AddressLine6?: string;
  AddressSingleLine: string;
  PostCode: string;
  DualEnergy: boolean;
  Services: string;
  FinalBill: boolean;
}

export type InteractiveBillChargeTypeFixed = 'Fixed';
export type InteractiveBillChargeTypeVariable = 'Variable';
export type InteractiveBillChargeTypeDiscount = 'Discount';
export type InteractiveBillChargeTypePayment = 'Payment';
export type InteractiveBillChargeTypeOther = 'Other';
export type InteractiveBillChargeTypeCredit = 'Credit';
export type InteractiveBillChargeTypeTransfer = 'Transfer';
export type InteractiveBillChargeTypeWriteOff = 'WriteOff';
export type InteractiveBillChargeTypeReversal = 'Reversal';
export type InteractiveBillChargeTypePPDAdjustment = 'PPDAdjustment';
// TODO when we have template string support
export type InteractiveBillChargeTypeClassificationDetail = string; //`ClassificationDetail${InteractiveBillClassificationType}`;

export type InteractiveBillChargeType =
  | InteractiveBillChargeTypeFixed
  | InteractiveBillChargeTypeVariable
  | InteractiveBillChargeTypeDiscount
  | InteractiveBillChargeTypePayment
  | InteractiveBillChargeTypeOther
  | InteractiveBillChargeTypeCredit
  | InteractiveBillChargeTypeTransfer
  | InteractiveBillChargeTypeWriteOff
  | InteractiveBillChargeTypePPDAdjustment
  | InteractiveBillChargeTypeClassificationDetail;

export interface InteractiveBillChargeBase {
  ChargeType?: string;
  Contract?: string;
  Description?: string;
  AmountExclGST?: string;
  GSTAmount?: string;
  LineItemType?: string;
  DynamicPeriodControl?: boolean;
}

export interface InteractiveBillChargeFixed extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeFixed;
  Contract: string;
  PriceKey: string;
  LineItemType: string;
  Description: string;
  AmountExclGST?: string;
  CurrencyType: string;
  Days: string;
  Price: string;
  Consumption: string;
  DynamicPeriodControl?: boolean;
  UnitOfMeasure?: string;
}

export interface InteractiveBillChargeVariable
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeVariable;
  Contract: string;
  PriceKey: string;
  LineItemType: string;
  Description: string;
  AmountExclGST?: string;
  CurrencyType: string;
  Price: string;
  UnitOfMeasure: string;
  Consumption: string;
  ElectricityExport: boolean;
  DynamicPeriodControl?: boolean;
}

// TODO: Will Authority Levy Discount be marked somehow or we check by the name to show the tooltip
export interface InteractiveBillChargeDiscount
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeDiscount;
  Contract: string;
  LineItemType: string;
  Description: string;
  AmountExclGST?: string;
  CurrencyType: string;
  Factor: string;
  UnitOfMeasure: string;
}

export interface InteractiveBillChargePayment
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypePayment;
  DocumentNumber: string;
  Description: string;
  Amount: string;
  PostingDate: string;
  DocumentType: string;
  MainTransaction: string;
  SubTransaction: string;
  DocumentOrigin: string;
}

export interface InteractiveBillChargeOther extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeOther;
  DocumentNumber: string;
  Description: string;
  AmountExclGST?: string;
  AmountInclGST?: string;
  PostingDate: string;
}

export interface InteractiveBillChargeCredit extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeCredit;
  DocumentNumber: string;
  Description: string;
  AmountExclGST?: string;
  AmountInclGST?: string;
  PostingDate: string;
}

export interface InteractiveBillChargeBalanceTransfer
  extends InteractiveBillChargeBase {
  DocumentNumber: string;
  Description: string;
  AmountExclGST?: string;
  AmountInclGST?: string;
  PostingDate: string;
  DocumentDate: string;
  TransferOut: boolean;
  UnitOfMeasure?: string;
}

export interface InteractiveBillChargeWriteOff
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeWriteOff;
  DocumentNumber: string;
  Description: string;
  AmountExclGST?: string;
  AmountInclGST?: string;
  PostingDate: string;
  DocumentDate: string;
}

export interface InteractiveBillChargePreviousReversal
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypeReversal;
  AmountInclGST?: string;
  Description: string;
  PostingDate: string;
  ReversedInvoice: string;
  GSTAmount: string;
  ReversalReason: string;
  AmountExclGST?: string;
}

export interface InteractiveBillChargePPDAdjustment
  extends InteractiveBillChargeBase {
  ChargeType: InteractiveBillChargeTypePPDAdjustment;
  AmountInclGST?: string;
  Description: string;
  PostingDate: string;
  DocumentDate: string;
  DocumentNumber: string;
  GSTAmount: string;
  AmountExclGST?: string;
  UnitOfMeasure?: string;
}

// TODO: Is this a type of a charge?
export interface InteractiveBillRegister extends InteractiveBillChargeBase {
  // TODO: Contract no longer available?
  // Contract: string;
  ICP: string;
  Installation: string;
  Meter: string;
  Register: string;
  Product: string;
  Device: string;
  Equipment: string;
  RateType: string;
  CurrentMeterReadType: string;
  CurrentMeterReadReason: string;
  Consumption: string;
  CurrentMeterRead: string;
  PreviousMeterRead: string;
  MeterUnitOfMeasure: string;
  BillUnitOfMeasure: string;
  MeterReadDate: string;
  ElectricityExport: boolean;
  PreviousMeterReadReason: string;
  PreviousMeterReadType: string;
  EnergyUnits: string;
  NumberOfDials: string;
  ConversionFactor?: string;
}

export type InteractiveBillCharge =
  | InteractiveBillChargeFixed
  | InteractiveBillChargeVariable
  | InteractiveBillChargeDiscount
  | InteractiveBillChargeClassificationDetail
  // | InteractiveBillChargePayment
  // | InteractiveBillChargeOther
  // | InteractiveBillChargeCredit
  | InteractiveBillChargeBalanceTransfer
  | InteractiveBillChargePPDAdjustment;
// | InteractiveBillChargeWriteOff;

export interface InteractiveBillChargeClassificationDetail
  extends InteractiveBillChargeBase {
  ChargeType?: InteractiveBillChargeTypeClassificationDetail;
  AmountExclGST?: string;
  Premise: string;
  Contract?: string;
  Classification: InteractiveBillClassificationType;
  Description?: string;
  BillPeriod?: string;
  AmountInclGST?: string;
  GSTAmount?: string;
  UnitOfMeasure?: string;
}
export type InteractiveBillClassificationDetail = InteractiveBillChargeClassificationDetail;

export interface InteractiveBillClassificationHeader {
  // Use contract from InteractiveBillTechnicalDetails
  // This is included to allow filling in of header + detail if they aren't present
  Contract?: unknown;
  Description: string;
  AmountExclGST?: string;
  Classification: InteractiveBillClassificationType;
  Premise: string;
  // TODO: Do we display the ExclGST if InclGST is missing?
  AmountInclGST?: string;
  GSTAmount?: string;
  DynamicPeriodControl?: boolean;
}

export interface InteractiveBillTechnicalDetails {
  ExecutionDatetime: string;
  FileName: string;
  ProcessingDatetime: string;
  Version: string;
}

export interface InteractiveBillTechnicalDetail
  extends Record<string, unknown> {
  Version?: string;
}

export interface InteractiveBillDetail extends InteractiveBillHeaderDetail {
  ClassificationHeaders?: InteractiveBillClassificationHeader[];

  BillMessages?: InteractiveBillDetailMessage[];

  // This does not exist
  Charges?: InteractiveBillCharge[];

  ClassificationDetails?: Omit<
    InteractiveBillClassificationDetail,
    'ChargeType'
  >[];
  FixedCharges?: Omit<InteractiveBillChargeFixed, 'ChargeType'>[];
  VariableCharges?: Omit<InteractiveBillChargeVariable, 'ChargeType'>[];
  OtherCharges?: Omit<InteractiveBillChargeOther, 'ChargeType'>[];
  BalanceTransfers?: Omit<InteractiveBillChargeBalanceTransfer, 'ChargeType'>[];
  WriteOffs?: Omit<InteractiveBillChargeWriteOff, 'ChargeType'>[];
  Discounts?: Omit<InteractiveBillChargeDiscount, 'ChargeType'>[];
  Payments?: Omit<InteractiveBillChargePayment, 'ChargeType'>[];
  PreviousReversals?: Omit<
    InteractiveBillChargePreviousReversal,
    'ChargeType'
  >[];
  PPDAdjustments?: Omit<InteractiveBillChargePPDAdjustment, 'ChargeType'>[];

  Registers?: InteractiveBillRegister[];
  Premises?: InteractiveBillPremise[];
  Installations?: InteractiveBillInstallation[];
  TechnicalDetails?: InteractiveBillTechnicalDetail[];

  isCurrentBill?: boolean;
}

export interface GetInteractiveBillDetailApiResponse
  extends InteractiveBillDetail {
  header?: never;
}

export interface InteractiveBillDetailMessage {
  AccountNumber: string;
  BusinessPartnerNumber: string;
  Description: string;
  MessageID: string;
  MessagePriority: string;
  MessageType: string;
}

export const InteractiveBillDetailRebillMessageId = 'Z013';
