import { AxiosRequestConfig } from 'axios';

export const defaultConfigForTest2: AxiosRequestConfig = {
  baseURL:
    process.env.REACT_APP_BASE_URL || // For Non-NX app or lib to use
    process.env.NX_BASE_URL || // For NX app or lib to use
    'https://api.test2.digital.contact.co.nz/',
  timeout: 60000, // value from react-ols settings
  headers: {
    'Content-Type': 'application/json',
    'x-api-key':
      process.env.REACT_APP_X_API_KEY ||
      process.env.NX_X_API_KEY ||
      'I9ifcA4oHn5I60UT2TOza80jnRuQoO8R2PlM49Bh',
  },
};
