import { AxiosRequestConfig } from 'axios';
import {
  GetBillingEligibilityPath,
  GetBillingEligibilityVersion,
} from './apiPaths';
import { baseInstance } from './base-service';
import {
  BillingEligibilityParams,
  BillingEligibilityItem,
} from './models/billing-eligibility';

export const getBillingEligibility = (
  version: GetBillingEligibilityVersion,
  data?: BillingEligibilityParams,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<BillingEligibilityItem[]>(
    GetBillingEligibilityPath[version],
    data
  );
};
