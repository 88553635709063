import axios, { AxiosRequestConfig } from "axios";
import _now from "lodash/now";
import { RatesUtil } from "react-components";
import { withUserType } from "../RatesUtils";
import * as actionTypes from "./actionTypes";
import { rehydratedCustomerData } from "./iJoin/CustomerActions";
import { clearSessionId } from "./PersistActions";
import { updateMyDetails } from "./iJoin/MyDetailsActions";
import {
  updateCreditCheck,
  saveForLaterFailure,
  saveForLaterSuccess,
  saveForLaterRequest,
} from "./CSRAgent/JoinAction";
import {
  updateCreditCheckResult,
  updateCreditCheckFetching,
  updateCreditCheckError,
} from "./CSRAgent/CreditCheckAction";
import { updateSubmittingJourney } from "./CSRAgent/JoinDetailsAction";
import * as Analytics from "../../Utils/analytics";
import {
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
  BASIC_PLAN,
  SERVICE_ID_PIPED_GAS,
  MOVE_JOURNEY_TYPE,
} from "../../Config/Constants";
import { getBrowserAndVersion } from "../../Utils/browserVersion";
import { LoginFixture } from "../Fixtures/express/LoginResponse";
import { AddressDetailsFixture } from "../Fixtures/express/AddressDetailsResponse";
import { IdentityCheckFixture } from "../Fixtures/express/IdentityCheckResponse";
import { ElectricityLookupFixture } from "../Fixtures/express/ElectricityLookupResponse";
import { ElectricityManualLookupFixture } from "../Fixtures/express/ElectricityManualLookupResponse";
import { IcpLookupFixture } from "../Fixtures/express/IcpLookupResponse";
import { PriceFixture } from "../Fixtures/express/PriceResponse";
import {
  Express as Constants,
  AgentJoin,
  SaveForLater as SaveForLaterConstants,
} from "../../Config/Constants";
import { apiResolveError } from "../../Utils/ApiUtils";
import { triggerEPValues } from "../../Utils/analytics";
import { saveForLaterCustomerList } from "../../Transformers/SaveForLaterTransformation";
import noop from "nop";
import {
  AasfPath,
  BroadbandLookupPath,
  BroadbandPath,
  CheckIdentityPath,
  CreateSaveForLaterPath,
  CsrLoginPath,
  ElectricityLookupPath,
  ElectricityRegistryICPDetailsPath,
  FindAddressPath,
  FormsGenericPath,
  GasLookupPath,
  GetBillingEligibilityPath,
  GetBroadbandOfferingsPath,
  GetCreateAccountBusinessPartnerPath,
  GetCreateAccountCreditCheckPath,
  GetElectricityRegistryManualSearchPath,
  GetGasRegistryICPDetailsPath,
  GetSiteCoreContentPath,
  LoginPath,
  McfuProfilePath,
  PlansPath,
  PricePath,
  SearchAddressPath,
  StatePath,
  SubmitJourneyPath,
  ValidatePromoCodePath,
} from "@contact/data-access";

const formKeyNPS = "nps.generic.feedback";

export const stateUrl = process.env.NX_BASE_URL + StatePath.v2 + "/";
const submitUrl = process.env.NX_BASE_URL + SubmitJourneyPath.v2 + "/";
const saveForLaterUrl =
  process.env.NX_BASE_URL + CreateSaveForLaterPath.v1 + "/";
const npsURL = process.env.NX_BASE_URL + FormsGenericPath.v1 + "/";
const loginUrl = process.env.NX_BASE_URL + LoginPath.v2 + "/";
const userProfileUrl = process.env.NX_BASE_URL + McfuProfilePath.v2;
const addressDetailsUrl = process.env.NX_BASE_URL + FindAddressPath.v1 + "/";
const electricityLookupUrl =
  process.env.NX_BASE_URL + ElectricityLookupPath.v1 + "/";
const icpLookupUrl =
  process.env.NX_BASE_URL + ElectricityRegistryICPDetailsPath.v1 + "/";
const electricityManualSearchUrl =
  process.env.NX_BASE_URL + GetElectricityRegistryManualSearchPath.v1;
const priceUrl = process.env.NX_BASE_URL + PricePath.v2 + "/";
const plansUrl = process.env.NX_BASE_URL + PlansPath.v1 + "/";
const identityCheckUrl = process.env.NX_BASE_URL + CheckIdentityPath.v1 + "/";
const broadbandUrl = process.env.NX_BASE_URL + BroadbandPath.v2 + "/";
const promoCodeUrl = process.env.NX_BASE_URL + ValidatePromoCodePath.v2;
const addressSearchUrl = process.env.NX_BASE_URL + SearchAddressPath.v1;
const sitcoContentUrl =
  process.env.NX_BASE_URL + GetSiteCoreContentPath.v2 + "/";
const createUpdateAccountUrl =
  process.env.NX_BASE_URL + GetCreateAccountBusinessPartnerPath.v2;
const creditCheckUrl =
  process.env.NX_BASE_URL + GetCreateAccountCreditCheckPath.v2;
export const csrLoginUrl = process.env.NX_BASE_URL + CsrLoginPath.v2;
export const redirectAuthUrl =
  process.env.NX_SIGN_UP_URL + "/auth/cognito/callback";
const billingEligibilityUrl =
  process.env.NX_BASE_URL + GetBillingEligibilityPath.v2;
const gasAddressSearchUrl = process.env.NX_BASE_URL + GasLookupPath.v1;
const gasIcpSearchUrl =
  process.env.NX_BASE_URL + GetGasRegistryICPDetailsPath.v1;
const broadbandAddressSearchUrl =
  process.env.NX_BASE_URL + BroadbandLookupPath.v2;
const broadbandOfferingsUrl =
  process.env.NX_BASE_URL + GetBroadbandOfferingsPath.v2;
const aasfUrl = process.env.NX_BASE_URL + AasfPath.v2 + "/";

export const config: Partial<AxiosRequestConfig> = {
  headers: {
    "x-api-key": process.env.NX_X_API_KEY,
    "Content-Type": "application/json",
  },
};

export const baseAxiosConfig: Partial<AxiosRequestConfig> = {
  baseURL: process.env.NX_BASE_URL,
  timeout: 60000,
  ...config,
};

const basicPlanParams = {
  division: "01",
  billingClass: "RESI",
};

export const redirectToAgentLogin = () => {
  return () => {
    const url = `${csrLoginUrl}?redirect=${redirectAuthUrl}`;
    return axios.get(url, config).then((response) => {
      window.location.href = response.data.url;
    });
  };
};

export const getAgentToken = (grantToken, redirectUrl, userAuth) =>
  axios.get(
    `${csrLoginUrl}/${grantToken}?redirect=${redirectAuthUrl}&sessionToken=${userAuth}`,
    config
  ); //TODO change to test 1 url

export const apiError = () => ({
  type: actionTypes.API_ERROR,
});

export const updateMyDetailsFail = (is404 = false) => {
  if (is404) {
    return [clearSessionId(), apiError()];
  }
  return apiError();
};

export const updateMyDetailsSuccess = () => {
  return { type: actionTypes.API_SUCCESS };
};

export const submitSuccess = () => ({
  type: actionTypes.API_SUBMIT_SUCCESS,
});

export const callAPI = () => {
  return { type: actionTypes.CALL_API };
};

export const getApiState = () => async (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    dispatch(rehydratedCustomerData(false));
    dispatch(callAPI());

    try {
      const { data } = await axios.get(stateUrl + sessionId, config);
      dispatch(updateMyDetails(data));
      dispatch(updateMyDetailsSuccess());
      dispatch(rehydratedCustomerData(true));
    } catch (error) {
      dispatch(
        updateMyDetailsFail(error.response && error.response.status === 404)
      );
    }
  } else {
    dispatch(updateMyDetailsFail());
  }
};

export function isPendingSaveApiState() {
  return { type: actionTypes.API_STATE_PENDING_SAVE };
}

export const getUpdateApiStateRequestBody = (state) => ({
  ...state,
  CreditCheckInfo: state.CreditCheck?.data,
  PropertyInfo: state.Property?.PropertyInfo || state.PropertyInfo,
  MedicalInfo: state.Property?.MedicalInfo || state.MedicalInfo,
  MoveInfo: state.Property?.MoveInfo || state.MoveInfo,
});

export const saveApiState = (
  newData = {},
  submit = false,
  isCustomerJoin = false
) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!submit && state.isCallingAPI) {
      // Leave this for later
      return dispatch(isPendingSaveApiState());
    }
    const data = {
      ...getUpdateApiStateRequestBody(state),
      ...newData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    try {
      await axios.put(stateUrl, data, config);
      if (submit) {
        return dispatch(submitToJourneyApi(undefined, isCustomerJoin));
      } else {
        dispatch(updateMyDetailsSuccess());
      }
    } catch (error) {
      dispatch(
        updateMyDetailsFail(error.response && error.response.status === 404)
      );
    }
  };
};

export const createApiState = (apiData) => {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...apiData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    return axios
      .post(stateUrl, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch(
          updateMyDetailsFail(error.response && error.response.status === 404)
        );
      });
  };
};

const withFixture = (fixture, apiCall) => (requestPayload, axiosOptions = {}) =>
  process.env.NX_USE_FIXTURES
    ? Promise.resolve(fixture)
    : apiCall(requestPayload, axiosOptions);

export const loginUser = withFixture(LoginFixture, (requestPayload) =>
  axios.post(loginUrl, requestPayload, config)
);

export const getAddressDetails = withFixture(AddressDetailsFixture, (id) =>
  axios.get(addressDetailsUrl + id, config)
);

export const getElectricityDetailsManualSearch = withFixture(
  ElectricityManualLookupFixture,
  (queryParams) =>
    axios.get(electricityManualSearchUrl, { ...config, params: queryParams })
);

export const getElectricityDetails = withFixture(
  ElectricityLookupFixture,
  (requestPayload) =>
    axios.post(
      electricityLookupUrl,
      {
        ...requestPayload,
        format: "websphere",
      },
      config
    )
);

const formatDateToAPIString = (date) => {
  const d = new Date(date);
  return `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()}`;
};
export const getGasRates = (gasIcp, plan) => {
  return getGasIcp({ icp: gasIcp }).then(({ data }) => {
    const payload = {
      packCode: plan.PackCode,
      icpNumber: data.icp,
      startDate: formatDateToAPIString(plan.StartDate), // NOTE: format is a bit strange: DDMMYYYY, no separators
      billingClass: "RESI", // NOTE: Hardcoded by design
      division: "02", // NOTE: Hardcoded, 02 = GAS, 01 = ELEC
      campaignId: plan.CampaignId,
      priceCategoryCode: data.priceCategoryCode,
      gxpNumber: data.gxpNumber,
      networkCode: data.networkCode,
    };

    return Promise.all([
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_LOW },
        config
      ),
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_STANDARD },
        config
      ),
    ]).then(([{ data: lowPricing }, { data: standardPricing }]) => {
      const rates = RatesUtil.getRates([
        {
          Rates: [
            ...withUserType(
              lowPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_LOW
            ),
            ...withUserType(
              standardPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_STANDARD
            ),
            ...withUserType(
              lowPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_LOW,
              true
            ),
            ...withUserType(
              standardPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_STANDARD,
              true
            ),
          ],
          Service: SERVICE_ID_PIPED_GAS,
        },
      ]);

      return rates;
    });
  });
  // TODO: This needs to get rates via POST /price endpoint
  // return axios.post(priceEstimationUrl, requestPayload, config)
  //   .then((response) => {
  //     console.log("API :: getGasRates :: response", response.data.ServiceRates.find(item => item.Service === 'GAS'))
  //     return response.data.ServiceRates.find(item => item.Service === 'GAS');
  //   })
};

export async function updateCreateBP(body, headers) {
  return await axios.post(createUpdateAccountUrl, body, {
    headers: {
      "x-api-key": process.env.NX_X_API_KEY,
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

export const checkCredit = (requestPayload, token?: string) => (
  dispatch,
  getState
) => {
  if (requestPayload && token) {
    const state = getState();
    const apiData = {
      headers: {
        "x-api-key": process.env.NX_X_API_KEY,
        "access-token": state.Login.token || token,
        "Content-Type": "application/json",
        journeyType: state.JourneyType.toLowerCase(),
      },
    };
    axios
      .post(creditCheckUrl, requestPayload, apiData)
      .then(({ data }) => {
        dispatch(
          // This will retain initial property keys, while
          // adding the actually used fields Result/Bond/Message
          //
          // Lowercase variations of this are used across the UI while
          // redux + api wants these as upper case
          updateCreditCheckResult({
            ...data,
            // TODO remove duplicate usage of casing in the UI, `Result`/`Bond`/`Message` should be used
            result: data.ragStatus || data.Result,
            Result: data.ragStatus || data.Result,
            Bond: data.Bond || data.paymentAmount,
            bond: data.Bond || data.paymentAmount,
            Message: data.Message || data.message,
            message: data.Message || data.message,
          })
        );
        dispatch(updateCreditCheckFetching(false));
        dispatch(updateCreditCheck());
      })
      .catch((error) => {
        dispatch(
          updateCreditCheckError(
            apiResolveError(
              {
                statusCode: error.response ? error.response.status : null,
                serviceResponse: error.response ? error.response : null,
              },
              "cc"
            )
          )
        );
      });
  }
};

export const getElecRegion = (requestPayload) => {
  return noop.call(requestPayload);
};

export const getIcpDetails = withFixture(
  IcpLookupFixture,
  (requestPayload, axiosOptions) =>
    axios.post(icpLookupUrl, requestPayload, {
      ...config,
      ...axiosOptions,
    })
);

export const getPrice = withFixture(
  PriceFixture,
  (requestPayload, axiosOptions) =>
    axios.post(priceUrl, requestPayload, {
      ...config,
      ...axiosOptions,
    })
);

export const getRates = (icp, planId, axiosOptions = {}) => {
  let regionCode = "";
  return getIcpDetails({ icp }, axiosOptions)
    .then((response) =>
      Promise.all([
        {
          icpNumber: icp,
          gxpNumber: response.data.icp.gxpNumber,
          networkCode: response.data.icp.networkCode,
          priceCategoryCode: response.data.icp.priceCategoryCode,
          registerContentCodes: response.data.icp.registerContentCodes,
          regionCode: response.data.icp.regionCode,
          startDate: _now().toString(),
          ...basicPlanParams,
        },
        axios.get(plansUrl, {
          ...config,
          ...axiosOptions,
        }),
      ])
    )
    .then(([request, response]) => {
      regionCode = request.regionCode;
      if (response.data && response.data.Plans) {
        const plan = response.data.Plans.find((plan) => plan.Id === planId);
        const basicPlan = response.data.Plans.find(
          (plan) => plan.Id === BASIC_PLAN
        );
        const currentPlan = plan ?? basicPlan;
        return {
          ...request,
          campaignId: currentPlan.CampaignId,
          packCode: currentPlan.PackCode,
        };
      }
      return request;
    })
    .then((request) =>
      Promise.all([
        getPrice(
          { ...request, electricityUserType: USAGE_TYPE_STANDARD },
          axiosOptions
        ),
        getPrice(
          { ...request, electricityUserType: USAGE_TYPE_LOW },
          axiosOptions
        ),
      ])
    )
    .then((response) => {
      triggerEPValues(icp, response.length !== 0, regionCode);
      return response;
    });
};

export const getDefaultPlans = (axiosOptions = {}) => {
  return axios
    .get(plansUrl, {
      ...config,
      ...axiosOptions,
    })
    .then((response) => {
      return response.data.Plans;
    });
};

export const getBroadbandAddress = (params?: unknown) => {
  if (!params) {
    console.error(
      "Cannot call getBroadbandAddress without pxid or address params."
    );
    return Promise.resolve(null);
  }

  return axios
    .get(broadbandAddressSearchUrl, { ...config, params })
    .then((response) => response.data);
};

export const getBroadbandInfo = (params, payload, axiosOptions = {}) => {
  if (!payload || !params) {
    console.error(
      "Cannot call getBroadbandInfo without an params and payload."
    );
    return Promise.resolve(null);
  }
  const { planId, selectedServices } = params;
  return axios
    .post(broadbandOfferingsUrl, payload, {
      ...config,
      ...axiosOptions,
      params: { planId, services: selectedServices.join() },
    })
    .then((response) => response.data);
};

// Note: No longer used in CSR Join
export const getBroadbandOffers = (
  services,
  planId,
  isManualAddress,
  address,
  pxid
) => {
  let addressPxidParams = "";
  if (isManualAddress) {
    addressPxidParams = `&address=${address}`;
  } else {
    addressPxidParams = `&pxid=${pxid}`;
  }
  const url =
    broadbandUrl + `?services=${services}&planId=${planId}` + addressPxidParams;
  return axios.get(url, config);
};

export const validatePromoCode = (promoCode) => {
  const url = promoCodeUrl + `?promoCode=${promoCode}`;
  return axios.get(url, config).then((response) => {
    return response.data;
  });
};

export const getAddressIcp = (address) => {
  const url = addressSearchUrl + `?Query=${address}`;
  return axios.get(url, config);
};

export const getGasAddress = (queryParams) => {
  return axios.get(gasAddressSearchUrl, { ...config, params: queryParams });
};

export const getGasIcp = (queryParams) => {
  return axios.get(gasIcpSearchUrl, { ...config, params: queryParams });
};

export const getSitcorePlans = (agent, journeyType, axiosOptions = {}) => {
  const urlCustomer =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-react";
  const urlAgentJoin =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-csr-react";
  const urlAgentMove =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Fmake-changes-csr-react";
  const url = agent
    ? journeyType === MOVE_JOURNEY_TYPE
      ? urlAgentMove
      : urlAgentJoin
    : urlCustomer;
  return axios.get(url, {
    ...config,
    ...axiosOptions,
  });
};
export const getUserProfile = (token) => {
  const browser = getBrowserAndVersion();
  const profileConfig = {
    headers: {
      "x-api-key": process.env.NX_X_API_KEY,
      Authorization: token,
      "Content-Type": "application/json",
      "X-Contact-Source": "Journey",
      "X-Contact-Platform": browser.name,
      "X-Contact-Platform-Version": browser.version,
    },
  };
  return axios.post(userProfileUrl, null, profileConfig);
};

export const getUserAccount = (accountId, token) => {
  const browser = getBrowserAndVersion();
  const url = userProfileUrl + "?ContractAccountID=" + accountId;
  const profileConfig = {
    headers: {
      "x-api-key": process.env.NX_X_API_KEY,
      Authorization: token,
      "Content-Type": "application/json",
      "X-Contact-Source": "Journey",
      "X-Contact-Platform": browser.name,
      "X-Contact-Platform-Version": browser.version,
    },
  };
  return axios.post(url, null, profileConfig);
};

export const getBillinEligibility = (data) => {
  return axios.post(billingEligibilityUrl, data, config);
};

export const submitToJourneyApi = (
  stateData = undefined,
  isCustomerJoin = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const apiData = {
      sessionID: state.Auth.sessionID,
      customerType: "Residential",
      journeyType: state.JourneyType || state.Confirmation.JourneyType,
    };

    return axios
      .post(
        submitUrl,
        apiData,
        isCustomerJoin
          ? config
          : {
              ...config,
              headers: {
                ...config.headers,
                "access-token": state.Login.token,
              },
            }
      )
      .then(() => {
        dispatch(updateSubmittingJourney(false));
        if (state.Confirmation.JourneyType === Constants.expressJourneyType) {
          Analytics.triggerExpressMoveSubmitted(
            (stateData || state).MedicalInfo.HasVulnerablePerson,
            (stateData || state).MedicalInfo.HasMedicalDependant,
            (stateData || state).PropertyInfo.Hazards
          );
        }
        dispatch(submitSuccess());
      })
      .catch((error) => {
        dispatch(updateSubmittingJourney(false));
        dispatch(
          updateMyDetailsFail(error.response && error.response.status === 404)
        );
      });
  };
};
export const checkIdentity = withFixture(IdentityCheckFixture, (stateData) =>
  axios.post(identityCheckUrl, stateData, config)
);

export const deleteStateApiSessionId = () => (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    return axios.delete(stateUrl + sessionId, config);
  }
};

export const submitToNPSApi = (apiData, formKey = formKeyNPS) => {
  return () => {
    const data = {
      formsKey: formKey,
      retainToken: "",
      enterPrizeDraw: "false",
      ...apiData,
    };

    axios
      .post(npsURL + formKey, data, config)
      .then(() => {
        if (apiData.preferredChannel) {
          Analytics.triggerAsyncNPSSubmit(apiData);
        } else {
          Analytics.triggerNPSSubmit(apiData.score, apiData.scoreComments);
        }
      })
      .catch(() => {
        // go silent and do not notify customer.
      });
  };
};

const SFL_STATE_OVERRIDES = {
  BillCompare: null,
};

export const saveCustomerInfoSaveForLater = () => {
  return (dispatch, getState) => {
    const state = getState();
    const apiHeader = {
      headers: {
        "x-api-key": process.env.NX_X_API_KEY,
        session: state.Login.token,
      },
    };

    const requestPayload = {
      journeyType: AgentJoin.journeyType,
      origin: SaveForLaterConstants.csrUser,
      saveForLaterEmail: state.Customer.CustomerInfo.EmailAddress,
      state: { ...state, ...SFL_STATE_OVERRIDES },
    };

    dispatch(saveForLaterRequest());
    return axios
      .post(saveForLaterUrl, requestPayload, apiHeader)
      .then(() => {
        dispatch(saveForLaterSuccess());
      })
      .catch((error) => {
        dispatch(
          saveForLaterFailure(
            apiResolveError({
              statusCode: error.response ? error.response.status : null,
              serviceResponse: error.response ? error.response : null,
            })
          )
        );
      });
  };
};

export const getCustomerInfoForSaveForLater = (customerData) => (
  dispatch,
  getState
) => {
  dispatch(saveForLaterRequest());
  const state = getState();
  const apiHeader = {
    headers: {
      "x-api-key": process.env.NX_X_API_KEY,
      session: state.Login.token,
    },
  };

  const requestPayload = {
    consumer: SaveForLaterConstants.csrUser,
    saveForLaterEmail: customerData.emailAddress,
    journeyType: AgentJoin.journeyType,
  };

  axios
    .get(saveForLaterUrl, { ...apiHeader, params: requestPayload })
    .then((data) => {
      dispatch(
        saveForLaterSuccess(
          saveForLaterCustomerList(data?.data),
          data?.data?.saveForLaterID,
          data?.data?.state
        )
      );
    })
    .catch((error) => {
      dispatch(
        saveForLaterFailure(
          apiResolveError({
            statusCode: error.response ? error.response.status : null,
            serviceResponse: error.response ? error.response : null,
          })
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createBusinessAccountNumber(data) {
  return noop();
}

export const getAccountAasf = (bp, token) => {
  const configParams = {
    headers: {
      ...config.headers,
      session: token,
    },
  };
  return axios.get(`${aasfUrl}/${bp}`, configParams);
};
