import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';
import { RollOff } from './models';

export const getRollOffDetails = (
  id: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<RollOff.RollOffDetails>(
    `/rolloff/roll-offs/${id}`
  );
};

export const queryRollOffsByBP = (
  bp: string,
  eventID?: string,
  isCSR?: boolean,
  token?: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<RollOff.RollOffsByBP>('/rolloff/roll-offs', {
    params: {
      bp,
      eventID,
      userType: isCSR ? 'AGENT' : 'CUSTOMER',
    },
    headers: { session: token },
  });
};
