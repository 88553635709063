import { servicesFullName } from "../Config/Constants";

export const saveForLaterCustomerList = (data) => {
  // For now we will always have only one customer. In the future state we will have multiple customers
  try {
    const customerList = [];
    let servicesAndPlansList = [];

    if (data && data.state) {
      if (data?.state?.Services?.length > 0) {
        servicesAndPlansList = data.state.Services.map((service) => {
          return servicesFullName[service];
        });
        servicesAndPlansList.push(data.state.PlanInfo.SelectedPlan?.Name);
        servicesAndPlansList.push(data.state.PlanInfo.SelectedBBPlan?.Name);
      } else {
        servicesAndPlansList.push("No services or plan selected yet");
      }

      const filteredServicesAndPlans = servicesAndPlansList.filter(
        (arrayItem) => arrayItem !== null && arrayItem !== undefined
      );

      customerList.push({
        firstName: data.state.Customer.CustomerInfo.FirstName,
        lastName: data.state.Customer.CustomerInfo.LastName,
        serviceAddress: data.state.Customer.CustomerInfo.ServiceAddress,
        emailAddress: data.state.Customer.CustomerInfo.EmailAddress,
        services: filteredServicesAndPlans.join(", "),
      });
    }
    return customerList;
  } catch (e) {
    console.log("Exception ", e);
  }
};
