import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { useMemoCompare } from "../../Utils/hooks";
import Icon from "../Icon";
import Card from "../Card";
import ExpansionPanel from "../ExpansionPanel";
import { Block } from "../../Assets/Icons";
import Checkbox from "../Checkbox";

import "./ExpandableCard.scss";

const ExpandableCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    disabled,
    content,
    alwaysOpen,
    isOpen: isOpenControlled,
    handleClick,
    checked,
    name,
    headerContent,
    onCheckboxChange,
  } = props;

  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const isControlled =
    typeof isOpenControlled === "boolean" ||
    typeof handleClick === "function" ||
    typeof onCheckboxChange === "function";
  const isOpen = isControlled ? isOpenControlled : uncontrolledOpen;

  const arrowDefaultRotation = content ? "0deg" : "270deg";
  const [arrowAnimation, setArrowAnimation] = useSpring(() => ({
    transform: `rotate(${arrowDefaultRotation})`,
  }));

  // Note: These caches ensure that we use a more expensive deep equality check to determine if these non-primitives
  //       data structures have actually changed their contents so the useMemo below does not unnecessarily re-run.
  const classesCache = useMemoCompare(props.classes, isEqual);
  const headerContentCache = useMemoCompare(headerContent, isEqual);
  const classes = useMemo(
    () => ({
      card: classNames({
        expandableCard: true,
        "expandableCard--closed": !isExpanded,
        "expandableCard--disabled": disabled,
        "expandableCard--selected":
          headerContentCache.cardIsSelected &&
          !content &&
          !headerContentCache.withCheckbox,
        "expandableCard--selectedWithHover":
          headerContentCache.cardIsSelected &&
          content &&
          !headerContentCache.withCheckbox,
      }),
      container: classNames({
        expandableCard_closedContainer: true,
        "expandableCard--noHover": alwaysOpen,
      }),
      infoContainer: classNames({
        expandableCard_accountInfoContainer: true,
        ...(classesCache?.infoContainer || {}),
      }),
      checkbox: classNames({
        expandableCard_checkbox: true,
        ...(classesCache?.checkbox || {}),
      }),
      icon: classNames({
        expandableCard_mainIcon: !headerContentCache.withCheckbox,
        expandableCard_mainIconWithCheckbox: headerContentCache.withCheckbox,
        ...(classesCache?.icon || {}),
      }),
      title: classNames({
        title: true,
        expandableCard_headerContainer:
          headerContentCache.mainIcon && !headerContentCache.withCheckbox,
        expandableCard_headerContainerWithCheckbox:
          headerContentCache.mainIcon && headerContentCache.withCheckbox,
        ...(classesCache?.title || {}),
      }),
    }),
    [
      isExpanded,
      disabled,
      content,
      alwaysOpen,
      headerContentCache,
      classesCache,
    ]
  );

  useEffect(() => {
    if (disabled) {
      setIsExpanded(false);
    } else if (typeof isOpen !== "undefined") {
      setIsExpanded(isOpen);
    }
  }, [isOpen, disabled, checked]);

  setArrowAnimation({
    transform: isExpanded
      ? "rotate(180deg)"
      : `rotate(${arrowDefaultRotation})`,
  });

  const handleCardClick = useCallback(
    (event) => {
      if (!disabled && checked !== undefined && checked) {
        headerContent.cardLink
          ? handleClick(event)
          : setIsExpanded(!isExpanded);
      }
      if (!disabled && !alwaysOpen) {
        if (isControlled) {
          if (handleClick) {
            handleClick(event);
          }
        } else {
          setUncontrolledOpen((open) => !open);
        }
      }
    },
    [
      isControlled,
      alwaysOpen,
      checked,
      disabled,
      handleClick,
      headerContent,
      isExpanded,
      setUncontrolledOpen,
    ]
  );

  return (
    <Card name={name} className={classes.card}>
      <div
        className={classes.container}
        role="menuitem"
        onClick={handleCardClick}
        onKeyDown={(e) => e.key === "Enter" && handleCardClick()}
        tabIndex={0}
      >
        <div className={classes.infoContainer}>
          {headerContent.withCheckbox ? (
            <Checkbox
              className={classes.checkbox}
              onChange={onCheckboxChange}
              checked={checked}
              name={headerContent.name}
              label={headerContent.checkboxLabel}
            />
          ) : null}
          {headerContent.mainIcon ? (
            <Icon icon={headerContent.mainIcon} className={classes.icon} />
          ) : null}
          <div className={classes.title}>
            <div
              className={
                headerContent.withCheckbox
                  ? "expandableCard_headerWithCheckbox"
                  : "expandableCard_accountHeader"
              }
            >
              {headerContent.cardTitle}
            </div>
            {headerContent.cardSubtitle ? (
              <div className="expandableCard_accountSubheader">
                {headerContent.cardSubtitle}
              </div>
            ) : undefined}
            {headerContent.withCheckbox ? null : (
              <ExpansionPanel isExpanded={!isExpanded}>
                <div className="expandableCard_accountText">
                  {disabled ? null : headerContent.cardContent}
                </div>
              </ExpansionPanel>
            )}
          </div>
        </div>
        <div className="expandableCard_iconContainer">
          {headerContent.cardLogo && (
            <Icon
              icon={headerContent.cardLogo}
              className="expandableCard_iconBank"
            />
          )}
          {!alwaysOpen && (
            <animated.div
              style={arrowAnimation}
              className="expandableCard_iconArrow"
            >
              <Icon
                icon={disabled ? <Block /> : headerContent.cardIcon}
                className="expandableCard_icon"
              />
            </animated.div>
          )}
        </div>
      </div>
      <ExpansionPanel isExpanded={isExpanded}>
        <div className="expandableCard_mainContent">{content && content}</div>
      </ExpansionPanel>
    </Card>
  );
};

export default ExpandableCard;
