import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  ExpressJourneyType,
  getJourneyData,
  GetJourneyDataApiResponse,
  GetJourneyDataPath,
  GetJourneyDataVersion,
} from '@contact/data-access';

export const useJourneyData = (
  version: GetJourneyDataVersion,
  journeyType: ExpressJourneyType,
  journeyId: string,
  accessToken: string,
  timestamp: string,
  signature: string,
  enabled: boolean,
  options?: Partial<AxiosRequestConfig>,
  onError?: (error) => void
) => {
  return useQuery<GetJourneyDataApiResponse>(
    [
      GetJourneyDataPath[version](journeyType, journeyId),
      journeyType,
      journeyId,
      accessToken,
      timestamp,
      signature,
      enabled,
    ],
    async () => {
      const { data } = await getJourneyData(
        version,
        journeyType,
        journeyId,
        accessToken,
        timestamp,
        signature,
        options
      );
      return data;
    },
    {
      enabled,
      onError,
    }
  );
};
