import { AxiosRequestConfig } from 'axios';
import { GetJourneyDataPath, GetJourneyDataVersion } from '../apiPaths';
import { baseInstance } from '../base-service';
import {
  GetJourneyDataApiResponse,
  ExpressJourneyType,
} from '../models/journeys';

export function getJourneyData(
  version: GetJourneyDataVersion,
  journeyType: ExpressJourneyType,
  journeyId: string,
  accessToken: string,
  timestamp: string,
  signature: string,
  config?: AxiosRequestConfig
) {
  const path = GetJourneyDataPath[version](journeyType, journeyId);

  return baseInstance(config).get<GetJourneyDataApiResponse>(path, {
    headers: {
      'access-token': accessToken,
      timestamp,
      signature,
    },
  });
}
