import React, { ReactChildren, ReactNode } from "react";
import Icon from "../Icon";
import { Close, Info } from "../../Assets/Icons";
import "./InfoMessage.scss";
import classNames from "classnames";

export interface InfoMessageProps {
  icon?: ReactNode;
  text?: ReactNode;
  header?: ReactNode;
  error?: boolean;
  center?: boolean;
  children?: ReactChildren;
  onClose?(): void;
  id?: string;
}

const InfoMessage = ({
  icon,
  text,
  header,
  error,
  center,
  onClose,
  id,
}: InfoMessageProps) => {
  return (
    <div id={id} className={classNames("infoMessage", { error })}>
      <div
        className={classNames(
          "infoMessage_icon",
          center ? "infoMessage_icon_centered" : ""
        )}
      >
        <Icon icon={icon ?? <Info />} className="infoMessage_icon_svg" />
      </div>
      <div className="infoMessage_content">
        {header ? (
          <div className="infoMessage_header">{header}</div>
        ) : undefined}
        <div className="infoMessage_text">{text ?? ""}</div>
      </div>
      {onClose ? (
        <button
          type="button"
          className="infoMessage_close"
          onClick={onClose}
          title="Close"
        >
          <Close />
        </button>
      ) : undefined}
    </div>
  );
};

export default InfoMessage;
