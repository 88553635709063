import _merge from "lodash/merge";

/**
 * @param {string} name
 * @returns {function}
 */
export function reducerPropUpdater(name) {
  return (state, propName, value) => {
    const [reducerName, ...keys] = propName;
    if (reducerName !== name) return state;

    const partialReduxState = keys.reverse().reduce(
      (acc, key) => ({
        [key]: acc,
      }),
      value
    );
    return _merge({}, state, partialReduxState);
  };
}

export function isChangeJourney(journeyType) {
  return journeyType.toLowerCase() === "change";
}

export function isMoveJourney(journeyType) {
  return journeyType.toLowerCase() === "move";
}

export function isAddJourney(journeyType) {
  return journeyType.toLowerCase() === "add";
}
