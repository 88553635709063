import { AxiosRequestConfig } from 'axios';
import { PricePath, PriceVersion } from '..';
import { baseInstance } from './base-service';
import { PriceApiResponse, PriceRequest } from './models/price';

export const getPrice = async (
  version: PriceVersion,
  request: PriceRequest,
  config: Partial<AxiosRequestConfig>
) => baseInstance(config).post<PriceApiResponse>(PricePath[version], request);
