import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  sendEmailTrimester,
  SendEmailTrimesterResponse,
  SendEmailTrimesterParams,
  FourthTrimesterEmailsNewSendEmailVersion,
  FourthTrimesterEmailsNewSendEmailPath,
} from '@contact/data-access';

export const useTrimesterSendEmail = (
  version: FourthTrimesterEmailsNewSendEmailVersion,
  requestId: string,
  token: string,
  params: SendEmailTrimesterParams,
  isEmailTriggered: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<SendEmailTrimesterResponse>(
    [FourthTrimesterEmailsNewSendEmailPath[version], requestId, params, token],
    async () => {
      const { data } = await sendEmailTrimester(
        version,
        requestId,
        token,
        params,
        options
      );
      return data;
    },
    {
      enabled: isEmailTriggered,
    }
  );
};
