import React from "react";
import classNames from "classnames";
import "./CardsRow.scss";

const CardsRow = (props) => {
  return (
    <div className={classNames("cardsRow", `cardsRow_${props.layout}`)}>
      <h2 className="cardsRow_header">{props.header}</h2>
      {props.cards.map((card, index) => (
        <div
          className={classNames("cardsRow_card", {
            // eslint-disable-next-line
            cardsRow_card_color: card.background,
          })}
          key={card.link}
        >
          <a
            name={"link" + index}
            href={card.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {card.image ? (
              <img
                src={card.image.desktop}
                srcSet={card.image.retina}
                alt={card.label}
                className="cardsRow_image-background"
              />
            ) : (
              card.background && (
                <div
                  style={{ backgroundColor: card.background }}
                  className="cardsRow_image-background cardsRow_solid-color"
                ></div>
              )
            )}
            <div className="cardsRow_label">
              <span>{card.label}</span>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default CardsRow;
