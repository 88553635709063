import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterValidateLinkPath,
  FourthTrimesterValidateLinkVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import { ValidateLinkTrimesterResponse } from '../models';

export const validateTrimesterLink = async (
  version: FourthTrimesterValidateLinkVersion,
  requestId: string,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<ValidateLinkTrimesterResponse>(
    `${FourthTrimesterValidateLinkPath[version]}/${requestId}`
  );
