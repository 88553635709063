import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
} from "Config/Constants";
import { RatesUtil } from "react-components";
import { USER_TYPE_STANDARD } from "react-components/Components/Rates/constants";
import { withUserType } from "Redux/RatesUtils";
import {
  updateBroadbandRates,
  updateElectricityRates,
  updateGasRates,
} from "Redux/Actions/CSRAgent/RatesAction";
import {
  useElectricityPrices,
  useGasPrices,
  usePipedGasInfoConsumptionBandSelector,
  usePropertyInfoElectricityUserTypeSelector,
  useIsElectricityInServiceList,
  useIsGasInServiceList,
} from ".";

export const useElectricityRatesSetter = () => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch(updateElectricityRates(payload)), [
    dispatch,
  ]);
};

export const useGasRatesSetter = () => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch(updateGasRates(payload)), [
    dispatch,
  ]);
};

export const useBroadbandRatesSetter = () => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch(updateBroadbandRates(payload)), [
    dispatch,
  ]);
};

export const useElectricityRates = (
  icp: string | undefined,
  planId: string | undefined,
  hasNoIcp: boolean,
  rateLabels: Record<string, unknown>
) => {
  const { data: prices, isLoading } = useElectricityPrices(icp, planId);
  const setElectricityRates = useElectricityRatesSetter();
  const userType = usePropertyInfoElectricityUserTypeSelector();
  const isElectricityInServiceList = useIsElectricityInServiceList();

  return useMemo(() => {
    const ratesWithUsageType = prices && [
      ...withUserType(
        prices[0]?.priceList.filter(isDisplayPrice),
        USAGE_TYPE_STANDARD
      ),
      ...withUserType(
        prices[1]?.priceList.filter(isDisplayPrice),
        USAGE_TYPE_LOW
      ),
      ...withUserType(
        prices[0]?.priceList.filter(isDisplayPrice),
        USAGE_TYPE_STANDARD,
        true
      ),
      ...withUserType(
        prices[1]?.priceList.filter(isDisplayPrice),
        USAGE_TYPE_LOW,
        true
      ),
    ];

    let ratesWithService;
    if (icp && planId) {
      ratesWithService = ratesWithUsageType
        ? [
            {
              Rates: ratesWithUsageType,
              Service: SERVICE_ID_ELECTRICITY,
            },
          ]
        : [];
    } else if (hasNoIcp) {
      ratesWithService = [{ Rates: [], Service: SERVICE_ID_ELECTRICITY }];
    } else {
      ratesWithService = [];
    }

    const rates = RatesUtil.getRates(ratesWithService, rateLabels);
    const usageType =
      userType === USER_TYPE_STANDARD ? USAGE_TYPE_STANDARD : USAGE_TYPE_LOW;

    if (isElectricityInServiceList && usageType && rates && rates[0]) {
      setElectricityRates({
        usageType,
        gstInclusive: false,
        rates:
          usageType === USAGE_TYPE_STANDARD
            ? rates[0].standardRatesGSTExclusive
            : rates[0].lowRatesGSTExclusive,
      });
    } else {
      setElectricityRates(undefined);
    }

    return { data: rates, isLoading };
  }, [
    hasNoIcp,
    icp,
    isElectricityInServiceList,
    isLoading,
    planId,
    prices,
    rateLabels,
    setElectricityRates,
    userType,
  ]);
};

function isDisplayPrice(rate) {
  return rate.Charge > 0 || !!rate.ChargeDescription;
}

export const useGasRates = (icp?: string) => {
  const { data, isLoading } = useGasPrices(icp);
  const setGasRates = useGasRatesSetter();
  const consumptionBand = usePipedGasInfoConsumptionBandSelector();
  const isGasInServiceList = useIsGasInServiceList();

  return useMemo(() => {
    const rates =
      data &&
      RatesUtil.getRates([
        {
          Rates: [
            ...withUserType(
              data[0]?.priceList.filter(isDisplayPrice),
              USAGE_TYPE_LOW
            ),
            ...withUserType(
              data[1]?.priceList.filter(isDisplayPrice),
              USAGE_TYPE_STANDARD
            ),
            ...withUserType(
              data[0]?.priceList.filter(isDisplayPrice),
              USAGE_TYPE_LOW,
              true
            ),
            ...withUserType(
              data[1]?.priceList.filter(isDisplayPrice),
              USAGE_TYPE_STANDARD,
              true
            ),
          ],
          Service: SERVICE_ID_PIPED_GAS,
        },
      ]);

    const usageType =
      consumptionBand === USER_TYPE_STANDARD
        ? USAGE_TYPE_STANDARD
        : USAGE_TYPE_LOW;

    if (isGasInServiceList && usageType && rates && rates[0]) {
      setGasRates({
        usageType,
        gstInclusive: false,
        rates:
          usageType === USAGE_TYPE_STANDARD
            ? rates[0].standardRatesGSTExclusive
            : rates[0].lowRatesGSTExclusive,
      });
    } else {
      setGasRates(undefined);
    }

    return { data: rates, isLoading };
  }, [consumptionBand, data, isGasInServiceList, isLoading, setGasRates]);
};
