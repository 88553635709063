import { cloneDeep } from 'lodash-es';
import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { InvoiceApiResponse } from './models';
import { InvoicePath, InvoiceVersion } from '..';

export const getInvoicePath = (version: InvoiceVersion, invoiceId: string) =>
  `${InvoicePath[version]}/${invoiceId}`;

export const getInvoice = (
  version: InvoiceVersion,
  invoiceId: string,
  config?: AxiosRequestConfig
) => {
  const path = getInvoicePath(version, invoiceId);
  const configWithoutSession = cloneDeep(config);
  delete configWithoutSession?.headers?.session;

  return baseInstance(configWithoutSession).post<InvoiceApiResponse>(
    path,
    null,
    {
      params: {},
      headers: {
        'x-csrf-token': 'fetch',
      },
    }
  );
};
