import { initialState } from "../../Reducers/CSRAgent/JoinDetailsRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { updateSubmittingJourney } from "../../Actions/CSRAgent/JoinDetailsAction";
import { pair } from "../../../Utils/pair";

interface JoinDetails {
  isSubmitting: boolean;
}

export function getJoinDetails(state): JoinDetails {
  return state.JoinDetails || initialState;
}

export function useJoinDetailsSelector() {
  return useSelector(getJoinDetails);
}

export function getJoinDetailsIsSubmitting(state) {
  return getJoinDetails(state).isSubmitting ?? false;
}

export function useJoinDetailsIsSubmittingSelector() {
  return useSelector(getJoinDetailsIsSubmitting);
}

export function useJoinDetailsIsSubmittingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isSubmitting) => dispatch(updateSubmittingJourney(isSubmitting)),
    [dispatch]
  );
}

export function useJoinDetailsIsSubmitting() {
  const value = useJoinDetailsIsSubmittingSelector();
  const setter = useJoinDetailsIsSubmittingSetter();
  return pair(value, setter);
}
