import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMeterAccess } from "@contact/data-access-hooks";
import { updateMeterAccess } from "Redux/Actions/CSRAgent/MeterAccessAction";
import { usePropertyInfoContactHasMeterKeySetter } from "./Property/PropertyInfo";
import {
  useIsElectricityInServiceList,
  useIsGasInServiceList,
} from "./Services";
import { useAxiosConfig } from "../utils";

export const useMeterAccessSetter = () => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch(updateMeterAccess(payload)), [
    dispatch,
  ]);
};

export const useMeterAccessKeys = (elecIcp?: string, gasIcp?: string) => {
  const isElectricityInServiceList = useIsElectricityInServiceList();
  const isGasInServiceList = useIsGasInServiceList();
  const axiosConfig = useAxiosConfig();

  const { data, isSuccess } = useMeterAccess(
    "v2",
    isElectricityInServiceList ? elecIcp : undefined,
    isGasInServiceList ? gasIcp : undefined,
    axiosConfig
  );

  const setMeterAccess = useMeterAccessSetter();
  const setContactHasMeterKey = usePropertyInfoContactHasMeterKeySetter();

  useEffect(() => {
    if (isSuccess) {
      setMeterAccess(data);
      if (data?.icps?.find((icp) => icp?.isKeyHeld)) {
        setContactHasMeterKey(true);
      } else {
        setContactHasMeterKey(false);
      }
    } else {
      setMeterAccess(undefined);
      setContactHasMeterKey(undefined);
    }
  }, [data, isSuccess, setContactHasMeterKey, setMeterAccess]);
};
