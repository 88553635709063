export const CustomerFixture = {
  CustomerInfo: {
    Title: "Mr",
    FirstName: "Chuck",
    MiddleName: "",
    LastName: "Testa",
    EmailAddress: "dylan.watson@contactenergy.co.nz",
    PhoneNumber: "039201932",
    DateOfBirth: "13/12/1977",
    AccountNumber: null,
    PostalAddress: null,
    CurrentAddress: null,
  },
  HasDriversLicense: true,
  DriversLicense: {
    Number: "DT123453",
    Version: "888",
  },
};
