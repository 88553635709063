import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";

export const updatePayment = (payment, journeyType) => {
  return {
    type: actionTypes.UPDATE_PAYMENT,
    payment,
    journeyType,
  };
};

export const savePaymentData = (payment) => {
  return (dispatch, getState) => {
    const state = getState();
    const discountPromoCode =
      state.Readonly.Discounts && state.Readonly.Discounts.PromoCode;
    const newPayment = {
      ...state.Payment,
      ...payment,
      PromoCode: state.Payment.PromoCode
        ? state.Payment.PromoCode
        : discountPromoCode,
      DirectDebitDetails: {
        ...state.Payment.DirectDebitDetails,
        ...payment.DirectDebitDetails,
      },
    };
    dispatch(updatePayment(newPayment));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(
        saveApiState({
          Payment: {
            ...newPayment,
          },
        })
      );
    }
  };
};
