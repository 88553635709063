import {
  PERSIST_SESSION_ID,
  CLEAR_SESSION_ID,
  PERSIST_LOCAL_DATA,
  HYDRATE_LOCAL_DATA,
} from "./actionTypes";

export const persistSessionId = () => ({
  type: PERSIST_SESSION_ID,
});

export const clearSessionId = () => ({
  type: CLEAR_SESSION_ID,
});

export const persistLocalData = (payload) => ({
  type: PERSIST_LOCAL_DATA,
  payload,
});

export const hydrateLocalData = (keys) => ({
  type: HYDRATE_LOCAL_DATA,
  keys,
});
