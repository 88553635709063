import React, { useState, useCallback } from "react";

import Input from "../Input";
import Modal from "../Modal";
import {
  ServiceModalConstants,
  ServiceModalPipedGasRegions,
} from "../../Config/Constants";
import Dropdown from "../Dropdown";

import "./ServiceCard.scss";
import BroadbandAddressSearch from "../BroadbandAddressSearch";

const ServiceModal = (props) => {
  // Address Modal
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [suburbOrTown, setSuburbOrTown] = useState("");
  const [region, setRegion] = useState();
  const [, setValidateItself] = useState(false);
  const [address, setAddress] = useState("");
  const [addressHasError, setAddressHasError] = useState(false);

  // ICP Modal
  const [icp, setIcp] = useState("");

  // Address modal builder based on service type
  const buildAddressModal = (serviceType) => {
    if (serviceType === "broadband") {
      return (
        <Modal
          showClose
          clearSessionStyle
          isActive={props.isActive}
          buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
          handlePrimaryClick={props.handlePrimaryClick}
          buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
          handleSecondaryClick={() =>
            props.handleSecondaryClick(address || props.manualAddress)
          }
          content={buildAddressModalContent()}
          contentStyle={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "60px",
            marginBottom: "60px",
          }}
          handleValidationClick={() => setValidateItself(true)}
          buttonDisabled={!address && !props.manualAddress}
        />
      );
    } else if (serviceType === "piped gas") {
      return (
        <Modal
          showClose
          clearSessionStyle
          isActive={props.isActive}
          buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
          handlePrimaryClick={props.handlePrimaryClick}
          buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
          handleSecondaryClick={() => {
            props.handleManualAddressSubmission({
              streetNumber: streetNumber,
              streetName: streetName,
              suburbOrTown: suburbOrTown,
              region: region,
            });
          }}
          content={buildManualAddressWithRegionDropdownModalContent()}
          contentStyle={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "60px",
            marginBottom: "60px",
          }}
        />
      );
    } else {
      return (
        <Modal
          showClose
          clearSessionStyle
          isActive={props.isActive}
          buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
          handlePrimaryClick={props.handlePrimaryClick}
          buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
          handleSecondaryClick={() => {
            props.handleManualAddressSubmission({
              streetNumber: streetNumber,
              streetName: streetName,
              suburbOrTown: suburbOrTown,
              region: region,
            });
          }}
          content={buildManualAddressModalContent()}
          contentStyle={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "60px",
            marginBottom: "60px",
            textAlign: "center",
          }}
        />
      );
    }
  };

  const { handleManualAddressChange, setAddressDetails } = props;

  const onSetManualAddress = useCallback(
    (address, details) => {
      if (address) {
        handleManualAddressChange(address);
      }
      if (details) {
        setAddressDetails({
          a: address, // NOTE: This is the formatted address string which is used in some API calls
          number: details.streetNumber,
          street: details.streetName,
          postcode: details.postcode,
          city: details.city,
          suburb: details.suburb,
          unit: details.unit,
        });
      }
    },
    [handleManualAddressChange, setAddressDetails]
  );

  const buildAddressModalContent = () => {
    return (
      <>
        <strong>Manually enter details for {props.serviceType}</strong>
        <BroadbandAddressSearch
          api={props.api}
          labelText={props.label}
          handleChange={setAddress}
          value={address}
          handleError={setAddressHasError}
          handleSetManualAddress={onSetManualAddress}
          manualAddress={props.manualAddress}
        />
        {addressHasError && (
          <p className="serviceCard_errorContainer">
            {ServiceModalConstants.errorMessage}
          </p>
        )}
      </>
    );
  };

  const buildManualAddressModalContent = () => {
    return (
      <>
        <strong>Manually enter details for {props.serviceType}</strong>
        <Input
          labelText={ServiceModalConstants.StreetNumber}
          value={streetNumber}
          handleChange={setStreetNumber}
        />
        <Input
          labelText={ServiceModalConstants.StreetName}
          value={streetName}
          handleChange={setStreetName}
        />
        <Input
          labelText={ServiceModalConstants.SuburbTown}
          value={suburbOrTown}
          handleChange={setSuburbOrTown}
        />
        <Input
          labelText={ServiceModalConstants.Region}
          value={region}
          handleChange={setRegion}
        />
      </>
    );
  };

  const buildManualAddressWithRegionDropdownModalContent = () => {
    return (
      <>
        <strong>Manually enter details for {props.serviceType}</strong>
        <Input
          labelText={ServiceModalConstants.StreetNumber}
          value={streetNumber}
          handleChange={setStreetNumber}
        />
        <Input
          labelText={ServiceModalConstants.StreetName}
          value={streetName}
          handleChange={setStreetName}
        />
        <Input
          labelText={ServiceModalConstants.SuburbTown}
          value={suburbOrTown}
          handleChange={setSuburbOrTown}
        />
        <div style={{ marginTop: 10 }}>
          <Dropdown
            placeholder="Select region"
            items={ServiceModalPipedGasRegions}
            selectedItem={region}
            handleSelectedItem={setRegion}
            labelText={ServiceModalConstants.Region}
          />
        </div>
      </>
    );
  };

  // Icp Modal builder
  const buildIcpModal = () => {
    return (
      <Modal
        showClose
        clearSessionStyle
        isActive={props.isActive}
        buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
        handlePrimaryClick={props.handlePrimaryClick}
        buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
        handleSecondaryClick={() => {
          props.handleIcpChange(icp);
        }}
        content={buildIcpModalContent()}
        contentStyle={{
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "60px",
          marginBottom: "60px",
          textAlign: "center",
        }}
      />
    );
  };

  const buildIcpModalContent = () => {
    return (
      <>
        <strong>Manually enter an {props.serviceType} ICP</strong>
        <Input
          labelText={`${props.serviceType} ICP`}
          handleChange={setIcp}
          value={icp}
        />
      </>
    );
  };

  // Build correct field type
  switch (props.modalType) {
    case "address":
      return buildAddressModal(props.serviceType);
    case "icp":
      return buildIcpModal();
    default:
      return null;
  }
};

export default ServiceModal;
