import React from "react";
import { StepList, HTML } from "react-components";

import { MyDetails as Constants } from "../../Config/Constants";

const Sidebar = () => (
  <div className="myDetails_sidebar">
    <h2 className="myDetails_sidebar_title">{Constants.title}</h2>
    <p className="myDetails_sidebar_intro">{Constants.intro}</p>
    <StepList className="myDetails_sidebar_stepList">
      {Constants.steps.map(({ title, icon, html }, key) => (
        <StepList.Item key={key} title={title} icon={icon}>
          <HTML html={html} />
        </StepList.Item>
      ))}
    </StepList>
  </div>
);

export default Sidebar;
