import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterUpdatePath,
  FourthTrimesterUpdateVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import { UpdateTrimesterParams, UpdateTrimesterResponse } from '../models';

export const updateTrimester = (
  version: FourthTrimesterUpdateVersion,
  requestId: string,
  params?: UpdateTrimesterParams,
  config?: AxiosRequestConfig
) => {
  const url = `${FourthTrimesterUpdatePath[version]}/${requestId}`;
  return baseInstance(config).put<UpdateTrimesterResponse>(url, params);
};
