import { useFeatureFlags } from "@contact/data-access-hooks";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import { useAuthSessionIDSelector } from "../CSRJoinJourney/Auth";

export const useFeatureFlag = (key: string) => {
  const sessionId = useAuthSessionIDSelector();

  const { data, isSuccess } = useFeatureFlags(
    "v1",
    process.env.NX_ENVIRONMENT_NAME as string,
    "",
    sessionId,
    baseAxiosConfig
  );

  const isFeatureFlagEnabled = isSuccess && data && data[key];

  return {
    isSuccess,
    data,
    isFeatureFlagEnabled,
  };
};
