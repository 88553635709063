import { createContext, useContext, useMemo } from 'react';
import {
  InteractiveBillCharge,
  InteractiveBillChargeClassificationDetail,
  InteractiveBillClassificationHeader,
  InteractiveBillInstallation,
  InteractiveBillPremise,
} from '@contact/data-access';
import {
  getIsNegative,
  useViewBillRegisterCurrentMeterReadType,
} from './useViewBill';

export interface ViewBillBodyClassificationContextValue {
  premiseHasSolar: boolean;
  premise: InteractiveBillPremise;
  installation: InteractiveBillInstallation;
  charges: InteractiveBillCharge[];
  classificationDetails: InteractiveBillChargeClassificationDetail[];
  classificationDetail: InteractiveBillChargeClassificationDetail;
  classificationHeader: InteractiveBillClassificationHeader;
  ICPNumber: InteractiveBillInstallation['ICP'];
  ClassificationTotal: string;
  AmountInclGST: string;
  GSTAmount: string;
}

export const ViewBillBodyClassificationContext = createContext<
  ViewBillBodyClassificationContextValue | undefined
>(undefined);
export const ViewBillBodyClassificationProvider =
  ViewBillBodyClassificationContext.Provider;

export function useViewBillBodyClassificationContextOptional(): ViewBillBodyClassificationContextValue {
  return useContext(
    ViewBillBodyClassificationContext
  ) as ViewBillBodyClassificationContextValue;
}

export function useViewBillBodyClassificationContext(): ViewBillBodyClassificationContextValue {
  const context = useViewBillBodyClassificationContextOptional();
  if (!context) {
    throw new Error('Expected ViewBillBodyClassificationProvider to be used');
  }
  return context;
}

export function useViewBillClassificationHeaderTranslationContext(
  defaultValue = 'NonClassification'
) {
  const options = useViewBillBodyClassificationContextOptional();
  if (!options) return defaultValue;
  const {
    classificationHeader: { AmountInclGST },
    premiseHasSolar,
  } = options;
  const context: string[] = [];
  if (premiseHasSolar) {
    context.push('Solar');
  }
  if (getIsNegative(AmountInclGST as string)) {
    context.push('Credit');
  }
  return context.join('_');
}

export function useViewBillClassificationHeaderTranslationOptions() {
  const context = useViewBillClassificationHeaderTranslationContext();
  const options = useViewBillBodyClassificationContextOptional();
  return useMemo(
    () => ({
      ...options,
      context,
      returnObjects: true,
      defaultValue: '',
    }),
    [context, options]
  );
}

export function useViewBillClassificationChargeTranslationOptions(
  charge?: InteractiveBillCharge
) {
  const tBaseOptions = useViewBillClassificationHeaderTranslationOptions();
  const tBaseCharge = tBaseOptions.classificationDetail;
  return useMemo(
    (): Record<string, unknown> => ({
      ...tBaseOptions,
      // These are all charge based fields
      // that we never want to be provided by tBaseOptions where we are talking about a charge
      // This ensures charge ?? tBaseCharge provides the values for these keys in all instances
      AmountInclGST: undefined,
      AmountExclGST: undefined,
      Price: undefined,
      ChargeType: undefined,
      Contract: undefined,
      Description: undefined,
      LineItemType: undefined,
      DynamicPeriodControl: undefined,
      ...(charge ?? tBaseCharge),
      UnitOfMeasure: charge?.UnitOfMeasure?.replace(
        /^Cents Per Day$/i,
        'cents/day'
      ),
      context: charge?.ChargeType ?? tBaseCharge?.ChargeType ?? '',
    }),
    [charge, tBaseOptions, tBaseCharge]
  );
}

export function useViewBillClassificationTranslationOptions() {
  return useViewBillClassificationHeaderTranslationOptions();
}

export function useViewBillClassificationRegisterCurrentMeterReadType() {
  const context = useViewBillBodyClassificationContextOptional();
  return useViewBillRegisterCurrentMeterReadType(
    context?.installation?.Installation,
    context?.classificationHeader?.Description
  );
}
