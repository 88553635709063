import { useQuery, UseQueryOptions } from 'react-query';
import {
  getBroadbandUsage,
  BroadbandUsagePath,
  BroadbandUsageParams,
  BroadbandUsageApiResponse,
  BroadbandUsageVersion,
  GetBroadbandUsageWeeklyDifferenceParams,
  BroadbandUsageWeeklyDifferenceApiSuccessResponse,
  getBroadbandUsageWeeklyDifference,
} from '@contact/data-access';

export interface BroadbandUsageHookParams extends BroadbandUsageParams {
  token: string;
}

export const useBroadbandUsage = (
  version: BroadbandUsageVersion,
  params: BroadbandUsageHookParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any, any, BroadbandUsageApiResponse, any>
) => {
  const { range, start, end, ba, token } = params;
  const valid = !!(token && ba && range && start);
  const enabled = valid && (options?.enabled ?? true);
  return useQuery(
    [BroadbandUsagePath[version], ba, range, start, end || ''],
    async () => {
      const { data } = await getBroadbandUsage(version, params);
      return data;
    },
    {
      ...options,
      enabled,
    }
  );
};

export const useBroadbandUsageWeeklyDifference = (
  params: GetBroadbandUsageWeeklyDifferenceParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<
    any,
    any,
    BroadbandUsageWeeklyDifferenceApiSuccessResponse,
    any
  >
) => {
  const { version, token, ba } = params;
  const valid = !!(token && ba);
  const enabled = valid && (options?.enabled ?? true);
  return useQuery(
    [BroadbandUsagePath[version], 'weekly-difference', token, ba],
    async () => {
      const { data } = await getBroadbandUsageWeeklyDifference(params);
      return data;
    },
    {
      ...options,
      enabled,
    }
  );
};
