import { AxiosRequestConfig } from 'axios';
import { BroadbandLookupPath, BroadbandLookupVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { BroadbandLookupApiResponse, BroadbandLookupParams } from './models';

export const broadbandLookup = (
  version: BroadbandLookupVersion,
  params?: BroadbandLookupParams,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<BroadbandLookupApiResponse>(
    BroadbandLookupPath[version],
    { params }
  );
};
