import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getGasRegistryICPDetails,
  GetGasRegistryICPDetailsPath,
  GetGasRegistryICPDetailsVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useGasRegistryICPDetails = (
  version: GetGasRegistryICPDetailsVersion,
  icp?: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [GetGasRegistryICPDetailsPath[version], icp],
    () =>
      cancellableQuery(async (extraOptions) => {
        return getGasRegistryICPDetails(version, icp as string, {
          ...options,
          ...extraOptions,
        });
      }),
    {
      enabled: !!icp,
    }
  );
