import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  validateTrimesterLink,
  ValidateLinkTrimesterResponse,
  FourthTrimesterValidateLinkVersion,
  FourthTrimesterValidateLinkPath,
} from '@contact/data-access';

export const useTrimesterValidateLink = (
  version: FourthTrimesterValidateLinkVersion,
  requestId: string,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<ValidateLinkTrimesterResponse>(
    [FourthTrimesterValidateLinkPath[version], requestId],
    async () => {
      const { data } = await validateTrimesterLink(version, requestId, options);
      return data;
    }
  );
};
