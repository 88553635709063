import { AxiosRequestConfig } from 'axios';

export const defaultConfig: AxiosRequestConfig = {
  baseURL:
    process.env.REACT_APP_BASE_URL || // For Non-NX app or lib to use
    process.env.NX_BASE_URL || // For NX app or lib to use
    'https://api.contact-digital-test.net',
  timeout: 60000, // value from react-ols settings
  headers: {
    'Content-Type': 'application/json',
    'x-api-key':
      process.env.REACT_APP_X_API_KEY ||
      process.env.NX_X_API_KEY ||
      'YowJxiYLxZ9Sjb2iF89az2E4CubDLHU46PjXxzJA',
  },
};
