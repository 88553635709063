import { getConfirmationReceiveNewsAndOffers } from "./Confirmation";
import {
  getPropertyInfoAddressDetails,
  usePropertyInfoAddressDetailsSelector,
} from "./Property/PropertyInfo";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "deep-equal";
import { RootState } from "../../Stores/csrAgentStore";
import {
  getSnapshotHashById,
  storeSnapshot,
} from "../../Reducers/SnapshotRedux";
import { useCallback, useMemo } from "react";
import { getAuthSessionID } from "./Auth";
import { useLoginTokenSelectorRequired } from "./Login";
import { useJourneyTypeSelector, getJourneyType } from "./JourneyType";
import { useHashed } from "../../../Utils/useHashed";
import { updateCreateBP } from "../../Actions/ApiCalls";
import { updateBP } from "../../Actions/CSRAgent/JoinAction";
import {
  updateBPError,
  updateBPFetched,
  updateBPFetching,
} from "../../Actions/CSRAgent/AboutYouAction";
import { apiResolveError } from "../../../Utils/ApiUtils";
import { pair } from "../../../Utils/pair";
import {
  getCustomerDriversLicense,
  getCustomerInfo,
  useCustomerInfoBusinessPartnerNumberSelector,
} from "./Customer";
import { getPhoneNumberType } from "Utils/getPhoneNumberType";

const getState = (state) => state;

export function getBPData(state) {
  const {
    DateOfBirth,
    PreferredName,
    FirstName,
    MiddleName,
    Title,
    EmailAddress,
    LastName,
    PhoneNumber,
  } = getCustomerInfo(state);
  const DriversLicense = getCustomerDriversLicense(state);
  const ReceiveNewsAndOffers = getConfirmationReceiveNewsAndOffers(state);
  const AddressDetails = getPropertyInfoAddressDetails(state);

  return {
    title: Title,
    preferredName: PreferredName,
    firstName: FirstName,
    middleName: MiddleName,
    lastName: LastName,
    phoneNumber: {
      type: getPhoneNumberType(PhoneNumber),
      number: PhoneNumber,
    },
    address:
      getJourneyType(state).toLowerCase() === "join"
        ? {
            pxid: AddressDetails?.pxid, // NOTE: only available for Address Finder matches, not manual entries
            number: AddressDetails?.number,
            street: AddressDetails?.street,
            postCode: AddressDetails?.postcode,
            city: AddressDetails?.city,
            country: "NZ",
            suburb: AddressDetails?.suburb,
            apartmentNumber:
              AddressDetails?.unit ||
              AddressDetails?.unit_identifier ||
              AddressDetails?.alpha,
          }
        : undefined,
    email: EmailAddress,
    isAllowedToSendMarketingEmails: ReceiveNewsAndOffers,
    driversLicence: DriversLicense?.Number
      ? {
          number: DriversLicense.Number,
          version: DriversLicense.Version,
          validTo: "9999/12/31",
        }
      : undefined,
    dateOfBirth: DateOfBirth?.split("/").reverse().join("/"),
    sex: "X",
  };
}

export function useBPDataSelector() {
  return useSelector(getBPData, isEqual);
}

export function useHasBPDataChanged() {
  const [storedHash] = useBPHash();
  const currentBPData = useBPDataSelector();
  const hashed = useHashed(currentBPData);

  return storedHash !== hashed;
}

export function getBPHashID(state) {
  const sessionId = getAuthSessionID(state);
  return `${sessionId}.BPData`;
}

export function useBPHashID() {
  return useSelector(getBPHashID);
}

export function useBPHashSelector() {
  const id = useBPHashID();
  const select = useCallback(
    (state: RootState) => getSnapshotHashById(state, id),
    [id]
  );
  return useSelector(select);
}

export function useBPHashSetter() {
  const dispatch = useDispatch();
  const id = useBPHashID();

  return useCallback(
    (hash: string) => {
      dispatch(
        storeSnapshot({
          id,
          hash,
        })
      );
    },
    [dispatch, id]
  );
}

export function useBPHash() {
  const value = useBPHashSelector();
  const setter = useBPHashSetter();

  return pair(value, setter);
}

export function useUpdateCreateBP() {
  const [, setBPHash] = useBPHash();
  const dispatch = useDispatch();

  const token = useLoginTokenSelectorRequired();
  const JourneyType = useJourneyTypeSelector();
  const config = useMemo(
    () => ({
      "access-token": token,
      journeyType: JourneyType.toLowerCase(),
    }),
    [JourneyType, token]
  );

  const AddressDetails = usePropertyInfoAddressDetailsSelector();
  const BusinessPartnerNumber = useCustomerInfoBusinessPartnerNumberSelector();
  const state = useSelector(getState);

  const currentBpData = useBPDataSelector();
  const hashed = useHashed(currentBpData);

  return useCallback(
    async (businessPartner: string | undefined = BusinessPartnerNumber) => {
      const pendingData = {
        ...currentBpData,
        businessPartner,
      };

      if (
        !AddressDetails ||
        !pendingData.dateOfBirth ||
        !pendingData.firstName ||
        !pendingData.lastName ||
        !pendingData.phoneNumber ||
        !pendingData.email
      ) {
        throw new Error("Required fields not provided");
      }

      try {
        dispatch(updateBPFetching(true));
        dispatch(updateBPFetched(false));

        const { data } = await updateCreateBP(pendingData, config);
        dispatch(updateBP(data));
        setBPHash(hashed);
        dispatch(updateBPFetching(false));
        dispatch(updateBPFetched(true));
      } catch (error) {
        dispatch(
          updateBPError(
            apiResolveError(
              {
                statusCode: error?.response?.status,
                serviceResponse: error?.response,
              },
              "bp",
              state
            )
          )
        );
      }
    },
    [
      AddressDetails,
      BusinessPartnerNumber,
      config,
      currentBpData,
      dispatch,
      hashed,
      setBPHash,
      state,
    ]
  );
}
