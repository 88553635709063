import { combineReducers } from "redux";
import Auth from "../AuthRedux";
import Customer from "./CustomerRedux";
import LpgInfo from "./BottledGasRedux";
import PipedGasInfo from "./GasRedux";
import Payment from "./PaymentRedux";
import Services from "./ServicesRedux";
import Property from "./PropertyRedux";
import Confirmation from "./ConfirmationRedux";
import BroadbandInfo from "./BroadbandRedux";
import BillCompare from "./BillCompareRedux";
import Plan from "./PlanRedux";
import Readonly from "./ReadonlyRedux";

export default combineReducers({
  Auth,
  Customer,
  LpgInfo,
  PipedGasInfo,
  Payment,
  Services,
  Property,
  Confirmation,
  BroadbandInfo,
  BillCompare,
  Plan,
  Readonly,
});
