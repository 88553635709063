import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";
import { BottledGas as Constants } from "../../../Config/Constants";
import { isOrderBottledGas } from "../../../Containers/BottledGas/utils";

export const initialState = {
  OrderBottledGas: isOrderBottledGas(Constants.numberOfBottlesDefault),
  NumberOfBottles: Constants.numberOfBottlesDefault,
  IsDifferentDeliveryAddress: false,
  DeliveryAddress: null,
  DeliveryInstructions: "",
  PaperlessStatements: Constants.paperlessStatementsDefault,
  BottledGasUsage: [],
  LocatedInGarage: undefined,
};

const updateProp = reducerPropUpdater("LpgInfo");

const updateBottledGas = (state, bottledGas) => {
  return { ...state, ...bottledGas };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_BOTTLED_GAS:
      return updateBottledGas(state, action.bottledGas);

    default:
      return state;
  }
};

export default reducer;
