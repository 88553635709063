import { parse, startOfToday, addDays, isAfter } from "date-fns";
import { ApiError } from "../Config/Constants";

const CONTRACT_END_DATE_FORMAT = "yyyy/MM/dd";

const isReturningWithin90Days = (account) => {
  return (
    account.status.toUpperCase() === "INACTIVE" &&
    !!account.contracts.find((contract) =>
      isAfter(
        parse(contract.endDate, CONTRACT_END_DATE_FORMAT, new Date()),
        addDays(startOfToday(), -90)
      )
    )
  );
};

export const apiResolveError = (
  { statusCode, serviceResponse },
  operation = "",
  state = null
) => {
  let data = null;
  if (serviceResponse) {
    data = serviceResponse.data || serviceResponse.problem;
  }

  if (statusCode && data) {
    const activeBaList = data?.customerDetails?.accounts
      ?.filter((item) => item.status.toUpperCase() === "ACTIVE")
      .map((item) => item.id);

    switch (true) {
      case statusCode === 400:
        return "badRequestError";
      case statusCode === 401:
        return "unauthorizedError";
      case statusCode > 401 && statusCode < 500:
        return "clientError";
      case statusCode > 499:
        if (state && data?.error?.includes("currently being processed")) {
          const bp = data.error.match(/\d+/g)[0];
          return {
            errorMessage: "processError",
            errorDetails: { businessPartner: bp },
          };
        } else if (
          state &&
          (data?.error?.includes("exists with") ||
            data?.error?.includes("is already assigned to partner"))
        ) {
          if (activeBaList?.length) {
            data.customerDetails.accounts = activeBaList;
            return {
              errorMessage: "baExists",
              errorDetails: data.customerDetails,
            };
          } else if (
            data?.customerDetails?.accounts?.find(isReturningWithin90Days)
          ) {
            data.customerDetails.accounts = data.customerDetails.accounts
              .filter(isReturningWithin90Days)
              .map((item) => item.id);
            return {
              errorMessage: "returningWithin90Days",
              errorDetails: data.customerDetails,
            };
          } else if (data?.customerDetails?.driversLicence?.number) {
            return {
              errorMessage: "dlExistError",
              errorDetails: data.customerDetails,
            };
          } else {
            return {
              errorMessage: "userExists",
              errorDetails: data.customerDetails,
            };
          }
        }
        return `${operation}ServerError`;
      default:
        return "clientError";
    }
  } else if (statusCode === null && !data) {
    return "dataError";
  } else {
    return "apiTimeoutError";
  }
};

// Sharan: We can update the message as we work on future tickets.
export const convertApiErrorToText = (error) => {
  switch (error) {
    case "returningWithin90Days":
      return {
        blurb1: ApiError.returningWithin90DaysErrorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "baExists":
      return {
        blurb1: ApiError.baExistsErrorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "dlExistError":
      return {
        blurb1: ApiError.dlExistsErrorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "userExists":
      return {
        blurb1: ApiError.dlExistsErrorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "badRequestError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "clientError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "ServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.serverErrorMessage,
      };
    case "bpServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.bpServerErrorMessage,
      };
    case "ccServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.ccServerErrorMessage,
      };
    case "dataError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "apiTimeoutError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    default:
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
  }
};
