import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandLookupParams,
  broadbandLookup,
  BroadbandLookupVersion,
  BroadbandLookupPath,
  BroadbandLookupApiResponse,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useBroadbandLookup = (
  version: BroadbandLookupVersion,
  params?: BroadbandLookupParams,
  axiosOptions?: Partial<AxiosRequestConfig>,
  queryOptions?: UseQueryOptions<BroadbandLookupApiResponse>
) =>
  useQuery<BroadbandLookupApiResponse>(
    [BroadbandLookupPath[version], params],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await broadbandLookup(version, params, {
          ...axiosOptions,
          ...extraOptions,
        });
        return data;
      }),
    {
      ...queryOptions,
      enabled: !!params,
    }
  );
