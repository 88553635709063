import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { getRewards, RewardsPath, RewardsVersion } from '@contact/data-access';

export const useRewards = (
  version: RewardsVersion,
  token: string,
  accountId: string,
  rewardType?: string,
  config?: AxiosRequestConfig
) =>
  useQuery(
    [RewardsPath[version], token, accountId, rewardType],
    async () => {
      const { data } = await getRewards(
        version,
        token,
        accountId,
        rewardType,
        config
      );
      return data;
    },
    {
      enabled: !!(token && accountId), // reward type is optional
      retry: false,
    }
  );
