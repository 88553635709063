import React from "react";
import { HTML, Modal } from "react-components";
import { Payment as Constants } from "../../Config/Constants";

const VerificationFailedModal = (props) => (
  <Modal
    isActive={props.isActive}
    className="payment_verificationFailedModal"
    handlePrimaryClick={props.onClose}
    content={<HTML html={Constants.verificationFailedModal[props.type]} />}
    buttonTextPrimary={Constants.verificationFailedModal.buttonLabel}
  />
);

export default VerificationFailedModal;
