import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  createSaveForLater,
  CreateSaveForLaterApiResponse,
  CreateSaveForLaterVersion,
  JourneyType,
  Origin,
} from '@contact/data-access';

type TData = CreateSaveForLaterApiResponse;
type TError = unknown;
type TVariables = {
  journeyType: JourneyType;
  origin: Origin;
  saveForLaterEmail: string;
  state: Record<string, unknown>;
};

export const useCreateSaveForLater = (
  version: CreateSaveForLaterVersion,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables>
) =>
  useMutation<TData, TError, TVariables>(
    async ({ journeyType, origin, saveForLaterEmail, state }) => {
      const { data } = await createSaveForLater(
        version,
        journeyType,
        origin,
        saveForLaterEmail,
        state,
        axiosOptions
      );
      return data;
    },
    mutationOptions
  );
