import { initialState } from "../../Reducers/iJoin/BottledGasRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { pair } from "../utils";
import { updateBottledGas } from "../../Actions/iJoin/BottledGasActions";
import { SELECT_ZONE } from "../../../Config/Constants";

interface LpgInfo {
  OrderBottledGas: boolean;
  NumberOfBottles: string;
  IsDifferentDeliveryAddress: boolean;
  DeliveryAddress: null | string;
  DeliveryInstructions: string;
  PaperlessStatements: boolean;
  BottledGasUsage: string[];
  LocatedInGarage: boolean;
  Zone: string;
  LocationId: number;
  CylinderPricing?: string;
  BottleRental?: string;
  RentalStartDate?: string;
}

export type GasUsageOption = {
  checked?: boolean;
  code: string;
  name: string;
  label?: string;
  bg: string;
  bgSelected: string;
  bgHover: string;
  bgSelectedHover: string;
};

export type SiteAccessOption = {
  checked?: boolean;
  name: string;
  value: boolean;
  label: string;
};

function getLpgInfo(state): LpgInfo {
  return state.LpgInfo || initialState;
}

export function getLpgInfoOrderBottledGas(state) {
  return getLpgInfo(state).OrderBottledGas;
}

export function useLpgInfoOrderBottledGasSelector() {
  return useSelector(getLpgInfoOrderBottledGas);
}

export function useLpgInfoOrderBottledGasSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (OrderBottledGas: LpgInfo["OrderBottledGas"]) =>
      dispatch(updateBottledGas({ OrderBottledGas })),
    [dispatch]
  );
}

export function useLpgInfoOrderBottledGas() {
  const value = useLpgInfoOrderBottledGasSelector();
  const setter = useLpgInfoOrderBottledGasSetter();
  return pair(value, setter);
}

export function getLpgInfoNumberOfBottles(state) {
  return getLpgInfo(state).NumberOfBottles;
}

export function useLpgInfoNumberOfBottlesSelector() {
  return useSelector(getLpgInfoNumberOfBottles);
}

export function useLpgInfoNumberOfBottlesSetter() {
  const dispatch = useDispatch();
  const updateOrderStatus = useLpgInfoOrderBottledGasSetter();

  return useCallback(
    (NumberOfBottles: LpgInfo["NumberOfBottles"]) => {
      dispatch(updateBottledGas({ NumberOfBottles }));
      updateOrderStatus(parseInt(NumberOfBottles, 10) > 0);
    },
    [dispatch, updateOrderStatus]
  );
}

export function useLpgInfoNumberOfBottles() {
  const value = useLpgInfoNumberOfBottlesSelector();
  const setter = useLpgInfoNumberOfBottlesSetter();
  return pair(value, setter);
}

export function getLpgInfoDeliveryAddress(state) {
  return getLpgInfo(state).DeliveryAddress;
}

export function useLpgInfoDeliveryAddressSelector() {
  return useSelector(getLpgInfoDeliveryAddress);
}

export function useLpgInfoDeliveryAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (DeliveryAddress: LpgInfo["DeliveryAddress"]) =>
      dispatch(updateBottledGas({ DeliveryAddress })),
    [dispatch]
  );
}

export function useLpgInfoDeliveryAddress() {
  const value = useLpgInfoDeliveryAddressSelector();
  const setter = useLpgInfoDeliveryAddressSetter();
  return pair(value, setter);
}

export function getLpgInfoDeliveryInstructions(state) {
  return getLpgInfo(state).DeliveryInstructions;
}

export function useLpgInfoDeliveryInstructionsSelector() {
  return useSelector(getLpgInfoDeliveryInstructions);
}

export function useLpgInfoDeliveryInstructionsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (DeliveryInstructions: LpgInfo["DeliveryInstructions"]) =>
      dispatch(updateBottledGas({ DeliveryInstructions })),
    [dispatch]
  );
}

export function useLpgInfoDeliveryInstructions() {
  const value = useLpgInfoDeliveryInstructionsSelector();
  const setter = useLpgInfoDeliveryInstructionsSetter();
  return pair(value, setter);
}

export function getLpgInfoPaperlessStatements(state) {
  return getLpgInfo(state).PaperlessStatements;
}

export function useLpgInfoPaperlessStatementsSelector() {
  return useSelector(getLpgInfoPaperlessStatements);
}

export function useLpgInfoPaperlessStatementsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PaperlessStatements: LpgInfo["PaperlessStatements"]) =>
      dispatch(updateBottledGas({ PaperlessStatements })),
    [dispatch]
  );
}

export function useLpgInfoPaperlessStatements() {
  const value = useLpgInfoPaperlessStatementsSelector();
  const setter = useLpgInfoPaperlessStatementsSetter();
  return pair(value, setter);
}

// Bottled Gas Usage
export function getLpgInfoBottledGasUsage(state) {
  return getLpgInfo(state).BottledGasUsage || initialState.BottledGasUsage;
}

export function useLpgInfoBottledGasUsageSelector() {
  return useSelector(getLpgInfoBottledGasUsage);
}

export function useLpgInfoBottledGasUsageSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BottledGasUsage: LpgInfo["BottledGasUsage"]) =>
      dispatch(updateBottledGas({ BottledGasUsage })),
    [dispatch]
  );
}

export function useLpgInfoBottledGasUsage() {
  const value = useLpgInfoBottledGasUsageSelector();
  const setter = useLpgInfoBottledGasUsageSetter();
  return pair(value, setter);
}

// Bottled Gas Usage for CheckboxList which is used in Join
// https://contactenergy.atlassian.net/browse/CSRT-1735
export function useLpgInfoBottledGasUsageSelectorForCheckboxList(
  options: GasUsageOption[]
) {
  const selectedGasUsages = useSelector(getLpgInfoBottledGasUsage);
  return useMemo(() => {
    return options.map((option) => ({
      ...option,
      checked: selectedGasUsages.includes(option.code),
    }));
  }, [selectedGasUsages, options]);
}

export function useLpgInfoBottledGasUsageSetterForCheckboxList(
  options: GasUsageOption[]
) {
  const dispatch = useDispatch();

  return useCallback(
    (index: number) => {
      const newOptions = [...options];
      const selectedOption = newOptions[index];
      selectedOption.checked = !selectedOption.checked;

      const checkedGasUsages = newOptions
        .filter((option) => option.checked)
        .map((option) => option.code);

      dispatch(updateBottledGas({ BottledGasUsage: checkedGasUsages }));
    },
    [dispatch, options]
  );
}

export function useLpgInfoBottledGasUsageForCheckboxList(
  options: GasUsageOption[]
) {
  // UsageOptions (GasUsageOption[]) is used for the UI CheckboxList
  const bottledGasUsageOptions = useLpgInfoBottledGasUsageSelectorForCheckboxList(
    options
  );

  const setBottledGasUsageOptions = useLpgInfoBottledGasUsageSetterForCheckboxList(
    options
  );

  // UsageList (string[]) is used for redux state, we need to keep this list since
  // Move / Add journeys are still using the ServiceButtons for Gas Usage which uses this string list
  const bottledGasUsageList = useMemo(
    () =>
      bottledGasUsageOptions
        .filter((option) => option.checked)
        .map((option) => option.code),
    [bottledGasUsageOptions]
  );

  const setBottledGasUsageList = useLpgInfoBottledGasUsageSetter();

  return {
    bottledGasUsageOptions,
    setBottledGasUsageOptions,
    bottledGasUsageList,
    setBottledGasUsageList,
  };
}

// Site Access State
export function getLpgInfoLocatedInGarage(state) {
  return getLpgInfo(state).LocatedInGarage;
}

export function useLpgInfoLocatedInGarageSelector() {
  return useSelector(getLpgInfoLocatedInGarage);
}

export function useLpgInfoSiteAccessOptionsSelector(
  options: SiteAccessOption[]
) {
  const selectedValue = useSelector(getLpgInfoLocatedInGarage);
  return useMemo(() => {
    return options.map((option) => ({
      ...option,
      checked: option.value === selectedValue,
    }));
  }, [options, selectedValue]);
}

export function useLpgInfoLocatedInGarageSetter(options: SiteAccessOption[]) {
  const dispatch = useDispatch();
  return useCallback(
    (index: number) => {
      const newOptions = [...options];
      newOptions.forEach((option) => {
        option.checked = false;
      });
      const selectedOption = newOptions[index];
      selectedOption.checked = true;

      const value = newOptions.find((option) => option.checked)?.value;
      dispatch(updateBottledGas({ LocatedInGarage: value }));
    },
    [dispatch, options]
  );
}

export function useLpgInfoLocatedInGarage(options: SiteAccessOption[] = []) {
  const locatedInGarage = useLpgInfoLocatedInGarageSelector();
  const setLocatedInGarage = useLpgInfoLocatedInGarageSetter(options);
  const siteAccessOptions = useLpgInfoSiteAccessOptionsSelector(options);
  return { locatedInGarage, setLocatedInGarage, siteAccessOptions };
}

export function getLpgInfoIsDifferentDeliveryAddress(state) {
  return getLpgInfo(state).IsDifferentDeliveryAddress;
}

export function useLpgInfoIsDifferentDeliveryAddressSelector() {
  return useSelector(getLpgInfoIsDifferentDeliveryAddress);
}

export function useLpgInfoIsDifferentDeliveryAddressSetter() {
  const dispatch = useDispatch();
  const updateDeliveryAddress = useLpgInfoDeliveryAddressSetter();
  return useCallback(
    (IsDifferentDeliveryAddress: LpgInfo["IsDifferentDeliveryAddress"]) => {
      dispatch(updateBottledGas({ IsDifferentDeliveryAddress }));

      if (!IsDifferentDeliveryAddress) {
        // Clear the address when the delivery address checkbox is unchecked
        updateDeliveryAddress(null);
      }
    },
    [dispatch, updateDeliveryAddress]
  );
}

export function useLpgInfoIsDifferentDeliveryAddress() {
  const value = useLpgInfoIsDifferentDeliveryAddressSelector();
  const setter = useLpgInfoIsDifferentDeliveryAddressSetter();
  return pair(value, setter);
}

export function getBottleGasZone(state) {
  return getLpgInfo(state).Zone ?? SELECT_ZONE;
}

export function getBottleGasLocationId(state) {
  return getLpgInfo(state).LocationId;
}

export function useBottleGasZoneSelector(): LpgInfo["Zone"] {
  return useSelector(getBottleGasZone);
}

export function useBottleGasLocationIdSelector(): LpgInfo["LocationId"] {
  return useSelector(getBottleGasLocationId);
}

export function useBottleGasZoneSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Zone: LpgInfo["Zone"]) => {
      dispatch(
        updateBottledGas({
          Zone: Zone === SELECT_ZONE ? undefined : Zone,
        })
      );
    },
    [dispatch]
  );
}

export function useBottleGasLocationIdSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (LocationId: LpgInfo["LocationId"]) => {
      dispatch(
        updateBottledGas({
          LocationId: LocationId,
        })
      );
    },
    [dispatch]
  );
}

export function useBottleGasZone() {
  const value = useBottleGasZoneSelector();
  const setter = useBottleGasZoneSetter();
  return pair(value, setter);
}
export function useBottledGasLocationId() {
  const value = useBottleGasLocationIdSelector();
  const setter = useBottleGasLocationIdSetter();
  return pair(value, setter);
}

export function getBottleGasCylinderPricing(state) {
  return getLpgInfo(state).CylinderPricing;
}

export function useBottleGasCylinderPricingSelector(): LpgInfo["CylinderPricing"] {
  return useSelector(getBottleGasCylinderPricing);
}

export function useBottleGasCylinderPricingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (CylinderPricing: LpgInfo["CylinderPricing"]) => {
      dispatch(
        updateBottledGas({
          CylinderPricing,
        })
      );
    },
    [dispatch]
  );
}

export function useBottleGasCylinderPricing() {
  const value = useBottleGasCylinderPricingSelector();
  const setter = useBottleGasCylinderPricingSetter();
  return pair(value, setter);
}

export function getBottleGasBottleRental(state) {
  return getLpgInfo(state).BottleRental;
}

export function useBottleGasBottleRentalSelector(): LpgInfo["BottleRental"] {
  return useSelector(getBottleGasBottleRental);
}

export function useBottleGasBottleRentalSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BottleRental: LpgInfo["BottleRental"]) => {
      dispatch(
        updateBottledGas({
          BottleRental,
        })
      );
    },
    [dispatch]
  );
}

export function useBottleGasBottleRental() {
  const value = useBottleGasBottleRentalSelector();
  const setter = useBottleGasBottleRentalSetter();
  return pair(value, setter);
}

export function getBottleGasRentalStartDate(state) {
  return getLpgInfo(state).RentalStartDate;
}

export function useBottleGasRentalStartDateSelector(): LpgInfo["RentalStartDate"] {
  return useSelector(getBottleGasRentalStartDate);
}

export function useBottleGasRentalStartDateSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (RentalStartDate: LpgInfo["RentalStartDate"]) => {
      dispatch(
        updateBottledGas({
          RentalStartDate,
        })
      );
    },
    [dispatch]
  );
}

export function useBottleGasRentalStartDate() {
  const value = useBottleGasRentalStartDateSelector();
  const setter = useBottleGasRentalStartDateSetter();
  return pair(value, setter);
}
