import { createContext, useContext } from 'react';

export interface DownloadOptions {
  base64?: string;
  mime: string;
  /** Filename. Ignored when `open` options is used. */
  name: string;
  /** Open the downloaded file in another tab instead of saving it to disk. */
  open?: boolean;
}

export interface DownloadFn {
  (options: DownloadOptions): void | Promise<void>;
}

export const DownloadContext = createContext<DownloadFn | undefined>(undefined);
export const DownloadProvider = DownloadContext.Provider;

export function useDownload() {
  const fn = useContext(DownloadContext);
  if (!fn) throw new Error('Expected DownloadProvider to be used');
  return fn;
}
