export interface Contract {
  contractId: string;
  startDate: string;
  endDate: string;
}

export interface Benefit {
  id: string;
  description: string;
  value: string;
  unit: string;
  devision: string;
  validityStartDate: string;
  validityEndDate: string;
}

export interface Price {
  gstInclusiveMonthlyPrice?: string;
}

export interface Addon {
  addOnId: string;
  addOnDescription: string;
  addonPrice: string;
}

export interface PlanDetails {
  planId?: string;
  internalPlanDescription: string;
  externalPlanDescription: string;
  internalBenefitGroupDescription: string;
  externalBenefitGroupDescription: string;
  campaignId?: string;
  campaignDesc?: string;
  oneOffCreditAmount: string;
  earlyTerminationFeeAmount: string;
  benefitStartDate?: string;
  benefitEndDate?: string;
  campaignTerm: CampaignTerm;
  campaignTermDescription?: string;
  packageId?: string;
  packageInternalDescription?: string;
  packageExternalDescription?: string;
  ppdPercentage?: string;
  benefits?: Benefit[];
  price: Price;
  addons?: Addon[];
}

export enum ServiceType {
  Electricity = 'ELEC',
  Gas = 'GAS',
  Broadband = 'BROADBAND',
}

export enum ServiceSubType {
  RLPG = 'RLPG',
  NGAS = 'NGAS',
  Fibre = 'Fibre',
  Wireless = 'FixedWireless',
  ADSL = 'ADSL',
  VDSL = 'VDSL',
}

export enum CampaignTerm {
  Open = 'Open',
  Fixed = 'Fixed',
  DurationBased = 'DurationBased',
}

export interface Service {
  serviceType: ServiceType;
  serviceDescription: string;
  consumptionRangeId: string;
  consumptionRangeDescription: string;
  contract: Contract;
  planDetails: PlanDetails;
  serviceSubType?: ServiceSubType;
  consumptionType?: string;
  consumptionArea?: string;
  oneOffCreditAmount?: string;
}

export interface Premises {
  premiseId: string;
  services: Service[];
}

export interface PanelPlansApiResponse {
  accountId: string;
  premises: Premises[];
}
