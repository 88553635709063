import { REDIRECT } from "../Actions/actionTypes";

export default () => (next) => (action) => {
  switch (action.type) {
    case REDIRECT:
      window.location.href = action.url;
      break;

    default:
      return next(action);
  }
};
