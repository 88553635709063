import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandAddressDetails,
  BroadbandOfferingsByPlansApiResponse,
  UserType,
} from './models';
import {
  GetBroadbandOfferingsByPlansPath,
  GetBroadbandOfferingsByPlansVersion,
} from './apiPaths';

export const getBroadbandOfferingsByPlans = (
  version: GetBroadbandOfferingsByPlansVersion,
  plans: string[],
  services: string[],
  addressDetails: BroadbandAddressDetails,
  userType: UserType,
  inclusions?: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<BroadbandOfferingsByPlansApiResponse>(
    GetBroadbandOfferingsByPlansPath[version],
    { userType, plans, services, addressDetails, inclusions }
  );
};
