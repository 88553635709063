import { initialState } from "../../Reducers/iJoin/ServicesRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { updateServices } from "../../Actions/iJoin/ServicesActions";
import { isInList, pair } from "../utils";
import {
  usePropertyInfoAddressIsSouthIsland,
  usePropertyInfoAddressSelector,
  usePropertyInfoElectricityICPSelector,
  usePropertyInfoGasICPSelector,
} from "./Property/PropertyInfo";
import { SERVICE_ELEC, SERVICE_GAS } from "../../../Config/Constants";

export type Service = "BB" | "GAS" | "ELEC" | "LPG";
export type Services = Service[];

export function getServices(state) {
  return state.Services || initialState;
}

export function useServicesSelector() {
  return useSelector(getServices);
}

export function useServicesSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (services: Services) => dispatch(updateServices(services)),
    [dispatch]
  );
}

export function useServices() {
  const value = useServicesSelector();
  const setter = useServicesSetter();
  return pair(value, setter);
}

export function useIsBroadbandInServiceList() {
  return isInList("BB", useServicesSelector());
}

export function useIsElectricityInServiceList() {
  return isInList("ELEC", useServicesSelector());
}

export function useIsLPGInServiceList() {
  return isInList("LPG", useServicesSelector());
}

export function useIsGasInServiceList() {
  return isInList("GAS", useServicesSelector());
}

export function useIsLPGOnly() {
  const isLPGInServiceList = useIsLPGInServiceList();
  const selectedServiceList = useServicesSelector();
  return isLPGInServiceList && selectedServiceList.length === 1;
}

export function useIsGasOnly() {
  const isGasInServiceList = useIsGasInServiceList();
  const selectedServiceList = useServicesSelector();
  return isGasInServiceList && selectedServiceList.length === 1;
}

export function useIsNGas() {
  const isGasInServiceList = useIsGasInServiceList();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  return isGasInServiceList && !isSouthIsland;
}

export function useIsRLPGOnly() {
  const isGasOnly = useIsGasOnly();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  return isGasOnly && isSouthIsland;
}

export function useServiceNoIcpMessage() {
  const serviceAddress = usePropertyInfoAddressSelector();
  const isElectricityInServiceList = useIsElectricityInServiceList();
  const isGasInServiceList = useIsGasInServiceList();
  const icp = usePropertyInfoElectricityICPSelector();
  const gasIcp = usePropertyInfoGasICPSelector();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  return useMemo(() => {
    const hasNoElecIcp = serviceAddress && isElectricityInServiceList && !icp;
    const hasNoGasIcp =
      serviceAddress && isGasInServiceList && !gasIcp && !isSouthIsland;
    let icpMessage = "";
    if (hasNoElecIcp && hasNoGasIcp) {
      icpMessage = `${SERVICE_ELEC} and ${SERVICE_GAS}`;
    } else if (hasNoGasIcp) {
      icpMessage = SERVICE_GAS;
    } else if (hasNoElecIcp) {
      icpMessage = SERVICE_ELEC;
    }
    return icpMessage;
  }, [
    gasIcp,
    icp,
    isElectricityInServiceList,
    isGasInServiceList,
    isSouthIsland,
    serviceAddress,
  ]);
}
