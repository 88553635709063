import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterSubmitPath,
  FourthTrimesterSubmitVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import { SubmitTrimesterParams, SubmitTrimesterResponse } from '../models';

export const submitTrimester = (
  version: FourthTrimesterSubmitVersion,
  requestId: string,
  params?: SubmitTrimesterParams,
  config?: AxiosRequestConfig
) => {
  const url = `${FourthTrimesterSubmitPath[version]}/${requestId}`;
  return baseInstance(config).post<SubmitTrimesterResponse>(url, params);
};
