import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { PlansApiResponse } from './models';
import { PlansPath, PlansVersion } from '..';

export const getPlans = (
  version: PlansVersion,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<PlansApiResponse>(PlansPath[version]);
};
