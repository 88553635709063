import { AxiosRequestConfig } from 'axios';
import { AasfPath, AasfVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { AasfApiResponse } from './models';

export const aasf = (
  version: AasfVersion,
  bpId: string,
  sessionToken: string,
  xcsrfToken: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<AasfApiResponse>(
    `${AasfPath[version]}/${bpId}`,
    {
      headers: {
        session: sessionToken,
        'x-csrf-token': xcsrfToken,
      },
    }
  );
};
