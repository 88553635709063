import { initialState } from "../../../Reducers/iJoin/PropertyRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { updateProperty } from "../../../Actions/iJoin/PropertyActions";
import { pair } from "../../utils";
import {
  USER_TYPE_LOW,
  USER_TYPE_STANDARD,
} from "../../../../react-components/Components/Rates/constants";
import { AgentJoin as Constants } from "../../../../Config/Constants";
import { useCustomerInfoServiceAddressSetter } from "../Customer";

export interface AddressDetails {
  a?: string;
  id?: string;
  pxid?: number | string;
  address_id?: number | string;
  number: string;
  street: string;
  postcode: string;
  city: string;
  suburb: string;
  unit?: string;
  unit_identifier?: string;
  alpha?: string;
  full_address?: string;
}

interface PropertyInfo {
  Address?: string;
  QASAddress?: string;
  AddressSameAsPostalAddress: boolean;
  Moniker?: number | string;
  ElectricityMeterType?: string;
  ElectricityICP?: string;
  ElectricityEmbeddedNetwork?: boolean;
  ElectricityTrader?: string;
  GasICP?: string;
  AddressICP?: string;
  ElectricityUserType?: string;
  Hazards: string[];
  HazardsOther?: string;
  HazardsDog?: string;
  HasDogs: boolean;
  WithAnotherSupplier: boolean;
  OwnsProperty: boolean;
  IsBach: boolean;
  HaveFinalMeterReading: boolean;
  FinalElectricityMeterRead?: string;
  FinalGasMeterRead?: string;
  SendMeterReader: boolean;
  AddressDetails?: AddressDetails;
  ManualAddress?: boolean;
  NeedGasReconnection?: boolean;
  NeedElecReconnection?: boolean;
  ContactHasMeterKey?: boolean;
  ContinueUsingKeys?: boolean;
}

function updatePropertyInfo(info: Partial<PropertyInfo>) {
  return updateProperty({
    PropertyInfo: info,
  });
}

export function getPropertyInfo(state): PropertyInfo {
  return (
    (state.Property || initialState).PropertyInfo || initialState.PropertyInfo
  );
}

export function usePropertyInfo() {
  return useSelector(getPropertyInfo);
}

export function getPropertyInfoAddress(state) {
  return getPropertyInfo(state).Address;
}

export function usePropertyInfoAddressSelector() {
  return useSelector(getPropertyInfoAddress);
}

export function usePropertyInfoAddressSetter() {
  return useCustomerInfoServiceAddressSetter();
}

export function usePropertyInfoAddress() {
  const value = usePropertyInfoAddressSelector();
  const setter = usePropertyInfoAddressSetter();
  return pair(value, setter);
}

export function isPostcodeSouthIsland(postcode?: string) {
  if (postcode?.length !== 4) return;
  const numeric = +postcode;
  return numeric >= 7000;
}

export function usePropertyInfoAddressIsSouthIsland() {
  const address = usePropertyInfoAddressSelector();
  const postcode = useMemo(() => {
    if (!address) return undefined;
    const postcodeLike = address.match(/(\d{4})/g);
    if (!postcodeLike?.length) return undefined;
    return postcodeLike[postcodeLike.length - 1];
  }, [address]);
  // Note default is false
  return isPostcodeSouthIsland(postcode);
}

export function getPropertyInfoQASAddress(state) {
  return getPropertyInfo(state).QASAddress;
}

export function usePropertyInfoQASAddressSelector() {
  return useSelector(getPropertyInfoQASAddress);
}

export function usePropertyInfoQASAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (QASAddress: PropertyInfo["QASAddress"]) =>
      dispatch(updatePropertyInfo({ QASAddress })),
    [dispatch]
  );
}

export function usePropertyInfoQASAddress() {
  const value = usePropertyInfoQASAddressSelector();
  const setter = usePropertyInfoQASAddressSetter();
  return pair(value, setter);
}

export function getPropertyInfoAddressSameAsPostalAddress(state) {
  return getPropertyInfo(state).AddressSameAsPostalAddress;
}

export function usePropertyInfoAddressSameAsPostalAddressSelector() {
  return useSelector(getPropertyInfoAddressSameAsPostalAddress);
}

export function usePropertyInfoAddressSameAsPostalAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AddressSameAsPostalAddress: PropertyInfo["AddressSameAsPostalAddress"]) =>
      dispatch(updatePropertyInfo({ AddressSameAsPostalAddress })),
    [dispatch]
  );
}

export function usePropertyInfoAddressSameAsPostalAddress() {
  const value = usePropertyInfoAddressSameAsPostalAddressSelector();
  const setter = usePropertyInfoAddressSameAsPostalAddressSetter();
  return pair(value, setter);
}

export function getPropertyInfoMoniker(state) {
  return getPropertyInfo(state).Moniker;
}

export function usePropertyInfoMonikerSelector() {
  return useSelector(getPropertyInfoMoniker);
}

export function usePropertyInfoMonikerSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Moniker: PropertyInfo["Moniker"]) =>
      dispatch(updatePropertyInfo({ Moniker })),
    [dispatch]
  );
}

export function usePropertyInfoMoniker() {
  const value = usePropertyInfoMonikerSelector();
  const setter = usePropertyInfoMonikerSetter();
  return pair(value, setter);
}

export function getPropertyInfoElectricityMeterType(state) {
  return getPropertyInfo(state).ElectricityMeterType;
}

export function usePropertyInfoElectricityMeterTypeSelector() {
  return useSelector(getPropertyInfoElectricityMeterType);
}

export function usePropertyInfoElectricityMeterTypeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ElectricityMeterType: PropertyInfo["ElectricityMeterType"]) =>
      dispatch(updatePropertyInfo({ ElectricityMeterType })),
    [dispatch]
  );
}

export function usePropertyInfoElectricityMeterType() {
  const value = usePropertyInfoElectricityMeterTypeSelector();
  const setter = usePropertyInfoElectricityMeterTypeSetter();
  return pair(value, setter);
}

export function getPropertyInfoAddressICP(state) {
  return getPropertyInfo(state).AddressICP;
}

export function usePropertyInfoAddressICPSelector() {
  return useSelector(getPropertyInfoAddressICP);
}

export function usePropertyInfoAddressICPSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AddressICP: PropertyInfo["AddressICP"]) =>
      dispatch(updatePropertyInfo({ AddressICP })),
    [dispatch]
  );
}

export function usePropertyInfoAddressICP() {
  const value = usePropertyInfoAddressICPSelector();
  const setter = usePropertyInfoAddressICPSetter();
  return pair(value, setter);
}

export function getPropertyInfoElectricityICP(state) {
  return getPropertyInfo(state).ElectricityICP;
}

export function usePropertyInfoElectricityICPSelector() {
  return useSelector(getPropertyInfoElectricityICP);
}

export function usePropertyInfoElectricityICPSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ElectricityICP: PropertyInfo["ElectricityICP"]) =>
      dispatch(updatePropertyInfo({ ElectricityICP })),
    [dispatch]
  );
}

export function usePropertyInfoElectricityICP() {
  const value = usePropertyInfoElectricityICPSelector();
  const setter = usePropertyInfoElectricityICPSetter();
  return pair(value, setter);
}

export function getPropertyInfoGasICP(state) {
  return getPropertyInfo(state).GasICP;
}

export function usePropertyInfoGasICPSelector() {
  return useSelector(getPropertyInfoGasICP);
}

export function usePropertyInfoGasICPSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (GasICP: PropertyInfo["GasICP"]) =>
      dispatch(updatePropertyInfo({ GasICP })),
    [dispatch]
  );
}

export function usePropertyInfoGasICP() {
  const value = usePropertyInfoGasICPSelector();
  const setter = usePropertyInfoGasICPSetter();
  return pair(value, setter);
}

function getElectricityUserType(electricityUserType?: string) {
  return electricityUserType === Constants.planServiceDetails.lowUser
    ? Constants.planServiceDetails.low
    : electricityUserType;
}

export function getPropertyInfoElectricityUserType(state) {
  const info = getPropertyInfo(state);
  return getElectricityUserType(info.ElectricityUserType);
}

export function usePropertyInfoElectricityUserTypeSelector() {
  return useSelector(getPropertyInfoElectricityUserType);
}

export function usePropertyInfoElectricityUserTypeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ElectricityUserType: PropertyInfo["ElectricityUserType"]) =>
      dispatch(
        updatePropertyInfo({
          ElectricityUserType:
            ElectricityUserType === Constants.planServiceDetails.low
              ? Constants.planServiceDetails.lowUser
              : ElectricityUserType,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoElectricityUserType() {
  const value = usePropertyInfoElectricityUserTypeSelector();
  const setter = usePropertyInfoElectricityUserTypeSetter();
  return pair(value, setter);
}

export function getPropertyInfoHazards(state) {
  return getPropertyInfo(state).Hazards;
}

export function usePropertyInfoHazardsSelector() {
  return useSelector(getPropertyInfoHazards);
}

export function usePropertyInfoHazardsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Hazards: PropertyInfo["Hazards"]) =>
      dispatch(updatePropertyInfo({ Hazards })),
    [dispatch]
  );
}

export function usePropertyInfoHazards() {
  const value = usePropertyInfoHazardsSelector();
  const setter = usePropertyInfoHazardsSetter();
  return pair(value, setter);
}

export function getPropertyInfoHazardsOther(state) {
  return getPropertyInfo(state).HazardsOther;
}

export function usePropertyInfoHazardsOtherSelector() {
  return useSelector(getPropertyInfoHazardsOther);
}

export function usePropertyInfoHazardsOtherSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HazardsOther: PropertyInfo["HazardsOther"]) =>
      dispatch(updatePropertyInfo({ HazardsOther })),
    [dispatch]
  );
}

export function usePropertyInfoHazardsOther() {
  const value = usePropertyInfoHazardsOtherSelector();
  const setter = usePropertyInfoHazardsOtherSetter();
  return pair(value, setter);
}

export function getPropertyInfoHasDogs(state) {
  return getPropertyInfo(state).HasDogs;
}

export function usePropertyInfoHasDogsSelector() {
  return useSelector(getPropertyInfoHasDogs);
}

export function usePropertyInfoHasDogsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HasDogs: PropertyInfo["HasDogs"]) =>
      dispatch(updatePropertyInfo({ HasDogs })),
    [dispatch]
  );
}

export function usePropertyInfoHasDogs() {
  const value = usePropertyInfoHasDogsSelector();
  const setter = usePropertyInfoHasDogsSetter();
  return pair(value, setter);
}

export function getPropertyInfoWithAnotherSupplier(state) {
  return getPropertyInfo(state).WithAnotherSupplier;
}

export function usePropertyInfoWithAnotherSupplierSelector() {
  return useSelector(getPropertyInfoWithAnotherSupplier);
}

export function usePropertyInfoWithAnotherSupplierSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (WithAnotherSupplier: PropertyInfo["WithAnotherSupplier"]) =>
      dispatch(updatePropertyInfo({ WithAnotherSupplier })),
    [dispatch]
  );
}

export function usePropertyInfoWithAnotherSupplier() {
  const value = usePropertyInfoWithAnotherSupplierSelector();
  const setter = usePropertyInfoWithAnotherSupplierSetter();
  return pair(value, setter);
}

export function getPropertyInfoOwnsProperty(state) {
  return getPropertyInfo(state).OwnsProperty;
}

export function usePropertyInfoOwnsPropertySelector() {
  return useSelector(getPropertyInfoOwnsProperty);
}

export function usePropertyInfoOwnsPropertySetter() {
  const dispatch = useDispatch();
  return useCallback(
    (OwnsProperty: PropertyInfo["OwnsProperty"]) =>
      dispatch(updatePropertyInfo({ OwnsProperty })),
    [dispatch]
  );
}

export function usePropertyInfoOwnsProperty() {
  const value = usePropertyInfoOwnsPropertySelector();
  const setter = usePropertyInfoOwnsPropertySetter();
  return pair(value, setter);
}

export function getPropertyInfoIsBach(state) {
  return getPropertyInfo(state).IsBach;
}

export function usePropertyInfoIsBachSelector() {
  return useSelector(getPropertyInfoIsBach);
}

export function usePropertyInfoIsBachSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (IsBach: PropertyInfo["IsBach"]) =>
      dispatch(updatePropertyInfo({ IsBach })),
    [dispatch]
  );
}

export function usePropertyInfoIsBach() {
  const value = usePropertyInfoIsBachSelector();
  const setter = usePropertyInfoIsBachSetter();
  return pair(value, setter);
}

export function getPropertyInfoHaveFinalMeterReading(state) {
  return getPropertyInfo(state).HaveFinalMeterReading;
}

export function usePropertyInfoHaveFinalMeterReadingSelector() {
  return useSelector(getPropertyInfoHaveFinalMeterReading);
}

export function usePropertyInfoHaveFinalMeterReadingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HaveFinalMeterReading: PropertyInfo["HaveFinalMeterReading"]) =>
      dispatch(updatePropertyInfo({ HaveFinalMeterReading })),
    [dispatch]
  );
}

export function usePropertyInfoHaveFinalMeterReading() {
  const value = usePropertyInfoHaveFinalMeterReadingSelector();
  const setter = usePropertyInfoHaveFinalMeterReadingSetter();
  return pair(value, setter);
}

export function getPropertyInfoFinalElectricityMeterRead(state) {
  return getPropertyInfo(state).FinalElectricityMeterRead;
}

export function usePropertyInfoFinalElectricityMeterReadSelector() {
  return useSelector(getPropertyInfoFinalElectricityMeterRead);
}

export function usePropertyInfoFinalElectricityMeterReadSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (FinalElectricityMeterRead: PropertyInfo["FinalElectricityMeterRead"]) =>
      dispatch(updatePropertyInfo({ FinalElectricityMeterRead })),
    [dispatch]
  );
}

export function usePropertyInfoFinalElectricityMeterRead() {
  const value = usePropertyInfoFinalElectricityMeterReadSelector();
  const setter = usePropertyInfoFinalElectricityMeterReadSetter();
  return pair(value, setter);
}

export function getPropertyInfoFinalGasMeterRead(state) {
  return getPropertyInfo(state).FinalGasMeterRead;
}

export function usePropertyInfoFinalGasMeterReadSelector() {
  return useSelector(getPropertyInfoFinalGasMeterRead);
}

export function usePropertyInfoFinalGasMeterReadSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (FinalGasMeterRead: PropertyInfo["FinalGasMeterRead"]) =>
      dispatch(updatePropertyInfo({ FinalGasMeterRead })),
    [dispatch]
  );
}

export function usePropertyInfoFinalGasMeterRead() {
  const value = usePropertyInfoFinalGasMeterReadSelector();
  const setter = usePropertyInfoFinalGasMeterReadSetter();
  return pair(value, setter);
}

export function getPropertyInfoSendMeterReader(state) {
  return getPropertyInfo(state).SendMeterReader;
}

export function getPropertyInfoManualAddress(state) {
  return getPropertyInfo(state).ManualAddress;
}

export function usePropertyInfoSendMeterReaderSelector() {
  return useSelector(getPropertyInfoSendMeterReader);
}

export function usePropertyInfoSendMeterReaderSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (SendMeterReader: PropertyInfo["SendMeterReader"]) =>
      dispatch(updatePropertyInfo({ SendMeterReader })),
    [dispatch]
  );
}

export function usePropertyInfoSendMeterReader() {
  const value = usePropertyInfoSendMeterReaderSelector();
  const setter = usePropertyInfoSendMeterReaderSetter();
  return pair(value, setter);
}

export function getPropertyInfoIsStandard(state) {
  return getPropertyInfoElectricityUserType(state) === USER_TYPE_STANDARD;
}

export function usePropertyInfoIsStandardSelector() {
  return useSelector(getPropertyInfoIsStandard);
}

export function usePropertyInfoIsStandardSetter() {
  const setElectricityUserType = usePropertyInfoElectricityUserTypeSetter();
  return useCallback(
    (isStandard: boolean) =>
      setElectricityUserType(isStandard ? USER_TYPE_STANDARD : USER_TYPE_LOW),
    [setElectricityUserType]
  );
}

export function usePropertyInfoIsStandard() {
  const value = usePropertyInfoIsStandardSelector();
  const setter = usePropertyInfoIsStandardSetter();
  return pair(value, setter);
}

export function getPropertyInfoAddressDetails(state) {
  return getPropertyInfo(state).AddressDetails;
}

export function usePropertyInfoAddressDetailsSelector() {
  return useSelector(getPropertyInfoAddressDetails);
}

export function usePropertyInfoAddressDetailsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AddressDetails: PropertyInfo["AddressDetails"]) =>
      dispatch(
        updatePropertyInfo({
          AddressDetails,
          Moniker: AddressDetails?.pxid,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoAddressDetails() {
  const value = usePropertyInfoAddressDetailsSelector();
  const setter = usePropertyInfoAddressDetailsSetter();
  return pair(value, setter);
}

export function usePropertyInfoManualSelector() {
  return useSelector(getPropertyInfoManualAddress);
}

export function usePropertyInfoManualSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ManualAddress: PropertyInfo["ManualAddress"]) =>
      dispatch(updatePropertyInfo({ ManualAddress })),
    [dispatch]
  );
}

export function usePropertyInfoManualReader() {
  const value = usePropertyInfoManualSelector();
  const setter = usePropertyInfoManualSetter();
  return pair(value, setter);
}

export function getPropertyInfoHazardsDog(state) {
  return getPropertyInfo(state).HazardsDog;
}

export function usePropertyInfoHazardsDogSelector() {
  return useSelector(getPropertyInfoHazardsDog);
}

export function usePropertyInfoHazardsDogSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HazardsDog: PropertyInfo["HazardsDog"]) =>
      dispatch(updatePropertyInfo({ HazardsDog })),
    [dispatch]
  );
}

export function usePropertyInfoHazardsDog() {
  const value = usePropertyInfoHazardsDogSelector();
  const setter = usePropertyInfoHazardsDogSetter();
  return pair(value, setter);
}

export function getPropertyInfoNeedGasReconnection(state) {
  return getPropertyInfo(state).NeedGasReconnection;
}

export function usePropertyInfoNeedGasReconnectionSelector() {
  return useSelector(getPropertyInfoNeedGasReconnection);
}

export function usePropertyInfoNeedGasReconnectionSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (NeedGasReconnection: PropertyInfo["NeedGasReconnection"]) =>
      dispatch(updatePropertyInfo({ NeedGasReconnection })),
    [dispatch]
  );
}

export function usePropetyInfoNeedGasReconnection() {
  const value = usePropertyInfoNeedGasReconnectionSelector();
  const setter = usePropertyInfoNeedGasReconnectionSetter();
  return pair(value, setter);
}

export function getPropertyInfoNeedElecReconnection(state) {
  return getPropertyInfo(state).NeedElecReconnection;
}

export function usePropertyInfoNeedElecReconnectionSelector() {
  return useSelector(getPropertyInfoNeedElecReconnection);
}

export function usePropertyInfoNeedElecReconnectionSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (NeedElecReconnection: PropertyInfo["NeedElecReconnection"]) =>
      dispatch(updatePropertyInfo({ NeedElecReconnection })),
    [dispatch]
  );
}

export function usePropetyInfoNeedElecReconnection() {
  const value = usePropertyInfoNeedElecReconnectionSelector();
  const setter = usePropertyInfoNeedElecReconnectionSetter();
  return pair(value, setter);
}

export function getPropertyInfoElectricityEmbeddedNetwork(state) {
  return getPropertyInfo(state).ElectricityEmbeddedNetwork;
}

export function usePropertyInfoElectricityEmbeddedNetworkSelector() {
  return useSelector(getPropertyInfoElectricityEmbeddedNetwork);
}

export function usePropertyInfoElectricityEmbeddedNetworkSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ElectricityEmbeddedNetwork: PropertyInfo["ElectricityEmbeddedNetwork"]) =>
      dispatch(
        updatePropertyInfo({
          ElectricityEmbeddedNetwork,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoElectricityEmbeddedNetwork() {
  const value = usePropertyInfoElectricityEmbeddedNetworkSelector();
  const setter = usePropertyInfoElectricityEmbeddedNetworkSetter();
  return pair(value, setter);
}

export function getPropertyInfoElectricityTrader(state) {
  return getPropertyInfo(state).ElectricityTrader;
}

export function usePropertyInfoElectricityTraderSelector() {
  return useSelector(getPropertyInfoElectricityTrader);
}

export function usePropertyInfoElectricityTraderSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ElectricityTrader: PropertyInfo["ElectricityTrader"]) =>
      dispatch(
        updatePropertyInfo({
          ElectricityTrader,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoElectricityTrader() {
  const value = usePropertyInfoElectricityTraderSelector();
  const setter = usePropertyInfoElectricityTraderSetter();
  return pair(value, setter);
}

export type ElectricityEmbeddedNetwork =
  | "YesWithContact"
  | "YesWithOtherRetailer"
  | "No";

export function useElectricityEmbeddedNetwork(): ElectricityEmbeddedNetwork {
  const elecEmbeddedNetwork = usePropertyInfoElectricityEmbeddedNetworkSelector();
  const elecTrader = usePropertyInfoElectricityTraderSelector();
  return useMemo(() => {
    if (elecEmbeddedNetwork) {
      if (elecTrader === "CTCT") {
        return "YesWithContact";
      } else {
        return "YesWithOtherRetailer";
      }
    } else {
      return "No";
    }
  }, [elecEmbeddedNetwork, elecTrader]);
}

export function getPropertyInfoContactHasMeterKey(state) {
  return getPropertyInfo(state).ContactHasMeterKey;
}

export function usePropertyInfoContactHasMeterKeySelector() {
  return useSelector(getPropertyInfoContactHasMeterKey);
}

export function usePropertyInfoContactHasMeterKeySetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ContactHasMeterKey: PropertyInfo["ContactHasMeterKey"]) =>
      dispatch(
        updatePropertyInfo({
          ContactHasMeterKey,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoContactHasMeterKey() {
  const value = usePropertyInfoContactHasMeterKeySelector();
  const setter = usePropertyInfoContactHasMeterKeySetter();
  return pair(value, setter);
}

export function getPropertyInfoContinueUsingKeys(state) {
  return getPropertyInfo(state).ContinueUsingKeys;
}

export function usePropertyInfoContinueUsingKeysSelector() {
  return useSelector(getPropertyInfoContinueUsingKeys);
}

export function usePropertyInfoContinueUsingKeysSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ContinueUsingKeys: PropertyInfo["ContinueUsingKeys"]) =>
      dispatch(
        updatePropertyInfo({
          ContinueUsingKeys,
        })
      ),
    [dispatch]
  );
}

export function usePropertyInfoContinueUsingKeys() {
  const value = usePropertyInfoContinueUsingKeysSelector();
  const setter = usePropertyInfoContinueUsingKeysSetter();
  return pair(value, setter);
}
