import axios, { AxiosRequestConfig, Canceler } from 'axios';

export function cancellableQuery<T>(
  fn: (options: Pick<Partial<AxiosRequestConfig>, 'cancelToken'>) => Promise<T>
) {
  const source = axios.CancelToken.source();
  const cancellablePromise: ReturnType<typeof fn> & {
    cancel?: Canceler;
  } = fn({
    cancelToken: source.token,
  });
  cancellablePromise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };
  return cancellablePromise;
}
