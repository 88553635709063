import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  AasfApiResponse,
  aasf,
  AasfVersion,
  AasfPath,
  CcRefundSubmitVersion,
  ccRefund,
} from '@contact/data-access';
import { cancellableQuery } from '../../utils';

export const useCcRefund = (
  version: CcRefundSubmitVersion,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<any, any, any>
) =>
  useMutation<any, any, any>(async (form) => {
    const result = await ccRefund(version, form, axiosOptions);
    return result;
  }, mutationOptions);
