import * as actionTypes from "../actionTypes";
// import { saveApiState } from '../ApiCalls';

export const updatePipedGas = (gas) => {
  return {
    type: actionTypes.UPDATE_PIPED_GAS,
    gas,
  };
};

// export const savePipedGasData = (gas) => {
//     debugger;
//     return (dispatch, getState) => {
//         const state = getState();
//         dispatch(updatePipedGas(gas));
//         if (!process.env.NX_USE_FIXTURES) {
//             debugger;
//             dispatch(saveApiState({
//                 ...state.GasInfo,
//                 ...gas
//             }));
//         }
//     };
// };
