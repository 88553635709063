import { useQuery, UseQueryOptions } from 'react-query';
import {
  CustomerApiResponse,
  CustomerPath,
  CustomerVersion,
  getCustomer,
  getCustomerConfigured,
} from '@contact/data-access';
import { useAxiosConfig } from './context';
import { cancellableQuery } from '../utils';
import { AxiosRequestConfig } from 'axios';

export const useCustomer = (
  version: CustomerVersion,
  token: string,
  fetchAccounts?: boolean,
  options?: UseQueryOptions<CustomerApiResponse>
) => {
  return useQuery<CustomerApiResponse>(
    [CustomerPath[version], token, fetchAccounts],
    async () => {
      const { data } = await getCustomer(version, token, fetchAccounts);
      return data;
    },
    {
      ...options,
      enabled: !!token,
    }
  );
};

export interface CustomerHookOptions {
  token: string;
  fetchAccounts?: boolean;
  providedConfig?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<CustomerApiResponse>;
}

export const useCustomerConfigured = (
  version: CustomerVersion,
  { token, fetchAccounts, providedConfig, queryOptions }: CustomerHookOptions
) => {
  const config = useAxiosConfig(providedConfig);
  return useQuery<CustomerApiResponse>(
    [CustomerPath[version], token, fetchAccounts],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getCustomerConfigured(version, fetchAccounts, {
          ...config,
          ...extraOptions,
        });
        return data;
      }),
    { enabled: !!token, ...queryOptions }
  );
};
