import { baseInstance } from '../base-service';
import { AxiosRequestConfig } from 'axios';
import { RegisterForPushNotificationsApiResponse } from '../models/push-notifications';
import {
  PushNotificationRegisterPath,
  PushNotificationRegisterVersion,
} from '../apiPaths';

export interface PushNotificationRegisterParams {
  /** Push notification device token */
  deviceToken: string;
  osType: 'IOS' | 'ANDROID';
  alertPermission: boolean;
  config?: AxiosRequestConfig;
}

interface PushNotificationRegisterRequestBody {
  device_token: string;
  os_type: 'IOS' | 'ANDROID';
  alert_permission: boolean;
}

export const registerForPushNotifications = (
  version: PushNotificationRegisterVersion,
  {
    token,
    deviceToken,
    osType,
    alertPermission,
    config,
  }: PushNotificationRegisterParams & { token: string }
) => {
  const body: PushNotificationRegisterRequestBody = {
    device_token: deviceToken,
    os_type: osType,
    alert_permission: !!alertPermission,
  };

  return baseInstance(config).post<RegisterForPushNotificationsApiResponse>(
    PushNotificationRegisterPath[version],
    body,
    {
      headers: {
        session: token,
      },
    }
  );
};
