import { BillingEligibilityItem } from "@contact/data-access";
import {
  GET_BILLING_ELIGIBILITY_FETCHED,
  GET_BILLING_ELIGIBILITY_FETCHING,
} from "../actionTypes";

export function getBillingEligibilityFetching() {
  return {
    type: GET_BILLING_ELIGIBILITY_FETCHING,
  };
}

export function getBillingEligibilityFetched(data: BillingEligibilityItem[]) {
  return {
    type: GET_BILLING_ELIGIBILITY_FETCHED,
    data,
  };
}
