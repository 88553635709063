import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BroadbandType } from "@contact/data-access";
import { initialState } from "../../Reducers/iJoin/BroadbandRedux";
import { updateBroadband } from "../../Actions/iJoin/BroadbandActions";
import { pair } from "../../../Utils/pair";
import { DateUtil } from "react-components";
import { DATE_FORMAT } from "Config/Constants";
import {
  ConnectionType,
  ModemStatus,
} from "../shared/ChargeForModem/EligibleModemOptionConfigs";
import { useIsBroadbandInServiceList } from "./Services";

type AddressDetails = Record<string, unknown>;

export type PhoneLineOption = {
  checked?: boolean;
  code: string;
  label: string;
};

export type ModemSupplier = "Contact" | "BYO" | "Existing-Contact";
export type ModemPayTermsType = "UPFRONT_COST" | "MONTHLY_RENTAL" | "FREE";

interface BroadbandInfo {
  BroadbandId: string;
  BroadbandType: BroadbandType;
  BroadbandFullName: string;
  HasBroadbandProvider: boolean;
  BroadbandProvider: string;
  BroadbandProviderOther?: string;
  AccountHolderName: string;
  AccountNumber: string;
  ModemDeliveredToSameAddress: boolean;
  DeliveryAddress: string;
  Moniker?: string | number;
  AddPhoneLine: boolean;
  HomePhoneNumber: string;
  PhoneLineOptions: string[];
  BroadbandAddressDetails?: AddressDetails;
  DevoliAddress?: string;
  DevoliTLC?: string | number;
  BroadbandConnectionDate?: string;
  ModemSupplier?: ModemSupplier;
  ModemPayTerms?: ModemPayTermsType;
  ModemWaiveReason?: "mm" | "oo";
  PHWaiveReason?: "pp" | "hh";
  ModemMake?: string;
  PHFee?: string;
  ExistingBroadbandType?: BroadbandType;
  ExistingModemPayTerms?: ModemPayTermsType;
  ExistingTelephoneLine?: boolean;
}

export function getBroadbandInfo(state): BroadbandInfo {
  return state.BroadbandInfo || initialState;
}

export function useBroadbandInfoSelector() {
  return useSelector(getBroadbandInfo);
}

export function getBroadbandInfoId(state) {
  return getBroadbandInfo(state).BroadbandId;
}

export function useBroadbandInfoIdSelector() {
  return useSelector(getBroadbandInfoId);
}

export function useBroadbandInfoIdSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandId: BroadbandInfo["BroadbandId"]) =>
      dispatch(updateBroadband({ BroadbandId })),
    [dispatch]
  );
}

export function useBroadbandInfoId() {
  const value = useBroadbandInfoIdSelector();
  const setter = useBroadbandInfoIdSetter();
  return pair(value, setter);
}

export function getBroadbandInfoType(state) {
  return getBroadbandInfo(state).BroadbandType;
}

export function useBroadbandInfoTypeSelector() {
  return useSelector(getBroadbandInfoType);
}

export function getBroadbandInfoFullName(state) {
  return getBroadbandInfo(state).BroadbandFullName;
}

export function useBroadbandInfoFullNameSelector() {
  return useSelector(getBroadbandInfoFullName);
}

export function useBroadbandInfoFullNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandFullName: BroadbandInfo["BroadbandFullName"]) =>
      dispatch(updateBroadband({ BroadbandFullName })),
    [dispatch]
  );
}

export function useBroadbandInfoFullName() {
  const value = useBroadbandInfoFullNameSelector();
  const setter = useBroadbandInfoFullNameSetter();
  return pair(value, setter);
}

export function getBroadbandInfoHasProvider(state) {
  return getBroadbandInfo(state).HasBroadbandProvider;
}

export function useBroadbandInfoHasProviderSelector() {
  return useSelector(getBroadbandInfoHasProvider);
}

export function useBroadbandInfoHasProviderSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HasBroadbandProvider: BroadbandInfo["HasBroadbandProvider"]) =>
      dispatch(updateBroadband({ HasBroadbandProvider })),
    [dispatch]
  );
}

export function useBroadbandInfoHasProvider() {
  const value = useBroadbandInfoHasProviderSelector();
  const setter = useBroadbandInfoHasProviderSetter();
  return pair(value, setter);
}

export function getBroadbandInfoProvider(state) {
  return getBroadbandInfo(state).BroadbandProvider;
}

export function useBroadbandInfoProviderSelector() {
  return useSelector(getBroadbandInfoProvider);
}

export function useBroadbandInfoProviderSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandProvider: BroadbandInfo["BroadbandProvider"]) =>
      dispatch(updateBroadband({ BroadbandProvider })),
    [dispatch]
  );
}

export function useBroadbandInfoProvider() {
  const value = useBroadbandInfoProviderSelector();
  const setter = useBroadbandInfoProviderSetter();
  return pair(value, setter);
}

export function getBroadbandInfoProviderOther(state) {
  return getBroadbandInfo(state).BroadbandProviderOther;
}

export function useBroadbandInfoProviderOtherSelector() {
  return useSelector(getBroadbandInfoProviderOther);
}

export function useBroadbandInfoProviderOtherSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandProviderOther: BroadbandInfo["BroadbandProviderOther"]) =>
      dispatch(updateBroadband({ BroadbandProviderOther })),
    [dispatch]
  );
}

export function useBroadbandInfoProviderOther() {
  const value = useBroadbandInfoProviderOtherSelector();
  const setter = useBroadbandInfoProviderOtherSetter();
  return pair(value, setter);
}

export function getBroadbandInfoAccountHolderName(state) {
  return getBroadbandInfo(state).AccountHolderName;
}

export function useBroadbandInfoAccountHolderNameSelector() {
  return useSelector(getBroadbandInfoAccountHolderName);
}

export function useBroadbandInfoAccountHolderNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AccountHolderName: BroadbandInfo["AccountHolderName"]) =>
      dispatch(updateBroadband({ AccountHolderName })),
    [dispatch]
  );
}

export function useBroadbandInfoAccountHolderName() {
  const value = useBroadbandInfoAccountHolderNameSelector();
  const setter = useBroadbandInfoAccountHolderNameSetter();
  return pair(value, setter);
}

export function getBroadbandInfoAccountNumber(state) {
  return getBroadbandInfo(state).AccountNumber;
}

export function useBroadbandInfoAccountNumberSelector() {
  return useSelector(getBroadbandInfoAccountNumber);
}

export function useBroadbandInfoAccountNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AccountNumber: BroadbandInfo["AccountNumber"]) =>
      dispatch(updateBroadband({ AccountNumber })),
    [dispatch]
  );
}

export function useBroadbandInfoAccountNumber() {
  const value = useBroadbandInfoAccountNumberSelector();
  const setter = useBroadbandInfoAccountNumberSetter();
  return pair(value, setter);
}

export function getBroadbandInfoModemSupplier(state) {
  return getBroadbandInfo(state).ModemSupplier;
}

export function useBroadbandInfoModemSupplierSelector() {
  return useSelector(getBroadbandInfoModemSupplier);
}

export function useBroadbandInfoModemSupplierSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ModemSupplier: BroadbandInfo["ModemSupplier"]) =>
      dispatch(updateBroadband({ ModemSupplier })),
    [dispatch]
  );
}

export function useBroadbandInfoModemSupplier() {
  const value = useBroadbandInfoModemSupplierSelector();
  const setter = useBroadbandInfoModemSupplierSetter();
  return pair(value, setter);
}

export function getBroadbandInfoModemDeliveredToSameAddress(state) {
  return getBroadbandInfo(state).ModemDeliveredToSameAddress;
}

export function useBroadbandInfoModemDeliveredToSameAddressSelector() {
  return useSelector(getBroadbandInfoModemDeliveredToSameAddress);
}

export function useBroadbandInfoModemDeliveredToSameAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (
      ModemDeliveredToSameAddress: BroadbandInfo["ModemDeliveredToSameAddress"]
    ) => dispatch(updateBroadband({ ModemDeliveredToSameAddress })),
    [dispatch]
  );
}

export function useBroadbandInfoModemDeliveredToSameAddress() {
  const value = useBroadbandInfoModemDeliveredToSameAddressSelector();
  const setter = useBroadbandInfoModemDeliveredToSameAddressSetter();
  return pair(value, setter);
}

export function getBroadbandInfoDeliveryAddress(state) {
  return getBroadbandInfo(state).DeliveryAddress;
}

export function useBroadbandInfoDeliveryAddressSelector() {
  return useSelector(getBroadbandInfoDeliveryAddress);
}

export function useBroadbandInfoDeliveryAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (DeliveryAddress: BroadbandInfo["DeliveryAddress"]) =>
      dispatch(updateBroadband({ DeliveryAddress })),
    [dispatch]
  );
}

export function useBroadbandInfoDeliveryAddress() {
  const value = useBroadbandInfoDeliveryAddressSelector();
  const setter = useBroadbandInfoDeliveryAddressSetter();
  return pair(value, setter);
}

export function getBroadbandInfoMoniker(state) {
  return getBroadbandInfo(state).Moniker;
}

export function useBroadbandInfoMonikerSelector() {
  return useSelector(getBroadbandInfoMoniker);
}

export function useBroadbandInfoMonikerSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Moniker: BroadbandInfo["Moniker"]) =>
      dispatch(updateBroadband({ Moniker })),
    [dispatch]
  );
}

export function useBroadbandInfoMoniker() {
  const value = useBroadbandInfoMonikerSelector();
  const setter = useBroadbandInfoMonikerSetter();
  return pair(value, setter);
}

export function getBroadbandInfoAddPhoneLine(state) {
  return getBroadbandInfo(state).AddPhoneLine;
}

export function useBroadbandInfoAddPhoneLineSelector() {
  return useSelector(getBroadbandInfoAddPhoneLine);
}

export function useBroadbandInfoAddPhoneLineSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AddPhoneLine: BroadbandInfo["AddPhoneLine"]) =>
      dispatch(updateBroadband({ AddPhoneLine })),
    [dispatch]
  );
}

export function useBroadbandInfoAddPhoneLine() {
  const value = useBroadbandInfoAddPhoneLineSelector();
  const setter = useBroadbandInfoAddPhoneLineSetter();
  return pair(value, setter);
}

export function getBroadbandInfoHomePhoneNumber(state) {
  return getBroadbandInfo(state).HomePhoneNumber;
}

export function useBroadbandInfoHomePhoneNumberSelector() {
  return useSelector(getBroadbandInfoHomePhoneNumber);
}

export function useBroadbandInfoHomePhoneNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HomePhoneNumber: BroadbandInfo["HomePhoneNumber"]) =>
      dispatch(updateBroadband({ HomePhoneNumber })),
    [dispatch]
  );
}

export function useBroadbandInfoHomePhoneNumber() {
  const value = useBroadbandInfoHomePhoneNumberSelector();
  const setter = useBroadbandInfoHomePhoneNumberSetter();
  return pair(value, setter);
}

export function getBroadbandInfoPhoneLineOptions(state) {
  return (
    getBroadbandInfo(state).PhoneLineOptions || initialState.PhoneLineOptions
  );
}

export function useBroadbandInfoPhoneLineOptionsSelector(
  options: PhoneLineOption[]
) {
  const selected = useSelector(getBroadbandInfoPhoneLineOptions);
  return useMemo(() => {
    return options.map((option): PhoneLineOption & { checked: boolean } => ({
      ...option,
      checked: selected.includes(option.code),
    }));
  }, [selected, options]);
}

export function useBroadbandInfoPhoneLineOptionsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (options: PhoneLineOption[]) => {
      const checked = options.filter((option) => option.checked);
      dispatch(
        updateBroadband({
          PhoneLineOptions: checked.map((option) => option.code),
          PhoneLineOptionsFullName: checked.map((option) => option.label),
        })
      );
    },
    [dispatch]
  );
}

export function useBroadbandInfoPhoneLineOptions(options: PhoneLineOption[]) {
  const value = useBroadbandInfoPhoneLineOptionsSelector(options);
  const setter = useBroadbandInfoPhoneLineOptionsSetter();
  return pair(value, setter);
}

export function getBroadbandInfoAddressDetails(state) {
  return getBroadbandInfo(state).BroadbandAddressDetails;
}

export function useBroadbandInfoAddressDetailsSelector() {
  return useSelector(getBroadbandInfoAddressDetails);
}

export function useBroadbandInfoAddressDetailsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandAddressDetails: BroadbandInfo["BroadbandAddressDetails"]) =>
      dispatch(updateBroadband({ BroadbandAddressDetails })),
    [dispatch]
  );
}

export function useBroadbandInfoAddressDetails() {
  const value = useBroadbandInfoAddressDetailsSelector();
  const setter = useBroadbandInfoAddressDetailsSetter();
  return pair(value, setter);
}

export function getBroadbandInfoDevoliAddress(state) {
  const info = getBroadbandInfo(state);
  return info.DevoliAddress;
}

export function useBroadbandInfoDevoliAddressSelector() {
  return useSelector(getBroadbandInfoDevoliAddress, shallowEqual);
}

export function useBroadbandInfoDevoliAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    // This signature is inline with BroadbandCard.onAddressSelect
    (
      DevoliTLC?: BroadbandInfo["DevoliTLC"],
      DevoliAddress?: BroadbandInfo["DevoliAddress"]
    ) => dispatch(updateBroadband({ DevoliTLC, DevoliAddress })),
    [dispatch]
  );
}

export function useBroadbandInfoDevoliAddress() {
  const value = useBroadbandInfoDevoliAddressSelector();
  const setter = useBroadbandInfoDevoliAddressSetter();
  return pair(value, setter);
}

export function getBroadbandConnectionDate(state) {
  const connectionDate = getBroadbandInfo(state).BroadbandConnectionDate;
  return connectionDate;
}

export function useBroadbandConnectionDateSelector() {
  return useSelector(getBroadbandConnectionDate);
}

export function useBroadbandConnectionDateSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BroadbandConnectionDate: BroadbandInfo["BroadbandConnectionDate"]) =>
      dispatch(updateBroadband({ BroadbandConnectionDate })),
    [dispatch]
  );
}

export function useBroadbandConnectionDate() {
  const value = useBroadbandConnectionDateSelector();
  const setter = useBroadbandConnectionDateSetter();
  return pair(value, setter);
}

export function useResetBroadbandOrderInfo() {
  const dispatch = useDispatch();
  return useCallback(
    () =>
      dispatch(
        updateBroadband({
          HasBroadbandProvider: false,
          BroadbandProvider: "",
          BroadbandProviderOther: null,
          AccountHolderName: "",
          AccountNumber: "",
          ModemDeliveredToSameAddress: true,
          DeliveryAddress: "",
          AddPhoneLine: false,
          HomePhoneNumber: "",
          PhoneLineOptions: [],
          BroadbandConnectionDate: DateUtil.convertDateInstanceToFormat(
            new Date(),
            DATE_FORMAT
          ),
          ModemSupplier: "",
        })
      ),
    [dispatch]
  );
}

export function getBroadbandInfoModemPayTerms(state) {
  return getBroadbandInfo(state).ModemPayTerms;
}

export function useBroadbandInfoModemPayTermsSelector() {
  return useSelector(getBroadbandInfoModemPayTerms);
}

export function useBroadbandInfoModemPayTermsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ModemPayTerms: BroadbandInfo["ModemPayTerms"]) =>
      dispatch(updateBroadband({ ModemPayTerms })),
    [dispatch]
  );
}

export function useBroadbandInfoModemPayTerms() {
  const value = useBroadbandInfoModemPayTermsSelector();
  const setter = useBroadbandInfoModemPayTermsSetter();
  return pair(value, setter);
}

export function getBroadbandInfoModemWaiveReason(state) {
  return getBroadbandInfo(state).ModemWaiveReason;
}

export function useBroadbandInfoModemWaiveReasonSelector() {
  return useSelector(getBroadbandInfoModemWaiveReason);
}

export function useBroadbandInfoModemWaiveReasonSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ModemWaiveReason: BroadbandInfo["ModemWaiveReason"]) =>
      dispatch(updateBroadband({ ModemWaiveReason })),
    [dispatch]
  );
}

export function useBroadbandInfoModemWaiveReason() {
  const value = useBroadbandInfoModemWaiveReasonSelector();
  const setter = useBroadbandInfoModemWaiveReasonSetter();
  return pair(value, setter);
}

export function getBroadbandInfoPHWaiveReason(state) {
  return getBroadbandInfo(state).PHWaiveReason;
}

export function useBroadbandInfoPHWaiveReasonSelector() {
  return useSelector(getBroadbandInfoPHWaiveReason);
}

export function useBroadbandInfoPHWaiveReasonSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PHWaiveReason: BroadbandInfo["PHWaiveReason"]) =>
      dispatch(updateBroadband({ PHWaiveReason })),
    [dispatch]
  );
}

export function useBroadbandInfoPHWaiveReason() {
  const value = useBroadbandInfoPHWaiveReasonSelector();
  const setter = useBroadbandInfoPHWaiveReasonSetter();
  return pair(value, setter);
}

export function getBroadbandInfoExistingBroadbandType(state) {
  return getBroadbandInfo(state).ExistingBroadbandType;
}

export function useBroadbandInfoExistingBroadbandTypeSelector() {
  return useSelector(getBroadbandInfoExistingBroadbandType);
}

export function useBroadbandInfoExistingBroadbandTypeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ExistingBroadbandType: BroadbandInfo["ExistingBroadbandType"]) =>
      dispatch(updateBroadband({ ExistingBroadbandType })),
    [dispatch]
  );
}

export function useBroadbandInfoExistingBroadbandType() {
  const value = useBroadbandInfoExistingBroadbandTypeSelector();
  const setter = useBroadbandInfoExistingBroadbandTypeSetter();
  return pair(value, setter);
}

export function getBroadbandInfoExistingModemPayTerms(state) {
  return getBroadbandInfo(state).ExistingModemPayTerms;
}

export function useBroadbandInfoExistingModemPayTermsSelector() {
  return useSelector(getBroadbandInfoExistingModemPayTerms);
}

export function useBroadbandInfoExistingModemPayTermsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ExistingModemPayTerms: BroadbandInfo["ExistingModemPayTerms"]) =>
      dispatch(updateBroadband({ ExistingModemPayTerms })),
    [dispatch]
  );
}

export function useBroadbandInfoExistingModemPayTerms() {
  const value = useBroadbandInfoExistingModemPayTermsSelector();
  const setter = useBroadbandInfoExistingModemPayTermsSetter();
  return pair(value, setter);
}

export function getBroadbandInfoExistingTelephoneLine(state) {
  return getBroadbandInfo(state).ExistingTelephoneLine;
}

export function useBroadbandInfoExistingTelephoneLineSelector() {
  return useSelector(getBroadbandInfoExistingTelephoneLine);
}

export function useBroadbandInfoExistingTelephoneLineSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ExistingTelephoneLine: BroadbandInfo["ExistingTelephoneLine"]) =>
      dispatch(updateBroadband({ ExistingTelephoneLine })),
    [dispatch]
  );
}

export function useBroadbandInfoExistingTelephoneLine() {
  const value = useBroadbandInfoExistingTelephoneLineSelector();
  const setter = useBroadbandInfoExistingTelephoneLineSetter();
  return pair(value, setter);
}

export function useExistingModemPayTerms() {
  return undefined;
}

export function useExistingTelephoneLine() {
  return undefined;
}

export function useConnectionType(): ConnectionType | undefined {
  const currentBb = undefined;
  const isBroadbandInNewServices = useIsBroadbandInServiceList();
  const isBroadbandInExistingServices = Boolean(currentBb);
  return isBroadbandInNewServices
    ? "New"
    : isBroadbandInExistingServices
    ? "Existing"
    : undefined;
}

export function useExistingModemStatus(): ModemStatus | undefined {
  const modemPayTerms = useExistingModemPayTerms();
  return useMemo(
    () =>
      modemPayTerms
        ? modemPayTerms === "MONTHLY_RENTAL"
          ? "Rental"
          : "Free"
        : undefined,
    [modemPayTerms]
  );
}
