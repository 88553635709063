import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  updateTrimester,
  UpdateTrimesterResponse,
  UpdateTrimesterParams,
  FourthTrimesterUpdateVersion,
  FourthTrimesterUpdatePath,
} from '@contact/data-access';

export const useTrimesterUpdate = (
  version: FourthTrimesterUpdateVersion,
  requestId: string,
  params: UpdateTrimesterParams,
  readyToCall: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<UpdateTrimesterResponse>(
    [FourthTrimesterUpdatePath[version], requestId, params],
    async () => {
      const { data } = await updateTrimester(
        version,
        requestId,
        params,
        options
      );
      return data;
    },
    {
      enabled: readyToCall,
    }
  );
};
