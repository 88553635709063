import { useQuery, UseQueryOptions } from 'react-query';
import {
  Consumption,
  getUsage,
  GetUsageParams,
  getUsagePlanDetails,
  GetUsagePlanDetailsParams,
  getWeeklyUsageDifference,
  GetWeeklyUsageDifferenceParams,
  UsagePath,
  UsagePlanDetailsApiSuccessResponse,
  WeeklyUsageDifferenceApiSuccessResponse,
} from '@contact/data-access';

export const UsageQueryBaseKey = 'usage';

/**
 * Usage plan details is designed to sit under usage queries.
 * So that its easier to reset them all in one go.
 */
export const UsagePlanDetailsQueryBaseKeys = [
  UsageQueryBaseKey,
  'plan-details',
];

/**
 * Weekly usage is designed to sit under usage queries.
 * So that its easier to reset them all in one go.
 */
export const WeeklyUsageDifferenceQueryBaseKeys = [
  UsageQueryBaseKey,
  'weekly-difference',
];

export const useUsage = (
  params: GetUsageParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any, any, Consumption[], any>
) => {
  const {
    version,
    token,
    xcsrfToken,
    baId,
    contractId,
    interval,
    from,
    to,
  } = params;
  const valid = !!(
    token &&
    xcsrfToken &&
    baId &&
    contractId &&
    interval &&
    from &&
    to
  );
  const enabled = valid && (options?.enabled ?? true);
  return useQuery(
    [
      UsagePath[version],
      UsageQueryBaseKey,
      token,
      xcsrfToken,
      baId,
      contractId,
      interval,
      from,
      to,
    ],
    async () => {
      const { data } = await getUsage(params);
      return data;
    },
    {
      ...options,
      enabled,
    }
  );
};

export const useUsagePlanDetails = (
  params: GetUsagePlanDetailsParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any, any, UsagePlanDetailsApiSuccessResponse, any>
) => {
  const { token, baId } = params;
  const valid = !!(token && baId);
  const enabled = valid && (options?.enabled ?? true);
  return useQuery(
    [...UsagePlanDetailsQueryBaseKeys, token, baId],
    async () => {
      const { data } = await getUsagePlanDetails(params);
      return data;
    },
    {
      ...options,
      enabled,
    }
  );
};

export const useWeeklyUsageDifference = (
  params: GetWeeklyUsageDifferenceParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<
    any,
    any,
    WeeklyUsageDifferenceApiSuccessResponse,
    any
  >
) => {
  const { token, xcsrfToken, contractId } = params;
  const valid = !!(token && xcsrfToken && contractId);
  const enabled = valid && (options?.enabled ?? true);
  return useQuery(
    [...WeeklyUsageDifferenceQueryBaseKeys, token, xcsrfToken, contractId],
    async () => {
      const { data } = await getWeeklyUsageDifference(params);
      return data;
    },
    {
      ...options,
      enabled,
    }
  );
};
