import { AxiosRequestConfig } from 'axios';
import {
  AddressFinderVerificationPath,
  AddressFinderVerificationVersion,
} from './apiPaths';
import { baseInstance } from './base-service';
import { AddressVerificationResponse } from './models';

export const verifyAddress = (
  version: AddressFinderVerificationVersion,
  address?: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<AddressVerificationResponse>(
    AddressFinderVerificationPath[version],
    {
      params: {
        address,
      },
    }
  );
};
