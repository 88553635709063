import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterOfferEligibilityPath,
  FourthTrimesterOfferEligibilityVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import {
  TrimesterOfferEligibilityApiResponse,
  EligibilityRequest,
} from '../models';

export const getTrimesterOfferEligibility = async (
  version: FourthTrimesterOfferEligibilityVersion,
  params: EligibilityRequest,
  config?: Partial<AxiosRequestConfig>
) =>
  baseInstance(config).post<TrimesterOfferEligibilityApiResponse>(
    FourthTrimesterOfferEligibilityPath[version],
    params
  );
