import { AxiosRequestConfig } from 'axios';
import { ValidatePromoCodePath, ValidatePromoCodeVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { ValidatePromoCodeApiResponse } from './models';

export const validatePromoCode = (
  version: ValidatePromoCodeVersion,
  promoCode: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<ValidatePromoCodeApiResponse>(
    ValidatePromoCodePath[version],
    {
      params: {
        promoCode,
      },
    }
  );
};
