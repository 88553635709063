import { useMutation, UseMutationOptions } from 'react-query';
import {
  registerForPushNotifications,
  PushNotificationRegisterParams,
  PushNotificationRegisterVersion,
} from '@contact/data-access';

export interface RegisterForPushNotificationsHookParams
  extends PushNotificationRegisterParams {
  token: string;
}

export const useRegisterForPushNotifications = (
  version: PushNotificationRegisterVersion,
  options?: Omit<
    UseMutationOptions<void, unknown, RegisterForPushNotificationsHookParams>,
    'mutationFn'
  >
) => {
  return useMutation(async (params: RegisterForPushNotificationsHookParams) => {
    const { data } = await registerForPushNotifications(version, params);
    return data;
  }, options);
};
