import { GetSiteCoreContentVersion } from '@contact/data-access';
import { useMemo } from 'react';
import { useSiteCoreContent } from './useSiteCoreContent';

export const MyAccountSitecoreUrl = 'ijoin-react-app%2Fhome%2Fmy-account';

export interface BroadbandOfferingDetails {
  [Id: string]: BroadbandOfferingDetail;
}

export interface BroadbandOfferingDetail {
  /** Broadband offer ID */
  Id: string;
  LearnMoreDescription: string;
}

/**
 * Returns {@link BroadbandOfferingDetails} under `data`.
 */
export function useBroadbandOfferingDetails(
  version: GetSiteCoreContentVersion
) {
  const { data, ...response } = useSiteCoreContent(
    version,
    MyAccountSitecoreUrl
  );
  const parsedData = useMemo(() => {
    const offers: BroadbandOfferingDetails = {};
    data?.sitecore.route.placeholders['jss-main']?.[0].placeholders?.[
      'broadband-placeholder'
    ][0].placeholders['broadband-tiles-placeholder'].forEach((placeholder) => {
      const id = placeholder.fields.BBExistingTile.fields.ApiId.value;
      if (id) {
        offers[id] = {
          Id: id,
          LearnMoreDescription:
            placeholder.fields.BBLearnMoreDescription.value || '',
        };
      }
    });
    return offers;
  }, [data]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ data: parsedData, ...response }), [
    parsedData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(response),
  ]);
}
