import React, { useState, useEffect } from "react";
import classNames from "classnames";

import "./NPSBar.scss";

import { useDebounce } from "../../Utils/hooks";
import { scores, lowest, highest } from "../../Utils/NPSBarConstants";

const NPSBar = (props) => {
  const [value, setValue] = useState(
    typeof props.score === "undefined" ? -1 : props.score
  );
  const [hover, setHover] = useState(-1);

  useEffect(() => {
    setValue(typeof props.score === "undefined" ? -1 : props.score);
  }, [props.score]);

  const debouncedHover = useDebounce(hover, 100);

  const handleClick = (score) => () => {
    setValue(score);
    setHover(-1);
    props.onChange(score);
  };

  const handleMouseEnter = (score) => () => {
    setHover(score);
  };

  const handleMouseLeave = () => {
    setHover(-1);
  };

  return (
    <div className="npsBar">
      <div className="npsBar_container">
        <div className="npsBar_wrap">
          {scores.map((score) => (
            <span
              id={props.name ? props.name + score : score}
              key={score}
              className={classNames("npsBar_wrap_item", {
                "npsBar_wrap_item--checked": value === score,
                "npsBar_wrap_item--hover":
                  score <= Math.max(debouncedHover, hover),
              })}
              onClick={handleClick(score)}
              onMouseEnter={handleMouseEnter(score)}
              onMouseLeave={handleMouseLeave}
            >
              {score}
            </span>
          ))}
        </div>
        <div className="npsBar_desc">
          <label className="npsBar_desc_lowest">{lowest}</label>
          <label className="npsBar_desc_highest">{highest}</label>
        </div>
      </div>
    </div>
  );
};

export default NPSBar;
