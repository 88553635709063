import { AxiosRequestConfig } from 'axios';
import { CheckIdentityPath, CheckIdentityVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { IdentityCheckApiResponse } from './models/identity-check';

export const checkIdentity = (
  version: CheckIdentityVersion,
  firstName: string,
  middleName: string,
  lastName: string,
  dateOfBirth: string,
  driverLicenceNumber: string,
  driverLicenceVersion: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).post<IdentityCheckApiResponse>(
    CheckIdentityPath[version],
    {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      driverLicenceNumber,
      driverLicenceVersion,
    }
  );
};
