import { AxiosRequestConfig } from 'axios';
import { BroadbandProvidersPath, BroadbandProvidersVersion } from '..';
import { baseInstance } from './base-service';
import { ProviderType, ProvidersApiResponse } from './models';

export const getProviders = (
  version: BroadbandProvidersVersion,
  type?: ProviderType,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<ProvidersApiResponse>(
    BroadbandProvidersPath[version],
    {
      params: {
        type,
      },
    }
  );
