import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import {
  UsagePath,
  UsagePlanDetailsPath,
  UsagePlanDetailsVersion,
  UsageVersion,
  UsageWeeklyDifferencePath,
  UsageWeeklyDifferenceVersion,
} from '..';
import {
  Consumption,
  UsagePlanDetailsApiSuccessResponse,
  WeeklyUsageDifferenceApiFailureResponse,
  WeeklyUsageDifferenceApiSuccessResponse,
} from './models';
import { v4 as uuidv4 } from 'uuid';

export interface GetUsageParams {
  version: UsageVersion;
  token: string;
  xcsrfToken: string;
  baId: string;
  contractId: string;
  interval: string;
  from: string;
  to: string;
}

export const getUsage = (
  {
    version,
    token,
    xcsrfToken,
    baId,
    contractId,
    interval,
    from,
    to,
  }: GetUsageParams,
  config?: AxiosRequestConfig
) => {
  const path = `${UsagePath[version]}/${contractId}`;

  return baseInstance(config).post<Consumption[]>(path, null, {
    params: {
      ba: baId,
      interval,
      from,
      to,
    },
    headers: {
      Authorization: token,
      'x-csrf-token': xcsrfToken,
      'x-correlation-id': uuidv4(),
    },
  });
};

export interface GetUsagePlanDetailsParams {
  version: UsagePlanDetailsVersion;
  token: string;
  baId: string;
}

export const getUsagePlanDetails = (
  { version, token, baId }: GetUsagePlanDetailsParams,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<UsagePlanDetailsApiSuccessResponse>(
    UsagePlanDetailsPath[version],
    {
      params: {
        ba: baId,
      },
      headers: {
        session: token,
        'x-correlation-id': uuidv4(),
      },
    }
  );
};

/** Returned when there is not enough data */
export const weeklyUsageDifferenceNotEnoughDataCode = 1522;

export interface GetWeeklyUsageDifferenceParams {
  version: UsageWeeklyDifferenceVersion;
  token: string;
  xcsrfToken: string;
  contractId: string;
}

export const getWeeklyUsageDifference = async (
  { version, token, xcsrfToken, contractId }: GetWeeklyUsageDifferenceParams,
  config?: AxiosRequestConfig
) => {
  const path = `${UsageWeeklyDifferencePath[version]}/${contractId}`;

  return baseInstance(config).get<
    | WeeklyUsageDifferenceApiSuccessResponse
    | WeeklyUsageDifferenceApiFailureResponse
  >(path, {
    headers: {
      session: token,
      'x-csrf-token': xcsrfToken,
      'x-correlation-id': uuidv4(),
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) ||
      status === weeklyUsageDifferenceNotEnoughDataCode,
  });
};
