import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";

export const updateBottledGas = (bottledGas) => {
  return {
    type: actionTypes.UPDATE_BOTTLED_GAS,
    bottledGas,
  };
};

export const saveBottledGasData = (bottledGas) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(updateBottledGas(bottledGas));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(
        saveApiState({
          LpgInfo: {
            ...state.LpgInfo,
            ...bottledGas,
          },
        })
      );
    }
  };
};
