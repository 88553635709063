import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  billEstimate,
  BillEstimatePath,
  BillEstimateVersion,
  EstimateApiRequest,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useBillEstimate = (
  version: BillEstimateVersion,
  request?: EstimateApiRequest,
  options?: Partial<AxiosRequestConfig>,
  onError?: (error: unknown) => void
) =>
  useQuery(
    [BillEstimatePath[version], request],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await billEstimate(version, request, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!request,
      retry: false,
      onError,
    }
  );
