export const ReadonlyFixture = {
  ApiPlan: {
    Filters: ["AA"],
  },
  Eligibilities: [
    { name: "CONTACT PREPAY", eligible: true, id: "01" },
    { name: "WEEKLY/FORTNIGHTLY BILLING", eligible: true, id: "02" },
  ],
  PriceChecker: {
    BroadbandOfferings: {
      LookupComplete: true,
      Offerings: [
        { Id: "FIBRE_100_20", OriginalPrice: "$89.99/mth" },
        {
          Id: "FIBRE_200_20",
          OriginalPrice: "$114.99/mth",
        },
        { Id: "FIBRE_1000_500", OriginalPrice: "$129.99/mth" },
      ],
      ResultCode: 200,
    },
    AvailableServices: {
      Services: ["BB", "ELEC", "GAS", "LPG"],
      LookupComplete: true,
    },
    CartInformation: {
      Services: ["ELEC"],
      BroadbandOptions: { AddHomeLine: false },
      Extras: ["30c off per litre of fuel once every month (max 50 L)"],
      PlanName: "Fuel Rewards Plus",
      DualEnergyDiscountAvailable: false,
      TotalDiscount: "0",
      ExtraColours: ["#E59437"],
      FixedTerm: "30-JUN-2021",
    },
    ElectricityICP: {
      Meters: [
        {
          MeterChannels: [{ EnergyFlowDirection: "X", ChannelId: "1" }],
          RegisterCodes: ["UN-24"],
          Ami: false,
          MeterSerialNumber: "24346103",
          MeterType: "NHH",
        },
      ],
      IcpNumber: "0000968470TU514",
      Pp: false,
      AnzsicCode: "000000",
      Unm: false,
      GxpNumber: "MTM0331",
      Mep: "TRUM",
      ExactRegistryMatch: true,
      PriceCategoryCode: "T05",
      NetworkParticipantCode: "POCO",
    },
    RegionImageUrl:
      "https://dev.contact.co.nz:443/-/media/contact/images/price-checker-regions/e032-tauranga-desktop.ashx",
    PricingAvailable: true,
    PipedGasAvailable: false,
    Eligibilities: [
      { name: "CONTACT PREPAY", eligible: false, id: "01" },
      {
        name: "WEEKLY/FORTNIGHTLY BILLING",
        eligible: false,
        id: "02",
      },
    ],
    Region: "E032",
    SummerEstimate: "157",
    WinterEstimate: "219",
    PricingAllowed: true,
    ServiceRates: [
      {
        Service: "ELEC",
        Rates: [
          {
            DisplayRate: "22.4",
            Rate: "0.224",
            Unit: "cents/kWh",
            PowerType: "E",
            PriceType: "V1",
            UserType: "S",
            Name: "Anytime",
          },
          {
            DisplayRate: "$1.318",
            Rate: "1.318",
            Unit: "dollars/day",
            PowerType: "E",
            PriceType: "F1",
            UserType: "S",
            Name: "Daily Charge",
          },
          {
            DisplayRate: "0.13",
            Rate: "0.0013",
            Unit: "cents/kWh",
            PowerType: "E",
            PriceType: "L1",
            UserType: "S",
            Name: "Electricity Authority Levy",
          },
        ],
      },
      { Service: "GAS" },
      { Service: "BB" },
    ],
  },
};
