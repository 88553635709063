import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { defaultConfigForTest2 as defaultConfig } from './defaultConfigForTest2';

export const AxiosConfigInstanceKey = '___AxiosInstance';

export type AxiosConfig = Partial<AxiosRequestConfig> & {
  [AxiosConfigInstanceKey]?: ReturnType<typeof axios['create']>;
};

function mergeAxiosConfig(base: AxiosRequestConfig, config?: AxiosConfig) {
  if (!config) return base;
  const partiallyMerged = {
    ...base,
    ...config,
    baseURL: config?.baseURL
      ? new URL(config.baseURL, base.baseURL).toString()
      : base.baseURL,
  };
  return {
    ...partiallyMerged,
    headers: {
      ...base.headers,
      ...partiallyMerged.headers,
    },
  };
}

export const baseInstance = (config?: AxiosConfig): AxiosInstance => {
  const merged = mergeAxiosConfig(defaultConfig);
  // This enables injecting a fully configured axios instance
  // Allowing for more in depth testing or mocking
  if (merged[AxiosConfigInstanceKey]) {
    return merged[AxiosConfigInstanceKey];
  }
  return axios.create(mergeAxiosConfig(defaultConfig, config));
};
