import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { AccountsApiResponse } from './models';
import { GetAccountsPath, GetAccountsVersion } from '..';

export const getAccounts = (
  version: GetAccountsVersion,
  token: string,
  ba?: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<AccountsApiResponse>(
    GetAccountsPath[version],
    {
      params: {
        ba,
      },
      headers: {
        session: token,
      },
    }
  );
};

export function getAccountsConfigured(
  version: GetAccountsVersion,
  ba?: string,
  config?: AxiosRequestConfig
) {
  return baseInstance(config).get<AccountsApiResponse>(
    GetAccountsPath[version],
    {
      params: {
        ba,
      },
    }
  );
}
