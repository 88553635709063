import { AxiosRequestConfig } from 'axios';
import {
  GetGasRegistryICPDetailsPath,
  GetGasRegistryICPDetailsVersion,
} from './apiPaths';
import { baseInstance } from './base-service';
import {
  GasRegistryICPLookupAPIResponse,
  GasRegistryICPLookupPlanParameters,
  GasRegistryICPLookupResponse,
} from './models/gas-registry';

export const gasRatesPlanParams: GasRegistryICPLookupPlanParameters = {
  division: '02',
  billingClass: 'RESI',
};

export const getGasRegistryICPDetails = async (
  version: GetGasRegistryICPDetailsVersion,
  icp: string,
  config: Partial<AxiosRequestConfig>
): Promise<GasRegistryICPLookupResponse> => {
  const { data } = await baseInstance(
    config
  ).get<GasRegistryICPLookupAPIResponse>(
    GetGasRegistryICPDetailsPath[version],
    {
      params: {
        icp,
      },
    }
  );
  return {
    ...gasRatesPlanParams,
    ...data,
  };
};
