export const BottledGasFixture = {
  PreferredCallbackPeriod: null,
  Comments: null,
  BottledGasUsage: ["Heating", "Hot water", "Cooking"],
  OrderBottledGas: true,
  NumberOfBottles: "2",
  DeliveryAddress: null,
  DeliveryInstructions: "Test delivery instructions",
  Island: null,
  Franchise: null,
  PaperlessStatements: true,
  PaperlessStatementsDisplayed: true,
};
