import * as actionTypes from "../actionTypes";

export const updateBPFetching = (isFetching) => {
  return {
    type: actionTypes.UPDATE_BP_FETCHING,
    isFetching,
  };
};

export const updateBPFetched = (isFetched) => {
  return {
    type: actionTypes.UPDATE_BP_FETCHED,
    isFetched,
  };
};

export const bPReset = () => {
  return {
    type: actionTypes.BP_RESET,
    isError: false,
    errorMessage: null,
    errorDetails: null,
  };
};

export const updateBPError = (error) => {
  return {
    type: actionTypes.UPDATE_BP_ERROR,
    isError: true,
    errorMessage: error.errorMessage,
    errorDetails: error.errorDetails,
  };
};
