import React from "react";
import classNames from "classnames";

import "../../Assets/Icons";
import "./Icon.scss";

const Icon = (props) => {
  const onKeyDown = (e) => {
    // TODO looks iffy
    if (e.key === "Enter") props.onClick();
  };

  return (
    <div
      name={props.name ? props.name : null}
      onClick={props.onClick}
      className={classNames("icon", props.className)}
      onKeyDown={props.onKeyDown ? props.onKeyDown : onKeyDown}
      tabIndex={props.tabindex ? props.tabindex : "-1"}
      style={props.customStyle}
    >
      {props.icon}
    </div>
  );
};

export default Icon;
