import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  FeatureFlagsPath,
  FeatureFlagsVersion,
  getFeatureFlags,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useFeatureFlags = (
  version: FeatureFlagsVersion,
  environment: string,
  userId?: string,
  sessionId?: string,
  options?: AxiosRequestConfig
) =>
  useQuery(
    [FeatureFlagsPath[version], environment, userId, sessionId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getFeatureFlags(
          version,
          environment,
          userId,
          sessionId,
          {
            ...options,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      enabled: !!environment,
      suspense: true,
    }
  );
