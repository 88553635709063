import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import { RollOff, queryRollOffsByBP } from '@contact/data-access';
import { cancellableQuery } from '../utils';

const UNKNOWN_ERROR = {
  message: 'An unknown error happened.',
};

export const useQueryRollOffsByBP = (
  bp: string,
  eventID?: string,
  isCSR?: boolean,
  token?: string,
  queryOptions?: UseQueryOptions<RollOff.RollOffsByBP>,
  axiosOptions?: Partial<AxiosRequestConfig>
) =>
  useQuery<RollOff.RollOffsByBP>(
    ['query-roll-offs-by-bp', bp, eventID, isCSR, token],
    () =>
      cancellableQuery(async (extraOptions) => {
        try {
          const { data } = await queryRollOffsByBP(bp, eventID, isCSR, token, {
            ...axiosOptions,
            ...extraOptions,
          });
          return data;
        } catch (exception) {
          const errorResponse = (exception as AxiosError<RollOff.ErrorResponse>)
            .response;
          if (errorResponse) {
            const error = {
              status: errorResponse.status,
              message: errorResponse.data.message,
              data: omit(errorResponse.data, ['message']),
            };
            throw error;
          }
          throw UNKNOWN_ERROR;
        }
      }),
    {
      enabled: !!bp && (isCSR ? !!token : !!eventID),
      ...queryOptions,
    }
  );
