import { AxiosRequestConfig } from 'axios';
import { CreateSaveForLaterPath, CreateSaveForLaterVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { CreateSaveForLaterApiResponse, JourneyType, Origin } from './models';

export const createSaveForLater = (
  version: CreateSaveForLaterVersion,
  journeyType: JourneyType,
  origin: Origin,
  saveForLaterEmail: string,
  state: Record<string, unknown>,
  config?: Partial<AxiosRequestConfig>
) =>
  baseInstance(config).post<CreateSaveForLaterApiResponse>(
    CreateSaveForLaterPath[version],
    {
      journeyType,
      origin,
      saveForLaterEmail,
      state,
    }
  );
