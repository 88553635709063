import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  accountActivation,
  AccountActivationVersion,
} from '@contact/data-access';

type TData = void;
type TError = unknown;
type TVariables = {
  activationKey: string;
  requestId: string;
};

export const useAccountActivation = (
  version: AccountActivationVersion,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables>
) =>
  useMutation<TData, TError, TVariables>(
    async ({ activationKey, requestId }) => {
      const { data } = await accountActivation(
        version,
        activationKey,
        requestId,
        axiosOptions
      );
      return data;
    },
    mutationOptions
  );
