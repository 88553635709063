import { initialState } from "../../Reducers/iJoin/PaymentRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { updatePayment } from "../../Actions/iJoin/PaymentActions";
import { pair } from "../utils";
import { getIsElectricityOnly } from "../../../Utils/selectors";
import { Payment as Constants } from "../../../Config/Constants";
import { isPrepay } from "../../../Containers/Payment/utils";
import { useLoginTokenSelectorRequired } from "./Login";
import {
  useCustomerInfoBusinessPartnerNumberSelector,
  useCustomerInfoPrepayEligibleSelector,
} from "./Customer";
import { getAccountAasf } from "../../Actions/ApiCalls";
import { getIsFuelRewards } from "../../../Utils/selectors";
import {
  PAYMENT_CYCLE_MONTHLY,
  PAYMENT_METHOD_BILL,
  SmoothPayFrequency,
} from "../../../Config/TypedConstants";
import { useJourneyTypeSelector } from "./JourneyType";

interface PaymentDirectDebitDetails {
  BankName?: string;
  BankAccountNumber?: string;
  IsSoleSignatory: boolean;
  AgreeWithTermsAndConditions: boolean;
}

interface Payment {
  PaymentMethod: string;
  PrePayMobileNumber?: string;
  PaperlessDiscount: boolean;
  JoinDirectDebit: boolean;
  DirectDebitDetails: Partial<PaymentDirectDebitDetails>;
  BillingCycle: string;
  BillStartDate?: string;
  PromoCode?: string;
  SmoothPaySetUp: boolean;
  SmoothPayAmount?: string;
  SmoothPayFrequency?: SmoothPayFrequency;
  SmoothPayStartDate?: string;
}

export function getPayment(state): Payment {
  return state.Payment || initialState;
}

export function usePaymentSelector() {
  return useSelector(getPayment, shallowEqual);
}

export function getPaymentMethod(state) {
  return getPayment(state).PaymentMethod || initialState.PaymentMethod;
}

export function usePaymentMethodSelector() {
  return useSelector(getPaymentMethod);
}

export function usePaymentMethodSetter() {
  const JourneyType = useJourneyTypeSelector();

  const dispatch = useDispatch();
  return useCallback(
    (PaymentMethod: Payment["PaymentMethod"]) =>
      dispatch(updatePayment({ PaymentMethod }, JourneyType)),
    [dispatch, JourneyType]
  );
}

export function usePaymentMethod() {
  const value = usePaymentMethodSelector();
  const setter = usePaymentMethodSetter();
  return pair(value, setter);
}

export function getPaymentPrePayMobileNumber(state) {
  return getPayment(state).PrePayMobileNumber;
}

export function usePaymentPrePayMobileNumberSelector() {
  return useSelector(getPaymentPrePayMobileNumber);
}

export function usePaymentPrePayMobileNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PrePayMobileNumber: Payment["PrePayMobileNumber"]) =>
      dispatch(updatePayment({ PrePayMobileNumber })),
    [dispatch]
  );
}

export function usePaymentPrePayMobileNumber() {
  const value = usePaymentPrePayMobileNumberSelector();
  const setter = usePaymentPrePayMobileNumberSetter();
  return pair(value, setter);
}

export function getPaymentPaperlessDiscount(state) {
  return isPrepay(getPaymentMethod(state))
    ? false
    : getPayment(state).PaperlessDiscount;
}

export function usePaymentPaperlessDiscountSelector() {
  return useSelector(getPaymentPaperlessDiscount);
}

export function usePaymentPaperlessDiscountSetter() {
  const dispatch = useDispatch();
  const PaymentMethod = usePaymentMethodSelector();
  return useCallback(
    (PaperlessDiscount: Payment["PaperlessDiscount"]) =>
      dispatch(
        updatePayment({
          PaperlessDiscount: isPrepay(PaymentMethod)
            ? false
            : PaperlessDiscount,
        })
      ),
    [dispatch, PaymentMethod]
  );
}

export function usePaymentPaperlessDiscount() {
  const value = usePaymentPaperlessDiscountSelector();
  const setter = usePaymentPaperlessDiscountSetter();
  return pair(value, setter);
}

export function getPaymentJoinDirectDebit(state) {
  return (
    getPayment(state).JoinDirectDebit && !isPrepay(getPaymentMethod(state))
  );
}

export function usePaymentJoinDirectDebitSelector() {
  return useSelector(getPaymentJoinDirectDebit);
}

export function usePaymentJoinDirectDebitSetter() {
  const dispatch = useDispatch();
  const PaymentMethod = usePaymentMethodSelector();
  const JourneyType = useJourneyTypeSelector();
  return useCallback(
    (JoinDirectDebit: Payment["JoinDirectDebit"]) =>
      dispatch(
        updatePayment(
          {
            JoinDirectDebit: JoinDirectDebit && !isPrepay(PaymentMethod),
          },
          JourneyType
        )
      ),
    [dispatch, PaymentMethod, JourneyType]
  );
}

export function usePaymentJoinDirectDebit() {
  const value = usePaymentJoinDirectDebitSelector();
  const setter = usePaymentJoinDirectDebitSetter();
  return pair(value, setter);
}

export function getPaymentDirectDebitDetailsBankName(state) {
  return (
    getPayment(state).DirectDebitDetails || initialState.DirectDebitDetails
  ).BankName;
}

export function usePaymentDirectDebitDetailsBankNameSelector() {
  return useSelector(getPaymentDirectDebitDetailsBankName);
}

export function usePaymentDirectDebitDetailsBankNameSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (BankName: Payment["DirectDebitDetails"]["BankName"]) =>
      dispatch(
        updatePayment(
          {
            DirectDebitDetails: {
              BankName,
            },
          },
          JourneyType
        )
      ),
    [dispatch, JourneyType]
  );
}

export function usePaymentDirectDebitDetailsBankName() {
  const value = usePaymentDirectDebitDetailsBankNameSelector();
  const setter = usePaymentDirectDebitDetailsBankNameSetter();
  return pair(value, setter);
}

export function getPaymentDirectDebitDetailsBankAccountNumber(state) {
  return (
    getPayment(state).DirectDebitDetails || initialState.DirectDebitDetails
  ).BankAccountNumber;
}

export function usePaymentDirectDebitDetailsBankAccountNumberSelector() {
  return useSelector(getPaymentDirectDebitDetailsBankAccountNumber);
}

export function usePaymentDirectDebitDetailsBankAccountNumberSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (BankAccountNumber: Payment["DirectDebitDetails"]["BankAccountNumber"]) => {
      dispatch(
        updatePayment(
          {
            DirectDebitDetails: {
              BankAccountNumber,
            },
          },
          JourneyType
        )
      );
    },
    [dispatch, JourneyType]
  );
}

export function usePaymentDirectDebitDetailsBankAccountNumber() {
  const value = usePaymentDirectDebitDetailsBankAccountNumberSelector();
  const setter = usePaymentDirectDebitDetailsBankAccountNumberSetter();
  return pair(value, setter);
}

export function getPaymentDirectDebitDetailsAgreeWithTermsAndConditions(state) {
  return (
    getPayment(state).DirectDebitDetails || initialState.DirectDebitDetails
  ).AgreeWithTermsAndConditions;
}

export function usePaymentDirectDebitDetailsAgreeWithTermsAndConditionsSelector() {
  return useSelector(getPaymentDirectDebitDetailsAgreeWithTermsAndConditions);
}

export function usePaymentDirectDebitDetailsAgreeWithTermsAndConditionsSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (
      AgreeWithTermsAndConditions: Payment["DirectDebitDetails"]["AgreeWithTermsAndConditions"]
    ) =>
      dispatch(
        updatePayment(
          {
            DirectDebitDetails: {
              IsSoleSignatory: AgreeWithTermsAndConditions,
              AgreeWithTermsAndConditions,
            },
          },
          JourneyType
        )
      ),
    [dispatch, JourneyType]
  );
}

export function usePaymentDirectDebitDetailsAgreeWithTermsAndConditions() {
  const value = usePaymentDirectDebitDetailsAgreeWithTermsAndConditionsSelector();
  const setter = usePaymentDirectDebitDetailsAgreeWithTermsAndConditionsSetter();
  return pair(value, setter);
}

export function getPaymentBillingCycle(state) {
  return getPayment(state).BillingCycle || initialState.BillingCycle;
}

export function usePaymentBillingCycleSelector() {
  const isElectricityOnly = useSelector(getIsElectricityOnly);
  const billingCycle = useSelector(getPaymentBillingCycle);
  return isElectricityOnly ? billingCycle : Constants.billingCycleDefault;
}

export function usePaymentBillingCycleSetter() {
  const isElectricityOnly = useSelector(getIsElectricityOnly);
  const dispatch = useDispatch();
  return useCallback(
    (BillingCycle: Payment["BillingCycle"]) =>
      dispatch(
        updatePayment({
          BillingCycle: isElectricityOnly
            ? BillingCycle
            : Constants.billingCycleDefault,
        })
      ),
    [dispatch, isElectricityOnly]
  );
}

export function usePaymentBillingCycle() {
  const value = usePaymentBillingCycleSelector();
  const setter = usePaymentBillingCycleSetter();
  return pair(value, setter);
}

export function getPaymentBillStartDate(state) {
  return getPayment(state).BillStartDate;
}

export function usePaymentBillStartDateSelector(
  defaultDates: [string, string]
) {
  const isElectricityOnly = useSelector(getIsElectricityOnly);
  const billStartDate = useSelector(getPaymentBillStartDate);
  return isElectricityOnly ? billStartDate : defaultDates[1];
}

export function usePaymentBillStartDateSetter(defaultDates: [string, string]) {
  const isElectricityOnly = useSelector(getIsElectricityOnly);
  const dispatch = useDispatch();
  return useCallback(
    (BillStartDate: Payment["BillStartDate"]) =>
      dispatch(
        updatePayment({
          BillStartDate: isElectricityOnly ? BillStartDate : defaultDates[1],
        })
      ),
    [dispatch, isElectricityOnly, defaultDates]
  );
}

export function usePaymentBillStartDate(defaultDates: [string, string]) {
  const value = usePaymentBillStartDateSelector(defaultDates);
  const setter = usePaymentBillStartDateSetter(defaultDates);
  return pair(value, setter);
}

export function getPaymentPromoCode(state) {
  return getPayment(state).PromoCode;
}

export function usePaymentPromoCodeSelector() {
  return useSelector(getPaymentPromoCode);
}

export function usePaymentPromoCodeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PromoCode: Payment["PromoCode"]) => dispatch(updatePayment({ PromoCode })),
    [dispatch]
  );
}

export function usePaymentPromoCode() {
  const value = usePaymentPromoCodeSelector();
  const setter = usePaymentPromoCodeSetter();
  return pair(value, setter);
}

export function getPaymentSmoothPaySetUp(state) {
  return getPayment(state).SmoothPaySetUp;
}

export function usePaymentSmoothPaySetUp() {
  const value = usePaymentSmoothPaySetUpSelector();
  const setter = usePaymentSmoothPaySetUpSetter();
  return pair(value, setter);
}

export function usePaymentSmoothPaySetUpSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (SmoothPaySetUp: boolean) => {
      dispatch(updatePayment({ SmoothPaySetUp }, JourneyType));
    },
    [dispatch, JourneyType]
  );
}

export function usePaymentSmoothPaySetUpSelector() {
  return useSelector(getPaymentSmoothPaySetUp);
}

export function getPaymentSmoothPayAmount(state) {
  return getPayment(state).SmoothPayAmount;
}

export function usePaymentSmoothPayAmount() {
  const value = usePaymentSmoothPayAmountSelector();
  const setter = usePaymentSmoothPayAmountSetter();
  return pair(value, setter);
}

export function usePaymentSmoothPayAmountSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (SmoothPayAmount: string) => {
      dispatch(updatePayment({ SmoothPayAmount }, JourneyType));
    },
    [dispatch, JourneyType]
  );
}

export function usePaymentSmoothPayAmountSelector() {
  return useSelector(getPaymentSmoothPayAmount);
}

export function getPaymentSmoothPayFrequency(state) {
  return getPayment(state).SmoothPayFrequency ?? PAYMENT_CYCLE_MONTHLY;
}

export function usePaymentSmoothPayFrequencySelector() {
  return useSelector(getPaymentSmoothPayFrequency);
}

export function usePaymentSmoothPayFrequencySetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (SmoothPayFrequency: SmoothPayFrequency) => {
      dispatch(updatePayment({ SmoothPayFrequency }, JourneyType));
    },
    [dispatch, JourneyType]
  );
}

export function usePaymentSmoothPayFrequency() {
  const value = usePaymentSmoothPayFrequencySelector();
  const setter = usePaymentSmoothPayFrequencySetter();
  return pair(value, setter);
}

export function useIsPaymentMethodPrepay() {
  const paymentMethod = usePaymentMethodSelector();
  return isPrepay(paymentMethod);
}

export function getPaymentSmoothPayStartDate(state) {
  return getPayment(state).SmoothPayStartDate;
}

export function usePaymentSmoothPayStartDate() {
  const value = usePaymentSmoothPayStartDateSelector();
  const setter = usePaymentSmoothPayStartDateSetter();
  return pair(value, setter);
}

export function usePaymentSmoothPayStartDateSetter() {
  const JourneyType = useJourneyTypeSelector();
  const dispatch = useDispatch();
  return useCallback(
    (SmoothPayStartDate: Payment["SmoothPayStartDate"]) => {
      dispatch(updatePayment({ SmoothPayStartDate }, JourneyType));
    },
    [dispatch, JourneyType]
  );
}

export function usePaymentSmoothPayStartDateSelector() {
  return useSelector(getPaymentSmoothPayStartDate);
}

export function useIsPrePayAndPrePayUnavailable() {
  const [paymentMethod, setPaymentMethod] = usePaymentMethod();
  const isPrepayEligible = useCustomerInfoPrepayEligibleSelector();

  useEffect(() => {
    if (isPrepay(paymentMethod) && isPrepayEligible === false) {
      setPaymentMethod(PAYMENT_METHOD_BILL);
    }
  }, [isPrepayEligible, paymentMethod, setPaymentMethod]);
}
