import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";
import { updateCustomer } from "./CustomerActions";

export const updateProperty = (property) => {
  return {
    type: actionTypes.UPDATE_PROPERTY,
    property,
  };
};

export const savePropertyData = (customer, property) => {
  return (dispatch, getState) => {
    const state = getState();
    const priceIcp =
      state.Readonly?.PriceChecker &&
      state.Readonly?.PriceChecker.ElectricityICP
        ? state.Readonly?.PriceChecker.ElectricityICP.IcpNumber
        : "";
    const icp = state.Property.PropertyInfo.ElectricityICP
      ? state.Property.PropertyInfo.ElectricityICP
      : priceIcp;
    const newProperty = {
      PropertyInfo: {
        ...state.Property.PropertyInfo,
        ...property.PropertyInfo,
        ElectricityICP: icp,
      },
      MedicalInfo: {
        ...state.Property.MedicalInfo,
        ...property.MedicalInfo,
      },
      MoveInfo: {
        ...state.Property.MoveInfo,
        ...property.MoveInfo,
      },
    };
    const newCustomer = {
      ...state.Customer,
      CustomerInfo: {
        ...state.Customer.CustomerInfo,
        ...customer.CustomerInfo,
      },
    };
    dispatch(updateCustomer(newCustomer));
    dispatch(updateProperty(newProperty));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(
        saveApiState({
          ...newProperty,
          Customer: {
            ...newCustomer,
          },
        })
      );
    }
  };
};

export const updatePropertySituation = (propertySituation) => {
  return {
    type: actionTypes.UPDATE_PROPERTY_SITUATION,
    propertySituation,
  };
};
