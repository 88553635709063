/**
 * Returns postcode from specified address.
 * @param address
 * @returns {string|undefined}
 */
export function getPostcodeFromAddress(address) {
  if (!address) return;
  const match = address.match(/(\d{4})(?:, [a-zA-Z0-9\s]+)?$/);
  if (match) {
    return match[1];
  }
}

/**
 * Returns true, if postcode is from South Island.
 * @param postcode
 * @returns {boolean}
 */
export function isSouthIslandPostcode(postcode) {
  return Boolean(postcode && postcode.match(/^[789]/));
}

/**
 * Returns true, if specified address is in South Island.
 * @param address
 * @returns {boolean}
 */
export function isSouthIslandAddress(address) {
  if (!address) return false;
  const postcode = getPostcodeFromAddress(address);
  return isSouthIslandPostcode(postcode);
}

/**
 * Returns customer's address.
 * @param customer
 * @returns {string}
 */
export function getCustomerAddress(customer) {
  return (
    customer &&
    customer.Property &&
    customer.Property.PropertyInfo &&
    customer.Property.PropertyInfo.Address
  );
}

/**
 * Returns true, if customer lives in South Island.
 * @param customer
 * @returns {boolean}
 */
export function isSouthIslandCustomer(customer) {
  const address = getCustomerAddress(customer);
  return Boolean(address && isSouthIslandAddress(address));
}
