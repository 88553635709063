import Moment from "moment";
import * as DateConstant from "./DateConstant";

class DateUtil {
  static addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };

  static isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  static getDateMinusXYears = (years) => {
    const newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() - years);
    return newDate;
  };

  static getDateMinusXMonths = (months) => {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() - months);
    newDate.setDate(newDate.getDate() - 1); //adds additional time if user choses exactly the same day in chosen month limit
    return newDate;
  };

  /**
   * GetTodays date
   *
   * @param {string} toFormat - (optional) the amount of days to add, defaults to 1
   *
   * @return string - today`s today
   */
  static getTodaysDate(toFormat?: string) {
    if (!toFormat) {
      toFormat = DateConstant.DATABASE_FORMAT;
    }
    return Moment().format(toFormat);
  }

  /**
   * Get Todays date in javascript date format
   *
   * @param {string} date - (optional) date to create moment data
   * @param {string} fromFormat - (optional) date using format
   *
   * @return {string} - today`s date in javascript date format
   *
   * Ex : Wed Jan 09 2019 14:35:32 GMT+1300 (NZDT)
   */
  static getJavascriptDateFormat(date, fromFormat) {
    const format = fromFormat ? fromFormat : DateConstant.DATABASE_FORMAT;

    if (date) {
      return Moment(date, format).toDate();
    }

    return Moment().toDate();
  }

  /**
   * Get Current Year  from today's date
   *
   * @return {string} - the year
   */
  static getCurrentYear() {
    return Moment().format(DateConstant.DATE_YEAR);
  }

  /**
   * Get Current Year from today's date
   *
   * @return {string} - the year
   */
  static getCurrentYearAndMonth() {
    return Moment().format(DateConstant.DATE_YEAR_MONTH);
  }

  /**
   * Get Current Month from today's date
   *
   * @return {string} - the month and date
   */
  static getCurrentMonthAndDate() {
    return Moment().format(DateConstant.DATE_MONTH_AND_DATE);
  }

  /**
   * Get month readable name by month number
   *
   * @param {int} month - month number to convert into readable name
   *
   * @return {string} - the month readable name
   */
  static getMonthReadableName(month) {
    return Moment().month(month).format("MMMM");
  }

  /**
   * Get Current Date
   *
   * @param {string} format - (optional) defaults to DD-MM-YYYY
   * @return {string} - the current date
   */
  static getCurrentDate(format = DateConstant.DATE_FORMAT_DATE_PICKER) {
    return Moment().format(format);
  }

  /**
   * Converts a date from one format to another.
   * If the from and to formats are not provided then we will convert
   *
   * @param {string} sDate - the date we want to add days to
   * @param {string} fromFormat - (optional) the from format, defaults to null
   * @param {string} toFormat - (optional) the amount of days to add, defaults to 1
   * @param {boolean} utcTimeZone - utc time zone
   *
   * @return {string} - the formatted date
   */
  static convertDateToFormat(
    sDate,
    fromFormat?: string,
    toFormat?: string,
    utcTimeZone = false
  ) {
    if (!toFormat) {
      toFormat = DateConstant.DATABASE_FORMAT;
    }

    if (!fromFormat) {
      fromFormat = DateConstant.DATABASE_FORMAT;
    }

    if (utcTimeZone) {
      return Moment.utc(sDate, fromFormat).format(toFormat);
    }

    return Moment(sDate, fromFormat).format(toFormat);
  }

  /**
   * Converts a date from date instanse format to given format.
   *
   * @param {string} sDate - the date we want to add days to
   * @param {string} toFormat - (optional) the amount of days to add, defaults to 1
   * @param {string} utcTimeZone - utc time zone
   *
   * @return {string} - the formatted date to produced to format type
   */
  static convertDateInstanceToFormat(
    sDate,
    toFormat?: string,
    utcTimeZone = false
  ) {
    if (!toFormat) {
      toFormat = DateConstant.DATABASE_FORMAT;
    }

    if (utcTimeZone) {
      return Moment.utc(sDate).format(toFormat);
    }

    return Moment(sDate).format(toFormat);
  }

  /**
   * Adds a set amount of days to the provided date.
   * Also formats the result date in the request format.
   * If the days is negative then the subtract function will be used.
   *
   * @param {string} sDate - the date we want to add days to
   * @param {int} iDays - (optional) the amount of days to add, defaults to 1
   * @param {string} fromFormat - (optional) the from format, defaults to null
   * @param {string} toFormat - (optional) the amount of days to add, defaults to 1
   *
   * @return {string} - the new date in the requested format
   */
  static addDaysToDate(
    sDate: string,
    iDays = 1,
    fromFormat?: string,
    toFormat?: string
  ) {
    if (!toFormat) {
      toFormat = DateConstant.DATABASE_FORMAT;
    }

    if (!fromFormat) {
      fromFormat = DateConstant.DATABASE_FORMAT;
    }

    return Moment(sDate, fromFormat).add(iDays, "day").format(toFormat);
  }

  /**
   * Substracts a set amount of days to the provided date.
   * Also formats the result date in the request format.
   *
   * @param {string} sDate - the date we want to substract days to
   * @param {int} iDays - (optional) the amount of days to substract, defaults to 1
   * @param {string} fromFormat - (optional) the from format, defaults to null
   * @param {string} toFormat - (optional) the amount of days to add, defaults to 1
   *
   * @return {string} - the new date in the requested format
   */
  static subDaysToDate(
    sDate: string,
    iDays = 1,
    fromFormat?: string,
    toFormat?: string
  ) {
    if (!fromFormat) {
      fromFormat = DateConstant.DATABASE_FORMAT;
    }

    if (!toFormat) {
      toFormat = DateConstant.DATABASE_FORMAT;
    }

    return Moment(sDate, fromFormat).subtract(iDays, "day").format(toFormat);
  }

  /**
   * Check the different between two days
   * expected format.
   *
   * @param {string} sDateA - the date or time field
   * @param {string} sDateB - second date or time field to compare
   *
   * @return {int} - the date difference between the two dates
   */
  static getDateDifference(sDateA, sDateB) {
    const sFirstDate = Moment(sDateA);
    const sSecondDateDate = Moment(sDateB);
    const sDuration = Moment.duration(sFirstDate.diff(sSecondDateDate));
    const iDayDiff = sDuration.asDays();

    return iDayDiff;
  }

  /**
   * Get Previous Month Start Date from today's date  given date
   *
   * @param {string} sDate - (optional) date to get last date of previous month
   *
   * @return {string} - the previous month start date
   */
  static getPreviousMonthStartDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }
    const sStartDate = Moment(sDate)
      .subtract(1, "months")
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sStartDate;
  }

  /**
   * Get Previous Month End Date from today's date or given date
   *
   * @param {string} sDate - (optional) date to get last date of previous month
   *
   * @return {string} - the previous month end date
   */
  static getPreviousMonthEndDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }
    const sLateDate = Moment(sDate)
      .subtract(1, "months")
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sLateDate;
  }

  /**
   * Get Current Month start Date from today's date or given date
   *
   * @param {string} sDate - (optional) date to get first date of current month
   *
   * @return {string} - the current month start date
   */
  static getMonthStartDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }
    const sStartDate = Moment(sDate)
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sStartDate;
  }

  /**
   * Get Current Month End Date from today's date or given date
   *
   * @param {string} sDate - (optional) date to get last date of previous month
   *
   * @return {string} - the previous month end date
   */
  static getMonthEndDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }
    const sLastDate = Moment(sDate)
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sLastDate;
  }

  /**
   * Get Next Month start date from today's date or from given date
   *
   * @param {string} sDate - (optional) date to get start date of the next month
   *
   * @return {string} - the next month start date
   */
  static getNextMonthStartDate(sDate?: string) {
    const sTodayDate = Moment().format(DateConstant.DATABASE_FORMAT);

    if (!sDate) {
      sDate = sTodayDate;
    }

    const sStartDateMonth = Moment(sDate)
      .add(1, "months")
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sStartDateMonth;
  }

  /**
   * Get Next Month end date from today's date or from given date
   *
   * @param {string} sDate - (optional) date to get end date of the next month
   *
   * @return {string} - the next month end date
   */
  static getNextMonthEndDate(sDate?: string) {
    const sTodayDate = Moment().format(DateConstant.DATABASE_FORMAT);

    if (!sDate) {
      sDate = sTodayDate;
    }

    const sEndDateMonth = Moment(sDate)
      .add(1, "months")
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sEndDateMonth;
  }

  /**
   * Get date is current Year and month from today's date
   *
   * @param {string} sDate - (optional) date to find the current year
   *
   * @return {boolean} - sending current month
   */
  static checkCurrentYearMonth(sDate) {
    const sTodayDate = Moment()
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    const sCompareDate = Moment(sDate)
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return !this.getDateDifference(sCompareDate, sTodayDate);
  }

  /**
   * Check passed parameter is less than 180 days from today's date
   *
   * @param {string} sDate - (optional) date to find the date differene
   *
   * @return {boolean} - check date count sending current month
   */
  static checkDateHalfMonthLess(sDate) {
    const sTodayDate = Moment()
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    const sCompareDate = Moment(sDate)
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    if (this.getDateDifference(sTodayDate, sCompareDate) > 305) {
      return true;
    }

    return false;
  }

  /**
   * Check date is a upcoming future date and current date
   *
   * @param {string} compareDate - compare date to find the date difference
   * @param {string} selectedDate - (optional) selected date to find the date difference
   *
   * @return {boolean}
   */
  static checkDateIsFuture(compareDate, selectedDate) {
    let sTodayDate = Moment().format(DateConstant.DATABASE_FORMAT);
    if (selectedDate) {
      sTodayDate = Moment(selectedDate).format(DateConstant.DATABASE_FORMAT);
    }

    const sCompareDate = Moment(compareDate).format(
      DateConstant.DATABASE_FORMAT
    );

    if (this.getDateDifference(sCompareDate, sTodayDate) > -1) {
      return true;
    }

    return false;
  }

  /**
   * Check the date month is a upcoming future month
   *
   * @param {string} sDate - (optional) date to find the date differene
   *
   * @return {boolean}
   */
  static checkMonthIsFuture(sDate) {
    const sTodayDate = Moment().format(DateConstant.DATE_YEAR_MONTH);
    const sCompareDate = Moment(sDate).format(DateConstant.DATE_YEAR_MONTH);

    if (this.getDateDifference(sCompareDate, sTodayDate) > 0) {
      return true;
    }

    return false;
  }

  /**
   * Get Previous Twelve Month Start Date from today's date or given date
   *
   * @param {string} sDate - (optional) date to get first date from this date
   *
   * @return {string} - Previous Twelve Month Start Date
   */
  static getPreviousTwelveMonthStartDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }

    const sEndDateMonth = Moment(sDate)
      .subtract(1, "months")
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    const sDateSub = this.subDaysToDate(
      sEndDateMonth,
      DateConstant.TOTAL_DAYS_YEAR
    );

    const iDateDiffernece = this.getDateDifference(sDate, sDateSub);

    let sStartDateMonth = Moment(sDateSub)
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    if (Math.ceil(iDateDiffernece) > DateConstant.TOTAL_DAYS_FOR_YEARS) {
      sStartDateMonth = Moment(sDateSub)
        .add(1, "months")
        .startOf("month")
        .format(DateConstant.DATABASE_FORMAT);
    }

    return sStartDateMonth;
  }

  /**
   * Get Previous Twelve Month End Date from today's date or given date
   *
   * @param {string} sDate - (optional) date to get first date of the year
   *
   * @return {string} -  Previous Twelve Month End Date
   */
  static getPreviousTwelveMonthEndDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().format(DateConstant.DATABASE_FORMAT);
    }

    const sLastDate = Moment(sDate)
      .subtract(1, "months")
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sLastDate;
  }

  /**
   * Get date and substact to given paramerter months
   *
   * @param {string} date - (optional) date to get first date from this date
   * @param {int} months - (optional) months to substarct
   *
   * @return string - Previous months
   */
  static substractDateByMonths(date?: string, months = 1) {
    if (!date) {
      date = Moment().format(DateConstant.DATABASE_FORMAT);
    }

    const startOfMonth = Moment(date)
      .subtract(months, "months")
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return startOfMonth;
  }

  /**
   * Get Current Year End Date from today's date or from given date
   *
   * @param {string} sDate - (optional) date to get last date of the year
   *
   * @return {string} - the year end date
   */
  static getNextTwelveMonthStartDate(sDate?: string) {
    const sTodayDate = Moment().format(DateConstant.DATABASE_FORMAT);

    if (!sDate) {
      sDate = sTodayDate;
    }

    const sStartDateMonth = Moment(sDate)
      .add(1, "months")
      .startOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sStartDateMonth;
  }

  /**
   * Get Current Year End Date from today's date or from given date
   *
   * @param {string} sDate - (optional) date to get last date of the year
   *
   * @return {string} - the year end date
   */
  static getNextTwelveMonthEndDate(sDate?: string) {
    if (!sDate) {
      sDate = Moment().startOf("month").format(DateConstant.DATABASE_FORMAT);
    }

    const sEndDateMonth = Moment(sDate)
      .add(1, "years")
      .endOf("month")
      .format(DateConstant.DATABASE_FORMAT);

    return sEndDateMonth;
  }

  /**
   * Convert single digits into double digits
   * E.g : if num = 1 & len = 2 then converted into 01
   * E.g : if num = 20 & len = 2 then show the same 20
   *
   * @param {int} num - date to convert into double digit
   * @param {int} len - converting the number from given number of length
   *
   * @return {int} - converted double digit number
   */
  static convertNumberDigit(num, len) {
    return (Array(len).join("0") + num).slice(-len);
  }

  /**
   * Get total days in the month
   *
   * @param {string} date - date to find the month to find number of days in the month
   *
   * @return {int} - total number of days in the month
   */
  static monthDays(date) {
    const days = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return days.getDate();
  }

  static getBeginningOfTodayDate(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }
}

export default DateUtil;
