import * as actionTypes from "../../Actions/actionTypes";

export const initialState = {
  isFetching: false,
  isGasPriceFetching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRICE_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case actionTypes.GAS_PRICE_FETCHING:
      return {
        ...state,
        isGasPriceFetching: action.isGasPriceFetching,
      };
    default:
      return state;
  }
};

export default reducer;
