import * as actionTypes from "../actionTypes";

export const updateJointAccountHolder = (JointAccountHolderInfo) => {
  return {
    type: actionTypes.UPDATE_JOINT_ACCOUNT_HOLDER,
    JointAccountHolderInfo,
  };
};
export const updateUnusedJointAccountHolder = (
  UnusedJointAccountHolderInfo
) => {
  return {
    type: actionTypes.UPDATE_UNUSED_JOINT_ACCOUNT_HOLDER,
    UnusedJointAccountHolderInfo,
  };
};
