import { AxiosRequestConfig } from 'axios';
import {
  GetSubmitJourneyDataPath,
  GetSubmitJourneyDataVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import {
  SubmitJourneyDataApiResponse,
  ExpressJourneyType,
  JourneyData,
} from '../models/journeys';

export function submitJourneyData(
  version: GetSubmitJourneyDataVersion,
  journeyType: ExpressJourneyType,
  ba: string,
  journeyId: string,
  journeyData: JourneyData,
  accessToken: string,
  timestamp: string,
  signature: string,
  config?: AxiosRequestConfig
) {
  const path = GetSubmitJourneyDataPath[version](journeyType, ba, journeyId);

  return baseInstance(config).post<SubmitJourneyDataApiResponse>(
    path,
    journeyData,
    {
      headers: {
        'access-token': accessToken,
        timestamp,
        signature,
      },
    }
  );
}
