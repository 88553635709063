import * as React from "react";

import "./PlanCard.scss";

const PlanCard = ({ banner, tag, content }) => (
  <div className="planCard">
    <div className="planCard_ribbon">
      <div className="planCard_ribbon_banner">
        <div className="planCard_ribbon_banner_text">{banner}</div>
      </div>
    </div>
    <div className="planCard_tag">{tag}</div>
    <div className="planCard_content">{content}</div>
  </div>
);

export default PlanCard;
