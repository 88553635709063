import React, {
  createContext,
  ProviderProps,
  useContext,
  useMemo,
} from 'react';
import { AxiosConfig, defaultConfig } from '@contact/data-access';
import { pick } from 'lodash-es';
import { AxiosRequestConfig } from 'axios';

function mergeAxiosConfig(
  base: AxiosRequestConfig = { baseURL: '/' },
  config?: AxiosConfig
) {
  if (!config) return base;
  const partiallyMerged = {
    ...base,
    ...config,
    baseURL: config?.baseURL
      ? new URL(config.baseURL, base.baseURL).toString()
      : base.baseURL,
  };
  return {
    ...partiallyMerged,
    headers: {
      ...base.headers,
      ...partiallyMerged.headers,
    },
  };
}

export const AxiosConfigContext = createContext<AxiosConfig>(defaultConfig);
export function AxiosConfigProvider({
  children,
  value,
}: ProviderProps<AxiosConfig | undefined>) {
  const nextValue = useAxiosConfig(value);
  return (
    <AxiosConfigContext.Provider value={nextValue}>
      {children}
    </AxiosConfigContext.Provider>
  );
}
export function useAxiosConfig(provided?: AxiosConfig) {
  const base = useContext(AxiosConfigContext);
  return useMemo(() => mergeAxiosConfig(base, provided), [base, provided]);
}

export function useBaseAxiosConfig() {
  const base = useContext(AxiosConfigContext);
  return useMemo(
    () => ({
      baseURL: base.baseURL,
      timeout: base.timeout,
      headers: pick(base.headers, 'x-api-key', 'Content-Type'),
    }),
    [base]
  );
}
