import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

import "./Layout.scss";

export default function withLayout(WrappedComponent) {
  return class Layout extends Component {
    wrappedComponent;
    render() {
      return (
        <div className="App">
          <Header />
          <div className="layout">
            <WrappedComponent navigation={this.props.navigation} />
          </div>
          <Footer />
        </div>
      );
    }
  };
}
