import {
  QueryObserverResult,
  QueryObserverSuccessResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import {
  AxiosConfig,
  getAccounts,
  getAccountsConfigured,
  GetAccountsPath,
  AccountsApiResponse,
  Transaction,
  GetAccountsVersion,
} from '@contact/data-access';
import { useAxiosConfig } from './context';

export const useAccounts = (
  version: GetAccountsVersion,
  token: string,
  ba?: string,
  options?: UseQueryOptions<AccountsApiResponse>
) =>
  useQuery<AccountsApiResponse>(
    [GetAccountsPath[version], token, ba],
    async () => {
      const { data } = await getAccounts(version, token, ba);
      return data;
    },
    {
      ...options,
      enabled: !!token,
    }
  );

export function useAccountsConfigured(
  version: GetAccountsVersion,
  ba?: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<AccountsApiResponse> & {
    suspense: true;
  }
): QueryObserverSuccessResult<AccountsApiResponse>;
export function useAccountsConfigured(
  version: GetAccountsVersion,
  ba?: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<AccountsApiResponse>
): QueryObserverResult<AccountsApiResponse>;
export function useAccountsConfigured(
  version: GetAccountsVersion,
  ba?: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<AccountsApiResponse>
): QueryObserverResult<AccountsApiResponse> {
  const config = useAxiosConfig(providedConfig);
  return useQuery<AccountsApiResponse>(
    [GetAccountsPath[version], ba],
    async () => {
      const { data } = await getAccountsConfigured(version, ba, config);
      return data;
    },
    options
  );
}
