import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  ContentSyncPath,
  ContentSyncVersion,
  getContentSync,
} from '@contact/data-access';

export const useContentSync = (
  version: ContentSyncVersion,
  path = '/OLS',
  config?: AxiosRequestConfig
) =>
  useQuery([ContentSyncPath[version], path], async () => {
    const { data } = await getContentSync(version, path, config);
    return data;
  });

export const useContentSyncOptional = (
  version: ContentSyncVersion,
  path?: string,
  config?: AxiosRequestConfig
) =>
  useQuery([ContentSyncPath[version], path], async () => {
    if (!path) return {};
    const { data } = await getContentSync(version, path, config);
    return data;
  });
