import React, { useEffect, useMemo } from "react";
import { useSpring, animated } from "react-spring";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { CloseOverlay, Close } from "../../Assets/Icons";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";

import "./Modal.scss";

const modalRoot = document.getElementById("modalRoot");
const body = document.getElementsByTagName("body")[0];

const Modal = (props) => {
  const contentStyle = classNames({
    modal_content: true,
    "modal_content--price": props.inputType,
    "modal_content--saveForLater": props.saveForLaterStyle,
  });

  const [animateModal, setAnimatedModal] = useSpring(() => ({ opacity: 0 }));

  setAnimatedModal({
    opacity: props.isActive ? 1 : 0,
  });

  useEffect(() => {
    body.style.overflow = props.isActive ? "hidden" : "";
    return () => {
      if (body.style.overflow === "hidden" && props.isActive) {
        body.style.overflow = "";
      }
    };
  }, [props.isActive]);

  const isPrimaryButtonClass = props.buttonTextSecondary
    ? "toggleCancel"
    : "toggleConfirm";

  const closeIconCustomStyle = useMemo(() => {
    if (props.clearSessionStyle) {
      return {
        width: "18px",
        height: "18px",
        marginRight: "14px",
      };
    }
    if (props.saveForLaterStyle) {
      return {
        display: "flex",
        width: "12px",
        height: "12px",
      };
    }
  }, [props.clearSessionStyle, props.saveForLaterStyle]);

  const buttonCustomStyle = useMemo(() => {
    if (props.clearSessionStyle) {
      return {
        fontSize: "16px",
      };
    }
    if (props.saveForLaterStyle) {
      return {
        fontSize: "18px",
        lineHeight: 1.2,
        textTransform: "none",
      };
    }
  }, [props.clearSessionStyle, props.saveForLaterStyle]);

  return props.isActive
    ? ReactDOM.createPortal(
        <animated.div
          style={animateModal}
          className={classNames("modal", {
            journeyStyle: props.journeyStyle,
            "modal--saveForLater": props.saveForLaterStyle,
          })}
        >
          <div
            name={props.name}
            className={"modal_container " + props.className}
          >
            {(props.journeyStyle || props.showClose) && (
              <div
                className={classNames("modal_closeContainer", {
                  "modal_closeContainer--saveForLater": props.saveForLaterStyle,
                })}
                onClick={props.handlePrimaryClick}
                aria-label="Close Modal"
              >
                {props.clearSessionStyle || props.saveForLaterStyle ? (
                  <Icon icon={<Close />} customStyle={closeIconCustomStyle} />
                ) : (
                  <CloseOverlay />
                )}
              </div>
            )}
            <div className="modal_contentContainer">
              <div className="modal_titleContainer">
                {props.icon && <div className="modal_icon">{props.icon}</div>}
                <h2
                  className={classNames("modal_title", {
                    "modal_title--saveForLater": props.saveForLaterStyle,
                  })}
                >
                  {props.title}
                </h2>
              </div>
              <div className={contentStyle} style={props.contentStyle}>
                {props.content}
              </div>
              {props.inputType && (
                <Input
                  validationType={props.inputType}
                  name="input"
                  handleChange={props.handleChange}
                  value={props.value}
                  iconType="edit"
                  labelText={props.inputLabelText}
                  placeholder="0.00"
                  handleError={props.handleError}
                  errorMessage={props.errorMessage}
                  hasError={props.hasError}
                />
              )}
            </div>
            {!props.hideButtonContainer && (
              <div className="modal_buttonWrapper">
                {!props.journeyStyle && (
                  <Button
                    type="button"
                    text={props.buttonTextPrimary}
                    handleClick={props.handlePrimaryClick}
                    theme={isPrimaryButtonClass}
                    aria-label="Close Modal"
                    customStyle={buttonCustomStyle}
                  />
                )}
                {props.buttonTextSecondary && (
                  <Button
                    type="button"
                    text={props.buttonTextSecondary}
                    handleValidationClick={props.handleValidationClick}
                    handleClick={props.handleSecondaryClick}
                    theme="toggleConfirm"
                    disabled={props.buttonDisabled}
                    customStyle={buttonCustomStyle}
                    isLoading={props.isLoading}
                  />
                )}
              </div>
            )}
          </div>
        </animated.div>,
        modalRoot
      )
    : null;
};

export default Modal;
