import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  AasfApiResponse,
  aasf,
  AasfVersion,
  AasfPath,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useAasf = (
  version: AasfVersion,
  bpId: string,
  sessionToken: string,
  xcsrfToken: string,
  queryOptions?: UseQueryOptions<AasfApiResponse>,
  axiosOptions?: Partial<AxiosRequestConfig>
) =>
  useQuery<AasfApiResponse>(
    [AasfPath[version], bpId, sessionToken, xcsrfToken],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await aasf(version, bpId, sessionToken, xcsrfToken, {
          ...axiosOptions,
          ...extraOptions,
        });
        return data;
      }),
    {
      ...queryOptions,
      enabled: !!(bpId && sessionToken && xcsrfToken),
    }
  );
