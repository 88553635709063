import { useQuery } from 'react-query';
import {
  ConsumptionPath,
  ConsumptionVersion,
  getConsumption,
} from '@contact/data-access';

export const useConsumption = (
  version: ConsumptionVersion,
  token: string,
  xcsrfToken: string,
  contractId: string,
  interval: string,
  from: string,
  to: string
) => {
  return useQuery(
    [
      ConsumptionPath[version],
      token,
      xcsrfToken,
      contractId,
      interval,
      from,
      to,
    ],
    async () => {
      const { data } = await getConsumption(
        version,
        token,
        xcsrfToken,
        contractId,
        interval,
        from,
        to
      );
      return data;
    },
    {
      enabled: !!(token && xcsrfToken && contractId && interval && from && to),
    }
  );
};
