import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import { RollOff, getRollOffDetails } from '@contact/data-access';
import { cancellableQuery } from '../utils';

const UNKNOWN_ERROR = {
  code: 'RO_UNKNOWN_ERROR',
  message: 'An unknown error happened.',
};

export const useRollOffDetails = (
  id: string,
  queryOptions?: UseQueryOptions<RollOff.RollOffDetails>,
  axiosOptions?: Partial<AxiosRequestConfig>
) =>
  useQuery<RollOff.RollOffDetails>(
    ['roll-off-details', id],
    () =>
      cancellableQuery(async (extraOptions) => {
        try {
          const { data } = await getRollOffDetails(id, {
            ...axiosOptions,
            ...extraOptions,
          });
          return data;
        } catch (exception) {
          const errorResponse = (exception as AxiosError<RollOff.ErrorResponse>)
            .response;
          if (errorResponse) {
            const error = {
              code: `RO_${errorResponse.data.code}`,
              message: errorResponse.data.message,
              data: omit(errorResponse.data, ['code', 'message']),
            };
            throw error;
          }
          throw UNKNOWN_ERROR;
        }
      }),
    {
      ...queryOptions,
      enabled: !!id,
    }
  );
