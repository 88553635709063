import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  ProviderType,
  getProviders,
  BroadbandProvidersVersion,
  BroadbandProvidersPath,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useProviders = (
  version: BroadbandProvidersVersion,
  type?: ProviderType,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [BroadbandProvidersPath[version], type],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getProviders(version, type, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: true,
    }
  );
