import React from "react";
import { withRouter } from "react-router-dom";

const GoBack = (props) => {
  const { history, backToJourneyUrl, backToJourneyText } = props;
  const withHistory = history && history.length > 3 && !backToJourneyUrl;
  const doHistoryBack = (event, redirect) => {
    if (redirect) {
      event.preventDefault();
      history.goBack();
    }
  };
  // FallBack if no url passed with variable, nor has a history
  const goBackUrl = backToJourneyUrl
    ? backToJourneyUrl
    : "contact.co.nz/residential/find-a-plan";
  return goBackUrl ? (
    <div className="myDetails_go_back_link_container">
      <a
        className="myDetails_go_back_link_item"
        href={withHistory ? "/" : goBackUrl}
        onClick={($e) => doHistoryBack($e, withHistory)}
      >
        {backToJourneyText ? backToJourneyText : "GO BACK"}
      </a>
    </div>
  ) : null;
};

export default withRouter(GoBack);
