import React from "react";
import { SaveForLater as Constants } from "./Constants";
import "./SaveForLaterTrigger.scss";

export interface SaveForLaterTriggerProps {
  onClick: () => void;
}

export const SaveForLaterTrigger = ({ onClick }: SaveForLaterTriggerProps) => (
  <button onClick={onClick} className="saveForLaterTrigger">
    {Constants.trigger.label}
  </button>
);
