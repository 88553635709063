import moment from "moment";
import {
  Payment as Constants,
  PAYMENT_METHOD_BILL,
  PAYMENT_METHOD_PREPAY,
  BILLING_CYCLE_MONTHLY,
  BILLING_CYCLE_FORTNIGHTLY,
} from "../../Config/Constants";

/**
 * Returns true, if prepay payment method.
 * @param {string} paymentMethod
 * @returns {boolean}
 */
export function isPrepay(paymentMethod) {
  return paymentMethod === PAYMENT_METHOD_PREPAY;
}

/**
 * Returns true, if receive bill payment method.
 * @param {string} paymentMethod
 * @returns {boolean}
 */
export function isReceiveBill(paymentMethod) {
  return paymentMethod === PAYMENT_METHOD_BILL;
}

/**
 * Returns payment method label.
 * @param {string} paymentMethod
 * @returns {string|undefined}
 */
export function paymentMethodLabel(paymentMethod) {
  const item = Constants.paymentMethods.find(
    ({ value }) => value === paymentMethod
  );
  return item?.label;
}

/**
 * Returns true, if billing cycle is monthly.
 * @param {string} billingCycle
 * @returns {boolean}
 */
export function isBillingMonthly(billingCycle) {
  return billingCycle === BILLING_CYCLE_MONTHLY;
}

/**
 * Returns true, if billing cycle is fortnightly.
 * @param {string} billingCycle
 * @returns {boolean}
 */
export function isBillingFortnightly(billingCycle) {
  return billingCycle === BILLING_CYCLE_FORTNIGHTLY;
}

/**
 * Returns billing cycle label.
 * @param {string} billingCycle
 * @returns {string|undefined}
 */
export function billingCycleLabel(billingCycle) {
  const item = Constants.billingCycleOptions.find(
    ({ value }) => value === billingCycle
  );
  return item?.label;
}

/**
 * Returns a collection of RadioButtonList options for available payment methods.
 * @param {boolean} isBillingCycleEligible
 * @param {boolean} isPrepayEligible
 * @returns {Array<{label: string, value: string}>}
 */
export function availablePaymentMethods(
  isBillingCycleEligible,
  isPrepayEligible
) {
  return (
    Constants.paymentMethods
      // filter prepay option if not eligible
      .filter(({ value }) => isPrepayEligible || !isPrepay(value))
      // replace receive bill option with default billing cycle if not billing cycle eligible
      .map((item) => {
        if (!isBillingCycleEligible && isReceiveBill(item.value)) {
          return {
            value: item.value,
            label:
              item.label +
              " (" +
              billingCycleLabel(Constants.billingCycleDefault) +
              ")",
          };
        }
        return item;
      })
  );
}

/**
 * Returns the options for BillStartDate.
 * Logic around:
 *   - When it's a weekday Mon-Fri - next Monday after next whole week
 *     taken for 1st and next after that Monday for 2nd
 *   - When it's a Sat-Sun - next Monday after next 2 weeks taken etc.
 *
 * @param from {Date} Date from when to start the calculation, if not provided today
 * @returns {string[]}
 */
export function generateBillDates(from?: string): [string, string] {
  const today = moment(from).day();
  switch (today) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return [
        moment(from)
          .add(1, "weeks")
          .endOf("isoWeek")
          .add(1, "day")
          .format("ddd DD MMMM YYYY"),
        moment(from)
          .add(2, "weeks")
          .endOf("isoWeek")
          .add(1, "day")
          .format("ddd DD MMMM YYYY"),
      ];
    case 0:
    case 6:
    default:
      return [
        moment(from)
          .add(2, "weeks")
          .endOf("isoWeek")
          .add(1, "day")
          .format("ddd DD MMMM YYYY"),
        moment(from)
          .add(3, "weeks")
          .endOf("isoWeek")
          .add(1, "day")
          .format("ddd DD MMMM YYYY"),
      ];
  }
}

export function isEligibleForSmoothPay(
  paymentMethod: string,
  billingCycle: string,
  joinDirectDebit: boolean
) {
  return (
    isReceiveBill(paymentMethod) &&
    isBillingMonthly(billingCycle) &&
    joinDirectDebit
  );
}
