import { AxiosRequestConfig } from 'axios';
import { LoginPath, LoginVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { LoginApiResponse } from './models';

export const login = (
  version: LoginVersion,
  username: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<LoginApiResponse>(LoginPath[version], {
    username,
    password,
  });
};
