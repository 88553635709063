import React from "react";
import HTML from "../../HTML";

const NoRates = ({ message }) => (
  <div className="ratesContent_detailedRate">
    <div className="ratesError">
      <HTML html={message} />
    </div>
  </div>
);

export default NoRates;
