import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { trackAction, ActionTracking } from '@contact/data-access';

type TData = ActionTracking.Response;
type TError = unknown;
type TVariables = ActionTracking.Action;

export const useActionTracking = (
  accessToken?: string,
  axiosOptions?: Partial<AxiosRequestConfig>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables>
) =>
  useMutation<TData, TError, TVariables>(async (action) => {
    const { data } = await trackAction(action, accessToken, axiosOptions);
    return data;
  }, mutationOptions);
