import React from "react";
import Input from "../Input";
import Loading from "../Loading";

function CustomInput(inputProps, props, value) {
  return (
    <div className="address--custom-input">
      <Input
        name={props.name}
        value={value}
        labelText={props.labelText}
        placeholder={props.placeholder}
        inputProps={inputProps}
        required={props.required}
        handleError={props.handleError}
        hasError={props.hasError}
        errorMessage={props.errorMessage}
        invalidMessage={props.invalidMessage}
        validateItself={props.validateItself}
      />
      {props.isLoading && <Loading customstyle={inputProps.customstyle} />}
    </div>
  );
}

export default CustomInput;
