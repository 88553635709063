import { initialState } from "../../Reducers/CSRAgent/PriceRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { get, difference, cloneDeep } from "lodash-es";

import {
  gasPriceFetching,
  priceFetching,
} from "../../Actions/CSRAgent/PriceAction";
import { pair } from "../utils";
import {
  usePlansData,
  useElectricityICPDetails,
  usePlanInfoSelectedPlanSelector,
  Service,
  useServices,
  useBroadbandOffersByPlanId,
} from ".";
import {
  BASIC_PLAN,
  BB_BUNDLE_PLAN_ID,
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
} from "Config/Constants";
import {
  Offering,
  Plan,
  useGasRegistryICPDetails,
  usePrice,
} from "@contact/data-access-hooks";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import { triggerEPValues } from "Utils/analytics";

interface Price {
  isFetching: boolean;
  isGasPriceFetching: boolean;
}

export function getPrice(state): Price {
  return state.Price || initialState;
}

export function usePriceSelector() {
  return useSelector(getPrice);
}

export function getIsPriceFetching(state) {
  return getPrice(state).isFetching;
}

export function useIsPriceFetchingSelector() {
  return useSelector(getIsPriceFetching);
}

export function useIsPriceFetchingSetter() {
  const dispatch = useDispatch();
  return useCallback((isFetching) => dispatch(priceFetching(isFetching)), [
    dispatch,
  ]);
}

export const useIsLowGasPricesAvailable = (icp?: string) => {
  const { data, isSuccess } = useLowGasPrices(icp);

  return useMemo(() => {
    return (
      isSuccess && !!data?.priceList.find((item) => Number(item.Charge) > 0)
    );
  }, [data, isSuccess]);
};

export function useIsPriceFetching() {
  const value = useIsPriceFetchingSelector();
  const setter = useIsPriceFetchingSetter();
  return pair(value, setter);
}

export function getIsGasPriceFetching(state) {
  return getPrice(state).isGasPriceFetching;
}

export function useIsGasPriceFetchingSelector() {
  return useSelector(getIsGasPriceFetching);
}

export function getCampaignId(plan: Plan, services: Service[]) {
  const dynamicCampaignIdMappings = get(plan, "DynamicCampaignId.Mappings", []);
  const dynamicCampaignIdMappingsDefaultValue = get(
    plan,
    "DynamicCampaignId.Default",
    ""
  );
  const campaignId = get(plan, "CampaignId", "");

  return (
    dynamicCampaignIdMappings.find(
      (mapping) =>
        difference(mapping.Services, services).length === 0 &&
        difference(services, mapping.Services).length === 0
    )?.CampaignId ||
    dynamicCampaignIdMappingsDefaultValue ||
    campaignId
  );
}

export const useStandardElectricityPrices = (icp?: string, planId?: string) => {
  const [services] = useServices();
  const { data: plansData, isLoading: isPlansDataLoading } = usePlansData();
  const {
    data: icpDetails,
    isLoading: isIcpDetailsLoading,
  } = useElectricityICPDetails(icp);

  const plan = plansData.find((plan) => plan.Id === planId);
  const basicPlan = plansData.find((plan) => plan.Id === BASIC_PLAN);
  const currentPlan = plan || basicPlan;

  const priceRequest = icpDetails &&
    currentPlan && {
      ...icpDetails,
      campaignId: getCampaignId(currentPlan, services),
      packCode: currentPlan.PackCode || "",
      productCategoryAttribute:
        currentPlan.ProductCategoryAttribute || undefined,
    };

  const {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading: isStandardPriceLoading,
  } = usePrice(
    "v2",
    priceRequest && {
      ...priceRequest,
      electricityUserType: USAGE_TYPE_STANDARD,
    },
    baseAxiosConfig
  );

  return {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading:
      isPlansDataLoading || isIcpDetailsLoading || isStandardPriceLoading,
  };
};

export const useLowElectricityPrices = (icp?: string, planId?: string) => {
  const [services] = useServices();
  const { data: plansData, isLoading: isPlansDataLoading } = usePlansData();
  const {
    data: icpDetails,
    isLoading: isIcpDetailsLoading,
  } = useElectricityICPDetails(icp);

  const plan = plansData.find((plan) => plan.Id === planId);
  const basicPlan = plansData.find((plan) => plan.Id === BASIC_PLAN);
  const currentPlan = plan || basicPlan;
  const priceRequest = icpDetails &&
    currentPlan && {
      ...icpDetails,
      campaignId: getCampaignId(currentPlan, services),
      packCode: currentPlan.PackCode || "",
      productCategoryAttribute:
        currentPlan.ProductCategoryAttribute || undefined,
    };

  const {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isLowPriceLoading,
  } = usePrice(
    "v2",
    priceRequest && {
      ...priceRequest,
      electricityUserType: USAGE_TYPE_LOW,
    },
    baseAxiosConfig
  );

  return {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isPlansDataLoading || isIcpDetailsLoading || isLowPriceLoading,
  };
};

export function useIsGasPriceFetchingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isGasPriceFetching) => dispatch(gasPriceFetching(isGasPriceFetching)),
    [dispatch]
  );
}

export function useIsGasPriceFetching() {
  const value = useIsGasPriceFetchingSelector();
  const setter = useIsGasPriceFetchingSetter();
  return pair(value, setter);
}

export const useElectricityPrices = (icp?: string, planId?: string) => {
  const {
    data: icpDetails,
    isLoading: isIcpDetailsLoading,
  } = useElectricityICPDetails(icp);

  const {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading: isStandardPriceLoading,
  } = useStandardElectricityPrices(icp, planId);

  const {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isLowPriceLoading,
  } = useLowElectricityPrices(icp, planId);

  type InternalPrices = [typeof standardPrice, typeof lowPrice] | undefined;
  const lastKnownPrices = useRef<InternalPrices>(undefined);

  const prices = useMemo(
    () =>
      isStandardPriceSuccess && isLowPriceSuccess
        ? (lastKnownPrices.current = [standardPrice, lowPrice])
        : lastKnownPrices.current,
    [isLowPriceSuccess, isStandardPriceSuccess, lowPrice, standardPrice]
  );

  useEffect(() => {
    if (icpDetails && isStandardPriceSuccess && isLowPriceSuccess) {
      triggerEPValues(icp, true, icpDetails.regionCode);
    }
  }, [icp, icpDetails, isLowPriceSuccess, isStandardPriceSuccess]);

  return {
    data: prices,
    isSuccess: isStandardPriceSuccess && isLowPriceSuccess,
    isLoading:
      isIcpDetailsLoading || isStandardPriceLoading || isLowPriceLoading,
  };
};

export const useStandardGasPrices = (icp?: string) => {
  const [services] = useServices();
  const selectedPlan = usePlanInfoSelectedPlanSelector();

  const {
    data: icpDetails,
    isLoading: isIcpDetailsLoading,
  } = useGasRegistryICPDetails("v1", icp, baseAxiosConfig);

  const priceRequest = icpDetails &&
    selectedPlan && {
      ...icpDetails,
      icpNumber: icp || "",
      packCode: selectedPlan.PackCode || "",
      startDate: formatDateToAPIString(selectedPlan.StartDate || ""), // NOTE: format is a bit strange: DDMMYYYY, no separators
      campaignId: getCampaignId(selectedPlan, services),
    };

  const {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading: isStandardPriceLoading,
  } = usePrice(
    "v2",
    priceRequest && {
      ...priceRequest,
      electricityUserType: USAGE_TYPE_STANDARD,
    },
    baseAxiosConfig
  );

  return {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading: isIcpDetailsLoading || isStandardPriceLoading,
  };
};

export const useLowGasPrices = (icp?: string) => {
  const [services] = useServices();
  const selectedPlan = usePlanInfoSelectedPlanSelector();

  const {
    data: icpDetails,
    isLoading: isIcpDetailsLoading,
  } = useGasRegistryICPDetails("v1", icp, baseAxiosConfig);

  const priceRequest = icpDetails &&
    selectedPlan && {
      ...icpDetails,
      icpNumber: icp || "",
      packCode: selectedPlan.PackCode || "",
      startDate: formatDateToAPIString(selectedPlan.StartDate || ""), // NOTE: format is a bit strange: DDMMYYYY, no separators
      campaignId: getCampaignId(selectedPlan, services),
    };

  const {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isLowPriceLoading,
  } = usePrice(
    "v2",
    priceRequest && {
      ...priceRequest,
      electricityUserType: USAGE_TYPE_LOW,
    },
    baseAxiosConfig
  );

  return {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isIcpDetailsLoading || isLowPriceLoading,
  };
};

export const useGasPrices = (icp?: string) => {
  const {
    data: lowPrice,
    isSuccess: isLowPriceSuccess,
    isLoading: isLowPriceLoading,
  } = useLowGasPrices(icp);

  const {
    data: standardPrice,
    isSuccess: isStandardPriceSuccess,
    isLoading: isStandardPriceLoading,
  } = useStandardGasPrices(icp);

  const prices = useMemo(
    () =>
      isLowPriceSuccess && isStandardPriceSuccess
        ? [lowPrice, standardPrice]
        : undefined,
    [isLowPriceSuccess, isStandardPriceSuccess, lowPrice, standardPrice]
  );

  return {
    data: prices,
    isSuccess: isStandardPriceSuccess && isLowPriceSuccess,
    isLoading: isLowPriceLoading || isStandardPriceLoading,
  };
};

const formatDateToAPIString = (date) => {
  const d = new Date(date);
  return `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()}`;
};

const getBundlePrice = (offer?: Offering) =>
  Number(offer?.BundlePrice.match(/\d+/)?.[0]) || undefined;

export const useLowestBroadbandPrice = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data: bbOffersResponse, isLoading } = useBroadbandOffersByPlanId(
    isCSR,
    journeyType,
    BB_BUNDLE_PLAN_ID
  );

  return useMemo(() => {
    const broadbandOffers = cloneDeep(bbOffersResponse?.Offerings || []);
    broadbandOffers.sort(
      (a, b) =>
        (getBundlePrice(a) || Infinity) - (getBundlePrice(b) || Infinity)
    );
    return {
      data: getBundlePrice(broadbandOffers[0]),
      isLoading,
    };
  }, [bbOffersResponse, isLoading]);
};
