import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getSiteCoreEventTracking,
  getSiteCoreIdentity,
  IdentifyPath,
  SiteCoreEventTrackingApiResponse,
  SiteCoreEventTrackingParams,
  SiteCoreIdentityApiResponse,
  SiteCoreIdentityParams,
} from '@contact/data-access';

export const useSiteCoreIdentity = (
  params: SiteCoreIdentityParams,
  axiosOptions?: Partial<AxiosRequestConfig>,
  queryOptions?: UseQueryOptions<
    SiteCoreIdentityApiResponse,
    unknown,
    SiteCoreIdentityApiResponse,
    string[]
  >
) => {
  const { version, bpId, baId, token } = params;
  const valid = !!(bpId && baId && token);
  const enabled = valid && (queryOptions?.enabled ?? true);
  return useQuery(
    [IdentifyPath[version], bpId, baId, token],
    async () => {
      const { data } = await getSiteCoreIdentity(params, axiosOptions);
      return data;
    },
    { ...queryOptions, enabled }
  );
};

/** Consider using {@link SiteCoreContext} to get the `cookie` value. */
export const useSiteCoreEventTracking = (
  axiosOptions?: Partial<AxiosRequestConfig>,
  queryOptions?: Omit<
    UseMutationOptions<
      SiteCoreEventTrackingApiResponse,
      unknown,
      SiteCoreEventTrackingParams
    >,
    'mutationFn'
  >
) =>
  useMutation(async (params: SiteCoreEventTrackingParams) => {
    const { data } = await getSiteCoreEventTracking(params, axiosOptions);
    return data;
  }, queryOptions);
