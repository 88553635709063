import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  AddressDetails,
  electricityLookup,
  ElectricityLookupPath,
  ElectricityLookupVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useElectricityLookup = (
  version: ElectricityLookupVersion,
  addressDetails?: AddressDetails,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [ElectricityLookupPath[version], addressDetails?.pxid],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await electricityLookup(version, addressDetails!, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!addressDetails,
    }
  );
