import { AxiosRequestConfig } from 'axios';
import { ResetPasswordPath, ResetPasswordVersion } from './apiPaths';
import { baseInstance } from './base-service';

export const resetPassword = (
  version: ResetPasswordVersion,
  activationKey: string,
  requestId: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).put<void>(ResetPasswordPath[version], {
    activationKey,
    requestId,
    password,
  });
};
