import * as actionTypes from "../../Actions/actionTypes";

export const initialState = {
  error: null,
  isSubmitting: false,
  success: false,
  successResponse: null,
  successMessage: null,
  state: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FOR_LATER_SUBMITTING:
      return {
        ...state,
        error: null,
        isSubmitting: true,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    case actionTypes.SAVE_FOR_LATER_SUCCESS:
      return {
        ...state,
        error: null,
        isSubmitting: false,
        success: true,
        successResponse: action.successResponse,
        successMessage: action.successMessage,
        state: action.state,
      };
    case actionTypes.SAVE_FOR_LATER_ERROR:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    case actionTypes.SAVE_FOR_LATER_INITIAL_STATE:
      return {
        ...state,
        error: null,
        isSubmitting: false,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    default:
      return state;
  }
};

export default reducer;
