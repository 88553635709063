import * as actionTypes from "../actionTypes";
import { getApiState } from "../ApiCalls";
import { MyDetailsFixture } from "../../Fixtures/iJoin/MyDetailsResponse";
import {
  updateAuthorizedPerson,
  updateUnusedAuthorizedPerson,
} from "../CSRAgent/AuthorizedPersonAction";
import { updateCustomer } from "./CustomerActions";
import { updateProperty } from "./PropertyActions";
import { updateBottledGas } from "./BottledGasActions";
import { updateBillCompareOutput } from "../CSRAgent/BillCompareActions";
import { updatePipedGas } from "./GasActions";
import { updatePayment } from "./PaymentActions";
import { updateBroadband } from "./BroadbandActions";
import { updateServices } from "./ServicesActions";
import { updateConfirmation } from "./ConfirmationActions";
import { updateReadonly } from "./ReadonlyActions";
import { updatePlan } from "./PlanActions";
import { updateJourneyType } from "../CSRAgent/JourneyTypeAction";
import { updateJourneyChannel } from "../CSRAgent/JourneyChannelAction";
import { saveCreditCheckResult } from "../CSRAgent/CreditCheckAction";
import {
  updateJointAccountHolder,
  updateUnusedJointAccountHolder,
} from "../CSRAgent/JointAccountAction";
import { triggerIJoinAnalytics } from "./AnalyticsActions";
import {
  getAccountAccessFetched,
  getAccountFetched,
  getCurrentPaymentMethodsFetched,
  getCurrentPaymentTypeFetched,
  getCustomerFetched,
  getMetersFetched,
  getSmoothPayEligibilityFetched,
  updateOLS,
  getPanelPlansFetched,
} from "../CSRMoveJourney/MoveAction";
import { updateAgentToken } from "../CSRAgent/LoginActions";
import { updateSnapshot } from "../../Reducers/SnapshotRedux";
import { getBillingEligibilityFetched } from "./BillingEligibilityActions";

export const updateAuth = (sessionID, deleteAfter) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: { sessionID: sessionID, deleteAfter: deleteAfter },
  };
};

export const updateProp = (propName, value) => {
  return {
    type: actionTypes.UPDATE_PROP,
    propName,
    value,
  };
};

function buildReadOnly({
  ApiPlan,
  Eligibilities,
  SitecoreContactId,
  PriceChecker,
  Discounts,
  BroadbandId,
  BroadbandFullName,
}) {
  return {
    ApiPlan,
    Eligibilities,
    SitecoreContactId,
    PriceChecker,
    Discounts,
    BroadbandId,
    BroadbandFullName,
  };
}

export const updateMyDetails = (apiData) => {
  const {
    sessionID,
    deleteAfter,
    Auth,
    Customer,
    LpgInfo,
    PipedGasInfo,
    Payment,
    BroadbandInfo,
    Services,
    Property,
    PropertyInfo,
    PlanInfo,
    MedicalInfo,
    CreditCheckInfo,
    CreditCheck,
    MoveInfo,
    JointAccountHolderInfo,
    UnusedJointAccountHolderInfo,
    AuthorizedPersonInfo,
    UnusedAuthorizedPersonInfo,
    Confirmation,
    JourneyChannel,
    JourneyType,
    Accounts,
    BillCompare,
    CurrentCustomer,
    Meters,
    OLS,
    Login,
    CurrentPaymentType,
    Snapshot,
    CurrentAccountAccess,
    CurrentPaymentMethods,
    SmoothPayEligibility,
    BillingEligibility,
    PanelPlans,
    ...rest
  } = apiData;
  return [
    sessionID && updateAuth(sessionID, deleteAfter),
    !sessionID && Auth && updateAuth(Auth.sessionID, Auth.deleteAfter),
    updateReadonly(buildReadOnly(rest)),
    JourneyChannel && updateJourneyChannel(JourneyChannel),
    JourneyType && updateJourneyType(JourneyType),
    PlanInfo && updatePlan(PlanInfo),
    LpgInfo && updateBottledGas(LpgInfo),
    BillCompare && updateBillCompareOutput(BillCompare),
    PipedGasInfo && updatePipedGas(PipedGasInfo),
    Payment && updatePayment(Payment),
    BroadbandInfo && updateBroadband(BroadbandInfo),
    (CreditCheckInfo && saveCreditCheckResult(CreditCheckInfo)) ||
      (CreditCheck?.data && saveCreditCheckResult(CreditCheck.data)),
    Services && updateServices(Services),
    Property
      ? updateProperty(Property)
      : updateProperty({
          PropertyInfo: PropertyInfo,
          MedicalInfo: MedicalInfo,
          MoveInfo: MoveInfo,
        }),
    JointAccountHolderInfo && updateJointAccountHolder(JointAccountHolderInfo),
    UnusedJointAccountHolderInfo &&
      updateUnusedJointAccountHolder(UnusedJointAccountHolderInfo),
    Confirmation && updateConfirmation(Confirmation),
    AuthorizedPersonInfo && updateAuthorizedPerson(AuthorizedPersonInfo),
    UnusedAuthorizedPersonInfo &&
      updateUnusedAuthorizedPerson(UnusedAuthorizedPersonInfo),
    Customer && updateCustomer(Customer),
    // do this last so that data are populated in the store
    triggerIJoinAnalytics(),
    Accounts && Accounts.data && getAccountFetched(Accounts.data),
    CurrentCustomer &&
      CurrentCustomer.data &&
      getCustomerFetched(CurrentCustomer.data),
    Meters && getMetersFetched(Meters.data),
    CurrentPaymentType &&
      CurrentPaymentType.data &&
      getCurrentPaymentTypeFetched(CurrentPaymentType.data),
    OLS && updateOLS(OLS),
    Login && Login.token && Login.isLoggedIn && updateAgentToken(Login.token),
    Snapshot && updateSnapshot(Snapshot),
    CurrentAccountAccess &&
      CurrentAccountAccess.data &&
      getAccountAccessFetched(CurrentAccountAccess.data),
    CurrentPaymentMethods &&
      CurrentPaymentMethods.data &&
      getCurrentPaymentMethodsFetched(CurrentPaymentMethods.data),
    SmoothPayEligibility &&
      SmoothPayEligibility.data &&
      getSmoothPayEligibilityFetched(SmoothPayEligibility.data),
    BillingEligibility &&
      BillingEligibility.data &&
      getBillingEligibilityFetched(BillingEligibility.data),
    PanelPlans && PanelPlans.data && getPanelPlansFetched(PanelPlans.data),
  ];
};

export const fetchMyDetailsData = () => {
  return process.env.NX_USE_FIXTURES
    ? updateMyDetails(MyDetailsFixture)
    : getApiState();
};
