import React from "react";
import { connect, Provider } from "react-redux";
import { compose, branch, renderNothing } from "recompose";
import {
  getHasBottledGas,
  getHasBroadband,
  getHasReticulatedGas,
} from "./selectors";
import { withErrorBoundary } from "./ErrorBoundary";

export { withErrorBoundary };

/**
 * HOC
 * Wraps component into Redux provider with specified store.
 * @param store
 */
export const withReduxProvider = (store) => (Component) => (props) =>
  (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

/**
 * HOC
 * Do not render component, if customer has not selected LPG service.
 */
export const withBottledGasMandatory = compose(
  connect((state) => ({
    hasBottledGas: getHasBottledGas(state),
  })),
  branch(({ hasBottledGas }) => !hasBottledGas, renderNothing)
);

/**
 * HOC
 * Do not render component, if customer has not selected broadband service.
 */
export const withBroadbandMandatory = compose(
  connect((state) => ({
    hasBroadband: getHasBroadband(state),
  })),
  branch(({ hasBroadband }) => !hasBroadband, renderNothing)
);

/**
 * HOC
 * Injects hasReticulatedGas prop.
 */
export const withHasReticulatedGas = connect((state) => ({
  hasReticulatedGas: getHasReticulatedGas(state),
}));

/**
 * HOC
 * Do not render component, if not a south island customer with piped gas selected
 */
export const withReticulatedGasMandatory = compose(
  withHasReticulatedGas,
  branch(({ hasReticulatedGas }) => !hasReticulatedGas, renderNothing)
);
