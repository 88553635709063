import * as actionTypes from "../actionTypes";

export const updateCreditCheckResult = (data) => {
  return {
    type: actionTypes.UPDATE_CREDIT_CHECK_RESULT,
    isError: false,
    isFetching: false,
    data,
  };
};

export const saveCreditCheckResult = (data) => {
  return {
    type: actionTypes.SAVE_CREDIT_CHECK_RESULT,
    data,
  };
};

export const updateCreditCheckFetching = (isFetching) => {
  return {
    type: actionTypes.UPDATE_CREDIT_CHECK_FETCHING,
    isError: false,
    isFetching,
  };
};

export const updateCreditCheckError = (errorMessage) => {
  return {
    type: actionTypes.UPDATE_CREDIT_CHECK_ERROR,
    isError: true,
    isFetching: false,
    errorMessage,
    data: {
      Result: "None",
      Bond: "N/A",
      Message: "",
    },
  };
};
