import React from "react";
import classNames from "classnames";

import "./Tooltip.scss";

const Tooltip = ({ children, className = undefined }) => (
  <div className={classNames(["tooltip", className])}>
    <div className="tooltip_info">i</div>
    <div className="tooltip_content">{children}</div>
  </div>
);

export default Tooltip;
