import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { cancellableQuery } from '../utils';
import {
  validatePromoCode,
  ValidatePromoCodePath,
  ValidatePromoCodeVersion,
} from '@contact/data-access';

export const useValidatePromoCode = (
  version: ValidatePromoCodeVersion,
  promoCode: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [ValidatePromoCodePath[version], promoCode],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await validatePromoCode(version, promoCode, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!promoCode,
    }
  );
