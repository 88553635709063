import { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { cancellableQuery } from '../utils';
import {
  ElectricityRegistryICPDetailsPath,
  ElectricityRegistryICPDetailsVersion,
  getElectricityRegistryICPDetails,
} from '@contact/data-access';

export const useElectricityRegistryICPDetails = (
  version: ElectricityRegistryICPDetailsVersion,
  icp?: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [ElectricityRegistryICPDetailsPath[version], icp],
    () =>
      cancellableQuery(async (extraOptions) => {
        return getElectricityRegistryICPDetails(version, icp!, {
          ...options,
          ...extraOptions,
        });
      }),
    {
      enabled: !!icp,
    }
  );
