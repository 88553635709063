import { createContext, useContext } from "react";
import { AgentConfig, Config } from "../Config/Config";
import {
  AgentAdd,
  AgentChange,
  AgentJoin,
  AgentMove,
} from "../Config/Constants";

export const ConfigContext = createContext<Config | undefined>(undefined);
export const ConfigProvider = ConfigContext.Provider;

export function useConfigContext() {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error("Expected ConfigContext");
  }
  return config;
}

export function useConfig<T extends Partial<Config> = Config>(): T {
  return useConfigContext() as T;
}

export function useAgentJoinConfig() {
  return useConfig<typeof AgentJoin>();
}

export function useAgentChangeConfig() {
  return useConfig<typeof AgentChange>();
}

export function useAgentMoveConfig() {
  return useConfig<typeof AgentMove>();
}

export function useAgentAddConfig() {
  return useConfig<typeof AgentAdd>();
}

export function useAgentConfig() {
  return useConfig<AgentConfig>();
}
