export const PropertyFixture = {
  Address: "5 Routeburn Avenue, Aotea, Porirua 5024",
  QASAddress: null,
  AddressSameAsPostalAddress: true,
  Moniker: "2-.F.11.1.awy",
  ElectricityMeterType: null,
  ElectricityICP: "1001102176UNEEA",
  GasICP: null,
  ElectricityUserType: "Standard",
  Hazards: ["Dog", "Steps", "Locked gate", "Steep driveway", "Other"],
  HazardsOther: "Cats",
  HasDogs: false,
  WithAnotherSupplier: false,
  OwnsProperty: false,
  IsBach: false,
  HaveFinalMeterReading: false,
  FinalElectricityMeterRead: null,
  FinalGasMeterRead: null,
  SendMeterReader: false,
};
