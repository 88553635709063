import { useServicesSelector } from "./Services";
import {
  useCustomerInfoPrepayEligible,
  useCustomerInfoWeeklyFortnightlyEligibleSetter,
} from "./Customer";
import { baseAxiosConfig } from "../../Actions/ApiCalls";
import { usePropertyInfoElectricityICPSelector } from "./Property/PropertyInfo";
import { useBillingEligibilityData as useBaseBillingEligibilityData } from "@contact/data-access-hooks";
import { usePlanInfoSelectedPlanSelector } from "./PlanInfo";
import { useEffect, useMemo } from "react";
import { usePaymentMethod } from "./Payment";
import {
  PAYMENT_METHOD_BILL,
  PAYMENT_METHOD_PREPAY,
} from "../../../Config/TypedConstants";
import { BASIC_PLAN } from "../../../Config/Constants";
import { useSelectedPlanNameSetter } from "./Plan";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "deep-equal";
import { BillingEligibilityItem } from "@contact/data-access";
import {
  getBillingEligibilityFetched as fetchedAction,
  getBillingEligibilityFetching as fetchingAction,
} from "../../Actions/iJoin/BillingEligibilityActions";
import {
  Alert,
  AlertSectionFinal,
  AlertSectionPlans,
  useAlerts,
} from "../../../Utils/Alert";
import { useAgentConfig } from "../../../Utils/Config";

export function useBillingEligibilityData() {
  const icp = usePropertyInfoElectricityICPSelector() || "";
  const services = useServicesSelector();
  return useBaseBillingEligibilityData(
    "v2",
    "v1",
    {
      icp,
      services,
    },
    baseAxiosConfig
  );
}

export function useBillingEligibilityDataEffect() {
  const { isLoading, data } = useBillingEligibilityData();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading || !data) {
      dispatch(fetchingAction());
    } else {
      dispatch(fetchedAction(data));
    }
  }, [isLoading, data, dispatch]);
}

export function useBillingEligibilityEffect() {
  useBillingEligibilityDataEffect();
  useTimeOfUsePlanEligibilityEffect();
  useTimeOfUsePlanApplicableEffect();
  usePrePayEligibility();
  useWeeklyFortnightlyEligibility();
}

function useWeeklyFortnightlyEligibility() {
  const weeklyFortnightly = useBillingEligibilityWeeklyFortnightly();
  const setWeeklyFortnightlyEligible = useCustomerInfoWeeklyFortnightlyEligibleSetter();
  useEffect(() => {
    setWeeklyFortnightlyEligible(weeklyFortnightly);
  }, [setWeeklyFortnightlyEligible, weeklyFortnightly]);
}

function useTimeOfUsePlanApplicableEffect() {
  const config = useAgentConfig();
  const setPlan = useSelectedPlanNameSetter();
  const timeOfUseIsApplicable = useBillingEligibilityTimeOfUseApplicable();
  const [, addAlert, removeAlert] = useAlerts();
  useEffect(() => {
    const finalAlert: Alert = {
      html: true,
      header: config.finalStep.touKeyTermsTitle,
      text: config.finalStep.touKeyTerms,
      section: AlertSectionFinal,
      isWarning: false,
      disableClose: true,
    };
    if (timeOfUseIsApplicable) {
      addAlert(finalAlert);
    } else {
      removeAlert(finalAlert);
    }
  }, [setPlan, timeOfUseIsApplicable, addAlert, config, removeAlert]);
}

function useTimeOfUsePlanEligibilityEffect() {
  const config = useAgentConfig();
  const setPlan = useSelectedPlanNameSetter();
  const timeOfUseIsApplicable = useBillingEligibilityTimeOfUseApplicable();
  const timeOfUseEligibility = useBillingEligibilitySmartTimeOfUse();
  const [, addAlert, removeAlert] = useAlerts();
  useEffect(() => {
    const touRemovedAlert: Alert = {
      text: config.planServiceDetails.touRemoved,
      isWarning: true,
      section: AlertSectionPlans,
    };
    if (timeOfUseEligibility === false && timeOfUseIsApplicable) {
      setPlan(BASIC_PLAN);
      addAlert(touRemovedAlert);
    } else if (timeOfUseEligibility && timeOfUseIsApplicable) {
      removeAlert(touRemovedAlert);
    }
  }, [
    timeOfUseEligibility,
    setPlan,
    timeOfUseIsApplicable,
    addAlert,
    config,
    removeAlert,
  ]);
}

function usePrePayEligibility() {
  const [paymentMethod, setPaymentMethod] = usePaymentMethod();
  const timeOfUseIsApplicable = useBillingEligibilityTimeOfUseApplicable();
  const basePrepayEligibility = useBillingEligibilityPrepay();
  const [, setPrepayEligible] = useCustomerInfoPrepayEligible();
  const resolvedPrepayEligibility =
    basePrepayEligibility && !timeOfUseIsApplicable;
  useEffect(() => {
    setPrepayEligible(resolvedPrepayEligibility);
  }, [resolvedPrepayEligibility, setPrepayEligible]);
  useEffect(() => {
    if (!resolvedPrepayEligibility && paymentMethod === PAYMENT_METHOD_PREPAY) {
      setPaymentMethod(PAYMENT_METHOD_BILL);
    }
  }, [paymentMethod, resolvedPrepayEligibility, setPaymentMethod]);
  usePrePayNotAvailableAlert();
}

function usePrePayNotAvailableAlert() {
  const config = useAgentConfig();
  const timeOfUseIsApplicable = useBillingEligibilityTimeOfUseApplicable();
  const [, addAlert, removeAlert] = useAlerts(AlertSectionPlans);
  useEffect(() => {
    if (timeOfUseIsApplicable) {
      addAlert(config.planServiceDetails.prePayNotAvailable, true);
    } else {
      removeAlert(config.planServiceDetails.prePayNotAvailable);
    }
  }, [
    addAlert,
    config.planServiceDetails.prePayNotAvailable,
    removeAlert,
    timeOfUseIsApplicable,
  ]);
}

export function getBillingEligibilityFromState(
  state
): BillingEligibilityItem[] {
  return state.BillingEligibility?.data ?? [];
}

export function useBillingEligibilitySelector() {
  return useSelector(getBillingEligibilityFromState, isEqual);
}

export function getBillingEligibilityFetching(state) {
  return !!state.BillingEligibility?.isFetching;
}

export function useBillingEligibilityFetchingSelector() {
  return useSelector(getBillingEligibilityFetching);
}

export function useBillingEligibility() {
  const data = useBillingEligibilitySelector();
  return useMemo(() => {
    if (!data.length) {
      return {
        "SMART TOU": false,
        "CONTACT PREPAY": false,
        "WEEKLY/FORTNIGHTLY BILLING": false,
      };
    }
    return createBillingEligibilityMap(data);
  }, [data]);
}

function createBillingEligibilityMap(
  input: BillingEligibilityItem[]
): Record<string, boolean> {
  return input.reduce(
    (map, input) => ({
      ...map,
      [input.name]: input.eligible,
    }),
    {}
  );
}

export function useBillingEligibilityPrepay() {
  const map = useBillingEligibility();
  return map["CONTACT PREPAY"];
}

export function useBillingEligibilityTimeOfUseApplicable() {
  const selectedPlan = usePlanInfoSelectedPlanSelector();
  return !!selectedPlan?.TOUPlan;
}

export function useBillingEligibilitySmartTimeOfUse(): boolean | undefined {
  const map = useBillingEligibility();
  const icp = usePropertyInfoElectricityICPSelector();
  const isLoading = useBillingEligibilityFetchingSelector();
  if (!icp || isLoading) {
    return undefined;
  }
  return map["SMART TOU"];
}

export function useBillingEligibilityWeeklyFortnightly() {
  const map = useBillingEligibility();
  return map["WEEKLY/FORTNIGHTLY BILLING"];
}
