import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

export const REDUCER_TYPE = "Login";
export const REDUCER_PROPS = {
  IS_FETCHING: "isFetching",
  HAS_ERROR: "hasError",
  IS_LOGGED_IN: "isLoggedIn",
  TOKEN: "token",
};

const initialState = {
  [REDUCER_PROPS.IS_FETCHING]: false,
  [REDUCER_PROPS.HAS_ERROR]: false,
  [REDUCER_PROPS.IS_LOGGED_IN]: false,
  [REDUCER_PROPS.TOKEN]: "",
};

const updateProp = reducerPropUpdater(REDUCER_TYPE);

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.payload);
    case actionTypes.HYDRATE_LOCAL_DATA:
      return action.payload[REDUCER_TYPE]
        ? {
            ...state,
            ...action.payload[REDUCER_TYPE],
            [REDUCER_PROPS.IS_LOGGED_IN]: Object.prototype.hasOwnProperty.call(
              action.payload[REDUCER_TYPE],
              REDUCER_PROPS.TOKEN
            ),
          }
        : state;
    default:
      return state;
  }
};
