import {
  getInteractiveBillDetail,
  GetInteractiveBillDetailApiResponse,
  InteractiveBillDetailPath,
  InteractiveBillDetailVersion,
} from '@contact/data-access';
import { useAxiosConfig } from './context';
import {
  QueryObserverResult,
  QueryObserverSuccessResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { cancellableQuery } from '../utils';
import { AxiosRequestConfig } from 'axios';

export function useInteractiveBillDetail(
  version: InteractiveBillDetailVersion,
  ba: string,
  bp?: string,
  invoiceId?: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<GetInteractiveBillDetailApiResponse> & {
    suspense: true;
  }
): QueryObserverSuccessResult<GetInteractiveBillDetailApiResponse>;
export function useInteractiveBillDetail(
  version: InteractiveBillDetailVersion,
  ba: string,
  bp?: string,
  invoiceId?: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<GetInteractiveBillDetailApiResponse>
): QueryObserverResult<GetInteractiveBillDetailApiResponse>;
export function useInteractiveBillDetail(
  version: InteractiveBillDetailVersion,
  ba: string,
  bp?: string,
  invoiceId?: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<GetInteractiveBillDetailApiResponse>
): QueryObserverResult<GetInteractiveBillDetailApiResponse> {
  const config = useAxiosConfig(providedConfig);
  return useQuery<GetInteractiveBillDetailApiResponse>(
    [InteractiveBillDetailPath[version], ba, bp, invoiceId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getInteractiveBillDetail(
          version,
          ba,
          bp,
          invoiceId,
          {
            ...config,
            ...extraOptions,
          }
        );
        return data;
      }),
    options
  );
}
