import { baseInstance } from '../base-service';
import { AxiosRequestConfig } from 'axios';
import { UpdateNotificationApiResponse } from '../models/notifications';
import { UpdateNotificationPath, UpdateNotificationVersion } from '../apiPaths';

/**
 * Sending an empty payload marks all notifications as read.
 */
export interface UpdateNotificationParams {
  /**
   * Specifying this will mark only the notification with this ID as read.
   */
  notification_id?: string;
  /**
   * Specifying this will mark all notification with this `category` as read.
   */
  category?: string;
  config?: AxiosRequestConfig;
}

export const updateNotification = (
  version: UpdateNotificationVersion,
  { token, config, ...params }: UpdateNotificationParams & { token: string }
) => {
  return baseInstance(config).post<UpdateNotificationApiResponse>(
    UpdateNotificationPath[version],
    validatedParams(params),
    { headers: { session: token } }
  );
};

function validatedParams({
  notification_id,
  category,
}: UpdateNotificationParams): UpdateNotificationParams {
  if (notification_id) {
    return { notification_id };
  } else if (category) {
    return { category };
  } else {
    return {};
  }
}
