import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  gasLookup,
  GasLookupParams,
  GasLookupPath,
  GasLookupVersion,
  GasManualAddressParams,
  GasPxidParams,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useGasLookup = (
  version: GasLookupVersion,
  params?: GasLookupParams,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [GasLookupPath[version], params],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await gasLookup(
          version,
          params as GasPxidParams | GasManualAddressParams,
          {
            ...options,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      enabled: !!params,
    }
  );
