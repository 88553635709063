import * as actionTypes from "../actionTypes";
import { CurrentPaymentTypeApiResponse } from "@contact/api-current-payment-type";
import { GetPaymentMethodsApiResponse } from "@contact/api-payment-methods";
import { OLSData } from "../../Reducers/CSRMoveJourney/OLSRedux";
import {
  AccountsApiResponse,
  CustomerApiResponse,
  PanelPlansApiResponse,
} from "@contact/data-access";
import { GetMetersResponse } from "@contact/api-automated-meter-reads";
import { GetAccountAccessResponse } from "@contact/api-account-access";
import { GetSmoothPayEligibilityResponse } from "@contact/api-smooth-pay-eligibility";

/**
 * These are all out pre-population actions
 *
 * We define a secondary type ApplyAPIStatePopulationAction to know
 * when we should utilise these as pre-population data vs something that is being
 * re-loaded from the state API
 *
 * isApplyAPIStatePopulationAction can be used to check if an action is one of these
 * apply actions, which will then provide the correct type alongside
 */

export interface GetAPIStateOptions {
  token: string;
  sessionID: string;
  deleteAfter: number;
  ols: OLSData;
  journeyType: string;
}

export interface APIStatePopulationAction<
  A extends string = string,
  D = unknown
> {
  type: A;
  data: D;
  apply?: boolean;
  options?: GetAPIStateOptions;
}

export interface ApplyAPIStatePopulationAction<
  A extends string = string,
  D = unknown
> extends APIStatePopulationAction<A, D> {
  apply: true;
  options: GetAPIStateOptions;
}

export function getAccountFetched(
  data: AccountsApiResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_ACCOUNTS_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyAccountFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_ACCOUNTS_FETCHED,
  AccountsApiResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_ACCOUNTS_FETCHED
  );
}

export function getCustomerFetched(
  data: CustomerApiResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_CUSTOMER_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyCustomerFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_CUSTOMER_FETCHED,
  CustomerApiResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_CUSTOMER_FETCHED
  );
}

export function getMetersFetched(
  data: GetMetersResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_METERS_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyMetersFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_METERS_FETCHED,
  GetMetersResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_METERS_FETCHED
  );
}

export function updateOLS(
  data: OLSData,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.UPDATE_OLS,
    data,
    apply,
    options,
  };
}

export function isApplyOLSAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.UPDATE_OLS,
  OLSData
> {
  return isApplyAPIStatePopulationAction(action, actionTypes.UPDATE_OLS);
}

export function getCurrentPaymentTypeFetched(
  data: CurrentPaymentTypeApiResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_CURRENT_PAYMENT_TYPE_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyCurrentPaymentTypeFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_CURRENT_PAYMENT_TYPE_FETCHED,
  CurrentPaymentTypeApiResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_CURRENT_PAYMENT_TYPE_FETCHED
  );
}

export function getAccountAccessFetched(
  data: GetAccountAccessResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_ACCOUNT_ACCESS_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyAccountAccessFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_ACCOUNT_ACCESS_FETCHED,
  GetAccountAccessResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_ACCOUNT_ACCESS_FETCHED
  );
}

export function getCurrentPaymentMethodsFetched(
  data: GetPaymentMethodsApiResponse,
  apply = false,
  options: GetAPIStateOptions
): APIStatePopulationAction {
  return {
    type: actionTypes.GET_CURRENT_PAYMENT_METHODS_FETCHED,
    data,
    apply,
    options,
  };
}

export function isApplyCurrentPaymentMethodsFetchedAction(
  action: unknown
): action is ApplyAPIStatePopulationAction<
  typeof actionTypes.GET_CURRENT_PAYMENT_METHODS_FETCHED,
  GetPaymentMethodsApiResponse
> {
  return isApplyAPIStatePopulationAction(
    action,
    actionTypes.GET_CURRENT_PAYMENT_METHODS_FETCHED
  );
}

export function isApplyAPIStatePopulationAction<T extends string, D>(
  action: unknown,
  type: T
): action is ApplyAPIStatePopulationAction<T, D> {
  function isLike(
    value: unknown
  ): value is { apply: unknown; type: unknown; options: unknown } {
    return typeof value === "object";
  }
  return (
    isLike(action) &&
    action.type === type &&
    action.apply === true &&
    !!action.options
  );
}

export function getSmoothPayEligibilityFetched(
  data: GetSmoothPayEligibilityResponse,
  apply = false
) {
  return {
    type: actionTypes.GET_SMOOTH_PAY_ELIGIBILITY_FETCHED,
    data,
    apply,
  };
}

export function getPanelPlansFetched(
  data: PanelPlansApiResponse,
  apply = false
) {
  return {
    type: actionTypes.GET_PANEL_PLANS_FETCHED,
    data,
    apply,
  };
}
