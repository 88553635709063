import { BroadbandLookupParams, GasLookupParams } from "@contact/data-access";
import { useBroadbandLookup, useGasLookup } from "@contact/data-access-hooks";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";

export const useGasAddressLookup = (params?: GasLookupParams) => {
  return useGasLookup("v1", params, baseAxiosConfig);
};

export const useBroadbandAddressLookup = (params?: BroadbandLookupParams) => {
  return useBroadbandLookup("v2", params, baseAxiosConfig);
};
