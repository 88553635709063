import { useInfiniteQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getInteractionRecords,
  GetInteractionRecordsVersion,
} from '@contact/data-access';

/**
 * Returns the list of interaction records found.
 * @param {string} token  A session token from SAP
 * @param {string} customerId The business partner id for the customer.
 * @param {string} accountId The business agreement id for the customer.
 * @param {string} interactionDateTime The earliest date to get interaction records from.
 * @param {number} interactionCount  The number of records to fetch the first time.
 * @param {number} nextInteractionCount  The number of records to fetch after the first time.
 * @param {AxiosRequestConfig} [config]
 * @returns interaction records {UseQueryResult<InteractionRecordsApiResponse, unknown>}
 */
export const useInteractionRecords = (
  version: GetInteractionRecordsVersion,
  token: string,
  customerId: string,
  accountId: string,
  interactionDateTime: string,
  interactionCount: number,
  nextInteractionCount: number,
  config?: AxiosRequestConfig
) =>
  useInfiniteQuery(
    'interaction-records',
    async ({
      pageParam: {
        interactionDateTime: nextInteractionDateTime,
        interactionCount: nextInteractionCount,
      } = {},
    }) => {
      const { data } = await getInteractionRecords(
        version,
        token,
        customerId,
        accountId,
        nextInteractionDateTime || interactionDateTime,
        nextInteractionCount || interactionCount,
        config
      );
      return data;
    },
    {
      // remove the accountId in the enabled expression here so that the API will ignore the ba filtering, this is mininum change we should make for now since in the future, we may need to introduce the ba back when the SAP will support it properly
      // https://contactenergy.atlassian.net/browse/OD-458
      enabled: !!(
        token &&
        customerId &&
        interactionDateTime &&
        interactionCount
      ),

      // https://react-query.tanstack.com/guides/infinite-queries
      // A hasNextPage boolean is now available and is true if getNextPageParam returns a value other than undefined
      getNextPageParam: (lastPage, pages) => {
        if (pages.length > 1) {
          return lastPage.interactions.length < nextInteractionCount
            ? undefined
            : true;
        }
        return lastPage.interactions.length < interactionCount
          ? undefined
          : true;
      },
    }
  );
