import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

export const initialState = ["ELEC"];

const updateProp = reducerPropUpdater("SERVICES_NAMES");

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_SERVICES:
      return action.services;

    default:
      return state;
  }
};

export default reducer;
