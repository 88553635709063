import { initialState } from "../../Reducers/iJoin/ConfirmationRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { pair } from "../utils";
import * as actionTypes from "../../Actions/actionTypes";
import {
  useHasBottledGas,
  useHasBroadband,
  useHasElectricity,
  useHasPipedGas,
  useHasReticulatedGas,
} from "../../../Utils/selectors";
import { useCreditCheckResult } from "./CreditCheck";
import { useJourneyTypeSelector } from "./JourneyType";

export interface Confirmation {
  CreditCheck: boolean;
  AcceptGeneralTermsAndConditions: boolean;
  ReceiveNewsAndOffers: boolean | null | undefined;
  AcceptPlanTermsAndConditions: boolean;
  AcceptPricingTermsAndConditions: boolean;
  AcceptBroadbandTermsAndConditions: boolean;
  AcceptBottledGasTermsAndConditions: boolean;
  AcceptPipedGasTermsAndConditions: boolean;
  AcceptSmoothPayTermsAndConditions: boolean;
  JourneyType: string;
}

function updateConfirmation(Confirmation: Partial<Confirmation>) {
  return {
    type: actionTypes.UPDATE_CONFIRMATION,
    confirmation: Confirmation,
  };
}

export function getConfirmation(state): Confirmation {
  return state.Confirmation || initialState;
}

export function useConfirmationSelector(): Confirmation {
  return useSelector(getConfirmation);
}

export function getConfirmationReceiveNewsAndOffers(state) {
  return getConfirmation(state).ReceiveNewsAndOffers;
}

export function useConfirmationReceiveNewsAndOffersSelector(): Confirmation["ReceiveNewsAndOffers"] {
  return useSelector(getConfirmationReceiveNewsAndOffers);
}

export function useConfirmationReceiveNewsAndOffersSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ReceiveNewsAndOffers: Confirmation["ReceiveNewsAndOffers"]) => {
      dispatch(updateConfirmation({ ReceiveNewsAndOffers }));
    },
    [dispatch]
  );
}

export function useConfirmationReceiveNewsAndOffers() {
  const value = useConfirmationReceiveNewsAndOffersSelector();
  const setter = useConfirmationReceiveNewsAndOffersSetter();
  return pair(value, setter);
}

export function getConfirmationAcceptGeneralTermsAndConditions(state) {
  return getConfirmation(state).AcceptGeneralTermsAndConditions;
}

export function useConfirmationAcceptGeneralTermsAndConditionsSelector() {
  return useSelector(getConfirmationAcceptGeneralTermsAndConditions);
}

export function useConfirmationAcceptGeneralTermsAndConditionsSetter() {
  const dispatch = useDispatch();

  // Pull in all services from redux selectors
  const hasElectricity = useHasElectricity();
  const hasPipedGas = useHasPipedGas();
  const hasReticulatedGas = useHasReticulatedGas();
  const hasBroadband = useHasBroadband();
  const hasBottledGas = useHasBottledGas();
  const creditCheckResult = useCreditCheckResult();
  const JourneyType = useJourneyTypeSelector();

  return useCallback(() => {
    dispatch(
      updateConfirmation({
        AcceptGeneralTermsAndConditions:
          hasElectricity || (hasPipedGas && !hasReticulatedGas),
        AcceptPlanTermsAndConditions:
          hasElectricity || (hasPipedGas && !hasReticulatedGas),
        AcceptBroadbandTermsAndConditions: hasBroadband,
        AcceptBottledGasTermsAndConditions: hasBottledGas,
        AcceptPipedGasTermsAndConditions: hasReticulatedGas,
        JourneyType,
        CreditCheck: !!creditCheckResult,
      })
    );
  }, [
    dispatch,
    hasElectricity,
    hasPipedGas,
    hasReticulatedGas,
    hasBroadband,
    hasBottledGas,
    creditCheckResult,
    JourneyType,
  ]);
}

export function useValidateReceiveNewsAndOffers() {
  const value = useConfirmationReceiveNewsAndOffersSelector();
  return useCallback(() => typeof value !== "undefined", [value]);
}

export function getPaymentAcceptSmoothPayTermsAndConditions(state) {
  return getConfirmation(state).AcceptSmoothPayTermsAndConditions;
}

export function usePaymentAcceptSmoothPayTermsAndConditionsSelector() {
  return useSelector(getPaymentAcceptSmoothPayTermsAndConditions);
}

export function usePaymentAcceptSmoothPayTermsAndConditionsSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AcceptSmoothPayTermsAndConditions: boolean) => {
      dispatch(updateConfirmation({ AcceptSmoothPayTermsAndConditions }));
    },
    [dispatch]
  );
}

export function usePaymentAcceptSmoothPayTermsAndConditions() {
  const value = usePaymentAcceptSmoothPayTermsAndConditionsSelector();
  const setter = usePaymentAcceptSmoothPayTermsAndConditionsSetter();

  return pair(value, setter);
}
