import React, { useState, useEffect, useRef, useCallback } from "react";
import Input from "../Input";
import { useDebounce } from "../../Utils/hooks";

const defaultProps = {
  formatFn: (value) => value,
};

function VerifiedInput(props) {
  const {
    value: defaultValue = "",
    hasError: hasErrorDefault = false,
    isVerified: isVerifiedDefault = false,
    toBeVerifiedFn,
    verifyFn,
    minLength,
    handleVerificationError,
    handleVerified,
    handleError: handleErrorDefault = undefined,
    formatFn = defaultProps.formatFn,
    handleChange: handleChangeDefault,
    onBlur,
    inputProps,
  } = props;
  const currentProps = useRef(props);
  currentProps.current = props;
  const [value, setValue] = useState(defaultValue);
  const [cursor, setCursor] = useState(defaultValue ? defaultValue.length : 0);
  const [hasError, setHasError] = useState(hasErrorDefault);
  const [isVerified, setIsVerified] = useState(isVerifiedDefault);
  const [isVerifying, setIsVerifying] = useState(false);
  const inputRef = useRef(null);

  // Note: this is used as a ref, it will always have unstable references to its scope, so useCallback is not needed here.
  const getInputRef = (input) => {
    inputRef.current = input;
    if (inputProps && inputProps.ref) {
      if (typeof inputProps.ref === "object") {
        inputProps.ref.current = input;
      } else if (typeof inputProps.ref === "function") {
        inputProps.ref(input);
      }
    }
  };

  const onSuccess = useCallback(
    (isValid) => {
      setHasError(!isValid);
      setIsVerified(isValid);
    },
    [setHasError, setIsVerified]
  );
  const onError = useCallback(() => {
    setHasError(true);
    handleVerificationError();
  }, [setHasError, handleVerificationError]);

  const verify = useCallback(
    (value) => {
      if (value.length < minLength) {
        // It's verified if the value is less than min length
        onSuccess(false, value);
      } else if (toBeVerifiedFn(value)) {
        // If is to be verified, verify asynchronously
        setIsVerifying(true);
        verifyFn(value, currentProps.current)
          .then((isValid) => onSuccess(isValid, value))
          .catch(onError)
          .finally(() => {
            setIsVerifying(false);
          });
      } else {
        // reset loading indicator, if not to be verified
        setIsVerifying(false);
      }
    },
    [onError, onSuccess, toBeVerifiedFn, verifyFn, minLength]
  );

  // Set value state from prop
  useEffect(() => {
    const newValue = formatFn(defaultValue); //format value if default provided to avoid wrong format error
    setValue(newValue);
  }, [defaultValue, formatFn]);

  // Set has error from prop
  useEffect(() => {
    setHasError(hasErrorDefault);
  }, [setHasError, hasErrorDefault]);

  // Debounce verification API call by 500ms
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    verify(debouncedValue);
  }, [verify, debouncedValue]);

  const handleChange = useCallback(
    (value) => {
      const newValue = formatFn(value);
      setValue(newValue);
      if (handleChangeDefault) {
        handleChangeDefault(newValue);
      }
    },
    [setValue, handleChangeDefault, formatFn]
  );

  const handleBlur = useCallback(
    (event) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  // Remember cursor position
  const handleCursor = useCallback(
    (e) => {
      setCursor(
        formatFn(e.target.value.substr(0, e.target.selectionStart)).length
      );
    },
    [formatFn]
  );

  // ... and restore it
  useEffect(() => {
    const input = inputRef.current;
    if (input instanceof HTMLInputElement) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [cursor, value]);

  const handleError = useCallback(
    (hasError) => {
      setHasError(hasError);
      if (hasError) {
        setIsVerified(false);
      }
      if (handleErrorDefault) {
        handleErrorDefault(hasError);
      }
    },
    [setHasError, setIsVerified, handleErrorDefault]
  );

  // Fire verified event handler if isVerified changes.
  useEffect(() => {
    if (handleVerified) {
      handleVerified(isVerified);
    }
  }, [isVerified, handleVerified]);

  return (
    <Input
      {...props}
      inputProps={{
        ...(inputProps || {}),
        ref: getInputRef,
        onBlur: handleBlur,
        onChange: handleCursor,
      }}
      value={value}
      handleChange={handleChange}
      handleError={handleError}
      hasError={hasError}
      showSuccessIcon={!hasError}
      showLoadingIcon={isVerifying}
      isValid={isVerified}
    />
  );
}

VerifiedInput.defaultProps = defaultProps;

export default VerifiedInput;
