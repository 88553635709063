import { initialState, updateLogin } from "../../Reducers/CSRAgent/LoginRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { pair } from "../utils";

interface Login {
  isLoggedIn: boolean;
  token?: string;
  redirectUrl?: string;
}

export function getLogin(state): Login {
  return state.Login || initialState;
}

export function useLoginSelector() {
  return useSelector(getLogin);
}

export function getIsLoggedIn(state) {
  return getLogin(state).isLoggedIn;
}

export function useIsLoggedInSelector() {
  return useSelector(getIsLoggedIn);
}

export function useIsLoggedInSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isLoggedIn: boolean) => dispatch(updateLogin({ isLoggedIn })),
    [dispatch]
  );
}

export function useIsLoggedIn() {
  const value = useIsLoggedInSelector();
  const setter = useIsLoggedInSetter();
  return pair(value, setter);
}

export function getLoginToken(state) {
  return getLogin(state).token;
}

export function useLoginTokenSelector() {
  return useSelector(getLoginToken);
}

export function useLoginTokenSelectorRequired() {
  const token = useLoginTokenSelector();
  if (!token) throw new Error("Expected login token");
  return token;
}

export function useLoginTokenSetter() {
  const dispatch = useDispatch();
  return useCallback((token: string) => dispatch(updateLogin({ token })), [
    dispatch,
  ]);
}

export function useLoginToken() {
  const value = useLoginTokenSelector();
  const setter = useLoginTokenSetter();
  return pair(value, setter);
}

export function getLoginRedirectURL(state) {
  return getLogin(state).redirectUrl;
}

export function useLoginRedirectURLSelector() {
  return useSelector(getLoginRedirectURL);
}

export function useLoginRedirectURLSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (redirectUrl: string) => dispatch(updateLogin({ redirectUrl })),
    [dispatch]
  );
}

export function useLoginRedirectURL() {
  const value = useLoginRedirectURLSelector();
  const setter = useLoginRedirectURLSetter();
  return pair(value, setter);
}
