import { useEffect, useMemo } from "react";
import {
  useElectricityRegistryICPDetails,
  useGasRegistryICPDetails,
} from "@contact/data-access-hooks";
import { Tracking } from "Config/Constants";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import { triggerServiceEnter } from "Utils/analytics";

export const useElectricityICPDetails = (icp?: string) => {
  const { data, isSuccess, isLoading } = useElectricityRegistryICPDetails(
    "v1",
    icp,
    baseAxiosConfig
  );

  return useMemo(() => {
    return { data: isSuccess ? data : undefined, isLoading };
  }, [data, isLoading, isSuccess]);
};

export const useGasICPDetails = (icp?: string) => {
  const { data, isSuccess, isLoading } = useGasRegistryICPDetails(
    "v1",
    icp,
    baseAxiosConfig
  );

  useEffect(() => {
    if (isSuccess) {
      triggerServiceEnter(Tracking.GAS, icp, data);
    }
  }, [data, icp, isSuccess]);

  return useMemo(() => {
    return { data: isSuccess ? data : undefined, isLoading };
  }, [data, isLoading, isSuccess]);
};
