import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandAddressDetails,
  BroadbandOfferingsApiResponse,
  UserType,
} from './models';
import {
  GetBroadbandOfferingsPath,
  GetBroadbandOfferingsVersion,
} from './apiPaths';

export const getBroadbandOfferings = (
  version: GetBroadbandOfferingsVersion,
  planId: string,
  services: string,
  addressDetails: BroadbandAddressDetails,
  userType: UserType,
  inclusions?: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<BroadbandOfferingsApiResponse>(
    GetBroadbandOfferingsPath[version],
    addressDetails,
    { params: { planId, services, userType, inclusions } }
  );
};
