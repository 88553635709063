import { CustomerFixture } from "./CustomerResponse";
import { BottledGasFixture } from "./BottledGasResponse";
import { PaymentFixture } from "./PaymentResponse";
import { ServicesFixture } from "./ServicesResponse";
import { PropertyFixture } from "./PropertyResponse";
import { MedicalFixture } from "./MedicalResponse";
import { MoveFixture } from "./MoveResponse";
import { ConfirmationFixture } from "./ConfirmationResponse";
import { BroadbandFixture } from "./BroadbandResponse";
import { ReadonlyFixture } from "./ReadonlyResponse";

export const MyDetailsFixture = {
  sessionID: "6iELUyfN5ol_q-hXXXXXXX-EFWfBHpgEEXXX=",
  deleteAfter: 1461938400,
  Customer: CustomerFixture,
  LpgInfo: BottledGasFixture,
  Payment: PaymentFixture,
  Services: ServicesFixture,
  PropertyInfo: PropertyFixture,
  MedicalInfo: MedicalFixture,
  MoveInfo: MoveFixture,
  Confirmation: ConfirmationFixture,
  BroadbandInfo: BroadbandFixture,
  ...ReadonlyFixture,
};
