import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';
import { AddressDetails, ElectricityLookupApiResponse } from './models';
import { ElectricityLookupPath, ElectricityLookupVersion } from './apiPaths';

export const electricityLookup = (
  version: ElectricityLookupVersion,
  addressDetails: AddressDetails,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).post<ElectricityLookupApiResponse>(
    ElectricityLookupPath[version],
    {
      ...addressDetails,
      format: 'websphere',
    }
  );
};
