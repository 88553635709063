import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  AttachmentType,
  getAttachment,
  GetAttachmentPath,
  GetAttachmentVersion,
} from '@contact/data-access';

/**
 * https://developer.contact-digital-devops.net/apis/b13375d44cd244f3e19ba5d8a78c62337c49fe97
 * https://contactenergy.atlassian.net/wiki/spaces/CSR/pages/1186398209/Interactions#Download-Attachments
 * Gets the attachment based on the id provided.
 * @param {string} token  A session token from SAP
 * @param {string} customerId The business partner id for the customer.
 * @param {string} attachmentId The document Id from the interaction record.
 * @param {string} attachmentType  The type of the attachment. Must be CM_FILE or OT_URL.
 * @param {AxiosRequestConfig} [config]
 * @returns the list of interaction records found
 */
export const useAttachment = (
  version: GetAttachmentVersion,
  token: string,
  customerId: string,
  attachmentId: string,
  attachmentType: AttachmentType,
  config?: AxiosRequestConfig
) =>
  useQuery(
    [
      GetAttachmentPath[version],
      token,
      customerId,
      attachmentId,
      attachmentType,
    ],
    async () => {
      const { data } = await getAttachment(
        version,
        token,
        customerId,
        attachmentId,
        attachmentType,
        config
      );
      return data;
    },
    {
      enabled: !!(token && customerId && attachmentId && attachmentType),
    }
  );
