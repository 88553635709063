import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import { useJourneyTypeSelector } from "./CSRJoinJourney";
export * from "../../Utils/pair";

export const isInList = (name, list) => {
  if (Array.isArray(list) && name) {
    return list.includes(name);
  }
  return false;
};

const getToken = (state) => state.Login.token;

export const useAxiosConfig = () => {
  const token = useSelector(getToken);
  const journeyType = useJourneyTypeSelector();
  return useMemo(
    () => ({
      ...baseAxiosConfig,
      headers: {
        ...baseAxiosConfig.headers,
        "access-token": token,
        journeyType,
      },
    }),
    [journeyType, token]
  );
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
