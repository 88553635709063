export type BroadbandAddressDetails = Record<string, unknown>;

// in Customer Journey it will be CUSTOMER
// in CSR Journey it will be AGENT
export type UserType = 'CUSTOMER' | 'AGENT';

export type BroadbandType = 'Fibre' | 'FixedWireless' | 'ADSL' | 'VDSL';

export interface Offering {
  Id: string;
  Type: BroadbandType;
  DataCapDesc: string;
  BYOModemAllowed: boolean;
  NetworkDesc?: string;
  OriginalPrice: string;
  BundlePrice: string;
  Name: string;
  DownloadSpeed: string;
  UploadSpeed: string;
  Label: string;
  Code: string;
}

export enum ModemPayTermCode {
  'Upfront Cost' = '01',
  'Monthly Rental' = '02',
  'Free' = '03',
}

export enum ModemTypeEnum {
  'phone-modem' = 'phone-modem',
  'fibre-modem' = 'fibre-modem',
  'wbb-modem' = 'wbb-modem',
}

export interface ModemPaymentTerm {
  code: '01' | '02' | '03'; // See if this can be inferred from the enum
  description: string;
  amount: string;
}

export interface Modem {
  modemType: keyof typeof ModemTypeEnum;
  skuCode: string;
  skuDescription: string;
  paymentTerms: ModemPaymentTerm[];
}

export interface BroadbandOfferingsApiResponse {
  Offerings: Offering[];
  Modems: Modem[];
  LookupComplete: boolean;
  ResultCode: number;
  Address: string;
  addressId: number;
  network: string;
  tlcId: number;
  samId: number;
  fibreAvailabilityStatus: string;
  consent: string;
  serialNumber: string;
  ontStatus: string;
  defaultResponse?: boolean;
}
