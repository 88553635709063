import React from "react";
import Input from "../Input";

function CustomInput(inputProps, props) {
  return (
    <Input
      name={props.name}
      handleChange={props.handleChange}
      value={props.value}
      labelText={props.labelText}
      placeholder={props.placeholder}
      inputProps={inputProps}
      required
      handleError={props.handleError}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      maxLength={props.maxLength}
      validateItself={props.validateItself}
    />
  );
}

export default CustomInput;
