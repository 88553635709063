import { initialState } from "../../Reducers/iJoin/CustomerRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { useIdentityCheck } from "@contact/data-access-hooks";
import { triggerDriverLicense } from "../../../Utils/analytics";
import { pair } from "../utils";
import {
  resetCustomerDriversLicense,
  updateCustomer,
  updateCustomerDriverLicense,
} from "../../Actions/iJoin/CustomerActions";
import { updateProperty } from "../../Actions/iJoin/PropertyActions";
import { baseAxiosConfig } from "../../Actions/ApiCalls";

/*

  isPrepayEligible: state.Customer.CustomerInfo.PrepayEligible,
  isWeeklyFortnightlyEligible: state.Customer.CustomerInfo.WfEligible,

 */
interface Customer {
  CustomerInfo: CustomerInfo;
  HasDriversLicense: boolean;
  DriversLicense: DriversLicense;
  isRehydrated?: boolean;
}

interface DriversLicense {
  Number: string;
  Version: string;
}

interface CustomerInfo {
  Title: null | string;
  PreferredName: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EmailAddress: string;
  PhoneNumber: string;
  DateOfBirth?: null | string;
  AccountNumber: string;
  BusinessPartnerNumber?: string;
  PostalAddress?: string;
  CurrentAddress?: string;
  ServiceAddress?: string;
  PostalAddressMoniker?: number | string;
  Moniker?: number | string;
  InteractionCategory?: string;
  InteractionNotes?: string;
  BPUpdated: boolean;
  PrepayEligible?: boolean;
  WfEligible?: boolean;
  BankId?: string;
}

function updateCustomerInfo(CustomerInfo: Partial<CustomerInfo>) {
  return updateCustomer({
    CustomerInfo,
  });
}

function updateDriversLicense(DriversLicense: Partial<DriversLicense>) {
  return updateCustomerDriverLicense(DriversLicense);
}

function getCustomer(state): Customer {
  return state.Customer || initialState;
}

export function getCustomerInfo(state): CustomerInfo {
  return getCustomer(state).CustomerInfo || initialState.CustomerInfo;
}

export function getCustomerHasDriversLicense(
  state
): Customer["HasDriversLicense"] {
  return getCustomer(state).HasDriversLicense || initialState.HasDriversLicense;
}

export function getCustomerDriversLicense(state): DriversLicense {
  return getCustomer(state).DriversLicense || initialState.DriversLicense;
}

export function useCustomer(): Customer {
  return useSelector(getCustomer);
}

export function useCustomerInfoSelector(): CustomerInfo {
  return useSelector(getCustomerInfo);
}

export function getCustomerInfoTitle(state) {
  return getCustomerInfo(state).Title;
}

export function useCustomerInfoTitleSelector(): CustomerInfo["Title"] {
  return useSelector(getCustomerInfoTitle);
}

export function useCustomerInfoTitleSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Title: CustomerInfo["Title"]) =>
      dispatch(
        updateCustomerInfo({
          Title,
        })
      ),
    [dispatch]
  );
}

export function useCustomerInfoTitle() {
  const value = useCustomerInfoTitleSelector();
  const setter = useCustomerInfoTitleSetter();
  return pair(value, setter);
}

export function getCustomerInfoPreferredName(state) {
  return getCustomerInfo(state).PreferredName;
}

export function useCustomerInfoPreferredNameSelector(): CustomerInfo["PreferredName"] {
  return useSelector(getCustomerInfoPreferredName);
}

export function useCustomerInfoPreferredNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PreferredName: CustomerInfo["PreferredName"]) =>
      dispatch(updateCustomerInfo({ PreferredName })),
    [dispatch]
  );
}

export function useCustomerInfoPreferredName() {
  const value = useCustomerInfoPreferredNameSelector();
  const setter = useCustomerInfoPreferredNameSetter();
  return pair(value, setter);
}

export function getCustomerInfoFirstName(state) {
  return getCustomerInfo(state).FirstName;
}

export function useCustomerInfoFirstNameSelector(): CustomerInfo["FirstName"] {
  return useSelector(getCustomerInfoFirstName);
}

export function useCustomerInfoFirstNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (FirstName: CustomerInfo["FirstName"]) =>
      dispatch(updateCustomerInfo({ FirstName })),
    [dispatch]
  );
}

export function useCustomerInfoFirstName() {
  const value = useCustomerInfoFirstNameSelector();
  const setter = useCustomerInfoFirstNameSetter();
  return pair(value, setter);
}

export function getCustomerInfoMiddleName(state) {
  return getCustomerInfo(state).MiddleName;
}

export function useCustomerInfoMiddleNameSelector(): CustomerInfo["MiddleName"] {
  return useSelector(getCustomerInfoMiddleName);
}

export function useCustomerInfoMiddleNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MiddleName: CustomerInfo["MiddleName"]) =>
      dispatch(updateCustomerInfo({ MiddleName })),
    [dispatch]
  );
}

export function useCustomerInfoMiddleName() {
  const value = useCustomerInfoMiddleNameSelector();
  const setter = useCustomerInfoMiddleNameSetter();
  return pair(value, setter);
}

export function getCustomerInfoLastName(state) {
  return getCustomerInfo(state).LastName;
}

export function useCustomerInfoLastNameSelector(): CustomerInfo["LastName"] {
  return useSelector(getCustomerInfoLastName);
}

export function useCustomerInfoLastNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (LastName: CustomerInfo["LastName"]) =>
      dispatch(updateCustomerInfo({ LastName })),
    [dispatch]
  );
}

export function useCustomerInfoLastName() {
  const value = useCustomerInfoLastNameSelector();
  const setter = useCustomerInfoLastNameSetter();
  return pair(value, setter);
}

export function getCustomerInfoEmailAddress(state) {
  return getCustomerInfo(state).EmailAddress;
}

export function useCustomerInfoEmailAddressSelector(): CustomerInfo["EmailAddress"] {
  return useSelector(getCustomerInfoEmailAddress);
}

export function useCustomerInfoEmailAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (EmailAddress: CustomerInfo["EmailAddress"]) =>
      dispatch(updateCustomerInfo({ EmailAddress })),
    [dispatch]
  );
}

export function useCustomerInfoEmailAddress() {
  const value = useCustomerInfoEmailAddressSelector();
  const setter = useCustomerInfoEmailAddressSetter();
  return pair(value, setter);
}

export function getCustomerInfoPhoneNumber(state) {
  return getCustomerInfo(state).PhoneNumber;
}

export function useCustomerInfoPhoneNumberSelector(): CustomerInfo["PhoneNumber"] {
  return useSelector(getCustomerInfoPhoneNumber);
}

export function useCustomerInfoPhoneNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PhoneNumber: CustomerInfo["PhoneNumber"]) =>
      dispatch(updateCustomerInfo({ PhoneNumber })),
    [dispatch]
  );
}

export function useCustomerInfoPhoneNumber() {
  const value = useCustomerInfoPhoneNumberSelector();
  const setter = useCustomerInfoPhoneNumberSetter();
  return pair(value, setter);
}

export function getCustomerInfoDateOfBirth(state) {
  return getCustomerInfo(state).DateOfBirth;
}

export function useCustomerInfoDateOfBirthSelector(): CustomerInfo["DateOfBirth"] {
  return useSelector(getCustomerInfoDateOfBirth);
}

export function useCustomerInfoDateOfBirthSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (DateOfBirth: CustomerInfo["DateOfBirth"]) =>
      dispatch(updateCustomerInfo({ DateOfBirth })),
    [dispatch]
  );
}

export function useCustomerInfoDateOfBirth() {
  const value = useCustomerInfoDateOfBirthSelector();
  const setter = useCustomerInfoDateOfBirthSetter();
  return pair(value, setter);
}

export function getCustomerInfoAccountNumber(state) {
  return getCustomerInfo(state).AccountNumber;
}

export function useCustomerInfoAccountNumberSelector(): CustomerInfo["AccountNumber"] {
  return useSelector(getCustomerInfoAccountNumber);
}

export function useCustomerInfoAccountNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (AccountNumber: CustomerInfo["AccountNumber"]) =>
      dispatch(updateCustomerInfo({ AccountNumber })),
    [dispatch]
  );
}

export function useCustomerInfoAccountNumber() {
  const value = useCustomerInfoAccountNumberSelector();
  const setter = useCustomerInfoAccountNumberSetter();
  return pair(value, setter);
}

export function getCustomerInfoBusinessPartnerNumber(state) {
  return getCustomerInfo(state).BusinessPartnerNumber;
}

export function useCustomerInfoBusinessPartnerNumberSelector(): CustomerInfo["BusinessPartnerNumber"] {
  return useSelector(getCustomerInfoBusinessPartnerNumber);
}

export function useCustomerInfoBusinessPartnerNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BusinessPartnerNumber: CustomerInfo["BusinessPartnerNumber"]) =>
      dispatch(updateCustomerInfo({ BusinessPartnerNumber })),
    [dispatch]
  );
}

export function useCustomerInfoBusinessPartnerNumber() {
  const value = useCustomerInfoBusinessPartnerNumberSelector();
  const setter = useCustomerInfoBusinessPartnerNumberSetter();
  return pair(value, setter);
}

export function getCustomerInfoPostalAddress(state) {
  return getCustomerInfo(state).PostalAddress;
}

export function useCustomerInfoPostalAddressSelector(): CustomerInfo["PostalAddress"] {
  return useSelector(getCustomerInfoPostalAddress);
}

export function useCustomerInfoPostalAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PostalAddress: CustomerInfo["PostalAddress"]) =>
      dispatch(updateCustomerInfo({ PostalAddress })),
    [dispatch]
  );
}

export function useCustomerInfoPostalAddress() {
  const value = useCustomerInfoPostalAddressSelector();
  const setter = useCustomerInfoPostalAddressSetter();
  return pair(value, setter);
}

export function getCustomerInfoCurrentAddress(state) {
  return getCustomerInfo(state).CurrentAddress;
}

export function useCustomerInfoCurrentAddressSelector(): CustomerInfo["CurrentAddress"] {
  return useSelector(getCustomerInfoCurrentAddress);
}

export function useCustomerInfoCurrentAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (CurrentAddress: CustomerInfo["CurrentAddress"]) =>
      dispatch(updateCustomerInfo({ CurrentAddress })),
    [dispatch]
  );
}

export function useCustomerInfoCurrentAddress() {
  const value = useCustomerInfoCurrentAddressSelector();
  const setter = useCustomerInfoCurrentAddressSetter();
  return pair(value, setter);
}

export function getCustomerInfoServiceAddress(state) {
  return getCustomerInfo(state).ServiceAddress;
}

export function useCustomerInfoServiceAddressSelector(): CustomerInfo["ServiceAddress"] {
  return useSelector(getCustomerInfoServiceAddress);
}

export function useCustomerInfoServiceAddressSetter() {
  const dispatch = useDispatch();
  const currentAddress = useCustomerInfoServiceAddressSelector();
  return useCallback(
    (ServiceAddress: CustomerInfo["ServiceAddress"]) => {
      dispatch([
        updateCustomerInfo({ ServiceAddress }),
        updateProperty({
          PropertyInfo: {
            Address: ServiceAddress,
            QASAddress: ServiceAddress,
          },
        }),
      ]);
      if (ServiceAddress !== currentAddress) {
        dispatch(
          updateProperty({
            PropertyInfo: {
              AddressICP: undefined,
              ElectricityICP: undefined,
              GasICP: undefined,
            },
          })
        );
      }
    },
    [dispatch, currentAddress]
  );
}

export function useCustomerInfoServiceAddress() {
  const value = useCustomerInfoServiceAddressSelector();
  const setter = useCustomerInfoServiceAddressSetter();
  return pair(value, setter);
}

export function getCustomerInfoPostalAddressMoniker(state) {
  return getCustomerInfo(state).PostalAddressMoniker;
}

export function useCustomerInfoPostalAddressMonikerSelector(): CustomerInfo["PostalAddressMoniker"] {
  return useSelector(getCustomerInfoPostalAddressMoniker);
}

export function useCustomerInfoPostalAddressMonikerSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PostalAddressMoniker: CustomerInfo["PostalAddressMoniker"]) =>
      dispatch(updateCustomerInfo({ PostalAddressMoniker })),
    [dispatch]
  );
}

export function useCustomerInfoPostalAddressMoniker() {
  const value = useCustomerInfoPostalAddressMonikerSelector();
  const setter = useCustomerInfoPostalAddressMonikerSetter();
  return pair(value, setter);
}

export function getCustomerInfoMoniker(state) {
  return getCustomerInfo(state).Moniker;
}

export function useCustomerInfoMonikerSelector(): CustomerInfo["Moniker"] {
  return useSelector(getCustomerInfoMoniker);
}

export function useCustomerInfoMonikerSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Moniker: CustomerInfo["Moniker"]) =>
      dispatch(updateCustomerInfo({ Moniker })),
    [dispatch]
  );
}

export function useCustomerInfoMoniker() {
  const value = useCustomerInfoMonikerSelector();
  const setter = useCustomerInfoMonikerSetter();
  return pair(value, setter);
}

export function getCustomerInfoInteractionCategory(state) {
  return getCustomerInfo(state).InteractionCategory;
}

export function useCustomerInfoInteractionCategorySelector(): CustomerInfo["InteractionCategory"] {
  return useSelector(getCustomerInfoInteractionCategory);
}

export function useCustomerInfoInteractionCategorySetter() {
  const dispatch = useDispatch();
  return useCallback(
    (InteractionCategory: CustomerInfo["InteractionCategory"]) =>
      dispatch(updateCustomerInfo({ InteractionCategory })),
    [dispatch]
  );
}

export function useCustomerInfoInteractionCategory() {
  const value = useCustomerInfoInteractionCategorySelector();
  const setter = useCustomerInfoInteractionCategorySetter();
  return pair(value, setter);
}

export function getCustomerInfoInteractionNotes(state) {
  return getCustomerInfo(state).InteractionNotes;
}

export function useCustomerInfoInteractionNotesSelector(): CustomerInfo["InteractionNotes"] {
  return useSelector(getCustomerInfoInteractionNotes);
}

export function useCustomerInfoInteractionNotesSetter(
  interactionCategory?: string
) {
  const dispatch = useDispatch();
  return useCallback(
    (InteractionNotes: CustomerInfo["InteractionNotes"]) => {
      const update: Partial<CustomerInfo> = {
        InteractionNotes,
      };
      if (interactionCategory) {
        update.InteractionCategory = interactionCategory;
      }
      dispatch(updateCustomerInfo(update));
    },
    [dispatch, interactionCategory]
  );
}

export function useCustomerInfoInteractionNotes(interactionCategory?: string) {
  const value = useCustomerInfoInteractionNotesSelector();
  const setter = useCustomerInfoInteractionNotesSetter(interactionCategory);
  return pair(value, setter);
}

export function getCustomerInfoBPUpdated(state) {
  return getCustomerInfo(state).BPUpdated ?? false;
}

export function useCustomerInfoBPUpdatedSelector(): CustomerInfo["BPUpdated"] {
  return useSelector(getCustomerInfoBPUpdated);
}

export function useCustomerInfoBPUpdatedSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BPUpdated: CustomerInfo["BPUpdated"]) =>
      dispatch(updateCustomerInfo({ BPUpdated })),
    [dispatch]
  );
}

export function useCustomerInfoBPUpdated() {
  const value = useCustomerInfoBPUpdatedSelector();
  const setter = useCustomerInfoBPUpdatedSetter();
  return pair(value, setter);
}

export function getCustomerInfoBankId(state) {
  return getCustomerInfo(state).BankId;
}

export function useCustomerInfoBankIdSelector(): CustomerInfo["BankId"] {
  return useSelector(getCustomerInfoBankId);
}

export function useCustomerInfoBankIdSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (BankId: CustomerInfo["BankId"]) =>
      dispatch(updateCustomerInfo({ BankId })),
    [dispatch]
  );
}

export function useCustomerInfoBankId() {
  const value = useCustomerInfoBankIdSelector();
  const setter = useCustomerInfoBankIdSetter();
  return pair(value, setter);
}

export function useCustomerHasDriversLicenseSelector() {
  return useSelector(getCustomerHasDriversLicense);
}

export function useCustomerHasDriversLicenseSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HasDriversLicense: Customer["HasDriversLicense"]) => {
      if (!HasDriversLicense) {
        // Clear fields when unselected
        dispatch(resetCustomerDriversLicense());
      }
      dispatch(updateCustomer({ HasDriversLicense }));
    },
    [dispatch]
  );
}

export function useCustomerHasDriversLicense() {
  const value = useCustomerHasDriversLicenseSelector();
  const setter = useCustomerHasDriversLicenseSetter();
  return pair(value, setter);
}

export function getCustomerDriversLicenseNumber(state) {
  return getCustomerDriversLicense(state).Number;
}

export function useCustomerDriversLicenseNumberSelector() {
  return useSelector(getCustomerDriversLicenseNumber);
}

export function useCustomerDriversLicenseNumberSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Number: DriversLicense["Number"]) =>
      dispatch(updateDriversLicense({ Number })),
    [dispatch]
  );
}

export function useCustomerDriversLicenseNumber() {
  const value = useCustomerDriversLicenseNumberSelector();
  const setter = useCustomerDriversLicenseNumberSetter();
  return pair(value, setter);
}

export function getCustomerDriversLicenseVersion(state) {
  return getCustomerDriversLicense(state).Version;
}

export function useCustomerDriversLicenseVersionSelector() {
  return useSelector(getCustomerDriversLicenseVersion);
}

export function useCustomerDriversLicenseVersionSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (Version: DriversLicense["Version"]) =>
      dispatch(updateDriversLicense({ Version })),
    [dispatch]
  );
}

export function useCustomerDriversLicenseVersion() {
  const value = useCustomerDriversLicenseVersionSelector();
  const setter = useCustomerDriversLicenseVersionSetter();
  return pair(value, setter);
}

export function useCustomerDriversLicense() {
  return useSelector(getCustomerDriversLicense);
}

export function getCustomerInfoPrepayEligible(state) {
  return getCustomerInfo(state).PrepayEligible;
}

export function useCustomerInfoPrepayEligibleSelector() {
  return useSelector(getCustomerInfoPrepayEligible);
}

export function useCustomerInfoPrepayEligibleSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PrepayEligible: boolean) =>
      dispatch(updateCustomerInfo({ PrepayEligible })),
    [dispatch]
  );
}

export function useCustomerInfoPrepayEligible() {
  const value = useCustomerInfoPrepayEligibleSelector();
  const setter = useCustomerInfoPrepayEligibleSetter();
  return pair(value, setter);
}

export function getCustomerInfoWeeklyFortnightlyEligible(state): boolean {
  return getCustomerInfo(state).WfEligible ?? false;
}

export function useCustomerInfoWeeklyFortnightlyEligibleSelector() {
  return useSelector(getCustomerInfoPrepayEligible);
}

export function useCustomerInfoWeeklyFortnightlyEligibleSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (WeeklyFortnightlyEligible: boolean) =>
      dispatch(updateCustomerInfo({ WfEligible: WeeklyFortnightlyEligible })),
    [dispatch]
  );
}

export function useCustomerInfoWeeklyFortnightlyEligible() {
  const value = useCustomerInfoWeeklyFortnightlyEligibleSelector();
  const setter = useCustomerInfoWeeklyFortnightlyEligibleSetter();
  return pair(value, setter);
}

export function useCustomerPostalOrCurrentAddress() {
  const postal = useCustomerInfoPostalAddressSelector();
  const current = useCustomerInfoCurrentAddressSelector();

  return postal || current;
}

export function getCustomerIsRehydrated(state) {
  return getCustomer(state).isRehydrated ?? false;
}

export function useCustomerIsRehydratedSelector() {
  return useSelector(getCustomerIsRehydrated);
}

export function useCustomerIsRehydratedSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isRehydrated) => dispatch(updateCustomer({ isRehydrated })),
    [dispatch]
  );
}

export function useCustomerIsRehydrated() {
  const value = useCustomerIsRehydratedSelector();
  const setter = useCustomerIsRehydratedSetter();
  return pair(value, setter);
}

export function useDriverLicenceIdentityCheck(
  dateOfBirthHasInputError: boolean
) {
  const firstName = useCustomerInfoFirstNameSelector();
  const middleName = useCustomerInfoMiddleNameSelector();
  const lastName = useCustomerInfoLastNameSelector();
  const dateOfBirth = useCustomerInfoDateOfBirthSelector();
  const driverLicence = useCustomerDriversLicenseNumberSelector();
  const cardNumber = useCustomerDriversLicenseVersionSelector();
  const hasDriverLicence = useCustomerHasDriversLicenseSelector();

  const {
    data: identityCheckData,
    isSuccess: isIdentityCheckSuccess,
    isFetching: isIdentityCheckFetching,
    isError: isIdentityCheckError,
  } = useIdentityCheck(
    'v1',
    {
      firstName,
      middleName,
      lastName,
      dateOfBirth: !dateOfBirthHasInputError ? (dateOfBirth as string) : "",
      driverLicenceNumber: driverLicence,
      driverLicenceVersion: cardNumber,
    },
    true,
    baseAxiosConfig
  );

  const driverLicenceCheckResult = useMemo(() => {
    if (hasDriverLicence && isIdentityCheckSuccess && identityCheckData) {
      triggerDriverLicense(identityCheckData);
      if (!identityCheckData.verified) {
        const invalidFields =
          identityCheckData.missingFields ||
          identityCheckData.invalidFields ||
          [];
        return {
          licenceValid: false,
          firstNameHasIdentityCheckError: invalidFields.includes("firstName"),
          middleNameHasIdentityCheckError: invalidFields.includes("middleName"),
          lastNameHasIdentityCheckError: invalidFields.includes("lastName"),
          dateOfBirthHasIdentityCheckError: invalidFields.includes(
            "dateOfBirth"
          ),
        };
      }
    }

    return {
      licenceValid: true,
      firstNameHasIdentityCheckError: false,
      middleNameHasIdentityCheckError: false,
      lastNameHasIdentityCheckError: false,
      dateOfBirthHasIdentityCheckError: false,
    };
  }, [hasDriverLicence, identityCheckData, isIdentityCheckSuccess]);

  return {
    ...driverLicenceCheckResult,
    isIdentityCheckFetching,
    isIdentityCheckSuccess,
    isIdentityCheckError,
  };
}
