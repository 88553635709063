import { AxiosRequestConfig } from 'axios';
import {
  GetSiteCoreContentPath,
  GetSiteCoreContentVersion,
  IdentifyEventPath,
  IdentifyEventVersion,
  IdentifyPath,
  IdentifyVersion,
} from './apiPaths';
import { baseInstance } from './base-service';
import {
  SiteCoreApiResponse,
  SiteCoreEventTrackingApiResponse,
  SiteCoreIdentityApiResponse,
} from './models';

export const getSiteCoreContent = (
  version: GetSiteCoreContentVersion,
  url: string,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<SiteCoreApiResponse>(
    `${GetSiteCoreContentPath[version]}/${url}`
  );

export interface SiteCoreIdentityParams {
  version: IdentifyVersion;
  bpId: string;
  baId: string;
  token: string;
}

export const getSiteCoreIdentity = (
  { version, bpId, baId, token }: SiteCoreIdentityParams,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<SiteCoreIdentityApiResponse>(IdentifyPath[version], {
    headers: {
      source: 'ListManager',
      username: bpId + '|' + baId,
      session: token,
    },
  });

export interface SiteCoreEventTrackingParams {
  version: IdentifyEventVersion;
  eventId: string;
  cookie: string;
}

/** Consider using {@link SiteCoreContext} to get the `cookie` value. */
export const getSiteCoreEventTracking = (
  { version, eventId, cookie }: SiteCoreEventTrackingParams,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<SiteCoreEventTrackingApiResponse>(
    IdentifyEventPath[version],
    {
      headers: {
        eventid: eventId,
        SCAL: cookie,
      },
    }
  );
