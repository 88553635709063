import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { UserVoiceApiResponse } from './models';
import { GetUserVoicePath, GetUserVoiceVersion } from '..';

export const getUserVoiceToken = (
  version: GetUserVoiceVersion,
  token: string,
  bp: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<UserVoiceApiResponse>(
    GetUserVoicePath[version],
    {
      params: {
        bp,
      },
      headers: {
        session: token,
      },
    }
  );
};
