import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";

import { NPS as Constants } from "../../../Config/Constants";
import { replaceKeys } from "../../../Utils";
import {
  Button,
  Input,
  NPSBar,
  YesNoButtonGroup,
  RadioButtonList,
  CheckboxList,
} from "react-components";
import { submitNPSData } from "../../../Redux/Actions/NPSActions";

import "./NpsAsyncSurvey.scss";

const SCORE = "SCORE";
const FEEDBACK = "FEEDBACK";
const HOWLONG = "HOWLONG";
const PREFERREDCHANNEL = "PREFERREDCHANNEL";
const CHANNELAGAIN = "CHANNELAGAIN";
const THANKYOU = "THANKYOU";

export const NpsAsyncSurvey = (props) => {
  const [historySections, setHistorySections] = useState([]);
  const [currentSection, setCurrentSection] = useState(SCORE);

  const [score, setScore] = useState(null);
  const [scoreComments, setScoreComments] = useState("");
  const [title, setTitle] = useState(null);
  const [howLongToWaitItems, setHowLongToWaitItems] = useState([]);
  const [preferredChannel, setPreferredChannel] = useState(null);
  const [useAsyncAgain, setUseAsyncAgain] = useState(null);

  const [buttonDisabled, setButtonDisable] = useState(false);

  const howLongCheckboxOptions = useMemo(
    () =>
      Constants.howlong.items.map((item) => ({
        checked: howLongToWaitItems.includes(item),
        label: item,
      })),
    [howLongToWaitItems]
  );

  const howLongChangeHandler = useCallback(
    (index) => {
      const updatedOptions = [...howLongCheckboxOptions];
      const updatedOption = updatedOptions[index];
      updatedOption.checked = !updatedOption.checked;

      setHowLongToWaitItems(
        updatedOptions
          .filter((option) => option.checked)
          .map((option) => option.label)
      );
    },
    [setHowLongToWaitItems, howLongCheckboxOptions]
  );

  useEffect(() => {
    switch (currentSection) {
      case SCORE:
        setButtonDisable(score == null);
        setTitle(Constants.score.subtitle);
        break;
      case FEEDBACK:
        setButtonDisable(false);
        setTitle(replaceKeys(Constants.feedback.subtitle, { score: score }));
        break;
      case HOWLONG:
        setButtonDisable(howLongToWaitItems.length < 1);
        setTitle(Constants.howlong.subtitle);
        break;
      case PREFERREDCHANNEL:
        setButtonDisable(!preferredChannel);
        setTitle(Constants.preferredChannel.title);
        break;
      case CHANNELAGAIN:
        setButtonDisable(useAsyncAgain === null);
        setTitle(Constants.asyncAgain.title);
        break;
      default:
    }
  }, [
    currentSection,
    score,
    scoreComments,
    preferredChannel,
    useAsyncAgain,
    howLongToWaitItems,
  ]);

  const handleContinueButtonClick = () => {
    if (historySections[historySections.length - 1] !== currentSection) {
      const newHistorySection = [...historySections];
      newHistorySection.push(currentSection);
      setHistorySections(newHistorySection);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });

    switch (currentSection) {
      case SCORE:
        setCurrentSection(FEEDBACK);
        break;
      case FEEDBACK:
        setCurrentSection(HOWLONG);
        break;
      case HOWLONG:
        setCurrentSection(PREFERREDCHANNEL);
        break;
      case PREFERREDCHANNEL:
        setCurrentSection(CHANNELAGAIN);
        break;
      case CHANNELAGAIN:
        props.submitNPSData({
          score,
          scoreComments,
          preferredChannel,
          useAsyncAgain,
          howLongToWaitItems,
        });
        setCurrentSection(THANKYOU);
        break;
      default:
    }
  };

  const handleBackButtonClick = () => {
    const newHistorySections = [...historySections];
    setCurrentSection(newHistorySections.pop());
    setHistorySections(newHistorySections);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // TODO: Bring back once contract is renewed
  // const handleJoinButtonClick = () => {
  //   window.location.href = Constants.thankYou.redirectURL;
  // };

  const ScoreSection = (
    <div>
      <NPSBar score={score} onChange={setScore} />
    </div>
  );

  const FeedbackSection = (
    <div>
      <Input
        name="feedback"
        handleChange={setScoreComments}
        value={scoreComments}
        labelText={Constants.feedback.feedbackLabel}
      />
    </div>
  );

  const HowLongSection = (
    <div>
      <CheckboxList
        name="howlong"
        options={howLongCheckboxOptions}
        onChange={howLongChangeHandler}
        required
      />
    </div>
  );

  const PreferredChannelSection = (
    <div>
      <RadioButtonList
        name="title"
        items={Constants.preferredChannel.channelLabels}
        value={preferredChannel}
        onChange={setPreferredChannel}
        required
      />
    </div>
  );

  const ChannelAgainSection = (
    <div>
      <YesNoButtonGroup name="useAsyncAgain" onChange={setUseAsyncAgain} />
    </div>
  );

  const ThankYouSection = (
    <div>
      <h2 className="npsSurvey_title">{Constants.thankYou.title}</h2>
      {/* TODO: Bring back once contract is renewed */}
      {/* <h3>{Constants.thankYou.subtitle}</h3>

      <div>
        <p className="npsSurvey_intro">{Constants.thankYou.content}</p>
      </div>

      <div className="npsSurvey_buttongroup">
        <Button
          name="joinButton"
          type="button"
          primaryOnLight
          text={Constants.thankYou.joinButton}
          handleClick={handleJoinButtonClick}
        />
      </div> */}
    </div>
  );

  const ButtonGroupSection = (
    <div className="npsSurvey_buttongroup">
      {currentSection !== SCORE && (
        <Button
          name="backButton"
          type="button"
          primaryOnDark
          text={Constants.backButton}
          handleClick={handleBackButtonClick}
        />
      )}
      <Button
        name="continueButton"
        type="button"
        primaryOnLight
        text={Constants.continueButton}
        handleClick={handleContinueButtonClick}
        disabled={buttonDisabled}
      />
    </div>
  );

  return (
    <div className="npsSurvey">
      {currentSection !== THANKYOU ? (
        <div className="npsSurvey_container">
          {currentSection === SCORE && (
            <h1 className="success_greeting">{Constants.thanksMsg}</h1>
          )}
          {title && <h2 className="npsSurvey_title">{title}</h2>}
          <div>
            {currentSection === SCORE && ScoreSection}
            {currentSection === FEEDBACK && FeedbackSection}
            {currentSection === HOWLONG && HowLongSection}
            {currentSection === PREFERREDCHANNEL && PreferredChannelSection}
            {currentSection === CHANNELAGAIN && ChannelAgainSection}
          </div>
          {ButtonGroupSection}
        </div>
      ) : (
        <div>{ThankYouSection}</div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitNPSData: (values) => {
      const npsInfo = {
        ...values,
      };
      dispatch(submitNPSData(npsInfo, Constants.asyncFormKey));
    },
  };
};

export default connect(null, mapDispatchToProps)(NpsAsyncSurvey);
