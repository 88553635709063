import { initialState } from "../../Reducers/CSRAgent/PlanRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { updatePlan } from "../../Actions/CSRAgent/JoinAction";
import { pair } from "../utils";
import { updateBroadband } from "../../Actions/iJoin/BroadbandActions";
import {
  ModemType,
  getCampaignId,
  useBroadbandOffersByPlanId,
  useJourneyType,
  useSiteCoreBBOffers,
  useSitecoreModemPrices,
  useSitecorePHFeeValue,
} from "../CSRJoinJourney";
import {
  ModemPayTermCode,
  ModemTypeEnum,
  Offering,
  Plan,
  UserType,
} from "@contact/data-access";
import { updateBroadbandRates } from "Redux/Actions/CSRAgent/RatesAction";
import {
  useIsBroadbandInServiceList,
  useServices,
  useServicesSelector,
} from "./Services";
import {
  useBroadbandInfoAddPhoneLineSelector,
  useBroadbandInfoAddressDetailsSelector,
  useBroadbandInfoTypeSelector,
} from "./BroadbandInfo";
import { useAgentConfig } from "Utils/Config";
import { useBroadbandOfferings } from "@contact/data-access-hooks";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";

export interface PlanService {
  mandatory: boolean;
  id: string;
}

interface PlanInfo {
  SelectedBBPlan?: Offering;
  SelectedPlan?: Plan;
  WithGST?: boolean;
  PlanName?: string;
}

export function getPlanInfo(state): PlanInfo {
  return state.PlanInfo || initialState;
}

export function usePlanInfoSelector() {
  return useSelector(getPlanInfo);
}

export function getPlanInfoSelectedPlan(state) {
  return getPlanInfo(state).SelectedPlan;
}

export function usePlanInfoSelectedPlanSelector() {
  return useSelector(getPlanInfoSelectedPlan);
}

export function usePlanInfoSelectedPlanSetter() {
  const dispatch = useDispatch();
  const [services] = useServices();

  return useCallback(
    (SelectedPlan: PlanInfo["SelectedPlan"]) => {
      dispatch(
        updatePlan({
          SelectedPlan: SelectedPlan && {
            ...SelectedPlan,
            CampaignId: getCampaignId(SelectedPlan, services),
          },
          PlanId: SelectedPlan?.Id || "",
          PlanName: SelectedPlan?.Name || "",
        })
      );
      const hasSmartFuelPlan = SelectedPlan?.Filters?.includes("AA")
        ? true
        : false;
    },
    [dispatch, services]
  );
}

export function usePlanInfoSelectedPlan() {
  const value = usePlanInfoSelectedPlanSelector();
  const setter = usePlanInfoSelectedPlanSetter();
  return pair(value, setter);
}

export function useSelectedPlan() {
  return usePlanInfoSelectedPlan();
}

export function useIsDiscountedPlan(constants: Record<string, unknown>) {
  const selectedPlan = usePlanInfoSelectedPlanSelector();
  return (
    selectedPlan?.Id === constants.bbBundle ||
    selectedPlan?.Id === constants.bbBundleAgent ||
    selectedPlan?.Id === constants.rockGas
  );
}

export function getPlanInfoSelectedBBPlan(state) {
  return getPlanInfo(state).SelectedBBPlan;
}

export function usePlanInfoSelectedBBPlanSelector() {
  return useSelector(getPlanInfoSelectedBBPlan);
}

export function usePlanInfoSelectedBBPlanSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (SelectedBBPlan: PlanInfo["SelectedBBPlan"]) =>
      dispatch([
        updatePlan({
          SelectedBBPlan,
        }),
        updateBroadband({
          ...SelectedBBPlan,
          BroadbandCode: SelectedBBPlan?.Code,
          BroadbandType: SelectedBBPlan?.Type,
          BroadbandId: SelectedBBPlan?.Id,
          BroadbandFullName: SelectedBBPlan?.Name,
        }),
        updateBroadbandRates(SelectedBBPlan),
      ]),
    [dispatch]
  );
}

export function usePlanInfoSelectedBBPlan() {
  const value = usePlanInfoSelectedBBPlanSelector();
  const setter = usePlanInfoSelectedBBPlanSetter();
  return pair(value, setter);
}

export function useSelectedBroadbandPlan() {
  return usePlanInfoSelectedBBPlan();
}

export function getPlanInfoWithGST(state) {
  return getPlanInfo(state).WithGST ?? false;
}

export function usePlanInfoWithGSTSelector() {
  return useSelector(getPlanInfoWithGST);
}

export function usePlanInfoWithGSTSetter() {
  const dispatch = useDispatch();
  const currentWithGST = usePlanInfoWithGSTSelector();
  return useCallback(
    (WithGST?: PlanInfo["WithGST"] | ((previous: boolean) => boolean)) => {
      if (typeof WithGST === "function") {
        dispatch(
          updatePlan({
            WithGST: WithGST(currentWithGST),
          })
        );
      } else {
        dispatch(
          updatePlan({
            // If no boolean supplied, we are toggling
            WithGST: typeof WithGST === "boolean" ? WithGST : !currentWithGST,
          })
        );
      }
    },
    [dispatch, currentWithGST]
  );
}

export function usePlanInfoWithGST() {
  const value = usePlanInfoWithGSTSelector();
  const setter = usePlanInfoWithGSTSetter();
  return pair(value, setter);
}

export function getPlanInfoPlanName(state) {
  return getPlanInfo(state).PlanName;
}

export function usePlanInfoPlanName() {
  return useSelector(getPlanInfoPlanName);
}

export function useIsFibre() {
  const isBroadbandInServiceList = useIsBroadbandInServiceList();
  const broadbandType = useBroadbandInfoTypeSelector();
  return isBroadbandInServiceList && broadbandType === "Fibre";
}

export function useIsWireless() {
  const isBroadbandInServiceList = useIsBroadbandInServiceList();
  const broadbandType = useBroadbandInfoTypeSelector();
  return isBroadbandInServiceList && broadbandType === "FixedWireless";
}

export function useIsADSL() {
  const isBroadbandInServiceList = useIsBroadbandInServiceList();
  const broadbandType = useBroadbandInfoTypeSelector();
  return isBroadbandInServiceList && broadbandType === "ADSL";
}

export function useIsVDSL() {
  const isBroadbandInServiceList = useIsBroadbandInServiceList();
  const broadbandType = useBroadbandInfoTypeSelector();
  return isBroadbandInServiceList && broadbandType === "VDSL";
}

export function useIsDSL() {
  const isADSL = useIsADSL();
  const isVDSL = useIsVDSL();
  return isADSL || isVDSL;
}

export function useModemType(): ModemType | undefined {
  const isFibre = useIsFibre();
  const isWireless = useIsWireless();
  const isDSL = useIsDSL();
  const addPhoneLine = useBroadbandInfoAddPhoneLineSelector();

  return useMemo(() => {
    if (isWireless) {
      return "wbb-modem";
    }
    if ((isFibre && addPhoneLine) || isDSL) {
      return "phone-modem";
    }
    if (isFibre && !addPhoneLine) {
      return "fibre-modem";
    }
    return undefined;
  }, [addPhoneLine, isDSL, isFibre, isWireless]);
}

export const useModemPrice = () => {
  const modemType = useModemType();
  const { journeyType } = useAgentConfig();

  const selectedPlan = usePlanInfoSelectedPlanSelector();

  const { data: bbOffersResponse, isLoading } = useBroadbandOffersByPlanId(
    true,
    journeyType,
    selectedPlan?.Id
  );
  const sitecoreModemPrices = useSitecoreModemPrices(false, journeyType)?.data;

  const modemPrices =
    modemType &&
    (bbOffersResponse?.Modems ?? sitecoreModemPrices ?? []).find(
      (item) => item.modemType === ModemTypeEnum[modemType]
    );
  const modemRentPrice =
    modemPrices?.paymentTerms.find(
      (item) => item.code === ModemPayTermCode["Monthly Rental"]
    )?.amount ?? "";
  const modemBuyPrice =
    modemPrices?.paymentTerms.find(
      (item) => item.code === ModemPayTermCode["Upfront Cost"]
    )?.amount ?? "";

  return { modemRentPrice, modemBuyPrice };
};

export const useAllModemPrices = () => {
  const selectedPlan = usePlanInfoSelectedPlanSelector();
  const { journeyType } = useAgentConfig();

  const { data: bbOffersResponse, isLoading } = useBroadbandOffersByPlanId(
    true,
    journeyType,
    selectedPlan?.Id
  );

  return bbOffersResponse?.Modems;
};

export const useSitecoreBBOffersUpdatedPrice = () => {
  const Constants = useAgentConfig();
  const { data: sitecoreBBOffers } = useSiteCoreBBOffers(
    true,
    Constants.journeyType
  );
  const allModemPrices = useAllModemPrices();
  const sitecorePHFeeValue = useSitecorePHFeeValue(
    false,
    Constants.journeyType
  );

  return allModemPrices
    ? sitecoreBBOffers?.map((offer) => {
        const doc = new DOMParser().parseFromString(
          offer.LearnMoreDescription,
          "text/html"
        );
        if (sitecorePHFeeValue) {
          const els = doc?.querySelectorAll("#modem_delivery");
          els.forEach((el) => (el.innerHTML = sitecorePHFeeValue));
        }

        const modemPrices = allModemPrices.find(
          (item) =>
            item.modemType ===
            ModemTypeEnum[
              offer.Type === "Fibre"
                ? "fibre-modem"
                : offer.Type === "FixedWireless"
                ? "wbb-modem"
                : "phone-modem"
            ]
        );
        const modemRentPrice =
          modemPrices?.paymentTerms.find(
            (item) => item.code === ModemPayTermCode["Monthly Rental"]
          )?.amount ?? "";
        const modemBuyPrice =
          modemPrices?.paymentTerms.find(
            (item) => item.code === ModemPayTermCode["Upfront Cost"]
          )?.amount ?? "";

        if (modemBuyPrice) {
          const els = doc?.querySelectorAll("#modem_buy");
          els.forEach((el) => (el.innerHTML = modemBuyPrice));
        }
        if (modemRentPrice) {
          const els = doc?.querySelectorAll("#modem_rent");
          els.forEach((el) => (el.innerHTML = modemRentPrice));
        }

        return {
          ...offer,
          LearnMoreDescription:
            doc?.querySelector?.("body")?.innerHTML ??
            offer.LearnMoreDescription,
        };
      })
    : sitecoreBBOffers;
};
