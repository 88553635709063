import { BottledGas as Constants } from "../../Config/Constants";

/**
 * Returns true, if there are more then 0 bottles to order.
 * @param numberOfBottles {string}
 * @returns {boolean}
 */
export function isOrderBottledGas(numberOfBottles) {
  const count = parseInt(numberOfBottles, 10);
  return !isNaN(count) && count > 0;
}

/**
 * Returns label for number of bottles.
 * @param numberOfBottles {string}
 * @returns {string|undefined}
 */
export function numberOfBottlesLabel(numberOfBottles) {
  const item = Constants.numberOfBottlesOptions.find(
    ({ value }) => value === numberOfBottles
  );
  return item?.label;
}
