import * as actionTypes from "../actionTypes";

export const priceFetching = (isFetching) => {
  return {
    type: actionTypes.PRICE_FETCHING,
    isFetching,
  };
};

export const gasPriceFetching = (isGasPriceFetching) => {
  return {
    type: actionTypes.GAS_PRICE_FETCHING,
    isGasPriceFetching,
  };
};
