import React from "react";
import classNames from "classnames";

import "./CheckboxList.scss";
import Checkbox from "../Checkbox";
import StringUtil from "../../Utils/StringUtil";

const defaultProps = {
  columns: 1,
};

const CheckboxList = (props) => {
  const isChecked = props.options.find((el) => el.checked);
  return (
    <div
      className={classNames("checkboxlist", {
        [`checkboxlist__columns-${Math.min(props.columns, 4)}`]:
          props.columns && props.columns >= 2,
      })}
      ref={props.inputProps && props.inputProps.ref}
    >
      <div
        name={props.name ? props.name + "Title" : null}
        htmlFor={props.title}
        className="checkboxlist_title"
      >
        {props.title}
      </div>
      <div
        name={props.name ? props.name + "Description" : null}
        className="checkboxlist_description"
      >
        {props.description}
      </div>
      <div id={props.title} className="checkboxlist_content">
        {props.options.map((opt, index) => (
          <Checkbox
            name={opt.code ? opt.code : StringUtil.toCamelCase(opt.label)}
            key={index}
            value={props.name}
            label={opt.label}
            subLabel={opt.subLabel}
            checked={opt.checked}
            onChange={() => props.onChange(index)}
            required={props.required && !isChecked}
            validateItself={props.validateItself}
          />
        ))}
      </div>
    </div>
  );
};

CheckboxList.defaultProps = defaultProps;

export default CheckboxList;
