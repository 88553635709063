import { AxiosRequestConfig } from 'axios';
import {
  ElectricityRegistryICPDetailsPath,
  ElectricityRegistryICPDetailsVersion,
} from '..';
import { baseInstance } from './base-service';
import {
  ElectricityRegistryICPLookupAPIResponse,
  ElectricityRegistryICPLookupPlanParameters,
  ElectricityRegistryICPLookupResponse,
  WebsphereElectricityRegistryICP,
  WebsphereElectricityRegistryICPLookupResponse,
} from './models/electricity-registry';

export const electricityRatesPlanParams: ElectricityRegistryICPLookupPlanParameters = {
  division: '01',
  billingClass: 'RESI',
};

export const getElectricityRegistryICPDetails = async (
  version: ElectricityRegistryICPDetailsVersion,
  icp: string,
  config?: Partial<AxiosRequestConfig>
): Promise<ElectricityRegistryICPLookupResponse> => {
  const { data } = await baseInstance(
    config
  ).post<ElectricityRegistryICPLookupAPIResponse>(
    ElectricityRegistryICPDetailsPath[version],
    { icp, ...config?.data }
  );
  return {
    ...electricityRatesPlanParams,
    ...data.icp,
    icpNumber: icp,
    startDate: Date.now().toString(),
  };
};

export const getWebsphereElectricityRegistryICPDetails = async (
  version: ElectricityRegistryICPDetailsVersion,
  icp: string,
  config: Partial<AxiosRequestConfig>
): Promise<WebsphereElectricityRegistryICP> => {
  const {
    data: { icp: data },
  } = await baseInstance(
    config
  ).post<WebsphereElectricityRegistryICPLookupResponse>(
    ElectricityRegistryICPDetailsPath[version],
    { icp, format: 'websphere', ...config?.data }
  );
  return data;
};
