import { AxiosRequestConfig } from 'axios';
import { ContentSyncPath, ContentSyncVersion } from '..';
import { baseInstance } from './base-service';
import { ContentSyncApiResponse } from './models';

export const getContentSync = (
  version: ContentSyncVersion,
  path = '/OLS',
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<ContentSyncApiResponse>(
    `${ContentSyncPath[version]}${path}`
  );
};
