import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { ArrowDown, Button, Checkbox, ExpandableCard } from "react-components";

import "./FinalStep.scss";

import { FinalStep as Constants } from "../../Config/Constants";
import { saveConfirmationData } from "../../Redux/Actions/iJoin/ConfirmationActions";
import {
  getHasBottledGas,
  getHasBroadband,
  getHasElectricity,
  getHasPipedGas,
  getHasReticulatedGas,
} from "../../Utils/selectors";

export const FinalStep = (props) => {
  const {
    hasElectricity,
    hasPipedGas,
    hasBottledGas,
    hasBroadband,
    hasReticulatedGas,
    origin,
  } = props;

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(
    false
  );
  const [validateItself, setValidateItself] = useState(false);
  const isJoinBtnDisable = !acceptTermsAndConditions;
  let clickingTermAndConditionsLink = false;

  const joinButtonClickedHandler = async () => {
    if (acceptTermsAndConditions) {
      await props.saveConfirmationData(
        hasElectricity,
        hasPipedGas,
        hasBroadband,
        hasBottledGas,
        hasReticulatedGas
      );
      props.onClick();
    }
  };

  useEffect(() => {
    if (origin && origin.toUpperCase() === "CUSTOMER") {
      setAcceptTermsAndConditions(false);
    }
  }, [origin, setAcceptTermsAndConditions]);

  const termAndConditionsCheckboxOnChange = () => {
    if (!clickingTermAndConditionsLink) {
      setAcceptTermsAndConditions(!acceptTermsAndConditions);
    }
    clickingTermAndConditionsLink = false;
  };

  const getTermsAndConditionsLabel = (termsAndConditions, preview) => (
    <span>
      {termsAndConditions.label}{" "}
      {preview ? (
        termsAndConditions.labelWithLink
      ) : (
        <a
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          name={termsAndConditions.label.split(" ").splice(-1)}
          href={
            process.env.NX_EXPRESS_BASE_URL +
            termsAndConditions.file +
            "?t=" +
            new Date().getTime()
          }
          download
          rel="noopener noreferrer"
          onClick={() => (clickingTermAndConditionsLink = true)}
        >
          {termsAndConditions.labelWithLink}
        </a>
      )}
      {", "}
    </span>
  );

  const fullTermsAndConditionsLabel = (preview) => (
    <Fragment>
      <span>{Constants.termsAndConditionsPrefix}</span>
      {(hasElectricity || (hasPipedGas && !hasReticulatedGas)) &&
        getTermsAndConditionsLabel(
          Constants.generalTermsAndConditions,
          preview
        )}
      {(hasElectricity || (hasPipedGas && !hasReticulatedGas)) &&
        getTermsAndConditionsLabel(Constants.planTermsAndConditions, preview)}
      {hasBroadband &&
        getTermsAndConditionsLabel(
          Constants.broadbandTermsAndConditions,
          preview
        )}
      {hasBottledGas &&
        getTermsAndConditionsLabel(
          Constants.bottledGasTermsAndConditions,
          preview
        )}
      {hasReticulatedGas &&
        getTermsAndConditionsLabel(
          Constants.pipedGasTermsAndConditions,
          preview
        )}
      <span>{Constants.termsAndConditionsSuffix}</span>
    </Fragment>
  );

  const getContent = (
    <div className="finalStep">
      <div className="finalStep_checkbox">
        <Checkbox
          name="acceptTermsAndConditions"
          className="accept-terms-and-conditions-checkbox"
          label={fullTermsAndConditionsLabel(false)}
          checked={acceptTermsAndConditions}
          onChange={termAndConditionsCheckboxOnChange}
          required
          validateItself={validateItself}
        />
      </div>
      <div className="commonButton finalStep_buttonContainer">
        <Button
          name="joinButton"
          type="button"
          className="join-button"
          text={Constants.buttonLabel}
          handleClick={joinButtonClickedHandler}
          primaryOnLight
          disabled={isJoinBtnDisable}
          handleValidationClick={() => setValidateItself(true)}
        />
      </div>
    </div>
  );

  const getPreview = (
    <div>
      {acceptTermsAndConditions && (
        <div className="field">
          <span className="bold">{fullTermsAndConditionsLabel(true)}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="FinalStep" className="cardId" />
      <ExpandableCard
        name="finalStepCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasElectricity: getHasElectricity(state),
  hasPipedGas: getHasPipedGas(state),
  hasBottledGas: getHasBottledGas(state),
  origin: state.origin || undefined,
  hasBroadband: getHasBroadband(state),
  hasReticulatedGas: getHasReticulatedGas(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveConfirmationData: async (
    hasElectricity,
    hasPipedGas,
    hasBroadband,
    hasBottleGas,
    hasReticulatedGas
  ) => {
    const confirmation = {
      AcceptGeneralTermsAndConditions:
        hasElectricity || (hasPipedGas && !hasReticulatedGas),
      AcceptPlanTermsAndConditions:
        hasElectricity || (hasPipedGas && !hasReticulatedGas),
      AcceptBroadbandTermsAndConditions: hasBroadband,
      AcceptBottledGasTermsAndConditions: hasBottleGas,
      AcceptPipedGasTermsAndConditions: hasReticulatedGas,
    };
    await dispatch(saveConfirmationData(confirmation, true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalStep);
