import * as actionTypes from "../../Actions/actionTypes";

export const initialState = {
  IsStandard: true,
  SelectedBBPlan: null,
  SelectedPlan: null,
};

const updatePlan = (state, plan) => {
  const planObject = plan.PlanInfo ?? plan;
  const newPlan = { ...state, ...planObject };
  return {
    ...state,
    ...newPlan,
    IsTimeOfUse: !!newPlan.SelectedPlan?.TOUPlan,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PLAN:
      return updatePlan(state, action.plan);

    default:
      return state;
  }
};

export default reducer;
