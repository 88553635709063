import { AxiosRequestConfig } from 'axios';
import { GetMeterAccessPath, GetMeterAccessVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { MeterAccessApiResponse } from './models';

export const getMeterAccess = (
  version: GetMeterAccessVersion,
  elecIcp: string,
  gasIcp: string,
  config?: Partial<AxiosRequestConfig>
) =>
  baseInstance(config).get<MeterAccessApiResponse>(
    GetMeterAccessPath[version],
    {
      params: {
        elecIcp,
        gasIcp,
      },
    }
  );
