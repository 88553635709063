import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import {
  BroadbandAddressDetails,
  getBroadbandOfferingsByPlans,
  GetBroadbandOfferingsByPlansPath,
  GetBroadbandOfferingsByPlansVersion,
  UserType,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useBroadbandOfferingsByPlans = (
  version: GetBroadbandOfferingsByPlansVersion,
  plans: string[] | undefined,
  services: string[] | undefined,
  addressDetails: BroadbandAddressDetails | undefined,
  userType: UserType | undefined,
  inclusions: string | undefined,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [
      GetBroadbandOfferingsByPlansPath[version],
      plans,
      services,
      addressDetails,
      userType,
      inclusions,
    ],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getBroadbandOfferingsByPlans(
          version,
          plans!,
          services!,
          addressDetails!,
          userType!,
          inclusions,
          {
            ...options,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      enabled:
        !isEmpty(plans) &&
        !isEmpty(services) &&
        !isEmpty(addressDetails) &&
        !isEmpty(userType),
    }
  );
