import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { getPlans, PlansPath, PlansVersion } from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const usePlans = (
  version: PlansVersion,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery([PlansPath[version]], () =>
    cancellableQuery(async (extraOptions) => {
      const { data } = await getPlans(version, {
        ...options,
        ...extraOptions,
      });
      return data;
    })
  );
