import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  findAddress,
  FindAddressPath,
  FindAddressVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useAddressFinder = (
  version: FindAddressVersion,
  moniker: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [FindAddressPath[version], moniker],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await findAddress(version, moniker, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!moniker,
    }
  );
