import { AxiosRequestConfig } from 'axios';
import { InteractiveBillDetailPath, InteractiveBillDetailVersion } from '..';
import { baseInstance } from './base-service';
import { GetInteractiveBillDetailApiResponse } from './models';

export function getInteractiveBillDetail(
  version: InteractiveBillDetailVersion,
  ba: string,
  bp?: string,
  invoiceId?: string,
  config?: AxiosRequestConfig
) {
  return baseInstance(config).get<GetInteractiveBillDetailApiResponse>(
    InteractiveBillDetailPath[version],
    {
      params: {
        ba,
        bp,
        invoiceId,
      },
    }
  );
}
