import { AxiosRequestConfig } from 'axios';
import { ForgotPasswordPath, ForgotPasswordVersion } from './apiPaths';
import { baseInstance } from './base-service';

export const forgotPassword = (
  version: ForgotPasswordVersion,
  username: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<void>(ForgotPasswordPath[version], {
    username,
  });
};
