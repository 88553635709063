import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  searchAddress,
  SearchAddressPath,
  SearchAddressVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useAddressSearch = (
  version: SearchAddressVersion,
  address: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [SearchAddressPath[version], address],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await searchAddress(version, address, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!address,
    }
  );
