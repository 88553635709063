import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";
import { updateConfirmation } from "./ConfirmationActions";

export const rehydratedCustomerData = (rehydrationStatus = false) => ({
  type: actionTypes.REHYDRATED_CUSTOMER,
  payload: rehydrationStatus,
});

export const updateCustomer = (customer) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER,
    customer: customer,
  };
};

export const updateCustomerDriverLicense = (DriversLicense) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_DL,
    DriversLicense,
  };
};

export const resetCustomerDriversLicense = () => ({
  type: actionTypes.RESET_CUSTOMER_DL,
});

export const saveCustomerData = (customer, confirmation) => {
  return (dispatch, getState) => {
    const state = getState();
    const customerData = {
      ...state.Customer,
      ...customer,
      CustomerInfo: {
        ...state.Customer.CustomerInfo,
        ...customer.CustomerInfo,
      },
      DriversLicense: {
        ...state.Customer.DriversLicense,
        ...customer.DriversLicense,
      },
    };
    const confirmationInfo = {
      Confirmation: {
        ...state.Confirmation,
        ...confirmation,
      },
    };
    dispatch(updateCustomer(customer));
    dispatch(updateConfirmation(confirmation));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(
        saveApiState({
          Customer: {
            ...customerData,
          },
          ...confirmationInfo,
        })
      );
    }
  };
};
