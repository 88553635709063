import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import multi from "redux-multi";

import persistDataMiddleware from "../Middlewares/persist-data";
import redirectMiddleware from "../Middlewares/redirect";
import analyticsMiddleware from "../Middlewares/ijoin-analytics";
import rootReducer from "../Reducers/express";
import { appStart } from "../Actions/express/AppActions";

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk,
      multi,
      persistDataMiddleware,
      redirectMiddleware,
      analyticsMiddleware
    )
  )
);

// Persist sessionId first
store.dispatch(appStart());

if (process.env.NODE_ENV === "development") {
  window.__EXPRESS_STORE__ = store;
}

export default store;
