import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  ExpressJourneyType,
  GetSubmitJourneyDataPath,
  GetSubmitJourneyDataVersion,
  JourneyData,
  submitJourneyData,
  SubmitJourneyDataApiResponse,
} from '@contact/data-access';
import { ApiError } from './ApiError';

export const useJourneyDataSubmit = (
  version: GetSubmitJourneyDataVersion,
  journeyType: ExpressJourneyType,
  ba: string,
  journeyId: string,
  journeyData: JourneyData,
  accessToken: string,
  timestamp: string,
  signature: string,
  enabled: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<SubmitJourneyDataApiResponse, ApiError>(
    [
      GetSubmitJourneyDataPath[version](journeyType, ba, journeyId),
      journeyType,
      ba,
      journeyId,
      journeyData,
      accessToken,
      timestamp,
      signature,
      enabled,
    ],
    async () => {
      const { data } = await submitJourneyData(
        version,
        journeyType,
        ba,
        journeyId,
        journeyData,
        accessToken,
        timestamp,
        signature,
        options
      );
      return data;
    },
    {
      enabled,
    }
  );
};
