import { AxiosConfig, baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { TransactionsApiResponse } from './models';
import { TransactionsPath, TransactionsVersion } from '..';

export const getTransactions = (
  version: TransactionsVersion,
  token: string,
  /* A business agreement id for an account. */
  account: string,

  /* The date to start getting transactions from. */
  fromDate: string,

  /* The date to stop getting transactions. */
  toDate: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<TransactionsApiResponse>(
    TransactionsPath[version],
    {
      params: {
        account,
        fromDate,
        toDate,
      },
      headers: {
        session: token,
      },
    }
  );
};

export function getTransactionsConfigured(
  version: TransactionsVersion,
  /* A business agreement id for an account. */
  account: string,

  /* The date to start getting transactions from. */
  fromDate: string,

  /* The date to stop getting transactions. */
  toDate: string,
  config?: AxiosConfig
) {
  return baseInstance(config).get<TransactionsApiResponse>(
    TransactionsPath[version],
    {
      params: {
        account,
        fromDate,
        toDate,
      },
    }
  );
}
