import * as React from "react";
import classNames from "classnames";
import "./StepList.scss";

import StepListItem from "./StepListItem";

const StepList = ({ className, children }) => (
  <ul className={classNames("stepList", className)}>{children}</ul>
);

StepList.Item = StepListItem;

export default StepList;
