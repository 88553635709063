import { initialState } from "../../../Reducers/iJoin/PropertyRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { updateProperty } from "../../../Actions/iJoin/PropertyActions";
import { pair } from "../../utils";

interface MedicalInfo {
  HasMedicalDependant?: boolean;
  CriticalMedicalEquipment: null | string;
  MedicalDependantFirstName: null | string;
  MedicalDependantLastName: null | string;
  MedicalDependantEMailAddress: null | string;
  MedicalDependantPhone: null | string;
  MedicalDependantSameAsAccountHolder: boolean;
  ContactHealthPractitioner: boolean;
  PracticeName: null | string;
  PracticePhone: null | string;
  PractitionerName: null | string;
  HasVulnerablePerson?: boolean;
  VulnerableType: null | string;
  VulnerableText: null | string;
  HasCreditCheckConsent: boolean;
}

function updateMedicalInfo(info: Partial<MedicalInfo>) {
  return updateProperty({ MedicalInfo: info });
}

export function getMedicalInfo(state): MedicalInfo {
  return (
    (state.Property || initialState).MedicalInfo || initialState.MedicalInfo
  );
}

export function useMedicalInfoSelector() {
  return useSelector(getMedicalInfo);
}

export function getMedicalInfoHasMedicalDependant(state) {
  return getMedicalInfo(state).HasMedicalDependant;
}

export function useMedicalInfoHasMedicalDependantSelector() {
  return useSelector(getMedicalInfoHasMedicalDependant);
}

export function useMedicalInfoHasMedicalDependantSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HasMedicalDependant: MedicalInfo["HasMedicalDependant"]) => {
      dispatch(updateMedicalInfo({ HasMedicalDependant }));
    },
    [dispatch]
  );
}

export function useMedicalInfoHasMedicalDependant() {
  const value = useMedicalInfoHasMedicalDependantSelector();
  const setter = useMedicalInfoHasMedicalDependantSetter();
  return pair(value, setter);
}

export function getMedicalInfoCriticalMedicalEquipment(state) {
  return getMedicalInfo(state).CriticalMedicalEquipment;
}

export function useMedicalInfoCriticalMedicalEquipmentSelector() {
  return useSelector(getMedicalInfoCriticalMedicalEquipment);
}

export function useMedicalInfoCriticalMedicalEquipmentSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (CriticalMedicalEquipment: MedicalInfo["CriticalMedicalEquipment"]) =>
      dispatch(updateMedicalInfo({ CriticalMedicalEquipment })),
    [dispatch]
  );
}

export function useMedicalInfoCriticalMedicalEquipment() {
  const value = useMedicalInfoCriticalMedicalEquipmentSelector();
  const setter = useMedicalInfoCriticalMedicalEquipmentSetter();
  return pair(value, setter);
}

export function getMedicalInfoMedicalDependantFirstName(state) {
  return getMedicalInfo(state).MedicalDependantFirstName;
}

export function useMedicalInfoMedicalDependantFirstNameSelector() {
  return useSelector(getMedicalInfoMedicalDependantFirstName);
}

export function useMedicalInfoMedicalDependantFirstNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MedicalDependantFirstName: MedicalInfo["MedicalDependantFirstName"]) =>
      dispatch(updateMedicalInfo({ MedicalDependantFirstName })),
    [dispatch]
  );
}

export function useMedicalInfoMedicalDependantFirstName() {
  const value = useMedicalInfoMedicalDependantFirstNameSelector();
  const setter = useMedicalInfoMedicalDependantFirstNameSetter();
  return pair(value, setter);
}

export function getMedicalInfoMedicalDependantLastName(state) {
  return getMedicalInfo(state).MedicalDependantLastName;
}

export function useMedicalInfoMedicalDependantLastNameSelector() {
  return useSelector(getMedicalInfoMedicalDependantLastName);
}

export function useMedicalInfoMedicalDependantLastNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MedicalDependantLastName: MedicalInfo["MedicalDependantLastName"]) =>
      dispatch(updateMedicalInfo({ MedicalDependantLastName })),
    [dispatch]
  );
}

export function useMedicalInfoMedicalDependantLastName() {
  const value = useMedicalInfoMedicalDependantLastNameSelector();
  const setter = useMedicalInfoMedicalDependantLastNameSetter();
  return pair(value, setter);
}

export function getMedicalInfoMedicalDependantEmailAddress(state) {
  return getMedicalInfo(state).MedicalDependantEMailAddress;
}

export function useMedicalInfoMedicalDependantEmailAddressSelector() {
  return useSelector(getMedicalInfoMedicalDependantEmailAddress);
}

export function useMedicalInfoMedicalDependantEmailAddressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (
      MedicalDependantEmailAddress: MedicalInfo["MedicalDependantEMailAddress"]
    ) =>
      dispatch(
        updateMedicalInfo({
          MedicalDependantEMailAddress: MedicalDependantEmailAddress,
        })
      ),
    [dispatch]
  );
}

export function useMedicalInfoMedicalDependantEmailAddress() {
  const value = useMedicalInfoMedicalDependantEmailAddressSelector();
  const setter = useMedicalInfoMedicalDependantEmailAddressSetter();
  return pair(value, setter);
}

export function getMedicalInfoMedicalDependantPhone(state) {
  return getMedicalInfo(state).MedicalDependantPhone;
}

export function useMedicalInfoMedicalDependantPhoneSelector() {
  return useSelector(getMedicalInfoMedicalDependantPhone);
}

export function useMedicalInfoMedicalDependantPhoneSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (MedicalDependantPhone: MedicalInfo["MedicalDependantPhone"]) =>
      dispatch(updateMedicalInfo({ MedicalDependantPhone })),
    [dispatch]
  );
}

export function useMedicalInfoMedicalDependantPhone() {
  const value = useMedicalInfoMedicalDependantPhoneSelector();
  const setter = useMedicalInfoMedicalDependantPhoneSetter();
  return pair(value, setter);
}

export function getMedicalInfoMedicalDependantSameAsAccountHolder(state) {
  return getMedicalInfo(state).MedicalDependantSameAsAccountHolder;
}

export function useMedicalInfoMedicalDependantSameAsAccountHolderSelector() {
  return useSelector(getMedicalInfoMedicalDependantSameAsAccountHolder);
}

export function useMedicalInfoMedicalDependantSameAsAccountHolderSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (
      MedicalDependantSameAsAccountHolder: MedicalInfo["MedicalDependantSameAsAccountHolder"]
    ) => dispatch(updateMedicalInfo({ MedicalDependantSameAsAccountHolder })),
    [dispatch]
  );
}

export function useMedicalInfoMedicalDependantSameAsAccountHolder() {
  const value = useMedicalInfoMedicalDependantSameAsAccountHolderSelector();
  const setter = useMedicalInfoMedicalDependantSameAsAccountHolderSetter();
  return pair(value, setter);
}

export function getMedicalInfoContactHealthPractitioner(state) {
  return getMedicalInfo(state).ContactHealthPractitioner;
}

export function useMedicalInfoContactHealthPractitionerSelector() {
  return useSelector(getMedicalInfoContactHealthPractitioner);
}

export function useMedicalInfoContactHealthPractitionerSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (ContactHealthPractitioner: MedicalInfo["ContactHealthPractitioner"]) =>
      dispatch(updateMedicalInfo({ ContactHealthPractitioner })),
    [dispatch]
  );
}

export function useMedicalInfoContactHealthPractitioner() {
  const value = useMedicalInfoContactHealthPractitionerSelector();
  const setter = useMedicalInfoContactHealthPractitionerSetter();
  return pair(value, setter);
}

export function getMedicalInfoPracticeName(state) {
  return getMedicalInfo(state).PracticeName;
}

export function useMedicalInfoPracticeNameSelector() {
  return useSelector(getMedicalInfoPracticeName);
}

export function useMedicalInfoPracticeNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PracticeName: MedicalInfo["PracticeName"]) =>
      dispatch(updateMedicalInfo({ PracticeName })),
    [dispatch]
  );
}

export function useMedicalInfoPracticeName() {
  const value = useMedicalInfoPracticeNameSelector();
  const setter = useMedicalInfoPracticeNameSetter();
  return pair(value, setter);
}

export function getMedicalInfoPracticePhone(state) {
  return getMedicalInfo(state).PracticePhone;
}

export function useMedicalInfoPracticePhoneSelector() {
  return useSelector(getMedicalInfoPracticePhone);
}

export function useMedicalInfoPracticePhoneSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PracticePhone: MedicalInfo["PracticePhone"]) =>
      dispatch(updateMedicalInfo({ PracticePhone })),
    [dispatch]
  );
}

export function useMedicalInfoPracticePhone() {
  const value = useMedicalInfoPracticePhoneSelector();
  const setter = useMedicalInfoPracticePhoneSetter();
  return pair(value, setter);
}

export function getMedicalInfoPractitionerName(state) {
  return getMedicalInfo(state).PractitionerName;
}

export function useMedicalInfoPractitionerNameSelector() {
  return useSelector(getMedicalInfoPractitionerName);
}

export function useMedicalInfoPractitionerNameSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PractitionerName: MedicalInfo["PractitionerName"]) =>
      dispatch(updateMedicalInfo({ PractitionerName })),
    [dispatch]
  );
}

export function useMedicalInfoPractitionerName() {
  const value = useMedicalInfoPractitionerNameSelector();
  const setter = useMedicalInfoPractitionerNameSetter();
  return pair(value, setter);
}

export function getMedicalInfoHasVulnerablePerson(state) {
  return getMedicalInfo(state).HasVulnerablePerson;
}

export function getMedicalInfoVulnerableType(state) {
  return getMedicalInfo(state).VulnerableType;
}

export function getMedicalInfoVulnerableText(state) {
  return getMedicalInfo(state).VulnerableText;
}

export function useMedicalInfoHasVulnerablePersonSelector() {
  return useSelector(getMedicalInfoHasVulnerablePerson);
}

export function useMedicalInfoVulnerableTypeSelector() {
  return useSelector(getMedicalInfoVulnerableType);
}

export function useMedicalInfoVulnerableTypeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (VulnerableType: MedicalInfo["VulnerableType"]) =>
      dispatch(updateMedicalInfo({ VulnerableType })),
    [dispatch]
  );
}

export function useMedicalInfoVulnerableTextSelector() {
  return useSelector(getMedicalInfoVulnerableText);
}

export function useMedicalInfoVulnerableTextSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (VulnerableText: MedicalInfo["VulnerableText"]) =>
      dispatch(updateMedicalInfo({ VulnerableText })),
    [dispatch]
  );
}

export function useMedicalInfoHasVulnerablePerson() {
  const value = useMedicalInfoHasVulnerablePersonSelector();
  const setter = useMedicalInfoHasVulnerablePersonSetter();
  return pair(value, setter);
}

export function useMedicalInfoVulnerableType() {
  const value = useMedicalInfoVulnerableTypeSelector();
  const setter = useMedicalInfoVulnerableTypeSetter();
  return pair(value, setter);
}

export function useMedicalInfoVulnerableText() {
  const value = useMedicalInfoVulnerableTextSelector();
  const setter = useMedicalInfoVulnerableTextSetter();
  return pair(value, setter);
}

export function useMedicalInfoHasVulnerablePersonSetter() {
  const dispatch = useDispatch();
  const setMedicalVulnerableType = useMedicalInfoVulnerableTypeSetter();
  const setMedicalVulnerableText = useMedicalInfoVulnerableTextSetter();
  return useCallback(
    (HasVulnerablePerson: MedicalInfo["HasVulnerablePerson"]) => {
      dispatch(updateMedicalInfo({ HasVulnerablePerson }));
      if (!HasVulnerablePerson) {
        setMedicalVulnerableType(null);
        setMedicalVulnerableText(null);
      }
    },
    [dispatch, setMedicalVulnerableText, setMedicalVulnerableType]
  );
}

export function getMedicalInfoHasCreditCheckConsent(state) {
  return getMedicalInfo(state).HasCreditCheckConsent ?? false;
}

export function useMedicalInfoHasCreditCheckConsentSelector() {
  return useSelector(getMedicalInfoHasCreditCheckConsent);
}

export function useMedicalInfoHasCreditCheckConsentSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (HasCreditCheckConsent: MedicalInfo["HasCreditCheckConsent"]) =>
      dispatch(updateMedicalInfo({ HasCreditCheckConsent })),
    [dispatch]
  );
}

export function useMedicalInfoHasCreditCheckConsent() {
  const value = useMedicalInfoHasCreditCheckConsentSelector();
  const setter = useMedicalInfoHasCreditCheckConsentSetter();
  return pair(value, setter);
}
