import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  JourneyType,
  Origin,
  useCreateSaveForLater,
  useSessionState,
} from "@contact/data-access-hooks";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";

const getSaveForLaterEmail = (state) =>
  state.Customer.CustomerInfo.EmailAddress;

export const useSaveForLaterEmail = () => {
  return useSelector(getSaveForLaterEmail);
};

const getSessionId = (state) => state.Auth.sessionID;

const useSessionId = () => {
  return useSelector(getSessionId);
};

const STATE_OVERRIDES = {
  BillCompare: null,
};

export const useSaveForLater = (journeyType: JourneyType, origin: Origin) => {
  const sessionId = useSessionId();

  const {
    data: state,
    refetch: fetchState,
    isLoading: isGetSessionStateLoading,
    isSuccess: isGetSessionStateSuccess,
    isError: isGetSessionStateError,
    remove: removeGetSessionState,
  } = useSessionState("v2", sessionId, baseAxiosConfig);

  const {
    mutate: createSaveForLater,
    isLoading: isCreateSaveForLaterLoading,
    isSuccess: isCreateSaveForLaterSuccess,
    isError: isCreateSaveForLaterError,
    reset: resetCreateSaveForLater,
  } = useCreateSaveForLater("v1", baseAxiosConfig);

  const [saveForLaterEmail, setSaveForLaterEmail] = useState("");

  useEffect(() => {
    if (state && saveForLaterEmail) {
      createSaveForLater({
        journeyType,
        origin,
        saveForLaterEmail,
        state: {
          ...state,
          ...STATE_OVERRIDES,
        },
      });
    }
  }, [createSaveForLater, journeyType, origin, saveForLaterEmail, state]);

  const create = useCallback(
    (saveForLaterEmail: string) => {
      setSaveForLaterEmail(saveForLaterEmail);
      fetchState({
        throwOnError: true,
        cancelRefetch: true,
      });
    },
    [fetchState]
  );

  const reset = useCallback(() => {
    removeGetSessionState();
    resetCreateSaveForLater();
  }, [removeGetSessionState, resetCreateSaveForLater]);

  return {
    isLoading: isGetSessionStateLoading || isCreateSaveForLaterLoading,
    isSuccess: isGetSessionStateSuccess && isCreateSaveForLaterSuccess,
    isError: isGetSessionStateError || isCreateSaveForLaterError,
    create,
    reset,
  };
};
