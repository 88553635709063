import React, { useState, useEffect, Fragment, useCallback } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Button,
  Checkbox,
  ExpandableCard,
  Input,
  Modal,
  RadioButtonList,
  WebNativeDateInput,
} from "react-components";
import moment from "moment";
import { AboutYou as Constants } from "../../Config/Constants";
import { checkIdentity } from "../../Redux/Actions/ApiCalls";
import { useStateFromProp } from "../../Utils/hooks";
import * as Analytics from "../../Utils/analytics";
import { saveCustomerData } from "../../Redux/Actions/iJoin/CustomerActions";

import driverLicenceImg from "../../Assets/Images/driverlicence.png";
import "./AboutYou.scss";

export const AboutYou = (props) => {
  const [title, setTitle] = useStateFromProp(props.title, "");
  const [firstName, setFirstName] = useStateFromProp(props.firstName, "");
  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(
    Constants.firstNameRequiredMsg
  );
  const [middleName, setMiddleName] = useStateFromProp(props.middleName, "");
  const [middleNameHasError, setMiddleNameHasError] = useState(false);
  const [lastName, setLastName] = useStateFromProp(props.lastName, "");
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState(
    Constants.lastNameRequiredMsg
  );
  const getDate = (date) => {
    //to get date in ISO format to pass to datepicker, witch only understand ISO, initially date saved in 'DD/MM/YYYY' format in redux because it how it shoulb be passed to api
    if (date) {
      const dob = date.split("/");
      return `${dob[2]}-${dob[1]}-${dob[0]}`;
    }
    return "";
  };
  const [dateOfBirth, setDateOfBirth] = useStateFromProp(
    getDate(props.dateOfBirth),
    ""
  );
  const [
    dateOfBirthHasIdentityCheckError,
    setDateOfBirthHasIdentityCheckError,
  ] = useState(false);

  const [dateOfBirthHasError, setDateOfBirthHasError] = useState(false);

  const [emailAddress, setEmailAddress] = useStateFromProp(
    props.emailAddress,
    ""
  );
  const [emailAddressHasError, setEmailAddressHasError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useStateFromProp(props.phoneNumber, "");
  const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
  const [driverLicence, setDriverLicence] = useStateFromProp(
    props.driverLicence,
    ""
  );
  const [driverLicenceHasError, setDriverLicenceHasError] = useState(false);
  const [driverLicenceInvalidMessage, setDriverLicenceInvalidMessage] =
    useState(Constants.driverInvalidMsg);
  const [cardNumber, setCardNumber] = useStateFromProp(props.cardNumber, "");
  const [cardNumberHasError, setCardNumberHasError] = useState(false);
  const [creditCheck, setCreditCheck] = useStateFromProp(
    props.creditCheck,
    false
  );
  const [isModalActive, setIsModalActive] = useState(false);
  const [validateItself, setValidateItself] = useState(false);
  const [receiveNewsAndOffers, setReceiveNewsAndOffers] = useState(false);
  const hasDriverLicence = driverLicence && !driverLicenceHasError;
  const hasCardNumber = cardNumber && !cardNumberHasError;
  const hasDriverLicenceComplete = Boolean(hasDriverLicence && hasCardNumber);
  const isDisable =
    !title ||
    !firstName ||
    !lastName ||
    !dateOfBirth ||
    !phoneNumber ||
    !emailAddress ||
    !creditCheck ||
    firstNameHasError ||
    lastNameHasError ||
    (middleName && middleNameHasError) ||
    dateOfBirthHasError ||
    phoneNumberHasError ||
    emailAddressHasError ||
    driverLicenceHasError ||
    cardNumberHasError ||
    ((driverLicence || cardNumber) && !hasDriverLicenceComplete);

  const triggerFirstinteraction = props.triggerFirstinteraction;
  const [invalidFields, setInvalidFields] = useState([]);
  useEffect(() => {
    if (
      title ||
      firstName ||
      middleName ||
      lastName ||
      dateOfBirth ||
      phoneNumber ||
      creditCheck
    ) {
      triggerFirstinteraction && triggerFirstinteraction();
    }
  }, [
    triggerFirstinteraction,
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    phoneNumber,
    creditCheck,
  ]);

  const setIdentityFieldsValid = useCallback(() => {
    setInvalidFields([]);
    if (firstName) {
      setFirstNameHasError(false);
    }
    if (middleName) {
      setMiddleNameHasError(false);
    }
    if (lastName) {
      setLastNameHasError(false);
    }
    if (dateOfBirth) {
      setDateOfBirthHasIdentityCheckError(false);
    }
    if (driverLicence && driverLicence.length === 8) {
      setDriverLicenceHasError(false);
    }
    if (cardNumber) {
      setCardNumberHasError(false);
    }
  }, [cardNumber, dateOfBirth, driverLicence, firstName, lastName, middleName]);

  const setDOB = useCallback(
    (date) => {
      if (date) {
        setIdentityFieldsValid();
        setDateOfBirth(date);
      } else {
        setDateOfBirth("");
      }
    },
    [setIdentityFieldsValid, setDateOfBirth]
  );

  const onDateOfBirthChange = useCallback(
    (value) => {
      setDOB(value);
      setIdentityFieldsValid();
    },
    [setDOB, setIdentityFieldsValid]
  );

  const clickedHandler = () => {
    const saveData = () => {
      props.saveCustomerData({
        title,
        firstName,
        middleName,
        lastName,
        dateOfBirth: moment(dateOfBirth).format("DD/MM/YYYY"), //date saved in 'DD/MM/YYYY' format in redux because it how it shoulb be passed to api
        emailAddress,
        phoneNumber,
        driverLicence,
        cardNumber,
        creditCheck,
        receiveNewsAndOffers,
        hasDriversLicence: hasDriverLicenceComplete,
      });
    };
    if (dateOfBirth && !dateOfBirthHasError) {
      if (Constants.checkIdentity && driverLicence && cardNumber) {
        setInvalidFields([]);
        checkIdentity({
          firstName,
          middleName,
          lastName,
          dateOfBirth: dateOfBirth,
          driverLicenceNumber: driverLicence,
          driverLicenceVersion: cardNumber,
        })
          // This is behaviour moved from the checkIdentity function
          // remove this to utilise the defined catch after the next then
          .catch(() => undefined)
          .then((response) => {
            if (!response?.data.verified) {
              const invalidFields =
                response.data.missingFields || response.data.invalidFields;
              if (invalidFields) {
                if (invalidFields.includes("driverLicenceVersion")) {
                  setDriverLicenceHasError(true);
                  setCardNumberHasError(true);
                  setDriverLicenceInvalidMessage(Constants.licenceNotMatchMsg);
                  if (!invalidFields.includes("driverLicence")) {
                    setInvalidFields((invalidFields) => [
                      ...invalidFields,
                      "driverLicence",
                      "cardNumber",
                    ]);
                  }
                } else {
                  invalidFields.forEach((field) => {
                    if (field === "firstName") {
                      setFirstNameHasError(true);
                      setFirstNameErrorMessage(Constants.nameNotMatchMsg);
                    }
                    if (field === "middleName") {
                      setMiddleNameHasError(true);
                    }
                    if (field === "lastName") {
                      setLastNameHasError(true);
                      setLastNameErrorMessage(Constants.nameNotMatchMsg);
                    }
                    if (field === "dateOfBirth") {
                      setDateOfBirthHasIdentityCheckError(true);
                    }
                  });
                }
                Analytics.triggerIdCheck("failed", invalidFields);
              }
            } else {
              Analytics.triggerIdCheck("passed");
              saveData();
              props.onClick();
            }
          })
          .catch(() => {
            saveData();
            props.onClick();
            Analytics.triggerIdCheck("failed", ["returned 500 error"]);
          });
      } else {
        saveData();
        props.onClick();
        Analytics.triggerIdCheck("not used");
      }
    }
  };

  const getContent = (
    <div className="aboutYou">
      <div className="field">
        <RadioButtonList
          name="title"
          title={Constants.salutationTitle}
          description={Constants.salutationDescription}
          items={Constants.salutationLabels}
          value={title}
          onChange={setTitle}
          required
          validateItself={validateItself}
        />
      </div>
      <div className="row">
        <div className="field">
          <Input
            name="firstName"
            handleChange={(value) => {
              setFirstName(value);
              setIdentityFieldsValid();
            }}
            value={firstName}
            labelText={Constants.firstNameLabel}
            placeholder={Constants.firstNamePlaceholder}
            maxLength={Constants.firstNameMaxLength}
            handleError={setFirstNameHasError}
            errorMessage={firstNameErrorMessage}
            hasError={firstNameHasError}
            validationType="name"
            required
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="middleName"
            handleChange={(value) => {
              setMiddleName(value);
              setIdentityFieldsValid();
            }}
            value={middleName}
            labelText={Constants.middleNameLabel}
            placeholder={Constants.middleNamePlaceholder}
            maxLength={Constants.middleNameMaxLength}
            handleError={setMiddleNameHasError}
            errorMessage={Constants.nameNotMatchMsg}
            hasError={middleNameHasError}
            validationType="name"
          />
        </div>
        <div className="field">
          <Input
            name="lastName"
            handleChange={(value) => {
              setLastName(value);
              setIdentityFieldsValid();
            }}
            value={lastName}
            labelText={Constants.lastNameLabel}
            placeholder={Constants.lastNamePlaceholder}
            maxLength={Constants.lastNameMaxLength}
            handleError={setLastNameHasError}
            errorMessage={lastNameErrorMessage}
            hasError={lastNameHasError}
            validationType="name"
            required
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="row">
        <div className="field">
          <WebNativeDateInput
            value={dateOfBirth}
            placeholder={Constants.datePlaceholder}
            labelText={dateOfBirthHasError ? "" : Constants.dateLabel}
            handleChange={onDateOfBirthChange}
            minAge={Constants.dateMinAge}
            maxAge={Constants.dateMaxAge}
            constants={Constants}
            setDateOfBirthHasError={setDateOfBirthHasError}
            dateOfBirthHasIdentityCheckError={dateOfBirthHasIdentityCheckError}
          />
        </div>
        <div className="field">
          <Input
            name="phone"
            handleChange={(value) => setPhoneNumber(value)}
            value={phoneNumber}
            labelText={Constants.phoneLabel}
            placeholder={Constants.phonePlaceholder}
            maxLength={Constants.phoneMaxLength}
            handleError={setPhoneNumberHasError}
            errorMessage={Constants.phoneRequiredMsg}
            invalidMessage={Constants.phoneInvalidMsg}
            hasError={phoneNumberHasError}
            validationType="phoneNumber"
            required
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="field">
        <Input
          name="email"
          handleChange={(value) => setEmailAddress(value)}
          value={emailAddress}
          labelText={Constants.emailLabel}
          placeholder={Constants.emailPlaceholder}
          maxLength={Constants.emailMaxLength}
          handleError={setEmailAddressHasError}
          errorMessage={Constants.emailRequiredMsg}
          invalidMessage={Constants.emailInvalidMsg}
          hasError={emailAddressHasError}
          validationType="email"
          showSuccessIcon
          required
          validateItself={validateItself}
        />
      </div>
      <div className="aboutYou_checkbox">
        <Checkbox
          name="receiveNewsAndOffers"
          className="news-check-checkbox"
          label={Constants.newsCheckboxLabel}
          checked={receiveNewsAndOffers}
          onChange={() => setReceiveNewsAndOffers(!receiveNewsAndOffers)}
        />
      </div>
      <div className="aboutYou_title">Credit check</div>
      <div className="aboutYou_checkbox">
        <Checkbox
          name="creditCheck"
          className="credit-check-checkbox"
          label={Constants.creditCheckLabel}
          subLabel={Constants.creditCheckSubLabel}
          checked={creditCheck}
          onChange={() => setCreditCheck(!creditCheck)}
          hasHelpIcon
          iconClicked={() => setIsModalActive(!isModalActive)}
          required
          validateItself={validateItself}
        />
      </div>
      {creditCheck && (
        <div className="row subItem">
          <div className="aboutYou_checkbox">
            <Input
              name="driverLicence"
              handleChange={(value) => {
                setDriverLicence(value);
                setIdentityFieldsValid();
              }}
              value={driverLicence}
              labelText={Constants.driverLabel}
              placeholder={Constants.driverPlaceholder}
              maxLength={Constants.driverMaxLength}
              handleError={setDriverLicenceHasError}
              errorMessage={Constants.driverRequiredMsg}
              invalidMessage={driverLicence && driverLicenceInvalidMessage}
              hasError={driverLicenceHasError}
              validationType="driverLicence"
              required={cardNumber}
              validateItself={validateItself}
            />
          </div>
          <div className="aboutYou_card">
            <Input
              name="cardNumber"
              handleChange={(value) => {
                setCardNumber(value);
                setIdentityFieldsValid();
              }}
              value={cardNumber}
              labelText={Constants.cardNumberLabel}
              placeholder={Constants.cardNumberPlaceholder}
              maxLength={Constants.cardNumberMaxLength}
              handleError={setCardNumberHasError}
              errorMessage={
                invalidFields && invalidFields.includes("cardNumber")
                  ? ""
                  : Constants.cardNumberRequiredMsg
              }
              invalidMessage={
                cardNumber
                  ? invalidFields && invalidFields.includes("cardNumber")
                    ? ""
                    : Constants.cardNumberInvalidMsg
                  : ""
              }
              hasError={cardNumberHasError}
              validationType="cardVersionNumber"
              required={driverLicence}
              validateItself={validateItself}
            />
          </div>
        </div>
      )}
      <div className="commonButton">
        <Button
          name="aboutYouContinueButton"
          className="continue-button"
          type="button"
          text={Constants.buttonLabel}
          handleClick={clickedHandler}
          handleValidationClick={() => setValidateItself(true)}
          primaryOnLight
          disabled={isDisable}
        />
      </div>
    </div>
  );

  const getPreview = (
    <div>
      {firstName && (
        <div className="field bold">
          {`${title} ${firstName} ${middleName} ${lastName}`}
        </div>
      )}
      {dateOfBirth && (
        <div className="field">
          {Constants.previewDate} <span className="bold">{dateOfBirth}</span>
        </div>
      )}
      {phoneNumber && (
        <div className="field">
          {Constants.previewPhone} <span className="bold">{phoneNumber}</span>
        </div>
      )}
      {emailAddress && (
        <div className="field">
          {Constants.previewEmail} <span className="bold">{emailAddress}</span>
        </div>
      )}
      {creditCheck && (
        <div className="field bold">{Constants.creditCheckLabel}</div>
      )}
      {creditCheck && driverLicence && (
        <div className="field">
          {Constants.previewDriver} <br />{" "}
          <span className="bold">{driverLicence}</span>
        </div>
      )}
      {creditCheck && cardNumber && (
        <div className="field">
          {Constants.previewCardNumber}{" "}
          <span className="bold">{cardNumber}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="AboutYou" className="cardId" />
      <Modal
        name="aboutYouModal"
        isActive={isModalActive}
        handlePrimaryClick={() => setIsModalActive(!isModalActive)}
        content={
          <img
            className="aboutYou_image"
            src={driverLicenceImg}
            alt=""
            aria-hidden="true"
          />
        }
        buttonTextPrimary="Close"
        journeyStyle={true}
      />
      <ExpandableCard
        name="aboutYouCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  title: state.Customer.CustomerInfo.Title,
  firstName: state.Customer.CustomerInfo.FirstName,
  middleName: state.Customer.CustomerInfo.MiddleName,
  lastName: state.Customer.CustomerInfo.LastName,
  emailAddress: state.Customer.CustomerInfo.EmailAddress,
  phoneNumber: state.Customer.CustomerInfo.PhoneNumber,
  dateOfBirth: state.Customer.CustomerInfo.DateOfBirth,
  hasDriverLicence: state.Customer.HasDriversLicense,
  driverLicence: state.Customer.DriversLicense?.Number,
  cardNumber: state.Customer.DriversLicense?.Version,
  creditCheck: state.Confirmation.CreditCheck,
  receiveNewsAndOffers: state.Confirmation.ReceiveNewsAndOffers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerData: (values) => {
      const customer = {
        CustomerInfo: {
          Title: values.title,
          FirstName: values.firstName,
          MiddleName: values.middleName,
          LastName: values.lastName,
          EmailAddress: values.emailAddress,
          PhoneNumber: values.phoneNumber,
          DateOfBirth: values.dateOfBirth,
        },
        HasDriversLicense: values.hasDriversLicence,
        DriversLicense: {
          Number: values.driverLicence,
          Version: values.cardNumber,
        },
      };
      const confirmation = {
        CreditCheck: values.creditCheck,
        ReceiveNewsAndOffers: values.receiveNewsAndOffers,
      };
      dispatch(saveCustomerData(customer, confirmation));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou);
