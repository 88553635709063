import { useQuery, UseQueryOptions } from 'react-query';
import {
  getNotifications,
  GetNotificationsPath,
  GetNotificationsParams,
  GetNotificationsApiResponse,
  GetNotificationsVersion,
} from '@contact/data-access';

export interface NotificationsHookParams extends GetNotificationsParams {
  token: string;
}

export const useNotifications = (
  version: GetNotificationsVersion,
  params: NotificationsHookParams,
  options?: Omit<
    UseQueryOptions<
      GetNotificationsApiResponse,
      unknown,
      GetNotificationsApiResponse,
      string[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { token } = params;
  return useQuery(
    createNotificationsHookKeys(version, token),
    async () => {
      const { data } = await getNotifications(version, params);
      return data;
    },
    {
      enabled: !!token,
      ...options,
    }
  );
};

export const createNotificationsHookKeys = (
  version: GetNotificationsVersion,
  token: string
) => [GetNotificationsPath[version], token];
