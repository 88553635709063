import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { RewardsApiResponse } from './models';
import { RewardsPath, RewardsVersion } from '..';

export const getRewards = (
  version: RewardsVersion,
  token: string,
  accountId: string,
  rewardType?: string,
  config?: Partial<AxiosRequestConfig>
) => {
  const url = `${RewardsPath[version]}/${accountId}`;
  return baseInstance(config).get<RewardsApiResponse>(url, {
    params: {
      type: rewardType,
    },
    headers: {
      session: token,
    },
  });
};
