import {
  QueryObserverResult,
  QueryObserverSuccessResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import {
  AxiosConfig,
  getTransactions,
  getTransactionsConfigured,
  Transaction,
  TransactionsApiResponse,
  TransactionsPath,
  TransactionsVersion,
} from '@contact/data-access';
import { useAxiosConfig } from './context';
import { addMonths, formatISO } from 'date-fns';
import { useMemo } from 'react';
import { assertQueryObserverSuccessResult } from './assert';

export const useTransactions = (
  version: TransactionsVersion,
  token: string,
  account: string,
  fromDate: string,
  toDate: string
) => {
  return useQuery(
    [TransactionsPath[version], token, account, fromDate, toDate],
    async () => {
      const { data } = await getTransactions(
        version,
        token,
        account,
        fromDate,
        toDate
      );
      return data.transactions;
    },
    {
      enabled: !!(token && account && fromDate && toDate),
    }
  );
};

export function useTransactionsConfigured(
  version: TransactionsVersion,
  account: string,
  fromDate: string,
  toDate: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]> & {
    suspense: true;
  }
): QueryObserverSuccessResult<Transaction[]>;
export function useTransactionsConfigured(
  version: TransactionsVersion,
  account: string,
  fromDate: string,
  toDate: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]>
): QueryObserverResult<Transaction[]>;
export function useTransactionsConfigured(
  version: TransactionsVersion,
  account: string,
  fromDate: string,
  toDate: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]>
): QueryObserverResult<Transaction[]> {
  const config = useAxiosConfig(providedConfig);
  return useQuery(
    [TransactionsPath[version], account, fromDate, toDate],
    async () => {
      const { data } = await getTransactionsConfigured(
        version,
        account,
        fromDate,
        toDate,
        config
      );
      return data.transactions;
    },
    // @ts-ignore
    {
      enabled: !!(account && fromDate && toDate),
      ...options,
    }
  );
}

export function useLatestTransactions(
  version: TransactionsVersion,
  account: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]> & {
    suspense: true;
  }
): QueryObserverSuccessResult<Transaction[]>;
export function useLatestTransactions(
  version: TransactionsVersion,
  account: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]>
): QueryObserverResult<Transaction[]>;
export function useLatestTransactions(
  version: TransactionsVersion,
  account: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<Transaction[]>
): QueryObserverResult<Transaction[]> {
  const [from, to] = useLatestTransactionDateRange();
  return useTransactionsConfigured(
    version,
    account,
    from,
    to,
    providedConfig,
    options
  );
}

export function useLatestTransactionDateRange() {
  const fromDate = useMemo(
    () =>
      formatISO(addMonths(new Date(), -24), {
        format: 'basic',
        representation: 'date',
      }),
    []
  );

  const toDate = useMemo(
    () =>
      formatISO(new Date(), {
        format: 'basic',
        representation: 'date',
      }),
    []
  );

  return [fromDate, toDate];
}

export function useLatestTransactionInvoiceId(
  version: TransactionsVersion,
  account: string,
  providedConfig?: AxiosConfig,
  options?: UseQueryOptions<TransactionsApiResponse['transactions']>
) {
  const { data: transactions } = useLatestTransactions(
    version,
    account,
    providedConfig,
    {
      suspense: true,
      ...options,
    }
  );
  return useMemo(() => {
    const withInvoice = transactions?.find(
      (transaction) => transaction.invoiceId
    );
    return withInvoice?.invoiceId;
  }, [transactions]);
}
