import Cookies from "js-cookie";
import _now from "lodash/now";

/**
 * Reads session id from cookie (if exists) and saves to localStorage for further use.
 * @return {string|undefined} returns saved session id
 */
export function persistSessionId() {
  const cookieSessionId = getCookieSessionId();
  if (!cookieSessionId) {
    return undefined;
  }
  setSessionId(cookieSessionId);
  clearCookieSessionId();
  return cookieSessionId;
}

/**
 * Clears session id from cookie and localStorage.
 */
export function clearSessionId() {
  clearCookieSessionId();
  removeSessionId();
}

/**
 * Returns the stored session id.
 * @return {string|null}
 */
export function getSessionId() {
  return localStorage.getItem("sessionID");
}

/**
 * Get session id from cookies.
 * @return {string|undefined}
 */
export function getCookieSessionId() {
  return Cookies.get("sessionID");
}

/**
 * Remove session id from cookies.
 */
export function clearCookieSessionId() {
  Cookies.remove("sessionID");
}

/**
 * Save session id in localStorage.
 * @param {string} sessionId
 */
export function setSessionId(sessionId) {
  localStorage.setItem("sessionID", sessionId);
  Cookies.set("sessionID", sessionId);
}

/**
 * Removes session id from localStorage.
 */
export function removeSessionId() {
  localStorage.removeItem("sessionID");
}

/**
 * Adds object payload at specified keys to localStorage.
 */
export function persistLocalData(payload) {
  Object.keys(payload).forEach((key) => {
    localStorage.setItem(key, JSON.stringify(payload[key]));
  });
}

/**
 * Returns object payload from localStorage at specified keys.
 * If expiry is specified and exceeded returns undefined
 * @param {string[]} keys
 */
export function hydrateLocalData(keys) {
  return keys.reduce((accum, key) => {
    const data = localStorage.getItem(key);

    if (!data) {
      return accum;
    }

    const { expiry, ...payload } = JSON.parse(data);

    if (expiry === undefined || _now() < expiry) {
      accum[key] = payload;
    } else {
      localStorage.removeItem(key);
    }

    return accum;
  }, {});
}
