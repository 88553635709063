import React from "react";

import SecondaryNavBar from "../SecondaryNavBar/SecondaryNavBar";
import PrimaryNavBar from "../PrimaryNavBar/PrimaryNavBar";
import "./Header.scss";

const Header = () => {
  return (
    <div className="header">
      <SecondaryNavBar />
      <PrimaryNavBar />
    </div>
  );
};

export default Header;
