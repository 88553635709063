export const SaveForLater = {
  trigger: { label: "Save for later" },
  popup: {
    title: "Save my sign up for later",
    content:
      "If you’re not quite ready to sign up, no probs! We’ll email you a summary of your plan, services & rates so you’ve got it on hand. If you decide to pick up where you left off, just click the link in your email.",
    emailLabel: "Email address",
    emailMaxLength: 50,
    emailRequiredMsg: "Email address is required",
    emailInvalidMsg: "Please enter a valid email address",
    primaryButtonLabel: "Save",
    secondaryButtonLabel: "Cancel",
    successMessage: "Thanks! Please check your email.",
    successButtonLabel: "Done",
    errorMessage: "Sorry something went wrong. Please close and try again.",
    errorButtonLabel: "Retry",
  },
};
