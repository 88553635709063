import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import _isEmpty from "lodash/isEmpty";

import IcpSelectList from "./IcpSelectList";

const mapAddress = ({ IcpAddress, IcpNumber }) => ({
  icp: IcpNumber,
  name: IcpAddress,
});

const IcpSelect = ({
  onSelect,
  onSelectLater,
  request,
  addressDetails,
  onSuccess = undefined,
  icp = undefined,
}) => {
  const [possibleAddresses, setPossibleAddresses] = useState([]);

  const handleResponse = useCallback((response) => {
    const addresses = response.data.PossibleAddresses.map(mapAddress);
    setPossibleAddresses(addresses);
  }, []);

  const selectedAddress = useMemo(
    () =>
      possibleAddresses.find((address) => address.icp === icp) ||
      (possibleAddresses.length === 1 ? possibleAddresses[0] : undefined),
    [icp, possibleAddresses]
  );

  const addressesRequested = useRef(false);
  useEffect(() => {
    if (!addressesRequested.current || !onSuccess) {
      return;
    }
    onSuccess(possibleAddresses);
  }, [onSuccess, possibleAddresses]);

  const handleSelect = useCallback(
    ({ name, icp }) => {
      if (icp) {
        onSelect({ name, icp });
      } else {
        onSelectLater();
      }
      setPossibleAddresses([]);
    },
    [onSelect, onSelectLater]
  );

  useEffect(() => {
    if (selectedAddress) {
      handleSelect(selectedAddress);
    }
  }, [selectedAddress, handleSelect]);

  useEffect(() => {
    if (!_isEmpty(addressDetails)) {
      addressesRequested.current = true;
      request(addressDetails).then(handleResponse);
    }
  }, [handleResponse, addressDetails, request]);

  return (
    <IcpSelectList
      possibleAddresses={possibleAddresses}
      onClick={handleSelect}
    />
  );
};

export default IcpSelect;
