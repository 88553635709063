import { PHONE_MOBILE, PHONE_HOME } from "../Config/Constants";

export function getPhoneNumberType(number: string) {
  if (
    number.startsWith("02") ||
    number.startsWith("642") ||
    number.startsWith("00642")
  ) {
    return PHONE_MOBILE;
  } else {
    return PHONE_HOME;
  }
}
