import * as actionTypes from "../actionTypes";

export const updateAuthorizedPerson = (AuthorizedPersonInfo) => {
  return {
    type: actionTypes.UPDATE_AUTHORISED_PERSON,
    AuthorizedPersonInfo,
  };
};
export const updateUnusedAuthorizedPerson = (UnusedAuthorizedPersonInfo) => {
  return {
    type: actionTypes.UPDATE_UNUSED_AUTHORISED_PERSON,
    UnusedAuthorizedPersonInfo,
  };
};
