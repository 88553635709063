import { QueryObserverResult, QueryObserverSuccessResult } from 'react-query';

export function ok(value: unknown, message: string): asserts value {
  if (!value) {
    throw new Error(message);
  }
}

export function assertQueryObserverSuccessResult<V>(
  result: QueryObserverResult<V>
): asserts result is QueryObserverSuccessResult<V> {
  ok(result.data && result.isSuccess, 'Expected QueryObserverSuccessResult');
}
