import { initialState } from "../../Reducers/iJoin/GasRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { updatePipedGas } from "../../Actions/iJoin/GasActions";
import { pair } from "../utils";

interface PipedGasInfo {
  gasRate: [];
  PipedGasConsumptionBand?: string;
  PipedGasUsage: string[];
}

export function getPipedGasInfo(state): PipedGasInfo {
  return state.PipedGasInfo || initialState;
}

export function usePipedGasInfoSelector() {
  return useSelector(getPipedGasInfo);
}

export function getPipedGasInfoConsumptionBand(state) {
  return getPipedGasInfo(state).PipedGasConsumptionBand;
}

export function usePipedGasInfoConsumptionBandSelector() {
  return useSelector(getPipedGasInfoConsumptionBand);
}

export function usePipedGasInfoConsumptionBandSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (PipedGasConsumptionBand) => {
      dispatch(
        updatePipedGas({
          PipedGasConsumptionBand,
        })
      );
    },
    [dispatch]
  );
}

export function usePipedGasInfoConsumptionBand() {
  const value = usePipedGasInfoConsumptionBandSelector();
  const setter = usePipedGasInfoConsumptionBandSetter();
  return pair(value, setter);
}

export function getPipedGasInfoUsage(state) {
  return getPipedGasInfo(state).PipedGasUsage ?? initialState.PipedGasUsage;
}

export function usePipedGasInfoUsageSelector() {
  return useSelector(getPipedGasInfoUsage);
}

function toggleOption(option: string, options: string[]): string[] {
  if (options.includes(option)) {
    return options.filter((value) => value !== option);
  } else {
    return options.concat(option);
  }
}

export function usePipedGasInfoUsageSetter() {
  const dispatch = useDispatch();
  const current = usePipedGasInfoUsageSelector();
  return useCallback(
    (option) => {
      dispatch(
        updatePipedGas({ PipedGasUsage: toggleOption(option, current) })
      );
    },
    [dispatch, current]
  );
}

export function usePipedGasInfoUsage() {
  const value = usePipedGasInfoUsageSelector();
  const setter = usePipedGasInfoUsageSetter();
  return pair(value, setter);
}
