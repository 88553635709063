import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  checkIdentity,
  CheckIdentityPath,
  CheckIdentityVersion,
} from '@contact/data-access';
import { format, isValid, parse } from 'date-fns';
import { cancellableQuery } from '../utils';

const DOB_UI_FORMAT = 'dd/MM/yyyy';

const DOB_API_FORMAT = 'yyyy-MM-dd';

export interface IdentityCheckInput {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  driverLicenceNumber: string;
  driverLicenceVersion: string;
}

export const useIdentityCheck = (
  version: CheckIdentityVersion,
  {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    driverLicenceNumber,
    driverLicenceVersion,
  }: IdentityCheckInput,
  enabled: boolean,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [
      CheckIdentityPath[version],
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      driverLicenceNumber,
      driverLicenceVersion,
    ],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await checkIdentity(
          version,
          firstName,
          middleName,
          lastName,
          format(parse(dateOfBirth, DOB_UI_FORMAT, new Date()), DOB_API_FORMAT),
          driverLicenceNumber,
          driverLicenceVersion,
          {
            ...options,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      retry: false,
      enabled: !!(
        firstName &&
        lastName &&
        dateOfBirth &&
        isValid(parse(dateOfBirth, DOB_UI_FORMAT, new Date())) &&
        driverLicenceNumber &&
        driverLicenceNumber.trim().match(/^[a-zA-Z]{2}[0-9]{6}$/) &&
        driverLicenceVersion &&
        driverLicenceVersion.length === 3 &&
        enabled
      ),
      cacheTime: 0,
    }
  );
