import { Contract, CustomerVersion } from '@contact/data-access';
import { useMemo } from 'react';
import { CustomerHookOptions, useCustomerConfigured } from './useCustomer';

export interface CustomerAccount {
  id: string;
  nickname: string;
  status: 'ACTIVE' | 'INACTIVE' | 'CANCELLED';
  premises: CustomerAccountPremise[];
  contracts: Contract[];
}

export interface CustomerAccountPremise {
  id: string;
  address: string;
  contracts: string[];
}

export const useCustomerAccounts = (
  version: CustomerVersion,
  options: Omit<CustomerHookOptions, 'fetchAccounts'>
) => {
  const { data: customer, ...result } = useCustomerConfigured(version, {
    ...options,
    fetchAccounts: true,
  });

  const accounts = useMemo(() => {
    const activeAccounts = customer?.accounts?.filter(
      (account) => account.status === 'ACTIVE'
    );

    const result: CustomerAccount[] =
      activeAccounts?.map((account) => ({
        ...account,
        premises: [],
      })) || [];

    activeAccounts?.forEach((account) => {
      account?.contracts?.forEach((contract) => {
        if (contract.status === 'ACTIVE') {
          const premises = result?.find((item) => item.id === account.id)
            ?.premises;
          const premise = premises?.find(
            (premise) => premise.id === contract.premiseId
          );
          if (!premise) {
            premises?.push({
              id: contract.premiseId,
              address: contract.address,
              contracts: [contract.contractId],
            });
          } else {
            premise.contracts.push(contract.contractId);
          }
        }
      });
    });
    return result;
  }, [customer]);

  return { ...result, data: accounts };
};
