import { initialState } from "../../Reducers/CSRAgent/JointAccountRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { pair } from "../utils";
import { updateJointAccountHolder } from "../../Actions/CSRAgent/JointAccountAction";

export interface JointAccountHolder {
  Id: string;
  Title: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  PhoneNumber: string;
  EmailAddress: string;
  AssociatedBa?: string;
}

export type JointAccountHolderInfo = JointAccountHolder[];

export function getJointAccountHolderInfo(state): JointAccountHolderInfo {
  return state.JointAccountHolderInfo || initialState;
}

export function useJointAccountHolderInfoSelector() {
  return useSelector(getJointAccountHolderInfo);
}

export function useJointAccountHolderInfoSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (JointAccountHolderInfo: JointAccountHolderInfo) =>
      dispatch(updateJointAccountHolder(JointAccountHolderInfo)),
    [dispatch]
  );
}

export function useJointAccountHolderInfo() {
  const value = useJointAccountHolderInfoSelector();
  const setter = useJointAccountHolderInfoSetter();
  return pair(value, setter);
}
