import React from 'react';
import { useRewards } from '../useRewards';
import { AxiosRequestConfig } from 'axios';
import { RewardsVersion } from '@contact/data-access';

export const useTrimesterReward = (
  version: RewardsVersion,
  token: string,
  accountId: string,
  config?: AxiosRequestConfig
) => {
  const { data = [], isSuccess, ...rest } = useRewards(
    version,
    token,
    accountId,
    'FourthTrimester',
    config
  );

  return {
    isTrimesterCustomer: isSuccess && isTrimesterCustomer(data[0]),
    ...rest,
  };
};

export const isTrimesterCustomer = (fourthTrimesterReward) => {
  if (fourthTrimesterReward) {
    const today = new Date();
    const isActive = fourthTrimesterReward.status === 'Active';

    const isWithinDateRange =
      today >= new Date(fourthTrimesterReward.startDate) &&
      today <= new Date(fourthTrimesterReward.expiryDate);

    return isActive && isWithinDateRange;
  }

  return false;
};
