import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

export const initialState = {
  CustomerInfo: {
    PreferredName: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailAddress: "",
    PhoneNumber: "",
    DateOfBirth: "",
    AccountNumber: "",
    PostalAddress: "",
    CurrentAddress: "",
  },
  HasDriversLicense: false,
  DriversLicense: {
    Number: "",
    Version: "",
  },
  Action: 0,
  IsExistingCustomer: false,
  Note: "",
  isRehydrated: false,
};

const updateProp = reducerPropUpdater("Customer");

const updateCustomer = (state, data) => {
  return {
    ...state,
    ...data,
    CustomerInfo: { ...state.CustomerInfo, ...data.CustomerInfo },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.RESET_CUSTOMER_DL:
      return {
        ...state,
        DriversLicense: {},
      };

    case actionTypes.UPDATE_CUSTOMER_DL:
      return {
        ...state,
        DriversLicense: {
          ...state.DriversLicense,
          ...action.DriversLicense,
        },
      };

    case actionTypes.UPDATE_CUSTOMER:
      return updateCustomer(state, action.customer);

    case actionTypes.REHYDRATED_CUSTOMER:
      return { ...state, isRehydrated: action.payload };

    default:
      return state;
  }
};

export default reducer;
