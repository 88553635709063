import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';
import { ActionTracking } from './models';

export const trackAction = (
  action: ActionTracking.Action,
  accessToken?: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<ActionTracking.Response>(
    '/action-tracking',
    [action],
    accessToken
      ? {
          headers: {
            'access-token': accessToken,
          },
        }
      : undefined
  );
};
