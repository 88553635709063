import { AxiosRequestConfig } from 'axios';
import { BillEstimatePath, BillEstimateVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { EstimateApiRequest, EstimateApiResponse } from './models';

export const billEstimate = (
  version: BillEstimateVersion,
  request?: EstimateApiRequest,
  config?: Partial<AxiosRequestConfig>
) =>
  baseInstance(config).post<EstimateApiResponse>(
    BillEstimatePath[version],
    request
  );
