import React, { useEffect } from "react";

import Button from "../Button";
import IcpSelectList from "../IcpSelect/IcpSelectList";
import Input from "../Input";
import Dropdown from "../Dropdown";

import "./ServiceCard.scss";

import noop from "nop";

const AddressField = ({ addressList, onIcpSelect, ...props }) => {
  useEffect(() => {
    if (addressList?.length === 1) {
      onIcpSelect(addressList[0].icp ?? addressList[0].value);
    }
  }, [addressList, onIcpSelect]);

  const helperText =
    props.setShowAddressModal || props.setShowIcpModal ? (
      <div className="serviceCardField_rightColumn_manual">
        Not right?{" "}
        {props.setShowAddressModal ? (
          <div
            className="serviceCardField_rightColumn_manual_link address"
            onClick={props.setShowAddressModal}
          >
            Manually enter address
          </div>
        ) : null}
        {props.setShowAddressModal && props.setShowIcpModal ? " or " : null}
        {props.setShowIcpModal ? (
          <div
            className="serviceCardField_rightColumn_manual_link icp"
            onClick={props.setShowIcpModal}
          >
            Enter a different ICP
          </div>
        ) : null}
      </div>
    ) : null;

  return (
    <div className="serviceCardField">
      <div className="serviceCardField_left">{props.title}</div>
      <div className="serviceCardField_rightColumn">
        <div className="serviceCardField_rightColumn_dropdown">
          {addressList?.length > 1 ? (
            <IcpSelectList
              possibleAddresses={addressList}
              listOnly
              onClick={onIcpSelect}
            />
          ) : (
            <Input value={props.value} disabled />
          )}
        </div>
        {helperText}
      </div>
    </div>
  );
};

const ServiceCardField = (props) => {
  const { selectedItem, setSelectedItem } = props;

  // Standard field builder
  const buildStandardField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={noop}
              value={props.value}
              disabled
              isValid={props.isValid}
              showSuccessIcon={props.showSuccessIcon}
              hasError={props.hasError}
              showCrossIcon={props.showCrossIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  // Button field builder
  const buildClipboardButtonField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={noop}
              value={props.value}
              disabled
              hasError={props.hasError}
            />
          </div>
          {!props.hasError && (
            <Button
              className="serviceCardField_right_button"
              type="button"
              text={props.buttonTitle}
              handleClick={() => {
                navigator.clipboard.writeText(props.value);
              }}
              handleValidationClick={noop}
              primaryOnLight
            />
          )}
        </div>
      </div>
    );
  };

  const buildButtonField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={noop}
              value={props.value}
              disabled
              hasError={props.hasError}
            />
          </div>
          {!props.hasError && (
            <Button
              className="serviceCardField_right_button"
              type="button"
              primaryOnLight
              dense
              href={props.href}
              target={props.target}
              text={props.buttonTitle}
              handleClick={() => {
                if (!props.href && props.onButtonClick) {
                  props.onButtonClick();
                }
              }}
              handleValidationClick={noop}
            />
          )}
        </div>
      </div>
    );
  };

  const buildDropdownField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <Dropdown
            items={props.dropdownItems}
            selectedItem={selectedItem}
            handleSelectedItem={setSelectedItem}
            labelText=""
            handleError={noop}
            errorMessage=""
            hasError={false}
            required
            isServiceCard
          />
        </div>
      </div>
    );
  };

  const buildCustomField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_rightColumn">{props.children}</div>
      </div>
    );
  };

  // Build correct field type
  switch (props.fieldType) {
    case "address":
      return <AddressField {...props} />;
    case "link":
      return buildButtonField();
    case "button":
      return buildClipboardButtonField();
    case "standard":
      return buildStandardField();
    case "dropdown":
      return buildDropdownField();
    case "custom":
      return buildCustomField();
    default:
      return null;
  }
};

export default ServiceCardField;
