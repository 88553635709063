import { AxiosRequestConfig } from 'axios';
import { AccountActivationPath, AccountActivationVersion } from './apiPaths';
import { baseInstance } from './base-service';

export const accountActivation = (
  version: AccountActivationVersion,
  activationKey: string,
  requestId: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).put<void>(AccountActivationPath[version], {
    activationKey,
    requestId,
  });
};
