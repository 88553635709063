import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { CustomerApiResponse } from './models';
import { CustomerPath, CustomerVersion } from '..';

export const getCustomer = (
  version: CustomerVersion,
  token: string,
  fetchAccounts?: boolean,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<CustomerApiResponse>(CustomerPath[version], {
    headers: {
      session: token,
    },
    params: {
      fetchAccounts,
    },
  });
};

export const getCustomerConfigured = (
  version: CustomerVersion,
  fetchAccounts?: boolean,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<CustomerApiResponse>(CustomerPath[version], {
    params: {
      fetchAccounts,
    },
  });
};
