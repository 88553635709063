import React, { useCallback, useEffect, useState } from "react";
import {
  Clock,
  Icon,
  Input,
  Modal,
  SelectedTick,
  Error,
} from "react-components";
import { JourneyType, Origin } from "@contact/data-access-hooks";
import sha256 from "crypto-js/sha256";
import { dataLayerEvent } from "Utils/analytics";

import { useSaveForLater, useSaveForLaterEmail } from "Redux/Hooks/shared";
import { SaveForLater as Constants } from "./Constants";
import { SaveForLaterTrigger } from "./SaveForLaterTrigger";
import "./SaveForLater.scss";

export interface SaveForLaterProps {
  journeyType: JourneyType;
  origin: Origin;
}

export const SaveForLater = ({ journeyType, origin }: SaveForLaterProps) => {
  const saveForLaterEmail = useSaveForLaterEmail();
  const { isLoading, isSuccess, isError, create, reset } = useSaveForLater(
    journeyType,
    origin
  );

  const [isSaveForLaterOpen, setIsSaveForLaterOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressHasError, setEmailAddressHasError] = useState(false);

  useEffect(() => {
    setEmailAddress(saveForLaterEmail);
  }, [saveForLaterEmail]);

  useEffect(() => {
    if (isError) {
      dataLayerEvent({
        event: "save_signup_error",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      dataLayerEvent({
        event: "save_signup_success",
        hashedEmail: sha256(emailAddress.toLowerCase()).toString(),
      });
    }
  }, [isSuccess, emailAddress]);

  const handleSave = useCallback(() => {
    if (isError) {
      dataLayerEvent({
        event: "cta_click",
        cta_text: "retry",
      });
    }
    create(emailAddress);
  }, [create, emailAddress, isError]);

  const handleClose = useCallback(() => {
    if (isSuccess) {
      dataLayerEvent({
        event: "cta_click",
        cta_text: "done",
      });
    } else {
      dataLayerEvent({
        event: "cta_click",
        cta_text: "cancel",
      });
    }
    reset();
    setIsSaveForLaterOpen(false);
  }, [reset, isSuccess]);

  useEffect(() => {
    if (isSaveForLaterOpen) {
      dataLayerEvent({
        event: "virtualPageview",
        virtualUrl: "/save-my-sign-up-for-later",
        virtualPageTitle: "VPV-Save my sign up for later",
      });
    }
  }, [isSaveForLaterOpen]);

  const buttonDisabled = emailAddressHasError || !emailAddress || isLoading;

  const content = (
    <div>
      <div>{Constants.popup.content}</div>
      <div className="row">
        <div className="field">
          <Input
            name="email"
            handleChange={setEmailAddress}
            value={emailAddress}
            labelText={Constants.popup.emailLabel}
            maxLength={Constants.popup.emailMaxLength}
            handleError={setEmailAddressHasError}
            errorMessage={Constants.popup.emailRequiredMsg}
            invalidMessage={Constants.popup.emailInvalidMsg}
            hasError={emailAddressHasError}
            validationType="email"
            showSuccessIcon
            required
            validateItself
          />
        </div>
      </div>
      <div className="saveForLater_messageContainer">
        <div className="saveForLater_message">
          {(isSuccess && Constants.popup.successMessage) ||
            (isError && Constants.popup.errorMessage) ||
            ""}
        </div>
        <div className="saveForLater_icon">
          {(isSuccess && (
            <Icon
              className="saveForLater_icon--success"
              icon={<SelectedTick />}
            />
          )) ||
            (isError && (
              <Icon className="saveForLater_icon--error" icon={<Error />} />
            )) ||
            null}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SaveForLaterTrigger
        onClick={() => {
          setIsSaveForLaterOpen(true);
        }}
      />
      <Modal
        showClose
        saveForLaterStyle
        isActive={isSaveForLaterOpen}
        icon={<Clock />}
        title={Constants.popup.title}
        buttonTextPrimary={Constants.popup.secondaryButtonLabel}
        handlePrimaryClick={handleClose}
        buttonTextSecondary={
          (isSuccess && Constants.popup.successButtonLabel) ||
          (isError && Constants.popup.errorButtonLabel) ||
          Constants.popup.primaryButtonLabel
        }
        handleSecondaryClick={
          (isSuccess && handleClose) || (isError && handleSave) || handleSave
        }
        buttonDisabled={buttonDisabled}
        content={content}
        isLoading={isLoading}
      />
    </>
  );
};
