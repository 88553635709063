import React, { useState, useEffect, useRef, useCallback } from "react";

import Icon from "../Icon";
import { ArrowDown } from "../../Assets/Icons";

import "./Dropdown.scss";
import noop from "nop";
import classNames from "classnames";

const Dropdown = ({
  name = undefined,
  items,
  handleSelectedItem,
  handleSelectedItemIndex,
  placeholder,
  selectedItem = undefined,
  validateItself = false,
  hasError = false,
  required = false,
  value = undefined,
  handleError = undefined,
  handleClick = undefined,
  handleBlur = undefined,
  isServiceCard = false,
  labelText,
  errorMessage = "",
  disabled = false,
  getFormatted: getFormattedExternal = undefined,
  inputProps = undefined,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [touched, setTouched] = useState(false);
  const dropDownContainer = useRef();
  const firstItem = items[0];
  const itemsLength = items.length;

  // Note: this is used as a ref, it will always have unstable references to its scope, so useCallback is not needed here.
  const getInputRef = (input) => {
    dropDownContainer.current = input;

    if (inputProps && inputProps.ref) {
      if (typeof inputProps.ref === "object") {
        inputProps.ref.current = input;
      } else if (typeof inputProps.ref === "function") {
        inputProps.ref(input);
      }
    }
  };

  //trigger auto validation
  useEffect(() => {
    if (disabled) return;
    if (validateItself && !hasError && required && !value) {
      setTouched(true);
      if (handleError) {
        handleError(true);
      }
    }
  }, [validateItself, value, handleError, required, hasError, disabled]);

  useEffect(() => {
    if (handleError && hasError && value) {
      handleError(false);
    }
  }, [handleError, hasError, required, value]);

  useEffect(() => {
    if (disabled) return;
    if (!showDropdown) {
      return;
    }
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

    function handleClick(event) {
      // outside click
      if (
        showDropdown &&
        dropDownContainer.current !== undefined &&
        !dropDownContainer.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
  }, [handleClick, showDropdown, setShowDropdown, disabled]);

  useEffect(() => {
    if (disabled) return;
    if (touched && !selectedItem && handleError) {
      handleError(true);
    }
  }, [touched, handleError, selectedItem, disabled]);

  useEffect(() => {
    if (disabled) return;
    if (selectedItem) {
      return;
    }
    if (itemsLength > 1 && placeholder) {
      return;
    }
    handleSelectedItem(firstItem);
    handleSelectedItemIndex(0);
  }, [
    selectedItem,
    itemsLength,
    placeholder,
    handleSelectedItem,
    handleSelectedItemIndex,
    firstItem,
    disabled,
  ]);

  const getFormatted = useCallback(
    (value) =>
      getFormattedExternal ? getFormattedExternal(value) : String(value),
    [getFormattedExternal]
  );

  return (
    <React.Fragment>
      {/* eslint-disable-next-line */}
      <div
        id={name}
        className={classNames("dropdown", {
          dropdown_error: hasError,
        })}
        ref={getInputRef}
        onClick={() => {
          if (disabled) return;
          if (items.length > 1) {
            setShowDropdown(!showDropdown);
          }
        }}
        onBlur={() => {
          if (disabled) return;
          setTouched(true);
          if (handleBlur) {
            handleBlur();
          }
        }}
        tabIndex={0}
        role="button"
        aria-disabled={disabled}
      >
        {!hasError && (
          <span
            className={`dropdown_label ${
              showDropdown ? "dropdown_label--selected" : ""
            }`}
          >
            {labelText}
          </span>
        )}
        {hasError && (
          <span
            className={`dropdown_label dropdown_label_error ${
              showDropdown ? "dropdown_label--selected" : ""
            }`}
          >
            {errorMessage}
          </span>
        )}
        <div className="dropdown_selected">
          <div className="dropdown_labelWrapper">
            <div className="dropdown_selectedItem">
              {selectedItem ? getFormatted(selectedItem) : placeholder}
            </div>
            <div>
              {items.length > 1 && (
                <Icon icon={<ArrowDown />} className="dropdown_icon" />
              )}
            </div>
          </div>
          {showDropdown && (
            <ul
              className={
                isServiceCard ? "dropdown_service_card_list" : "dropdown_list"
              }
            >
              {items.map((item, index) => {
                const formatted = getFormatted(item);
                return (
                  <li
                    className="dropdown_listItem"
                    key={`${formatted}:${index}`}
                  >
                    <div
                      className="dropdown_option"
                      onClick={() => {
                        if (disabled) return;
                        handleSelectedItem(item);
                        handleSelectedItemIndex(index);
                      }}
                      onKeyDown={(e) => {
                        if (disabled) return;
                        if (e.key === "Enter") {
                          handleSelectedItem(item);
                          handleSelectedItemIndex(index);
                        }
                      }}
                      tabIndex={0}
                      role="option"
                      aria-selected={selectedItem === item}
                    >
                      {formatted}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Dropdown.defaultProps = {
  labelText: "What is your message about?",
  value: "Billing",
  defaultValue: "Billing",
  handleSelectedItem: noop,
  handleSelectedItemIndex: noop,
  placeholder: "",
};

export default Dropdown;
