import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { updateJourneyType } from "../../Actions/CSRAgent/JourneyTypeAction";
import { pair } from "../utils";

export function getJourneyType(state) {
  return state.JourneyType;
}

export function useJourneyTypeSelector() {
  return useSelector(getJourneyType);
}

export function useJourneyTypeSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (JourneyType: string) => {
      dispatch(updateJourneyType(JourneyType));
    },
    [dispatch]
  );
}

export function useJourneyType() {
  const value = useJourneyTypeSelector();
  const setter = useJourneyTypeSetter();
  return pair(value, setter);
}

export function useJourneyTypeSet(value: string) {
  const setter = useJourneyTypeSetter();
  useEffect(() => setter(value), [setter, value]);
}
