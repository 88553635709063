import React, { Fragment } from "react";
import classNames from "classnames";

import "./LayoutRow.scss";

const LayoutRow = ({ left, right, reverseRow = false, hideColumn = false }) => {
  const layoutRow = classNames({
    layoutRow: true,
    "layoutRow--standard": !reverseRow,
    "layoutRow--reverseRow": reverseRow,
    "layoutRow--hideColumn": hideColumn,
  });

  return (
    <div className={layoutRow}>
      <div className="layoutRow_rowPrimary">{left}</div>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <Fragment>{right}</Fragment>
    </div>
  );
};

export default LayoutRow;
