import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandAddressDetails,
  getBroadbandOfferings,
  GetBroadbandOfferingsPath,
  GetBroadbandOfferingsVersion,
  UserType,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useBroadbandOfferings = (
  version: GetBroadbandOfferingsVersion,
  planId?: string,
  services?: string,
  addressDetails?: BroadbandAddressDetails,
  userType?: UserType,
  inclusions?: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [
      GetBroadbandOfferingsPath[version],
      planId,
      services,
      addressDetails,
      userType,
    ],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getBroadbandOfferings(
          version,
          planId!,
          services!,
          addressDetails!,
          userType!,
          inclusions,
          {
            ...options,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      enabled: !!(planId && services && addressDetails),
    }
  );
