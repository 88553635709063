import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { AttachmentApiResponse, AttachmentType } from './models';
import { GetAttachmentPath, GetAttachmentVersion } from './apiPaths';

/**
 * https://developer.contact-digital-devops.net/apis/b13375d44cd244f3e19ba5d8a78c62337c49fe97
 * Gets the attachment based on the id provided.
 * @param {string} token  A session token from SAP
 * @param {string} customerId The business partner id for the customer.
 * @param {string} attachmentId The document Id from the interaction record.
 * @param {string} attachmentType  The type of the attachment. Must be CM_FILE or OT_URL.
 * @param {AxiosRequestConfig} [config]
 * @returns the list of interaction records found
 */
export const getAttachment = (
  version: GetAttachmentVersion,
  token: string,
  customerId: string,
  attachmentId: string,
  attachmentType: AttachmentType,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<AttachmentApiResponse>(
    `${GetAttachmentPath[version]}/${attachmentId}`,
    {
      params: {
        businessPartner: customerId,
        attachmentType,
      },
      headers: {
        session: token,
      },
    }
  );
};
