import { initialState } from "../../Reducers/CSRAgent/AboutYouRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { pair } from "../utils";
import {
  updateBPFetching,
  updateBPError,
  updateBPFetched,
  bPReset,
} from "../../Actions/CSRAgent/AboutYouAction";

interface AboutYou {
  isFetching: boolean;
  isError: boolean;
  isFetched: boolean;
  errorMessage?: string;
  errorDetails?: Record<string, string> & {
    driversLicence?: Record<string, string>;
    accounts?: Array<string>;
  };
}

export function getAboutYou(state): AboutYou {
  return state.AboutYou || initialState;
}

export function getAboutYouIsFetching(state): AboutYou["isFetching"] {
  return getAboutYou(state).isFetching;
}

export function useAboutYouIsFetchingSelector() {
  return useSelector(getAboutYouIsFetching);
}

export function useAboutYouIsFetchingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isFetching: AboutYou["isFetching"]) =>
      dispatch(updateBPFetching(isFetching)),
    [dispatch]
  );
}

export function useAboutYouIsFetching() {
  const value = useAboutYouIsFetchingSelector();
  const setter = useAboutYouIsFetchingSetter();
  return pair(value, setter);
}

export function getAboutYouIsFetched(state): AboutYou["isFetched"] {
  return getAboutYou(state).isFetched;
}

export function useAboutYouIsFetchedSelector() {
  return useSelector(getAboutYouIsFetched);
}

export function useAboutYouIsFetchedSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isFetched: AboutYou["isFetched"]) => dispatch(updateBPFetched(isFetched)),
    [dispatch]
  );
}

export function useAboutYouIsFetched() {
  const value = useAboutYouIsFetchedSelector();
  const setter = useAboutYouIsFetchedSetter();
  return pair(value, setter);
}

export function getAboutYouIsError(state): AboutYou["isError"] {
  return getAboutYou(state).isError;
}

export function useAboutYouIsErrorSelector() {
  return useSelector(getAboutYouIsError);
}

export function getAboutYouErrorMessage(state): AboutYou["errorMessage"] {
  return getAboutYou(state).errorMessage;
}

export function useAboutYouErrorMessageSelector() {
  return useSelector(getAboutYouErrorMessage);
}

export function getAboutYouErrorDetails(state): AboutYou["errorDetails"] {
  return getAboutYou(state).errorDetails;
}

export function useAboutYouErrorDetailsSelector() {
  return useSelector(getAboutYouErrorDetails);
}

export function useAboutYouReset() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(bPReset()), [dispatch]);
}

// Error object abstractions, needs to be looked at when binding to the UI.
// Maybe delete..
export function useAboutYouErrorSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (error: Pick<AboutYou, "errorMessage" | "errorDetails">) =>
      dispatch(updateBPError(error)),
    [dispatch]
  );
}

export function useAboutYouErrorSelector(): Pick<
  AboutYou,
  "errorMessage" | "errorDetails"
> {
  const errorMessage = useAboutYouErrorMessageSelector();
  const errorDetails = useAboutYouErrorDetailsSelector();

  return useMemo(
    () => ({
      errorMessage,
      errorDetails,
    }),
    [errorMessage, errorDetails]
  );
}

export function useAboutYouError() {
  const value = useAboutYouErrorSelector();
  const setter = useAboutYouErrorSetter();
  return pair(value, setter);
}
