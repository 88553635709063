import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getSiteCoreContent,
  GetSiteCoreContentPath,
  GetSiteCoreContentVersion,
  SiteCoreApiResponse,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useSiteCoreContent = (
  version: GetSiteCoreContentVersion,
  url?: string,
  axiosOptions?: Partial<AxiosRequestConfig>,
  queryOptions?: UseQueryOptions<SiteCoreApiResponse>
) =>
  useQuery<SiteCoreApiResponse>(
    [GetSiteCoreContentPath[version], url],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getSiteCoreContent(version, url!, {
          ...axiosOptions,
          ...extraOptions,
        });
        return data;
      }),
    {
      ...queryOptions,
      enabled: !!url,
    }
  );
