import React, {
  useCallback,
  useMemo,
  ChangeEvent,
  useRef,
  useEffect,
} from "react";
import { Input } from "react-components";
import moment from "moment";
import {
  DateOfBirthInvalidReason,
  getDateOfBirthValidationReason,
} from "../../Utils/ValidationUtil";

export interface WebNativeDateInputConstants {
  dobNotMatchMsg?: string;
  dateMinAgeErrorMsg?: string;
  dateRequiredMsg?: string;
  dateMaxAgeErrorMsg?: string;
}

export interface WebNativeDateInputProps {
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  labelText?: string;
  minAge?: number;
  maxAge?: number;
  constants: WebNativeDateInputConstants;
  dateOfBirthHasIdentityCheckError?: boolean;
  handleChange(value: string, date?: Date): void;
  setDateOfBirthHasError(hasError: boolean): boolean;
}
const WebNativeDateInput = ({
  value,
  placeholder,
  labelText,
  handleChange,
  minAge = 18,
  maxAge = 110,
  constants,
  setDateOfBirthHasError,
  dateOfBirthHasIdentityCheckError,
}: WebNativeDateInputProps) => {
  const errorMessage = useMemo(() => {
    if (dateOfBirthHasIdentityCheckError) {
      return constants.dobNotMatchMsg;
    }
    if (!value) {
      return;
    }
    const [year, month, day] = value.split("-");
    const reason = getDateOfBirthValidationReason(
      {
        day,
        month,
        year,
      },
      undefined,
      {
        minAge,
        maxAge,
      }
    );

    const reasons: Record<DateOfBirthInvalidReason, string | undefined> = {
      REQUIRED: constants.dateRequiredMsg,
      MAX_AGE: constants.dateMaxAgeErrorMsg,
      MIN_AGE: constants.dateMinAgeErrorMsg,
      INVALID: constants.dateRequiredMsg,
    };
    return reason
      ? reasons[reason] || constants.dateRequiredMsg || reason
      : undefined;
  }, [
    dateOfBirthHasIdentityCheckError,
    value,
    minAge,
    maxAge,
    constants.dateRequiredMsg,
    constants.dateMaxAgeErrorMsg,
    constants.dateMinAgeErrorMsg,
    constants.dobNotMatchMsg,
  ]);
  const hasError = !!errorMessage;
  useEffect(() => {
    setDateOfBirthHasError(hasError);
  }, [setDateOfBirthHasError, hasError]);
  const possiblyUnstableChange = useRef(handleChange);
  const onChangeForExternal = useCallback((value: string, date?: Date) => {
    possiblyUnstableChange.current?.(value, date);
  }, []);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const date = event.target.valueAsDate;
      if (!date) {
        onChangeForExternal(event.target.value, undefined);
      } else {
        // https://developers.google.com/web/updates/2012/08/Quick-FAQs-on-input-type-date-in-Google-Chrome
        const formatted = moment(date).format("YYYY-MM-DD");
        onChangeForExternal(formatted, date);
      }
    },
    [onChangeForExternal]
  );
  return (
    <Input
      name="dob"
      inputType="date"
      placeholder={placeholder}
      labelText={labelText}
      value={value}
      handleChange={handleChange}
      onChange={onChange}
      hasError={hasError}
      errorMessage={errorMessage}
      inputProps={{
        // This should hopefully pin the locale
        lang: "en-nz",
      }}
    />
  );
};

export default WebNativeDateInput;
