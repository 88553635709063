import React, { Fragment, useState, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import classNames from "classnames";
import Icon from "../Icon";
import Loading from "../Loading";
import { validate } from "../../Utils/ValidationUtil";
import "./Input.scss";
import { Error, SelectedTick, Cross } from "../../Assets/Icons";
import noop from "nop";

const Input = ({
  isValid: defaultIsValid = undefined,
  validateItself = false,
  hasError = false,
  required = false,
  value = Input.defaultProps.value,
  handleError = Input.defaultProps.handleError,
  invalidMessage = undefined,
  labelText = undefined,
  noPriceStyles = false,
  inputProps = undefined,
  maxLength,
  disabled = false,
  readOnly = false,
  handleChange,
  onChange = undefined,
  validationType = undefined,
  placeholder = undefined,
  onBlur = undefined,
  name,
  showLoadingIcon = false,
  iconType = undefined,
  errorMessage = undefined,
  onKeyDown = undefined,
  onKeyPress = undefined,
  hint = undefined,
  showSuccessIcon = false,
  hideErrorIcon = false,
  showCrossIcon = false,
  inputType = "text",
}) => {
  const [placeholderState, setPlaceholderState] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(
    typeof defaultIsValid !== "undefined" ? defaultIsValid : false
  );
  const [inputRef, setInputRef] = useState(null);

  //trigger auto validation
  useEffect(() => {
    if (validateItself && !hasError && required && !value) {
      setTouched(true);
      handleError(true);
    }
  }, [validateItself, value, handleError, required, hasError]);

  useEffect(() => {
    setIsFocused((isFocused) => (value ? true : isFocused));
  }, [value, setIsFocused]);

  useEffect(() => {
    if (handleError && !required && !invalidMessage) {
      handleError(false);
    }
  }, [required, handleError, invalidMessage]);

  // Set is valid
  useEffect(() => {
    if (typeof defaultIsValid === "undefined") {
      if (value) {
        const fakeChangeEvent = {
          preventDefault: noop,
          target: { value: value },
        };
        const isValid = !validate(fakeChangeEvent, noop, validationType, true);
        setIsValid(isValid);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(defaultIsValid);
    }
  }, [defaultIsValid, value, validationType]);

  const animateInput = useSpring({
    opacity: !isFocused && labelText ? 0 : 1,
    config: config.stiff,
  });
  const animateLabel = useSpring({
    opacity: isFocused ? 0.6 : 1,
    bottom: isFocused ? "24px" : "0px",
    fontSize: isFocused ? "14px" : "18px",
    config: config.stiff,
  });

  const inputStyle = classNames({
    inputContainer_input: true,
    "inputContainer_input--price": validationType === "price" && !noPriceStyles,
  });

  const onChangeHandler = (event) => {
    if (disabled) return;

    if (onChange) {
      return onChange(event);
    }

    handleError(validate(event, handleChange, validationType, touched));

    // Run autocomplete onchange event
    if (inputProps && inputProps.onChange) {
      inputProps.onChange(event);
    }
  };

  const onFocusHandler = (event) => {
    setIsFocused(true);
    setPlaceholderState(placeholder);

    // Focus's the autocomplete
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event);
    }
    if (inputRef && inputRef.focus) inputRef.focus();
  };

  const onBlurHandler = (event) => {
    setTouched(true);
    if (onBlur) {
      onBlur();
    }
    setIsFocused(event.target.value && event.target.value !== "");
    setPlaceholderState("");
    if (validationType === "price") {
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : parseFloat(event.target.value).toFixed(2);
    }
    if (
      validate(event, handleChange, validationType, true) ||
      (required && !value)
    ) {
      handleError(true);
    }

    // Blur's the autocomplete
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event);
    }
  };

  // Note: this is used as a ref, it will always have unstable references to its scope, so useCallback is not needed here.
  const getInputRef = (input) => {
    setInputRef(input);
    if (typeof inputProps.ref === "object") {
      inputProps.ref.current = input;
    } else if (typeof inputProps.ref === "function") {
      inputProps.ref(input);
    }
  };

  return (
    <Fragment>
      <div
        className="inputContainer"
        aria-disabled={disabled}
        aria-invalid={!isValid}
        data-testrelatedto={name}
      >
        {/* eslint-disable-next-line*/}
        {labelText && !hasError && (
          <animated.label
            style={animateLabel}
            id={name}
            name={name}
            htmlFor={name}
            className="inputContainer_label"
            onClick={() => onFocusHandler()}
          >
            {labelText}
          </animated.label>
        )}
        {/* eslint-disable jsx-a11y/label-has-for */}
        {hasError && (
          <label
            style={{
              color: "red",
              opacity: 0.6,
              bottom: "24px",
              fontSize: "14px",
            }}
            id={name}
            htmlFor={name}
            className="inputContainer_label"
            onClick={() => onFocusHandler()}
          >
            {value && invalidMessage ? invalidMessage : errorMessage}
          </label>
        )}

        {validationType === "price" && !noPriceStyles && (
          <span className="inputContainer_dollar">$</span>
        )}
        {validationType === "price" && noPriceStyles && isFocused && (
          <span className="inputContainer_dollar--noPriceStyle">$</span>
        )}
        <animated.input
          id={name}
          name={name}
          className={inputStyle}
          type={inputType}
          value={value || ""}
          placeholder={labelText ? placeholderState : placeholder}
          htmlFor={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          style={animateInput}
          // InputProps must be before input events as it
          // contains events that will overide the events below
          // The inputProps have other fields needed so don't remove
          {...inputProps}
          onChange={(e) => onChangeHandler(e)}
          onFocus={(e) => onFocusHandler(e)}
          onBlur={(e) => onBlurHandler(e)}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          ref={inputProps && inputProps.ref ? getInputRef : null}
          maxLength={maxLength}
          autoComplete="off"
        />
        {iconType && !hasError && (
          <div
            onClick={() => onFocusHandler()}
            onKeyDown={(e) => {
              if (e.key === "Enter") onFocusHandler();
            }}
            role="button"
            tabIndex="0"
          >
            {!hasError && (
              <Icon icon={iconType} className="inputContainer_icon" />
            )}
          </div>
        )}
        {showLoadingIcon && (
          <Icon
            name="loadingIcon"
            icon={<Loading />}
            className="inputContainer_icon--loading"
          />
        )}
        {!showLoadingIcon && hasError && !hideErrorIcon && (
          <Icon
            name="errorIcon"
            icon={<Error />}
            className="inputContainer_icon--error"
          />
        )}
        {showCrossIcon && (
          <Icon
            name="crossIcon"
            icon={<Cross />}
            className="inputContainer_icon--cross"
          />
        )}
        {!showLoadingIcon && showSuccessIcon && isValid && (
          <Icon
            name="successIcon"
            icon={<SelectedTick />}
            className="inputContainer_icon--success"
          />
        )}
      </div>
      {hint && <div className="inputContainer_hint">{hint}</div>}
    </Fragment>
  );
};

Input.defaultProps = {
  handleError: noop,
  value: "",
};

export default Input;
