import React from "react";
import { connect } from "react-redux";
import { LayoutRow, Card } from "react-components";

import "./NpsAsync.scss";
import NPSSurvey from "./NpsAsyncSurvey/NpsAsyncSurvey";

export const NpsAsync = () => {
  return (
    <LayoutRow
      reverseRow
      left={
        <Card name="successCard" className="successCard">
          <div className="success">
            <div className="success_rateYourExperience">
              <NPSSurvey />
            </div>
          </div>
        </Card>
      }
      right={null}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NpsAsync);
