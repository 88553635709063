import { initialState } from "../../Reducers/AuthRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { updateAuth } from "../../Actions/CSRAgent/JoinAction";
import { pair } from "../utils";

export interface Auth {
  sessionID: string;
  deleteAfter: number;
  hasError: boolean;
  isCallingAPI: boolean;
  submitted: boolean;
  redirectFromExpress: boolean;
  isPendingAPI: boolean;
}

export function getAuth(state) {
  return state.Auth || initialState;
}

export function getAuthSessionID(state) {
  return getAuth(state).sessionID;
}

export function useAuthSessionIDSelector() {
  return useSelector(getAuthSessionID);
}

export function useAuthSessionIDSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (sessionID: Auth["sessionID"]) => dispatch(updateAuth({ sessionID })),
    [dispatch]
  );
}

export function useAuthSessionID() {
  const value = useAuthSessionIDSelector();
  const setter = useAuthSessionIDSetter();
  return pair(value, setter);
}

export function getAuthDeleteAfter(state) {
  return getAuth(state).deleteAfter;
}

export function useAuthDeleteAfterSelector() {
  return useSelector(getAuthDeleteAfter);
}

export function useAuthDeleteAfterSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (deleteAfter: Auth["deleteAfter"]) => dispatch(updateAuth({ deleteAfter })),
    [dispatch]
  );
}

export function useAuthDeleteAfter() {
  const value = useAuthDeleteAfterSelector();
  const setter = useAuthDeleteAfterSetter();
  return pair(value, setter);
}

export function getAuthHasError(state) {
  return getAuth(state).hasError;
}

export function useAuthHasErrorSelector() {
  return useSelector(getAuthHasError);
}

export function useAuthHasErrorSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (hasError: Auth["hasError"]) => dispatch(updateAuth({ hasError })),
    [dispatch]
  );
}

export function useAuthHasError() {
  const value = useAuthHasErrorSelector();
  const setter = useAuthHasErrorSetter();
  return pair(value, setter);
}

export function getAuthIsCallingAPI(state) {
  return getAuth(state).isCallingAPI;
}

export function useAuthIsCallingAPISelector() {
  return useSelector(getAuthIsCallingAPI);
}

export function useAuthIsCallingAPISetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isCallingAPI: Auth["isCallingAPI"]) =>
      dispatch(updateAuth({ isCallingAPI })),
    [dispatch]
  );
}

export function useAuthIsCallingAPI() {
  const value = useAuthIsCallingAPISelector();
  const setter = useAuthIsCallingAPISetter();
  return pair(value, setter);
}

export function getAuthSubmitted(state) {
  return getAuth(state).submitted;
}

export function useAuthSubmittedSelector() {
  return useSelector(getAuthSubmitted);
}

export function useAuthSubmittedSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (submitted: Auth["submitted"]) => dispatch(updateAuth({ submitted })),
    [dispatch]
  );
}

export function useAuthSubmitted() {
  const value = useAuthSubmittedSelector();
  const setter = useAuthSubmittedSetter();
  return pair(value, setter);
}

export function getAuthRedirectFromExpress(state) {
  return getAuth(state).redirectFromExpress;
}

export function useAuthRedirectFromExpressSelector() {
  return useSelector(getAuthRedirectFromExpress);
}

export function useAuthRedirectFromExpressSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (redirectFromExpress: Auth["redirectFromExpress"]) =>
      dispatch(updateAuth({ redirectFromExpress })),
    [dispatch]
  );
}

export function useAuthRedirectFromExpress() {
  const value = useAuthRedirectFromExpressSelector();
  const setter = useAuthRedirectFromExpressSetter();
  return pair(value, setter);
}

export function getAuthIsPendingAPI(state) {
  return getAuth(state).isPendingAPI;
}

export function useAuthIsPendingAPISelector() {
  return useSelector(getAuthIsPendingAPI);
}

export function useAuthIsPendingAPISetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isPendingAPI: Auth["isPendingAPI"]) =>
      dispatch(updateAuth({ isPendingAPI })),
    [dispatch]
  );
}

export function useAuthIsPendingAPI() {
  const value = useAuthIsPendingAPISelector();
  const setter = useAuthIsPendingAPISetter();
  return pair(value, setter);
}
