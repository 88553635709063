import * as actionTypes from "../actionTypes";

const triggerSitecoreContractId = () => ({
  type: actionTypes.TRIGGER_SITECORE_CONTRACT_ID,
});

const triggerMeasurementProtocolLive = () => ({
  type: actionTypes.TRIGGER_MEASUREMENT_PROTOCOL_LIVE,
});

const triggerAddToCart = () => ({
  type: actionTypes.TRIGGER_ADD_TO_CART,
});

export const triggerIJoinAnalytics = () => [
  triggerSitecoreContractId(),
  triggerMeasurementProtocolLive(),
  triggerAddToCart(),
];

export const triggerExpressAnalytics = () => [
  triggerSitecoreContractId(),
  triggerMeasurementProtocolLive(),
];

const triggerPurchase = () => ({
  type: actionTypes.TRIGGER_PURCHASE,
});

const triggerMyDetailsPageView = () => ({
  type: actionTypes.TRIGGER_MY_DETAILS_PAGEVIEW,
});

export const triggerIJoinSuccessAnalytics = () => [
  triggerPurchase(),
  triggerMyDetailsPageView(),
];

export const triggerCustomerInfo = () => ({
  type: actionTypes.TRIGGER_CUSTOMER_INFO,
});

export const triggerPropertyInfo = () => ({
  type: actionTypes.TRIGGER_PROPERTY_INFO,
});

export const triggerSuccessLogin = () => ({
  type: actionTypes.TRIGGER_SUCCESS_LOGIN,
});

export const triggerPurchaseExpress = () => ({
  type: actionTypes.TRIGGER_PURCHASE_EXPRESS,
});
