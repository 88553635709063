import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getPrice,
  PricePath,
  PriceRequest,
  PriceVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const usePrice = (
  version: PriceVersion,
  request?: PriceRequest,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [PricePath[version], request],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getPrice(version, request!, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: !!request,
    }
  );
