import { initialState } from "../../Reducers/CSRAgent/JoinRedux";
import { useSelector, shallowEqual } from "react-redux";

interface SaveForLaterItem {
  firstName?: string;
  lastName?: string;
  serviceAddress?: string;
  emailAddress?: string;
  services?: string;
}

interface Join {
  error: unknown;
  isSubmitting: boolean;
  success: boolean;
  successResponse?: SaveForLaterItem[];
  successMessage?: string;
  // You wanna get fancy?
  state?: ReturnType<typeof JSON.parse>;
}

export function getJoin(state): Join {
  return state.Join || initialState;
}

export function useJoinSelector() {
  return useSelector(getJoin, shallowEqual);
}
