export enum AppNotificationCategory {
  Bill = 'bill',
}

export enum AppNotificationSubCategory {
  DuePayment = 'due payment',
}

export enum AppNotificationStatus {
  Read = 'read',
  Unread = 'unread',
}

export interface AppNotification {
  notification_id: string;
  category: AppNotificationCategory;
  sub_category: AppNotificationSubCategory;
  notification_details: {
    /** This is used as the title for both in app notification and push notification. */
    title: string;
    /** Not used at the moment. */
    subtitle?: string;
    /** This is used as the body for push notification only. */
    body?: string;
    /** This is displayed as the body in in-app notifications under the title. */
    detailed: string;
    [key: string]: unknown;
  };
  bp: string;
  ba: string;
  data?: Record<string, unknown>;
  status: AppNotificationStatus;
  sent_on?: number;
}

export type GetNotificationsApiResponse = AppNotification[];

export type UpdateNotificationApiResponse = void;
