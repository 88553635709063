export const ServiceModalConstants = {
  buttonTextPrimary: "Cancel",
  buttonTextSecondary: "Use these details",
  addressPlaceHolder: "Enter your address",
  addressInvalidMessage: "Address is invalid",
  StreetNumber: "Street number",
  StreetName: "Street name",
  SuburbTown: "Suburb or town",
  Region: "Region",
  errorMessage: "Something went wrong. Please try again.",
};

export const ServiceModalPipedGasRegions = [
  "Auckland",
  "Bay of Plenty",
  "Gisborne",
  "Hawkes Bay",
  "Manawatu",
  "Northland",
  "Taranak",
  "Waikato",
  "Wanganui",
  "Wellington",
];
