import { baseInstance } from '../base-service';
import { AxiosRequestConfig } from 'axios';
import { UnregisterFromPushNotificationsApiResponse } from '../models/push-notifications';
import {
  PushNotificationUnregisterPath,
  PushNotificationUnregisterVersion,
} from '../apiPaths';

export interface PushNotificationUnregisterParams {
  /** Push notification device token */
  deviceToken: string;
  config?: AxiosRequestConfig;
}

export const unregisterFromPushNotifications = (
  version: PushNotificationUnregisterVersion,
  { deviceToken, config }: PushNotificationUnregisterParams
) => {
  return baseInstance(config).post<UnregisterFromPushNotificationsApiResponse>(
    PushNotificationUnregisterPath[version],
    {
      device_token: deviceToken,
    }
  );
};
