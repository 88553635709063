import React, { useState, Fragment, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  BankAccountInput,
  Button,
  Checkbox,
  ExpandableCard,
  Input,
  RadioButtonList,
  HTML,
  ExpansionPanel,
} from "react-components";

import "./Payment.scss";

import { Payment as Constants } from "../../Config/Constants";
import { useStateFromProp } from "../../Utils/hooks";
import {
  getIsFuelRewards,
  getIsElectricityOnly,
  getIsBillingCycleEligible,
  getIsPrepayEligible,
} from "../../Utils/selectors";
import { savePaymentData } from "../../Redux/Actions/iJoin/PaymentActions";
import {
  isReceiveBill,
  isPrepay,
  paymentMethodLabel,
  isBillingMonthly,
  isBillingFortnightly,
  billingCycleLabel,
  availablePaymentMethods,
  generateBillDates,
} from "./utils";
import VerificationFailedModal from "./VerificationFailedModal";

export const Payment = (props) => {
  const billDates = generateBillDates(props.today);

  const [PaymentMethod, setPaymentMethod] = useStateFromProp(
    props.PaymentMethod,
    Constants.paymentMethodDefault
  );
  const [BillingCycle, setBillingCycle] = useStateFromProp(
    props.BillingCycle,
    Constants.billingCycleDefault
  );
  const [BillStartDate, setBillStartDate] = useStateFromProp(
    props.BillStartDate,
    billDates[1]
  );

  const [JoinDirectDebit, setJoinDirectDebit] = useStateFromProp(
    props.JoinDirectDebit,
    Constants.joinDirectDebitDefault
  );

  const [BankAccountNumber, setBankAccountNumber] = useStateFromProp(
    props.BankAccountNumber,
    ""
  );
  const [bankAccountHasError, setBankAccountHasError] = useState(false);
  const [bankAccountNumberVerified, setBankAccountNumberVerified] = useState(
    false
  );
  const origin = props.origin;

  const [
    bankAccountNumberVerificationFailed,
    setBandAccountNumberVerificationFailed,
  ] = useState(false);
  const [
    AgreeWithTermsAndConditions,
    setAgreeWithTermsAndConditions,
  ] = useStateFromProp(
    origin && origin.toUpperCase() === "CUSTOMER"
      ? false
      : props.AgreeWithTermsAndConditions,
    false
  );

  const [PrePayMobileNumber, setPrePayMobileNumber] = useStateFromProp(
    props.PrePayMobileNumber,
    props.mobileNumberFromAboutYou
  );
  const [prePayMobileNumberHasError, setPrePayMobileNumberHasError] = useState(
    false
  );
  const [prePayMobileNumberAgree, setPrePayMobileNumberAgree] = useState(false);
  const [validateItself, setValidateItself] = useState(false);

  useEffect(() => {
    if (origin && origin.toUpperCase() === "CUSTOMER") {
      setAgreeWithTermsAndConditions(false);
    }
  }, [origin, setAgreeWithTermsAndConditions]);

  const isDisable =
    (isReceiveBill(PaymentMethod) &&
      JoinDirectDebit &&
      (bankAccountHasError ||
        !BankAccountNumber ||
        !bankAccountNumberVerified ||
        !AgreeWithTermsAndConditions)) ||
    (props.isElectricityOnly &&
      props.isPrepayEligible &&
      isPrepay(PaymentMethod) &&
      (!prePayMobileNumberAgree ||
        !PrePayMobileNumber ||
        prePayMobileNumberHasError));
  const clickedHandler = () => {
    props.saveCustomerData({
      PaymentMethod,
      PrePayMobileNumber: isPrepay(PaymentMethod) ? PrePayMobileNumber : null,
      BillingCycle:
        props.isElectricityOnly && BillingCycle
          ? BillingCycle
          : Constants.billingCycleDefault,
      BillStartDate:
        props.isElectricityOnly && isBillingFortnightly(BillingCycle)
          ? BillStartDate
          : null,
      PaperlessDiscount: isPrepay(PaymentMethod) ? false : true,
      JoinDirectDebit: !isPrepay(PaymentMethod) && JoinDirectDebit,
      BankAccountNumber,
      AgreeWithTermsAndConditions,
    });
    props.onClick();
  };

  const cardIntroMessage = props.isPrepayEligible
    ? Constants.cardIntroWithPrepay
    : Constants.cardIntro;

  const onHandleAccountNumberVerificationError = useCallback(
    () => setBandAccountNumberVerificationFailed(true),
    [setBandAccountNumberVerificationFailed]
  );

  const getContent = (
    <div className="payment">
      {props.isElectricityOnly &&
        (props.isBillingCycleEligible || props.isPrepayEligible) && (
          <div className="field">
            <RadioButtonList
              name="paymentVariant"
              title={cardIntroMessage}
              items={availablePaymentMethods(
                props.isBillingCycleEligible,
                props.isPrepayEligible
              )}
              value={PaymentMethod}
              onChange={setPaymentMethod}
            />
          </div>
        )}
      <ExpansionPanel isExpanded={isReceiveBill(PaymentMethod)}>
        {props.isElectricityOnly && props.isBillingCycleEligible && (
          <ExpansionPanel isExpanded={JoinDirectDebit}>
            <div className="row subItem">
              <div className="field">
                <RadioButtonList
                  name="paymentPeriod"
                  title={Constants.billingCycle}
                  description={Constants.billingCycleText}
                  items={Constants.billingCycleOptions}
                  value={BillingCycle}
                  onChange={setBillingCycle}
                />
              </div>
            </div>

            <ExpansionPanel isExpanded={isBillingFortnightly(BillingCycle)}>
              <div className="payment_subSection payment_billingCycle">
                <div className="row subItem">
                  <div className="field">
                    <RadioButtonList
                      name="paymentStartDate"
                      title={Constants.billStartDate}
                      items={billDates}
                      value={BillStartDate}
                      onChange={setBillStartDate}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanel>

            {!isBillingMonthly(BillingCycle) && (
              <div className="payment_subSection">
                <div className="row subItem">
                  <div className="payment_disclaimer payment_stand-alone">
                    <HTML html={Constants.billingCycleDisclaimer} />
                  </div>
                </div>
              </div>
            )}
          </ExpansionPanel>
        )}

        <div className="row payment_disclaimer payment_stand-alone">
          {Constants.sectionDisclaimer}
        </div>

        <div className="payment_checkbox direct-debit">
          <Checkbox
            name="directDebit"
            className="dd-check-checkbox"
            label={Constants.joinDirectDebit}
            checked={JoinDirectDebit}
            onChange={() => setJoinDirectDebit(!JoinDirectDebit)}
          />
          <div className="subItem payment_disclaimer">
            <HTML html={Constants.joinDirectDebitText} />
          </div>

          {props.isElectricityOnly &&
            props.isBillingCycleEligible &&
            !JoinDirectDebit && (
              <div className="subItem payment_disclaimer">
                <div className="payment_disclaimer-alert">
                  {Constants.noJoinDirectDebitMessage}
                </div>
              </div>
            )}

          <ExpansionPanel isExpanded={JoinDirectDebit}>
            <div className="row subItem">
              <div className="payment_bankAccount">
                <div className="field">
                  <BankAccountInput
                    api={{
                      baseUrl: process.env.NX_BASE_URL,
                      key: process.env.NX_X_API_KEY,
                    }}
                    name="bankAccount"
                    handleChange={setBankAccountNumber}
                    handleVerified={setBankAccountNumberVerified}
                    handleVerificationError={
                      onHandleAccountNumberVerificationError
                    }
                    value={BankAccountNumber}
                    isVerified={bankAccountNumberVerified}
                    labelText={Constants.bankAccountNumber}
                    handleError={setBankAccountHasError}
                    errorMessage={Constants.bankAccountNumberErrorMessage}
                    invalidMessage={Constants.bankAccountNumberInvalidMessage}
                    hasError={bankAccountHasError}
                    required
                    validateItself={validateItself}
                  />
                </div>
              </div>
            </div>

            <div className="row subItem">
              <div className="payment_checkbox bank-account">
                <Checkbox
                  name="bankAccountCheckbox"
                  className="bankAccount-check-checkbox"
                  label={<HTML html={Constants.agreeWithTermsAndConditions} />}
                  checked={AgreeWithTermsAndConditions}
                  onChange={() =>
                    setAgreeWithTermsAndConditions(!AgreeWithTermsAndConditions)
                  }
                  required={JoinDirectDebit}
                  validateItself={validateItself}
                />
              </div>
            </div>
          </ExpansionPanel>
        </div>
      </ExpansionPanel>

      <ExpansionPanel
        isExpanded={
          props.isElectricityOnly &&
          props.isPrepayEligible &&
          isPrepay(PaymentMethod)
        }
      >
        <div className="row subItem">
          <div className="payment_disclaimer payment_stand-alone">
            {Constants.prePayMobileNumberDisclaimer}
          </div>
        </div>

        <div className="row subItem">
          <div className="payment_mobile">
            <div className="field">
              <Input
                name="mobileNumber"
                handleChange={(value) => setPrePayMobileNumber(value)}
                value={PrePayMobileNumber}
                labelText={Constants.prePayMobileNumber}
                maxLength={Constants.prePayMobileNumberMaxLength}
                handleError={setPrePayMobileNumberHasError}
                errorMessage={Constants.prePayMobileNumberErrorMessage}
                invalidMessage={Constants.prePayMobileNumberInvalidMessage}
                hasError={prePayMobileNumberHasError}
                validationType="phoneNumber"
                required
                validateItself={validateItself}
              />
            </div>
          </div>
        </div>

        <div className="row subItem payment_checkbox mobile">
          <Checkbox
            name="mobile"
            className="mobile-check-checkbox"
            label={<HTML html={Constants.prePayMobileNumberAgree} />}
            checked={prePayMobileNumberAgree}
            onChange={() =>
              setPrePayMobileNumberAgree(!prePayMobileNumberAgree)
            }
            required
            validateItself={validateItself}
          />
        </div>
      </ExpansionPanel>

      <div className="commonButton">
        <Button
          name="paymentContinueButton"
          className="continue-button"
          type="button"
          text={Constants.buttonLabel}
          handleClick={clickedHandler}
          primaryOnLight
          disabled={isDisable}
          handleValidationClick={() => setValidateItself(true)}
        />
      </div>
    </div>
  );

  const shouldShowPrepayDetails =
    props.isElectricityOnly &&
    props.isPrepayEligible &&
    isPrepay(PaymentMethod) &&
    PrePayMobileNumber &&
    !prePayMobileNumberHasError;

  const getPreview = (
    <div>
      {isReceiveBill(PaymentMethod) && (
        <Fragment>
          <div className="field">
            {paymentMethodLabel(PaymentMethod) + ": "}
            <span className="bold">{billingCycleLabel(BillingCycle)}</span>
          </div>
          {props.isElectricityOnly &&
            props.isBillingCycleEligible &&
            isBillingFortnightly(BillingCycle) && (
              <div className="field">
                {Constants.billStartDatePreview + ": "}
                <span className="bold">{BillStartDate}</span>
              </div>
            )}
          <div className="field">
            <span className="bold">&#10004; </span>
            {Constants.paperlessDiscountPreview}
          </div>
          {JoinDirectDebit && (
            <div className="field">
              <span className="bold">&#10004; </span>
              {Constants.joinDirectDebitPreview}
            </div>
          )}
          {JoinDirectDebit && BankAccountNumber && (
            <div className="field">
              {Constants.bankAccountNumber + ": "}
              <span className="bold">{BankAccountNumber}</span>
            </div>
          )}
          {JoinDirectDebit && AgreeWithTermsAndConditions && (
            <div className="field">
              <HTML html={Constants.agreeWithTermsAndConditions} />
            </div>
          )}
        </Fragment>
      )}

      {shouldShowPrepayDetails && (
        <>
          <div className="field">
            {paymentMethodLabel(PaymentMethod) + ": "}
            <span className="bold">{PrePayMobileNumber}</span>
          </div>
          {prePayMobileNumberAgree && (
            <div className="field">
              <HTML html={Constants.prePayMobileNumberAgree} />
            </div>
          )}
        </>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <>
      <div id="Payment" className="cardId" />
      <ExpandableCard
        name="paymentCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
      <VerificationFailedModal
        type="bankAccountNumber"
        isActive={bankAccountNumberVerificationFailed}
        onClose={() => {
          setJoinDirectDebit(false);
          setBankAccountNumber("");
          setBandAccountNumberVerificationFailed(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  mobileNumberFromAboutYou: state.Customer.CustomerInfo.PhoneNumber,
  PaymentMethod: state.Payment.PaymentMethod || undefined,
  PrePayMobileNumber: state.Payment.PrePayMobileNumber || undefined,
  BillingCycle: state.Payment.BillingCycle || undefined,
  BillStartDate: state.Payment.BillStartDate || undefined,
  JoinDirectDebit: Boolean(state.Payment.JoinDirectDebit),
  origin: state.origin || undefined,
  BankAccountNumber:
    state.Payment.DirectDebitDetails.BankAccountNumber || undefined,
  AgreeWithTermsAndConditions: Boolean(
    state.Payment.DirectDebitDetails.IsSoleSignatory &&
      state.Payment.DirectDebitDetails.AgreeWithTermsAndConditions
  ),
  isFuelRewards: getIsFuelRewards(state),
  isElectricityOnly: getIsElectricityOnly(state),
  isBillingCycleEligible: getIsBillingCycleEligible(state),
  isPrepayEligible: getIsPrepayEligible(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerData: (values) => {
      const joinDirectDebit = Boolean(values.JoinDirectDebit);
      const payment = {
        PaymentMethod: values.PaymentMethod || null,
        PrePayMobileNumber: values.PrePayMobileNumber || null,
        BillingCycle: values.BillingCycle || null,
        BillStartDate: values.BillStartDate || null,
        PaperlessDiscount: values.PaperlessDiscount,
        JoinDirectDebit: joinDirectDebit,
      };
      payment.DirectDebitDetails = joinDirectDebit
        ? {
            // TODO: BankName
            BankAccountNumber: values.BankAccountNumber,
            IsSoleSignatory: values.AgreeWithTermsAndConditions,
            AgreeWithTermsAndConditions: values.AgreeWithTermsAndConditions,
          }
        : {
            // TODO: BankName
            BankAccountNumber: "",
            IsSoleSignatory: "",
            AgreeWithTermsAndConditions: "",
          };

      dispatch(savePaymentData(payment));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
