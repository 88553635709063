// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Importing this style first resolves ordering issues later down the track 🤦
import "./FindPlan/FindPlan.scss";

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import JoinApp from "./JoinApp";
import AppError from "./AppError";
import NpsApp from "./NpsApp";

import { withErrorBoundary } from "../Utils/enhancers";
import { Loading } from "../react-components";
import { AlertProvider } from "../Utils/Alert";

const CSRJoinJourney = lazy(() => import("./CSRJoinJourney"));
const CSRMoveJourney = lazy(() => import("./CSRMoveJourney"));
const CSRAddJourney = lazy(() => import("./CSRAddJourney"));
const CSRChangeJourney = lazy(() => import("./CSRChangeJourney"));

const App = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path="/residential/my-details/join-success"
              component={JoinApp}
            />
            <Route path="/residential/my-details" component={JoinApp} />
            <Route path="/residential/find-a-plan" component={JoinApp} />
            <Route path="/residential/summary" component={JoinApp} />
            <Route
              path="/residential/agent/move-house"
              component={CSRMoveJourney}
            />
            <Route
              path="/residential/agent/add-property"
              component={CSRAddJourney}
            />
            <Route
              path="/residential/agent/change-plan"
              component={CSRChangeJourney}
            />
            <Route path="/residential/agent" component={CSRJoinJourney} />
            <Route path="/auth" component={CSRJoinJourney} />
            <Route path="/asyncsurvey" component={NpsApp} />
            <Route path="/404-not-found" render={() => "Not Found"} />
            <Route
              path="/"
              component={() => {
                window.location.href =
                  process.env.NX_EXPRESS_BASE_URL + "/404-not-found";
                return null;
              }}
            />
          </Switch>
        </Suspense>
      </AlertProvider>
    </BrowserRouter>
  );
};

export default withErrorBoundary((message) => <AppError message={message} />)(
  App
);
