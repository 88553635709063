import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { updateJourneyChannel } from "../../Actions/CSRAgent/JourneyChannelAction";
import { pair } from "../utils";

export function getJourneyChannel(state) {
  return state.JourneyChannel;
}

export function useJourneyChannelSelector() {
  return useSelector(getJourneyChannel);
}

export function useJourneyChannelSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (JourneyChannel: string) => {
      dispatch(updateJourneyChannel(JourneyChannel));
    },
    [dispatch]
  );
}

export function useJourneyChannel() {
  const value = useJourneyChannelSelector();
  const setter = useJourneyChannelSetter();
  return pair(value, setter);
}

export function useJourneyChannelSet(value: string) {
  const setter = useJourneyChannelSetter();
  useEffect(() => setter(value), [setter, value]);
}
