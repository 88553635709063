import { BILLING_CYCLE_MONTHLY } from "../../../Config/TypedConstants";

export interface SmoothPayPayment {
  TransferMySmoothPay?: boolean;
  SmoothPaySetUp?: boolean;
  BillingCycle?: string;
  JoinDirectDebit?: boolean;
}

export function smoothPayState<P extends SmoothPayPayment>(
  updatedState: P,
  initialState: P
): P {
  const key = updatedState.SmoothPaySetUp
    ? "SmoothPaySetUp"
    : "TransferMySmoothPay";
  if (!updatedState[key]) {
    return updatedState;
  }

  if (updatedState.BillingCycle !== BILLING_CYCLE_MONTHLY) {
    if (initialState.BillingCycle === updatedState.BillingCycle) {
      // CSRT-1571#1.3 The user changed TransferMySmoothPay, we will automatically
      // select monthly
      return smoothPayState(
        {
          ...updatedState,
          BillingCycle: BILLING_CYCLE_MONTHLY,
        },
        initialState
      );
    } else {
      // CSRT-1571#1.2 The user changed their billing cycle
      // We can only use smooth pay with BILLING_CYCLE_MONTHLY
      return {
        ...updatedState,
        [key]: false,
      };
    }
  }
  if (!updatedState.JoinDirectDebit) {
    if (initialState.JoinDirectDebit === updatedState.JoinDirectDebit) {
      // CSRT-1571#1.3 The user changed TransferMySmoothPay, we will automatically
      // select JoinDirectDebit
      return smoothPayState(
        {
          ...updatedState,
          JoinDirectDebit: true,
        },
        initialState
      );
    } else {
      // CSRT-1571#1.4
      // We can only use smooth pay with JoinDirectDebit
      return {
        ...updatedState,
        [key]: false,
      };
    }
  }
  return updatedState;
}
