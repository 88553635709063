import * as actionTypes from "../../Actions/actionTypes";

export const reducerType = "AgentLogin";

export const initialState = {
  isLoggedIn: false,
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_AGENT_TOKEN:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
      };
    default:
      return state;
  }
};

// Selectors
export const getSessionId = (state) => state.Login.token;

export function updateLogin(payload) {
  return {
    type: actionTypes.UPDATE_LOGIN,
    payload,
  };
}
