export const IdentityCheckFixture = {
  // If the licence is valid, but the names or date of birth are not:
  verified: false,
  message: "One or more fields were not valid.",
  invalidFields: ["firstName", "lastName", "middleName"],
  // If the licence is not valid:
  // "verified": false,
  // "message": "One or more fields were not valid.",
  // "invalidFields": [
  //   "driverLicenseNumber",
  //   "driverLicenseVersion"
  // ]

  // If all fields are validated:
  // "verified": true,
  // "message": "All fields were valid."

  // If the date of birth is formatted incorrectly:
  // "message": "Date of birth provided was not valid."
};
