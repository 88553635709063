import { AxiosRequestConfig } from 'axios';
import { SearchAddressPath, SearchAddressVersion } from '..';
import { baseInstance } from './base-service';
import { AddressSearchApiResponse } from './models';

export const searchAddress = (
  version: SearchAddressVersion,
  address: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<AddressSearchApiResponse>(
    SearchAddressPath[version],
    {
      params: {
        Query: address,
      },
    }
  );
};
