export const PAYMENT_METHOD_BILL = "PostPay";
export const PAYMENT_METHOD_PREPAY = "PrePay";

export type PaymentMethod =
  | typeof PAYMENT_METHOD_BILL
  | typeof PAYMENT_METHOD_PREPAY;

export const BILLING_CYCLE_MONTHLY = "Monthly";
export const BILLING_CYCLE_FORTNIGHTLY = "Fortnightly";
export const BILLING_CYCLE_WEEKLY = "Weekly";
export type BillingCycle =
  | typeof BILLING_CYCLE_MONTHLY
  | typeof BILLING_CYCLE_FORTNIGHTLY
  | typeof BILLING_CYCLE_WEEKLY;

export const ELIGIBLE_PERIODIC = "WEEKLY/FORTNIGHTLY BILLING";
export const ELIGIBLE_PREPAY = "CONTACT PREPAY";

export const PAYMENT_CYCLE_MONTHLY = "Monthly";
export const PAYMENT_CYCLE_FORTNIGHTLY = "Fortnightly";
export const PAYMENT_CYCLE_WEEKLY = "Weekly";
export type PaymentCycle =
  | typeof PAYMENT_CYCLE_MONTHLY
  | typeof PAYMENT_CYCLE_FORTNIGHTLY
  | typeof PAYMENT_CYCLE_WEEKLY;

export type SmoothPayFrequency = PaymentCycle;
