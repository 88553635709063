import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";

export const updateBroadband = (broadband) => {
  return {
    type: actionTypes.UPDATE_BROADBAND,
    broadband: broadband,
  };
};

export const saveBroadbandData = (broadband) => {
  return (dispatch, getState) => {
    const state = getState();
    const BroadbandFullName = broadband.BroadbandFullName
      ? broadband.BroadbandFullName
      : state.Readonly.BroadbandFullName;
    const BroadbandId = broadband.BroadbandId
      ? broadband.BroadbandId
      : state.Readonly.BroadbandId;
    const newBroadband = {
      ...broadband,
      BroadbandId: BroadbandId,
      BroadbandFullName: BroadbandFullName,
    };
    dispatch(updateBroadband(newBroadband));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(
        saveApiState({
          BroadbandInfo: {
            ...state.BroadbandInfo,
            ...newBroadband,
          },
        })
      );
    }
  };
};
