import { AxiosRequestConfig } from 'axios';
import { StatePath, StateVersion } from '..';
import { baseInstance } from './base-service';

export const getState = (
  version: StateVersion,
  sessionId: string,
  config?: Partial<AxiosRequestConfig>
) =>
  baseInstance(config).get<Record<string, unknown>>(
    `${StatePath[version]}/${sessionId}`
  );
