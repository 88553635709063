import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  createTrimester,
  CreateTrimesterParams,
  CreateTrimesterResponse,
  FourthTrimesterCreatePath,
  FourthTrimesterCreateVersion,
} from '@contact/data-access';

export const useTrimesterCreate = (
  version: FourthTrimesterCreateVersion,
  token: string,
  enabled: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<CreateTrimesterResponse>(
    [FourthTrimesterCreatePath[version], token, enabled],
    async () => {
      const channel = token ? 'csr' : 'customer';
      const params: CreateTrimesterParams = {
        channel,
      };
      const { data } = await createTrimester(version, token, params, options);
      return data;
    },
    {
      enabled,
    }
  );
};
