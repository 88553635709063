import hash from "object-hash";
import { useMemo } from "react";

export function getHash(data: Record<string, unknown>) {
  return hash(data, {
    unorderedArrays: true,
    unorderedObjects: true,
  });
}

export function useHashed(data: Record<string, unknown>) {
  return useMemo(() => getHash(data), [data]);
}
