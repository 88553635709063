export const BroadbandFixture = {
  BroadbandId: "FIBRE_1000_500",
  BroadbandFullName: "Max Fibre",
  HasBroadbandProvider: true,
  BroadbandProvider: "Vodafone",
  BroadbandProviderOther: null,
  AccountHolderName: "Test",
  AccountNumber: "23423423423",
  ModemDeliveredToSameAddress: false,
  DeliveryAddress: "5 Routeburn Avenue, Aotea, Porirua 5024",
  Moniker: "2-.F.11.1.awy",
  AddPhoneLine: true,
  HomePhoneNumber: "021 456 1234",
  PhoneLineOptions: [
    {
      code: "MOBILE100",
      fullName: "Landline to Mobile call 100 minutes",
    },
    {
      code: "MOBILE200",
      fullName: "Landline to Mobile call 200 minutes",
    },
    {
      code: "INTERNATIONAL",
      fullName:
        "100 hours calling to Top 10 Countries: Australia, UK, USA, Canada, Ireland, South Africa, South Korea, India, Hong Kong and China",
    },
  ],
};
