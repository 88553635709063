export class DisplayError extends Error {
  readonly display = true;
}

export function isDisplayError(
  error: unknown
): error is Error & { display: true } {
  function isDisplayErrorLike(
    error: unknown
  ): error is Error & { display: unknown } {
    return error instanceof Error;
  }
  return isDisplayErrorLike(error) && error.display === true;
}
