import { AxiosRequestConfig } from 'axios';
import { FeatureFlagsPath, FeatureFlagsVersion } from '..';
import { baseInstance } from './base-service';

export const getFeatureFlags = (
  version: FeatureFlagsVersion,
  environment: string,
  userId?: string,
  sessionId?: string,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<Record<string, boolean>>(FeatureFlagsPath[version], {
    params: {
      environment,
      userId,
      sessionId,
    },
  });
