import React from "react";
import Icon from "../Icon";
import { ArrowRight } from "../../Assets/Icons";
import "./ControlArrows.scss";

const ControlArrows = ({ onClick, customStyle = undefined }) => {
  const scrollRight = () => {
    onClick("right", false);
  };

  const scrollLeft = () => {
    onClick("left", false);
  };

  return (
    <div className="controlArrows" style={customStyle}>
      <div className="controlArrows_block" onClick={scrollLeft}>
        <Icon
          icon={<ArrowRight />}
          size={28}
          className="controlArrows_block_icon--left"
        />
      </div>
      <div className="controlArrows_block" onClick={scrollRight}>
        <Icon
          icon={<ArrowRight />}
          size={28}
          className="controlArrows_block_icon--right"
        />
      </div>
    </div>
  );
};

export default ControlArrows;
