/* eslint-disable @typescript-eslint/no-explicit-any */
import Decimal from "decimal.js";
import _uniqBy from "lodash/uniqBy";

export const getUnit = (currencyType, time) => {
  const timeDisplay = time.toLowerCase().replace(/kwh/, "kWh");

  return `${currencyType.toLowerCase()}${timeDisplay}`;
};
export const getDisplayRate = (currencyType, charge) =>
  currencyType.toLowerCase().includes("dollars") ? `$${charge}` : `${charge}c`;

/*
Mapping below from SAP:
Charge -> CurrType1
ChargePpd -> CurrType2
ChargeGst -> CurrType3
ChargePpdGst -> CurrType4
*/
export const withUserType = (rates, UserType, isGSTInclusive = false) =>
  _uniqBy(rates, "PriceKeyDesc").map((rate: any) => ({
    Name: rate.PriceKeyDesc,
    UserType,
    Rate: isGSTInclusive
      ? getDisplayRate(rate.CurrType3, new Decimal(rate.ChargeGst).toDP(3))
      : getDisplayRate(rate.CurrType1, new Decimal(rate.Charge).toDP(3)),
    Unit: getUnit(rate.CurrType1, rate.UomTimeCat),
    GSTInclusive: isGSTInclusive,
    Description: rate.ChargeDescription,
  }));
