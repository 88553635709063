export const PERSIST_SESSION_ID = "PERSIST_SESSION_ID";
export const CLEAR_SESSION_ID = "CLEAR_SESSION_ID";

export const PERSIST_LOCAL_DATA = "PERSIST_LOCAL_DATA";
export const HYDRATE_LOCAL_DATA = "HYDRATE_LOCAL_DATA";

export const REDIRECT = "REDIRECT";

export const TRIGGER_SITECORE_CONTRACT_ID =
  "@ANALYTICS/TRIGGER_SITECORE_CONTRACT_ID";
export const TRIGGER_MEASUREMENT_PROTOCOL_LIVE =
  "@ANALYTICS/TRIGGER_MEASUREMENT_PROTOCOL_LIVE";
export const TRIGGER_MY_DETAILS_JOURNEY =
  "@ANALYTICS/TRIGGER_MY_DETAILS_JOURNEY";
export const TRIGGER_ADD_TO_CART = "@ANALYTICS/TRIGGER_ADD_TO_CART";
export const TRIGGER_PURCHASE = "@ANALYTICS/TRIGGER_PURCHASE";
export const TRIGGER_MY_DETAILS_PAGEVIEW =
  "@ANALYTICS/TRIGGER_MY_DETAILS_PAGEVIEW";
export const TRIGGER_CUSTOMER_INFO = "@ANALYTICS/TRIGGER_CUSTOMER_INFO";
export const TRIGGER_PROPERTY_INFO = "@ANALYTICS/TRIGGER_PROPERTY_INFO";
export const TRIGGER_SUCCESS_LOGIN = "@ANALYTICS/TRIGGER_SUCCESS_LOGIN";
export const TRIGGER_PURCHASE_EXPRESS = "@ANALYTICS/TRIGGER_PURCHASE_EXPRESS";

export const UPDATE_MY_DETAILS = "UPDATE_MY_DETAILS";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_PROP = "UPDATE_PROP";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_DL = "UPDATE_CUSTOMER_DL";
export const RESET_CUSTOMER_DL = "RESET_CUSTOMER_DL";
export const REHYDRATED_CUSTOMER = "REHYDRATED_CUSTOMER";
export const UPDATE_BOTTLED_GAS = "UPDATE_BOTTLED_GAS";
export const UPDATE_PIPED_GAS = "UPDATE_PIPED_GAS";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_CONFIRMATION = "UPDATE_CONFIRMATION";
export const UPDATE_BROADBAND = "UPDATE_BROADBAND";
export const UPDATE_READONLY = "UPDATE_READONLY";
export const UPDATE_PLAN = "UPDATE_PLAN";

export const CALL_API = "CALL_API";
export const API_ERROR = "API_ERROR";
export const API_SUCCESS = "API_SUCCESS";
export const API_SUBMIT_SUCCESS = "API_SUBMIT_SUCCESS";
export const API_STATE_PENDING_SAVE = "API_STATE_PENDING_SAVE";

export const UPDATE_USER_PROFILE = " UPDATE_USER_PROFILE";

export const UPDATE_CREDIT_CHECK_RESULT = "UPDATE_CREDIT_CHECK_RESULT";
export const UPDATE_CREDIT_CHECK_FETCHING = "UPDATE_CREDIT_CHECK_FETCHING";
export const UPDATE_CREDIT_CHECK_ERROR = "UPDATE_CREDIT_CHECK_ERROR";
export const SAVE_CREDIT_CHECK_RESULT = "SAVE_CREDIT_CHECK_RESULT";

export const UPDATE_BP_FETCHING = "UPDATE_BP_FETCHING";

export const UPDATE_BP_FETCHED = "UPDATE_BP_FETCHED";

export const UPDATE_BP_ERROR = "UPDATE_BP_ERROR";

export const BP_RESET = "BP_RESET";

export const UPDATE_JOURNEY_TYPE = "UPDATE_JOURNEY_TYPE";
export const UPDATE_JOURNEY_CHANNEL = "UPDATE_JOURNEY_CHANNEL";

export const UPDATE_SUBMITTING_JOURNEY = "UPDATE_SUBMITTING_JOURNEY";

export const UPDATE_AGENT_TOKEN = "UPDATE_AGENT_TOKEN";
export const UPDATE_LOGIN = "UPDATE_LOGIN";

export const UPDATE_JOINT_ACCOUNT_HOLDER = "UPDATE_JOINT_ACCOUNT_HOLDER";

export const UPDATE_UNUSED_JOINT_ACCOUNT_HOLDER =
  "UPDATE_UNUSED_JOINT_ACCOUNT_HOLDER";

export const UPDATE_AUTHORISED_PERSON = "UPDATE_AUTHORISED_PERSON";

export const UPDATE_UNUSED_AUTHORISED_PERSON =
  "UPDATE_UNUSED_AUTHORISED_PERSON";

export const PRICE_FETCHING = "PRICE_FETCHING";

export const GAS_PRICE_FETCHING = "GAS_PRICE_FETCHING";

export const SAVE_FOR_LATER_SUCCESS = "SAVE_FOR_LATER_SUCCESS";

export const SAVE_FOR_LATER_SUBMITTING = "SAVE_FOR_LATER_SUBMITTING";

export const SAVE_FOR_LATER_ERROR = "SAVE_FOR_LATER_ERROR";

export const SAVE_FOR_LATER_INITIAL_STATE = "SAVE_FOR_LATER_INITIAL_STATE";

export const UPDATE_PROPERTY_SITUATION = "UPDATE_PROPERTY_SITUATION";

export const GET_ACCOUNTS_FETCHED = "GET_ACCOUNTS_FETCHED";

export const GET_ACCOUNT_ACCESS_FETCHED = "GET_ACCOUNT_ACCESS_FETCHED";

export const GET_CUSTOMER_FETCHED = "GET_CUSTOMER_FETCHED";

export const GET_METERS_FETCHED = "GET_METERS_FETCHED";

export const GET_CURRENT_PAYMENT_TYPE_FETCHED =
  "GET_CURRENT_PAYMENT_TYPE_FETCHED";

export const GET_CURRENT_PAYMENT_METHODS_FETCHED =
  "GET_CURRENT_PAYMENT_METHODS_FETCHED";

export const GET_SMOOTH_PAY_ELIGIBILITY_FETCHED =
  "GET_SMOOTH_PAY_ELIGIBILITY_FETCHED";

export const GET_PANEL_PLANS_FETCHED = "GET_PANEL_PLANS_FETCHED";

export const UPDATE_OLS = "UPDATE_OLS";
export const SET_BILL_COMPARE_OUTPUT = "SET_BILL_COMPARE_OUTPUT";

export const UPDATE_ELEC_RATES = "UPDATE_ELEC_RATES";
export const UPDATE_GAS_RATES = "UPDATE_GAS_RATES";
export const UPDATE_BB_RATES = "UPDATE_BB_RATES";

export const UPDATE_METER_ACCESS = "UPDATE_METER_ACCESS";

export const GET_BILLING_ELIGIBILITY_FETCHED =
  "GET_BILLING_ELIGIBILITY_FETCHED";
export const GET_BILLING_ELIGIBILITY_FETCHING =
  "GET_BILLING_ELIGIBILITY_FETCHING";
