import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { InteractionRecordsApiResponse } from './models';
import {
  GetInteractionRecordsPath,
  GetInteractionRecordsVersion,
} from './apiPaths';

/**
 * https://developer.contact-digital-devops.net/apis/b13375d44cd244f3e19ba5d8a78c62337c49fe97
 * Returns the list of interaction records found.
 * @param {string} token  A session token from SAP
 * @param {string} customerId The business partner id for the customer.
 * @param {string} accountId The business agreement id for the customer.
 * @param {string} interactionDateTime The earliest date to get interaction records from.
 * @param {number} interactionCount  The number of records to fetch.
 * @param {AxiosRequestConfig} [config]
 * @returns the list of interaction records found
 */
export const getInteractionRecords = (
  version: GetInteractionRecordsVersion,
  token: string,
  customerId: string,
  accountId: string,
  interactionDateTime: string,
  interactionCount: number,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<InteractionRecordsApiResponse>(
    GetInteractionRecordsPath[version],
    {
      params: {
        businessPartner: customerId,
        businessAgreement: accountId,
        interactionDateTime,
        interactionCount,
      },
      headers: {
        session: token,
      },
    }
  );
};
