import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { BroadbandProvidersApiResponse } from './models';
import { BroadbandProvidersPath, BroadbandProvidersVersion } from '..';

export const getBroadbandProviders = (
  version: BroadbandProvidersVersion,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<BroadbandProvidersApiResponse>(
    BroadbandProvidersPath[version]
  );
};
