import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  customer: {},
};

const updateUserProfile = (state, userProfile) => {
  return { ...state, ...userProfile };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PROFILE:
      return updateUserProfile(state, action.userProfile);

    default:
      return state;
  }
};

export default reducer;
