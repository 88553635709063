/**
 * Date on change check the changes date picker input is start
 */
export const DATE_START = "start";

/**
 * Date on change check the changes date picker input is end
 */
export const DATE_END = "end";

/**
 * Date format for use on getting only the year from date.
 * Example: "2015"
 */
export const DATE_YEAR = "YYYY";

/**
 * Date format for use on getting only the month from date.
 * Example: "03"
 */
export const DATE_MONTH = "MM";

/**
 * Date format for use on getting only the day from date.
 * Example: "31"
 */
export const DATE_DAY = "DD";

/**
 * Date format for use on getting year and month
 * Example: "2016-11"
 */
export const DATE_YEAR_MONTH = "YYYY-MM";

/**
 * Date format for use on getting month and date
 * Example: "11-20"
 */
export const DATE_MONTH_AND_DATE = "MM-DD";

/**
 * Date format for use on the database when we only need the date.
 * Example: "2015-06-29"
 */
export const DATABASE_FORMAT = "YYYY-MM-DD";

/**
 * Date format for display to regluar users.
 * Example: "29 Jun 2015"
 */
export const DATE_FORMAT_DISPLAY = "DD MMM YYYY";

/**
 * Date format for display to regluar users only the month and year
 * Example: "Jun 2018"
 */
export const MONTH_FORMAT_DISPLAY = "MMM YYYY";

/**
 * Date format for display to regluar users.
 * Example: "29-10-2018"
 */
export const DATE_FORMAT_DATE_PICKER = "DD-MM-YYYY";

/**
 * Total days in the year
 */
export const TOTAL_DAYS_YEAR = 454;

/**
 * Fifteen Months in days
 */
export const TOTAL_FIFTEEN_MONTHS_DAYS = 454;

/**
 * Total days to calculate
 */
export const TOTAL_DAYS_FOR_YEARS = 365;
