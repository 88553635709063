import { AxiosRequestConfig } from 'axios';
import {
  AasfPath,
  AasfVersion,
  CcRefundSubmitPath,
  CcRefundSubmitVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import { AasfApiResponse, CcRefundApiResponse } from '../models';

export const ccRefund = (
  version: CcRefundSubmitVersion,
  form: any,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).post<CcRefundApiResponse>(
    `${CcRefundSubmitPath[version]}`,
    form
  );
};
