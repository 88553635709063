import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandRange,
  BroadbandUsageApiResponse,
  BroadbandUsageWeeklyDifferenceApiSuccessResponse,
} from './models';
import {
  BroadbandUsagePath,
  BroadbandUsageVersion,
  BroadbandUsageWeeklyDifferencePath,
  BroadbandUsageWeeklyDifferenceVersion,
} from './apiPaths';
import { v4 as uuidv4 } from 'uuid';

// API documentation: https://developer.contact-digital-devops.net/apis/6272bdb261713e740d4526aca4bc49d192e48c5a

export interface BroadbandUsageParams {
  range: BroadbandRange;
  /** format: YYYY-MM-DD */
  start: string;
  /** format: YYYY-MM-DD */
  end?: string;
  ba: string;
  config?: AxiosRequestConfig;
}

export const getBroadbandUsage = (
  version: BroadbandUsageVersion,
  {
    range,
    start,
    end,
    ba,
    config,
    token,
  }: BroadbandUsageParams & { token: string }
) => {
  return baseInstance(config).get<BroadbandUsageApiResponse>(
    BroadbandUsagePath[version],
    {
      params: {
        range,
        start,
        end,
        ba,
      },
      headers: { session: token },
    }
  );
};

export interface GetBroadbandUsageWeeklyDifferenceParams {
  version: BroadbandUsageWeeklyDifferenceVersion;
  token: string;
  ba: string;
}

export const getBroadbandUsageWeeklyDifference = async (
  { version, token, ba }: GetBroadbandUsageWeeklyDifferenceParams,
  config?: AxiosRequestConfig
) => {
  return baseInstance(
    config
  ).get<BroadbandUsageWeeklyDifferenceApiSuccessResponse>(
    BroadbandUsageWeeklyDifferencePath[version],
    {
      params: {
        ba,
      },
      headers: {
        session: token,
        'x-correlation-id': uuidv4(),
      },
    }
  );
};
