import { initialState } from "../../Reducers/CSRAgent/CreditCheckRedux";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { pair } from "../utils";
import {
  updateCreditCheckResult,
  saveCreditCheckResult,
  updateCreditCheckFetching,
  updateCreditCheckError,
} from "../../Actions/CSRAgent/CreditCheckAction";
import { Property as Constants } from "../../../Config/Constants";
import moment from "moment";
import { useServicesSelector } from "./Services";
import {
  usePropertyInfoAddressDetailsSelector,
  usePropertyInfoAddressIsSouthIsland,
  usePropertyInfoElectricityICPSelector,
} from "./Property/PropertyInfo";
import { useLoginTokenSelector } from "./Login";
import { checkCredit } from "../../Actions/ApiCalls";
import { useCustomerInfoBusinessPartnerNumberSelector } from "./Customer";
import { useMedicalInfoHasMedicalDependantSelector } from "./Property/MedicalInfo";
import { CreditCheckData } from "../../../Utils/transformCreditCheck";
import { useElectricityICPDetails } from "./ICP";

interface CreditCheck {
  data?: CreditCheckData;
  isFetching?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

function getCreditCheck(state): CreditCheck {
  return state.CreditCheck || initialState;
}

export function useCreditCheckSelector() {
  return useSelector(getCreditCheck);
}

export function getCreditCheckData(state) {
  return getCreditCheck(state).data;
}

export function useCreditCheckData() {
  return useSelector(getCreditCheckData);
}

export function getCreditCheckResult(state) {
  return getCreditCheckData(state)?.result;
}

export function useCreditCheckResult() {
  return useSelector(getCreditCheckResult);
}

export function useUpdateCreditCheckResult() {
  const dispatch = useDispatch();
  return useCallback(
    (data: CreditCheck["data"]) => dispatch(updateCreditCheckResult(data)),
    [dispatch]
  );
}

export function useSaveCreditCheckResult() {
  const dispatch = useDispatch();
  return useCallback(
    (data: CreditCheck["data"]) => dispatch(saveCreditCheckResult(data)),
    [dispatch]
  );
}

export function getCreditCheckIsFetching(state) {
  return getCreditCheck(state).isFetching;
}

export function useCreditCheckIsFetchingSelector() {
  return useSelector(getCreditCheckIsFetching);
}

export function useCreditCheckIsFetchingSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (isFetching: CreditCheck["isFetching"]) =>
      dispatch(updateCreditCheckFetching(isFetching)),
    [dispatch]
  );
}

export function useCreditCheckIsFetching() {
  const value = useCreditCheckIsFetchingSelector();
  const setter = useCreditCheckIsFetchingSetter();
  return pair(value, setter);
}

export function getCreditCheckIsError(state) {
  return getCreditCheck(state).isError;
}

export function useCreditCheckIsErrorSelector() {
  return useSelector(getCreditCheckIsError);
}

export function getCreditCheckErrorMessage(state) {
  return getCreditCheck(state).errorMessage;
}

export function useCreditCheckErrorMessageSelector() {
  return useSelector(getCreditCheckErrorMessage);
}

export function useCreditCheckErrorMessageSetter() {
  const dispatch = useDispatch();
  return useCallback(
    (errorMessage: CreditCheck["errorMessage"]) =>
      dispatch(updateCreditCheckError(errorMessage)),
    [dispatch]
  );
}

export function useCreditCheckErrorMessage() {
  const value = useCreditCheckErrorMessageSelector();
  const setter = useCreditCheckErrorMessageSetter();
  return pair(value, setter);
}

export function useCreditCheckDataBondSetter(
  allowedResult,
  checkedBond,
  defaultBond
) {
  const dispatch = useDispatch();
  const result = useCreditCheckData();
  return useCallback(
    (bond) => {
      if (result?.Result === allowedResult) {
        dispatch(
          saveCreditCheckResult({
            ...result,
            Bond: bond ? checkedBond : defaultBond,
          })
        );
      }
    },
    [result, dispatch, allowedResult, checkedBond, defaultBond]
  );
}

export function useCreditCheckDataBond(
  allowedResult,
  checkedBond,
  defaultBond
) {
  const result = useCreditCheckData();
  const setter = useCreditCheckDataBondSetter(
    allowedResult,
    checkedBond,
    defaultBond
  );
  return pair(
    allowedResult === result?.Result && result?.Bond === checkedBond,
    setter
  );
}

export function useCreditCheckStart(givenSupplier?: string) {
  const setIsFetching = useCreditCheckIsFetchingSetter();
  const dispatch = useDispatch();
  const services = useServicesSelector();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  const addressDetails = usePropertyInfoAddressDetailsSelector();
  const token = useLoginTokenSelector();
  const businessPartnerNumber = useCustomerInfoBusinessPartnerNumberSelector();
  const hasMedicalDependant = useMedicalInfoHasMedicalDependantSelector();
  const updateCreditCheckError = useCreditCheckErrorMessageSetter();
  const icp = usePropertyInfoElectricityICPSelector();
  const { data: icpDetails } = useElectricityICPDetails(icp);
  const supplier = givenSupplier || icpDetails?.trader || undefined;
  return useCallback(() => {
    if (!(token && addressDetails)) {
      updateCreditCheckError("dataError");
      return false;
    }
    let service = "";
    if (services.includes("ELEC") && services.length >= 1) {
      service = Constants.ccServiceElec;
    } else if (
      (services.includes("LPG") || services.includes("GAS")) &&
      services.length === 1
    ) {
      service = isSouthIsland
        ? Constants.ccServiceGasSI
        : Constants.ccServiceGasNI;
    }
    setIsFetching(true);
    dispatch(
      checkCredit(
        {
          address: {
            number: addressDetails.number,
            street: addressDetails.street,
            suburb: addressDetails.suburb,
            postCode: addressDetails.postcode,
            city: addressDetails.city,
            country: "NZ",
          },
          service: service,
          businessPartner: businessPartnerNumber,
          isSuppliedByContact: supplier === "CTCT", // CTCT = Contact, hence if we have this value, we are the supplier
          hasMedicalDependant: hasMedicalDependant,
          contractStartDate: moment(new Date()).format("YYYY/MM/DD"),
        },
        token
      )
    );
    return true;
  }, [
    updateCreditCheckError,
    addressDetails,
    businessPartnerNumber,
    dispatch,
    hasMedicalDependant,
    isSouthIsland,
    services,
    supplier,
    token,
    setIsFetching,
  ]);
}
