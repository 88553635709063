import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import RadioButton from "../RadioButton";
import "./RadioButtonList.scss";

const RadioButtonList = ({
  name,
  title = undefined,
  description = undefined,
  items,
  stackOptions = false,
  disabledItems = [],
  onKeyDown = undefined,
  value = undefined,
  onChange,
  validateItself = undefined,
  required = undefined,
  handleError = undefined,
  inputProps = undefined,
}) => {
  const hasValue = useMemo(() => !!value || value === false, [value]);

  useEffect(() => {
    if (handleError) {
      handleError(validateItself && required && !hasValue);
    }
  }, [handleError, required, validateItself, hasValue]);

  return (
    <div className="radioButtonList" ref={inputProps && inputProps.ref}>
      {title && (
        <div
          id={name ? name + "Title" : null}
          className="radioButtonList_title"
        >
          {title}
        </div>
      )}
      {description && (
        <div
          id={name ? name + "Description" : null}
          className="radioButtonList_description"
        >
          {description}
        </div>
      )}
      <div
        className={classNames("radioButtonList_container", {
          radioButtonList_stackOptions: stackOptions,
        })}
      >
        {items.map((item, index) => {
          const itemValue = typeof item === "object" ? item.value : item;
          const label = typeof item === "object" ? item.label : undefined;
          const description =
            typeof item === "object" ? item.description : undefined;
          const tooltip = typeof item === "object" ? item.tooltip : undefined;
          return (
            <RadioButton
              key={index}
              name={`${name}-${itemValue}-${index}`}
              checked={value === itemValue}
              value={itemValue}
              disabled={
                disabledItems?.length && disabledItems.indexOf(itemValue) > -1
                  ? true
                  : false
              }
              label={label}
              tooltip={tooltip}
              description={description}
              onChange={onChange}
              required={required && !hasValue}
              validateItself={validateItself}
              onKeyDown={onKeyDown}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtonList;
