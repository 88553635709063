import { AxiosRequestConfig } from 'axios';
import {
  ProductChangeEligibilityPath,
  ProductChangeEligibilityVersion,
} from './apiPaths';
import { baseInstance } from './base-service';
import { ProductChangeEligibilityApiResponse } from './models';

export const productChangeEligibility = (
  version: ProductChangeEligibilityVersion,
  session: string,
  businessAgreement: string,
  targetCampaignId?: string,
  icp?: string,
  contractId?: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<ProductChangeEligibilityApiResponse>(
    ProductChangeEligibilityPath[version],
    {
      headers: {
        session,
      },
      params: {
        businessAgreement,
        targetCampaignId,
        icp,
        contractId,
      },
    }
  );
};
