export const MedicalFixture = {
  HasMedicalDependant: true,
  CriticalMedicalEquipment: "test medical equipment",
  MedicalDependantFirstName: "Test First Name",
  MedicalDependantLastName: "Test Last Name",
  MedicalDependantEMailAddress: "TestEmailAddress@test.com",
  MedicalDependantPhone: "022222222",
  MedicalDependantSameAsAccountHolder: false,
  ContactHealthPractitioner: true,
  PracticeName: "Test Practice Name",
  PracticePhone: "0211015995",
  PractitionerName: "Test Practitioner Name",
  HasVulnerablePerson: true,
};
