import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterEmailsNewSendEmailPath,
  FourthTrimesterEmailsNewSendEmailVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import {
  SendEmailTrimesterParams,
  SendEmailTrimesterResponse,
} from '../models';

export const sendEmailTrimester = (
  version: FourthTrimesterEmailsNewSendEmailVersion,
  requestId: string,
  token: string,
  params?: SendEmailTrimesterParams,
  config?: AxiosRequestConfig
) => {
  const url = `${FourthTrimesterEmailsNewSendEmailPath[version]}/${requestId}`;
  return baseInstance(config).post<SendEmailTrimesterResponse>(url, params, {
    headers: {
      session: token,
    },
  });
};
