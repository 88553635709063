import { baseInstance } from '../base-service';
import { AxiosRequestConfig } from 'axios';
import { GetNotificationsApiResponse } from '../models/notifications';
import { GetNotificationsPath, GetNotificationsVersion } from '../apiPaths';

export interface GetNotificationsParams {
  config?: AxiosRequestConfig;
}

export const getNotifications = (
  version: GetNotificationsVersion,
  { token, config }: GetNotificationsParams & { token: string }
) => {
  return baseInstance(config).get<GetNotificationsApiResponse>(
    GetNotificationsPath[version],
    {
      headers: { session: token },
    }
  );
};
