import "./Alerts.scss";
import React, { useCallback } from "react";
import { useAlerts, Alert } from "../../../Utils/Alert";
import InfoMessage from "../InfoMessage";
import ClassNames from "classnames";
import HTML from "../HTML";

interface AlertMessageProps {
  alert: Alert;
}

function AlertMessage({ alert }: AlertMessageProps) {
  const [, , removeAlert] = useAlerts();
  const remove = useCallback(() => {
    removeAlert(alert);
  }, [alert, removeAlert]);
  return (
    <div
      ref={alert.ref}
      className={ClassNames("alertMessage", { isWarning: alert.isWarning })}
    >
      <InfoMessage
        error={alert.isWarning}
        onClose={alert.disableClose ? undefined : remove}
        text={alert.html ? <HTML html={alert.text} /> : alert.text}
        header={alert.header}
      />
    </div>
  );
}

export interface AlertsProps {
  section?: unknown;
}

export function Alerts({ section }: AlertsProps) {
  const [alerts] = useAlerts(section);
  return (
    <>
      {alerts.map((alert, index) => (
        <AlertMessage key={index} alert={alert} />
      ))}
    </>
  );
}
