export const ConfirmationFixture = {
  CreditCheck: true,
  AcceptGeneralTermsAndConditions: true,
  ReceiveNewsAndOffers: true,
  AcceptPlanTermsAndConditions: true,
  AcceptPricingTermsAndConditions: false,
  AcceptBroadbandTermsAndConditions: true,
  AcceptBottledGasTermsAndConditions: true,
  JourneyType: "Join",
};
