import * as actionTypes from "../Actions/actionTypes";

export const initialState = {
  sessionID: "",
  deleteAfter: 0,
  hasError: false,
  isCallingAPI: false,
  submitted: false,
  redirectFromExpress: false,
  isPendingAPI: false,
};

const updateAuth = (state, auth) => {
  return { ...state, ...auth };
};

const callAPI = (state) => {
  return { ...state, isPendingAPI: false, isCallingAPI: true, hasError: false };
};

const pendingAPISave = (state) => {
  return { ...state, isPendingAPI: true };
};

const setError = (state) => {
  return { ...state, isPendingAPI: false, isCallingAPI: false, hasError: true };
};

const setSuccess = (state) => {
  return { ...state, isCallingAPI: false, hasError: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTH:
      return updateAuth(state, action.auth);

    case actionTypes.CALL_API:
      return callAPI(state);

    case actionTypes.API_STATE_PENDING_SAVE:
      return pendingAPISave(state);

    case actionTypes.API_ERROR:
      return setError(state);

    case actionTypes.API_SUCCESS:
      return setSuccess(state);

    case actionTypes.API_SUBMIT_SUCCESS:
      return { ...setSuccess(state), submitted: true };

    default:
      return state;
  }
};

export default reducer;
