import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { PanelPlansApiResponse } from './models';
import { GetPanelPlansPath, GetPanelPlansVersion } from './apiPaths';

export const getPanelPlans = (
  version: GetPanelPlansVersion,
  token: string,
  accountId: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<PanelPlansApiResponse>(
    GetPanelPlansPath[version],
    {
      params: {
        ba: accountId,
      },
      headers: {
        session: token,
      },
    }
  );
};
