import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  productChangeEligibility,
  ProductChangeEligibilityApiResponse,
  ProductChangeEligibilityVersion,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useProductChangeEligibility = (
  version: ProductChangeEligibilityVersion,
  session: string,
  businessAgreement: string,
  targetCampaignId?: string,
  icp?: string,
  contractId?: string,
  queryOptions?: UseQueryOptions<ProductChangeEligibilityApiResponse>,
  axiosOptions?: Partial<AxiosRequestConfig>
) =>
  useQuery<ProductChangeEligibilityApiResponse>(
    [
      'product-change-eligibility',
      session,
      businessAgreement,
      targetCampaignId,
      icp,
      contractId,
    ],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await productChangeEligibility(
          version,
          session,
          businessAgreement,
          targetCampaignId,
          icp,
          contractId,
          {
            ...axiosOptions,
            ...extraOptions,
          }
        );
        return data;
      }),
    {
      ...queryOptions,
      enabled: !!(session && businessAgreement),
    }
  );
