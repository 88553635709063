import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import {
  UpdateNotificationParams,
  updateNotification,
  UpdateNotificationVersion,
} from '@contact/data-access';
import { createNotificationsHookKeys } from './useNotifications';

export interface UpdateNotificationHookParams extends UpdateNotificationParams {
  token: string;
}

/**
 * Returns a mutation hook to update notifications.
 *
 * The mutation will automatically invalidate the fetch query on error.
 */
export function useUpdateNotification(
  version: UpdateNotificationVersion,
  options?: Omit<
    UseMutationOptions<void, unknown, UpdateNotificationHookParams>,
    'mutationFn'
  >
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateNotificationHookParams) => {
      await updateNotification(version, params);
    },
    {
      ...options,
      onError: (error, params, context) => {
        queryClient.invalidateQueries(
          createNotificationsHookKeys(version, params.token)
        );
        options?.onError?.(error, params, context);
      },
    }
  );
}
