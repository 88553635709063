import React from "react";
import { isDisplayError } from "./DisplayError";

export const withErrorBoundary = (renderError) => {
  class ErrorBoundary extends React.Component {
    state = {
      hasError: false,
      message: undefined,
    };

    static getDerivedStateFromError(error) {
      return {
        hasError: true,
        message: isDisplayError(error) ? error.message : undefined,
      };
    }

    componentDidCatch(error, info) {
      if (process.env.NODE_ENV === "development") {
        console.log(error, info);
      }
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return renderError(this.state.message);
      }
      return this.props.children;
    }
  }

  return (Component) => (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};
