export const ElectricityLookupFixture = {
  icp: [
    {
      icp: "0125966024lcb7c",
      number: "49",
      street: "mount roskill road",
      suburb: "mount roskill",
      town: "auckland",
      postcode: "1041",
    },
    {
      icp: "0062616894lc41c",
      unit: "b",
      number: "24",
      street: "mount roskill road",
      suburb: "mount roskill",
      town: "auckland",
      postcode: "1041",
    },
    {
      icp: "1001287404lc092",
      number: "49 a",
      street: "mount roskill road",
      suburb: "mount roskill",
      town: "auckland",
      postcode: "1041",
    },
  ],
};
