import React from "react";
import { HTML, Modal } from "react-components";
import { AppError as Constants } from "../Config/Constants";

const AppError = ({ message = undefined }) => (
  <Modal
    isActive={true}
    buttonTextPrimary={Constants.buttonLabel}
    handlePrimaryClick={() => {
      window.location.reload();
    }}
    content={message || <HTML html={Constants.message} />}
  />
);

export default AppError;
