import * as actionTypes from "../actionTypes";
import {
  saveApiState,
  createApiState,
  getApiState,
  saveCustomerInfoSaveForLater,
  isPendingSaveApiState,
} from "../ApiCalls";
import { updateServices } from "../iJoin/ServicesActions";
import { updateProperty } from "../iJoin/PropertyActions";
import { updateConfirmation } from "../iJoin/ConfirmationActions";
import { updateCustomer } from "../iJoin/CustomerActions";
import { updatePipedGas } from "../iJoin/GasActions";
import { updateBottledGas } from "../iJoin/BottledGasActions";
import { updatePayment } from "../iJoin/PaymentActions";
import { updateBroadband } from "../iJoin/BroadbandActions";
import { updateJourneyType } from "../CSRAgent/JourneyTypeAction";
import { updateJourneyChannel } from "../CSRAgent/JourneyChannelAction";
import { updateJointAccountHolder } from "../CSRAgent/JointAccountAction";
import { updateAuthorizedPerson } from "./AuthorizedPersonAction";

export const updatePlan = (plan) => {
  return {
    type: actionTypes.UPDATE_PLAN,
    plan: plan,
  };
};

export const createState = () => {
  return (dispatch) => {
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(createApiState({}));
    }
  };
};

export const getState = () => {
  return (dispatch, getState) => {
    if (process.env.NX_USE_FIXTURES) {
      return;
    }
    const state = getState();
    if (state.Customer?.isRehydrated) {
      return; // Already good to go
    }
    dispatch(getApiState());
  };
};

export const updateAuth = (auth) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: auth,
  };
};

export const saveAuthData = (auth) => {
  return (dispatch, getState) => {
    const state = getState();
    const authData = {
      ...state.Auth,
      ...auth,
    };
    dispatch(updateAuth(authData));
  };
};

export const updateBP = (apiData) => {
  return (dispatch) => {
    const customer = {
      Customer: {
        CustomerInfo: {
          BusinessPartnerNumber: apiData.bp,
          BankId: apiData.bankDetails?.BankId,
        },
      },
    };
    dispatch(storeCustomerData(customer));
  };
};

export const updateCreditCheck = () => {
  return (dispatch) => {
    const customer = {
      Customer: {
        CustomerInfo: {
          BPUpdated: false,
        },
      },
    };
    dispatch(updateCustomer(customer));
  };
};

export const storeCustomerData = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.Auth.sessionID) {
      return;
    }

    const actions = [];

    if (data.PlanInfo) {
      actions.push(
        updatePlan({
          PlanInfo: {
            ...state.PlanInfo,
            ...data.PlanInfo,
          },
        })
      );
    }
    if (data.Services) {
      actions.push(
        updateServices(data.Services ? [...data.Services] : state.Services)
      );
    }
    if (data.Property) {
      actions.push(
        updateProperty({
          PropertyInfo: {
            ...state.Property?.PropertyInfo,
            ...data.Property?.PropertyInfo,
          },
          MedicalInfo: {
            ...state.Property?.MedicalInfo,
            ...data.Property?.MedicalInfo,
          },
          MoveInfo: {
            ...state.Property?.MoveInfo,
            ...data.Property?.MoveInfo,
          },
        })
      );
    }
    if (data.Customer) {
      actions.push(
        updateCustomer({
          ...state.Customer,
          ...data.Customer,
          CustomerInfo: {
            ...state.Customer?.CustomerInfo,
            ...data.Customer?.CustomerInfo,
          },
        })
      );
    }
    if (data.PipedGasInfo) {
      actions.push(
        updatePipedGas({
          ...state.PipedGasInfo,
          ...data.PipedGasInfo,
        })
      );
    }
    if (data.LpgInfo) {
      const lpgData = {
        ...state.LpgInfo,
        ...data.LpgInfo,
      };
      if (lpgData.BottledGasUsage) {
        actions.push(updateBottledGas(lpgData));
      }
    }
    if (data.Payment) {
      actions.push(
        updatePayment({
          ...state.Payment,
          ...data.Payment,
        })
      );
    }
    if (data.BroadbandInfo) {
      actions.push(
        updateBroadband({
          ...state.BroadbandInfo,
          ...data.BroadbandInfo,
        })
      );
    }
    if (data.Confirmation) {
      actions.push(
        updateConfirmation({
          ...state.Confirmation,
          ...data.Confirmation,
        })
      );
    }
    if (data.AuthorizedPersonInfo) {
      actions.push(
        updateAuthorizedPerson(
          data.AuthorizedPersonInfo || state.AuthorizedPersonInfo
        )
      );
    }
    if (data.JointAccountHolderInfo) {
      actions.push(
        updateJointAccountHolder(
          data.JointAccountHolderInfo || state.JointAccountHolderInfo
        )
      );
    }

    if (data.JourneyChannel) {
      actions.push(
        updateJourneyChannel(data.JourneyChannel || state.JourneyChannel)
      );
    }

    if (data.JourneyType) {
      actions.push(updateJourneyType(data.JourneyType || state.JourneyType));
    }
    dispatch(actions);
    dispatch(isPendingSaveApiState());
  };
};

export const saveCustomerData = (data, submit = false) => {
  return (dispatch) => {
    dispatch(storeCustomerData(data));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(saveApiState({}, submit));
    }
  };
};

export const saveCustomerInfoForSaveForLater = (data) => {
  return (dispatch) => {
    dispatch(storeCustomerData(data));
    if (!process.env.NX_USE_FIXTURES) {
      dispatch(saveCustomerInfoSaveForLater());
    }
  };
};

export const saveForLaterRequest = () => {
  return {
    type: actionTypes.SAVE_FOR_LATER_SUBMITTING,
  };
};

export const saveForLaterSuccess = (successResponse, successMessage, state) => {
  return {
    type: actionTypes.SAVE_FOR_LATER_SUCCESS,
    successResponse,
    successMessage,
    state,
  };
};

export const saveForLaterFailure = (error) => {
  return {
    type: actionTypes.SAVE_FOR_LATER_ERROR,
    error,
  };
};

export const saveForLaterReset = () => {
  return {
    type: actionTypes.SAVE_FOR_LATER_INITIAL_STATE,
  };
};
