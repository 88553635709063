import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { cancellableQuery } from '../utils';
import { AxiosRequestConfig } from 'axios';
import {
  getBillingEligibility,
  BillingEligibilityItem,
  ElectricityRegistryICPDetailsVersion,
  GetBillingEligibilityVersion,
  GetBillingEligibilityPath,
} from '@contact/data-access';
import { useWebsphereElectricityRegistryICPDetails } from './useWebsphereElectricityRegistryICPDetails';

export type BillingEligibilityNameSmartTimeOfUse = 'SMART TOU';
export type BillingEligibilityNameWeeklyFortnightly = 'WEEKLY/FORTNIGHTLY BILLING';
export type BillingEligibilityNamePrepay = 'CONTACT PREPAY';
export type BillingEligibilityName =
  | BillingEligibilityNameSmartTimeOfUse
  | BillingEligibilityNameWeeklyFortnightly
  | BillingEligibilityNamePrepay;

export const BillingEligibilityNames: BillingEligibilityName[] = [
  'SMART TOU',
  'CONTACT PREPAY',
  'WEEKLY/FORTNIGHTLY BILLING',
];
export interface KnownBillingEligibilityItem extends BillingEligibilityItem {
  name: BillingEligibilityName;
}

export type BillingEligibilityMap = Record<BillingEligibilityName, boolean>;

export const DefaultBillingEligibilityMap: BillingEligibilityMap = {
  'SMART TOU': false,
  'CONTACT PREPAY': false,
  'WEEKLY/FORTNIGHTLY BILLING': false,
};

export function isKnownBillingEligibilityItem(
  input: BillingEligibilityItem
): input is KnownBillingEligibilityItem {
  return isBillingEligibilityName(input.name);
}

export function isBillingEligibilityName(
  value: string
): value is BillingEligibilityName {
  const names: string[] = BillingEligibilityNames;
  return names.includes(value);
}

function createBillingEligibilityMap(
  input: BillingEligibilityItem[]
): BillingEligibilityMap {
  return input.filter(isKnownBillingEligibilityItem).reduce(
    (map, input) => ({
      ...map,
      [input.name]: input.eligible,
    }),
    DefaultBillingEligibilityMap
  );
}
export function useBillingEligibilityData(
  version: GetBillingEligibilityVersion,
  versionEleIcp: ElectricityRegistryICPDetailsVersion,
  { icp, services }: BillingEligibilityOptions,
  baseAxiosConfig?: Partial<AxiosRequestConfig>
) {
  const {
    data: BaseElectricityICP,
  } = useWebsphereElectricityRegistryICPDetails(
    versionEleIcp,
    icp,
    baseAxiosConfig
  );
  return useQuery(
    [GetBillingEligibilityPath[version], icp, services],
    () =>
      cancellableQuery(
        async (extraOptions): Promise<BillingEligibilityItem[]> => {
          const { Meters, ...restElectricityICP } = BaseElectricityICP!;
          const { data } = await getBillingEligibility(
            version,
            {
              Services: services,
              ElectricityICP: {
                ...restElectricityICP,
                Meters: Meters.map((meter) => ({
                  ...meter,
                  Ami: `${meter.Ami}` === 'true',
                  Unm: `${meter.Unm}` === 'true',
                })),
              },
            },
            {
              ...baseAxiosConfig,
              ...extraOptions,
            }
          );
          return data;
        }
      ),
    {
      enabled: !!(BaseElectricityICP?.IcpNumber === icp && icp),
    }
  );
}

export interface BillingEligibilityOptions {
  icp: string;
  services: string[];
}

export function useBillingEligibility(
  version: GetBillingEligibilityVersion,
  versionEleIcp: ElectricityRegistryICPDetailsVersion,
  options: BillingEligibilityOptions,
  baseAxiosConfig?: Partial<AxiosRequestConfig>
) {
  const isElectricityInServiceList = options.services.includes('ELEC');
  const { data } = useBillingEligibilityData(
    version,
    versionEleIcp,
    options,
    baseAxiosConfig
  );
  return useMemo(() => {
    if (!isElectricityInServiceList || !data) {
      return DefaultBillingEligibilityMap;
    }
    return createBillingEligibilityMap(data || []);
  }, [isElectricityInServiceList, data]);
}
