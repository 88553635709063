import { useMemo } from "react";
import { useSiteCoreContent } from "@contact/data-access-hooks";
import { BillComparePromoCode } from "@contact/ui-bill-compare";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";

export type ModemType = "fibre-modem" | "phone-modem" | "wbb-modem";

export const SITE_CORE_CONTENT_CUSTOMER_URL =
  "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-react";
export const SITE_CORE_CONTENT_CSR_JOIN_URL =
  "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-csr-react";
export const SITE_CORE_CONTENT_CSR_MOVE_URL =
  "ijoin-react-app%2Fhome%2Fresidential%2Fmake-changes-csr-react";

const getSiteCoreUrl = (isCSR: boolean, journeyType: string) =>
  isCSR
    ? journeyType === "Move"
      ? SITE_CORE_CONTENT_CSR_MOVE_URL
      : SITE_CORE_CONTENT_CSR_JOIN_URL
    : SITE_CORE_CONTENT_CUSTOMER_URL;

export const useSiteCoreContinueButton = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () => ({
      data:
        data?.sitecore?.route?.placeholders?.["jss-main"]?.find(
          (item) => item.componentName === "ContinueButton"
        )?.fields || {},
      isLoading,
    }),
    [data, isLoading]
  );
};

export const useSiteCoreBBOffers = (isCSR: boolean, journeyType: string) => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () => ({
      data: isSuccess
        ? data?.sitecore?.route?.placeholders?.["jss-main"]
            ?.find((item) => item?.componentName === "PlansAndServices")
            ?.placeholders?.["broadband-placeholder"]?.find(
              (item) => item.componentName === "BroadbandContainer"
            )
            ?.placeholders?.["broadband-tiles-placeholder"]?.map((item) => ({
              Id: item.fields.BBExistingTile.fields.ApiId.value,
              Type: item.fields.BBExistingTile.fields.Type.value,
              DataCapDesc: item.fields.BBExistingTile.fields.DataCapDesc.value,
              BYOModemAllowed:
                item.fields.BBExistingTile.fields.BYOModemAllowed.value,
              NetworkDesc: item.fields.BBExistingTile.fields.NetworkDesc.value,
              OriginalPrice: item.fields.BBExistingTile.fields.Price.value,
              BundlePrice: item.fields.BBExistingTile.fields.Price.value,
              Name: item.fields.BBExistingTile.fields.OptionName.value,
              DownloadSpeed:
                item.fields.BBExistingTile.fields.DownloadSpeed.value,
              UploadSpeed: item.fields.BBExistingTile.fields.UploadSpeed.value,
              Label: "",
              Code: "",
              LearnMoreDescription: item.fields.BBLearnMoreDescription.value,
            }))
        : [],
      isLoading,
    }),
    [data, isLoading, isSuccess]
  );
};

export const useSiteCoreBCPromoCode = (
  isCSR: boolean,
  journeyType: string
): { data?: BillComparePromoCode; isLoading: boolean } => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(() => {
    const promoCodeContent = isSuccess
      ? data?.sitecore?.route?.placeholders?.["jss-main"]?.find(
          (item) =>
            item.componentName === "PromoCodeBanner" &&
            item.fields.Label.value === "BC"
        )?.fields
      : undefined;
    return {
      data: promoCodeContent && {
        promoCode: promoCodeContent.MappedPromo.fields.PromoCodeName.value,
        promoText: promoCodeContent.PromoText.value,
        planRestrictions: promoCodeContent.MappedPromo.fields.PlanRestricted.map(
          (item) => item.fields.PlanId.value
        ),
      },
      isLoading,
    };
  }, [data, isLoading, isSuccess]);
};

export const useSiteCorePlaceholders = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () => ({
      data:
        isSuccess && data
          ? data.sitecore?.route?.placeholders?.["jss-main"]?.find(
              (item) => item.componentName === "PlansAndServices"
            )?.placeholders?.["plans-filter-placeholder"]
          : undefined,
      isLoading,
    }),
    [data, isLoading, isSuccess]
  );
};

export const useSiteCoreRatesContent = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data: placeholders, isLoading } = useSiteCorePlaceholders(
    isCSR,
    journeyType
  );

  return useMemo(
    () => ({
      data:
        placeholders &&
        placeholders.find((item) => item.componentName === "EnergyRates")
          ?.fields,
      isLoading,
    }),
    [isLoading, placeholders]
  );
};

export const useSiteCoreAllPlanOffers = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data: placeholders, isLoading } = useSiteCorePlaceholders(
    isCSR,
    journeyType
  );

  return useMemo(() => {
    const plans =
      placeholders &&
      placeholders.filter((item) => item.componentName === "PlanFilter");

    const plansOffers = plans && plans[0].placeholders["plans-placeholder"];

    return {
      data: plansOffers || [],
      isLoading,
    };
  }, [isLoading, placeholders]);
};

export const useSitecoreModemInfo = (
  modemType: ModemType | undefined,
  isCSR: boolean,
  journeyType: string
) => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(() => {
    const modemInfo = data?.sitecore?.route?.placeholders?.["jss-main"]
      ?.find((item) => item?.componentName === "PlansAndServices")
      ?.placeholders?.["messaging-placeholder"]?.find(
        (item) => item.componentName === "Messaging"
      )
      ?.fields?.Messages?.find((item) => item?.fields?.Id?.value === modemType);

    return {
      data: modemInfo
        ? {
            image: modemInfo?.fields?.Color?.value,
            desc: modemInfo?.fields?.Msg?.value,
          }
        : undefined,
      isSuccess,
      isLoading,
    };
  }, [data, isSuccess, isLoading, modemType]);
};

export const useSitecoreModemPrices = (isCSR: boolean, journeyType: string) => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(() => {
    const modems = data?.sitecore?.route?.placeholders?.["jss-main"]?.find(
      (item) =>
        item?.componentName === "ContentBlock" &&
        item?.fields?.Heading?.value === "modems"
    )?.fields?.Content?.value;

    return {
      data: modems ? JSON.parse(modems) : modems,
      isSuccess,
      isLoading,
    };
  }, [data, isSuccess, isLoading]);
};

export const useSitecoreDisclaimer = (isCSR: boolean, journeyType: string) => {
  const { data, isSuccess, isLoading } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () => ({
      data: isSuccess
        ? data?.sitecore?.route?.placeholders?.["jss-main"]
            ?.find((item) => item.componentName === "PlansAndServices")
            ?.placeholders?.["messaging-placeholder"]?.find(
              (item) => item.componentName === "Messaging"
            )
            ?.fields.Messages.find(
              (item) => item.fields.Id.value === "bcdisclaimer"
            )
        : undefined,
      isSuccess,
      isLoading,
    }),
    [data, isSuccess, isLoading]
  );
};

export const useSitecorePHFeeValue = (isCSR: boolean, journeyType: string) => {
  const { data } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () =>
      data?.sitecore?.route?.placeholders?.["jss-main"]
        ?.find((item) => item?.componentName === "PlansAndServices")
        ?.placeholders?.["messaging-placeholder"]?.find(
          (item) => item?.componentName === "Messaging"
        )
        ?.fields?.Messages?.find(
          (item) => item?.fields?.Id?.value === "modemdelivery"
        )?.fields?.Msg?.value,
    [data?.sitecore?.route?.placeholders]
  );
};

export const useSitecoreFibreModemList = (
  isCSR: boolean,
  journeyType: string
) => {
  const { data } = useSiteCoreContent(
    "v2",
    getSiteCoreUrl(isCSR, journeyType),
    baseAxiosConfig
  );

  return useMemo(
    () =>
      data?.sitecore?.route?.placeholders?.["jss-main"]
        ?.find((item) => item?.componentName === "PlansAndServices")
        ?.placeholders?.["messaging-placeholder"]?.find(
          (item) => item?.componentName === "Messaging"
        )
        ?.fields?.Messages?.find(
          (item) => item?.fields?.Id?.value === "fibre-modem-list"
        )?.fields?.Msg?.value,
    [data?.sitecore?.route?.placeholders]
  );
};
