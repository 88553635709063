import { AxiosRequestConfig } from 'axios';
import {
  FourthTrimesterCreatePath,
  FourthTrimesterCreateVersion,
} from '../apiPaths';
import { baseInstance } from '../base-service';
import { CreateTrimesterParams, CreateTrimesterResponse } from '../models';

export const createTrimester = (
  version: FourthTrimesterCreateVersion,
  token?: string,
  params?: CreateTrimesterParams,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<CreateTrimesterResponse>(
    FourthTrimesterCreatePath[version],
    params,
    {
      headers: {
        session: token,
      },
    }
  );
};
