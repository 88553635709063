import { useQuery, UseQueryOptions } from 'react-query';
import {
  getInvoice,
  getInvoicePath,
  InvoiceApiResponse,
  InvoiceVersion,
} from '@contact/data-access';
import { useAxiosConfig } from './context';
import { AxiosRequestConfig } from 'axios';
import { cancellableQuery } from '../utils';
import { DownloadOptions, useDownload } from './download';

export const useInvoice = (
  version: InvoiceVersion,
  invoiceId: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<InvoiceApiResponse>
) => {
  const config = useAxiosConfig(providedConfig);
  return useQuery<InvoiceApiResponse>(
    [getInvoicePath(version, invoiceId), invoiceId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getInvoice(version, invoiceId, {
          ...config,
          ...extraOptions,
        });
        return data;
      }),
    options
  );
};

export function useInvoiceAttachment(
  version: InvoiceVersion,
  invoiceId: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<string>
) {
  const config = useAxiosConfig(providedConfig);
  return useQuery(
    [getInvoicePath(version, invoiceId), invoiceId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getInvoice(version, invoiceId, {
          ...config,
          ...extraOptions,
        });
        if (data.status === 2204)
          throw new Error('Failed to download PDF, not yet available');
        if (!data.attachment) throw new Error('No attachment provided');
        return data.attachment;
      }),
    // @ts-ignore
    options
  );
}

export function useInvoiceDownload(
  version: InvoiceVersion,
  invoiceId: string,
  providedConfig?: AxiosRequestConfig,
  options?: UseQueryOptions<void> & Pick<DownloadOptions, 'open'>
) {
  const download = useDownload();
  const config = useAxiosConfig(providedConfig);
  return useQuery(
    [getInvoicePath(version, invoiceId), invoiceId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getInvoice(version, invoiceId, {
          ...config,
          ...extraOptions,
        });
        if (data.status === 2204)
          throw new Error('Failed to download PDF, not yet available');
        if (!data.attachment) throw new Error('No attachment provided');
        await download({
          base64: data.attachment,
          mime: 'application/pdf', // application/pdf breaks some browsers, e.g. iOS
          name: 'bill.pdf',
          open: options?.open,
        });
      }),
    // @ts-ignore
    {
      enabled: false,
      suspense: false,
      ...options,
    }
  );
}
