import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { getState, StatePath, StateVersion } from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useSessionState = (
  version: StateVersion,
  sessionId: string,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [StatePath[version], sessionId],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getState(version, sessionId, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    { enabled: false }
  );
