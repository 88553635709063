import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  getTrimesterOfferEligibility,
  TrimesterOfferEligibilityApiResponse,
  EligibilityRequest,
  FourthTrimesterOfferEligibilityVersion,
  FourthTrimesterOfferEligibilityPath,
} from '@contact/data-access';

export const useTrimesterEligibility = (
  version: FourthTrimesterOfferEligibilityVersion,
  params: EligibilityRequest,
  isSubmitted: boolean,
  options?: Partial<AxiosRequestConfig>
) => {
  return useQuery<TrimesterOfferEligibilityApiResponse>(
    [FourthTrimesterOfferEligibilityPath[version], params],
    async () => {
      const { data } = await getTrimesterOfferEligibility(
        version,
        params,
        options
      );
      return data;
    },
    {
      enabled: isSubmitted,
    }
  );
};
