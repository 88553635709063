import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  BroadbandProvidersPath,
  BroadbandProvidersVersion,
  getBroadbandProviders,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useBroadbandProviders = (
  version: BroadbandProvidersVersion,
  options?: Partial<AxiosRequestConfig>
) =>
  useQuery(
    [BroadbandProvidersPath[version]],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await getBroadbandProviders(version, {
          ...options,
          ...extraOptions,
        });
        return data;
      }),
    {
      enabled: true,
    }
  );
